import React, { useEffect } from "react";

const Dropdown = ({
    options,
    selectedOption,
    setSelectedOption,
    showDropdown,
    setShowDropdown,
    name,
    handleChange, 
}) => {

    // Function to handle selection
    const handleSelection = (value) => {
        setSelectedOption(value); 
        setTimeout(() => {
            setShowDropdown(false); // Close the dropdown after a short delay
        }, 100); // Short delay to avoid re-triggering
        handleChange({ target: { name: name, value } });
    };

    return (
        <>
            {showDropdown && (
                <div className="absolute left-0 mt-2 w-48 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10" onClick={(e) => e.stopPropagation()}>
                    {options.map((option) => (
                        <button
                            key={option.value}
                            onClick={() => handleSelection(option.value)}
                            className={`block w-full text-left px-4 py-2 text-sm rounded-lg ${selectedOption === option.value
                                ? "bg-sky-100 text-sky-500 hover:bg-sky-200 hover:text-sky-600"
                                : "text-black hover:bg-gray-100 hover:text-gray-800"
                                }`}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </>
    );
};

export default Dropdown;
