import React, { useState } from "react";
import TitleWithAddButton from "./TitleWithAddButton";
import File_dock from "../assets/Images/PatientProfile/File_dock.png";
import moment from "moment";
import ModalComponent from "./Modal";
import seeDocument from "../assets/Images/PatientProfile/seeDocument.png";
import toast from "react-hot-toast";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import PdfComp from "./PdfComp";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function MedicalCondition({ medicalCondition, patient }) {
  if (medicalCondition?.length === 0) {
    return (
      <div>
        <TitleWithAddButton title="Pre- Medical Condition" showAddButton={true} />
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }

  return (
    <div>
      <TitleWithAddButton title="Pre- Medical Condition" showAddButton={true} />
      <div className="flex flex-row flex-wrap gap-4 mt-5">
        {medicalCondition.map((item) => (
          <div key={item?.PMCID} className="px-2.5 py-1 bg-sky-100 rounded-xl">
            <div className="text-sky-400 text-sm font-semibold font-poppins">
              {item?.Name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MedicalCondition;
