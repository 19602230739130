import React, { useState } from "react";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractLine } from "react-icons/ri";
import sortIcon from "../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import ModalComponent from "../components/Modal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addServicesCardRate,
  deleteServicesCardRate,
  updateServicesCardRate,
} from "../services/operations/masterDataAPI";
import editIcon from "../assets/Images/MasterDataManagement/editIcon.png";
import deleteIcon from "../assets/Images/MasterDataManagement/deleteIcon.png";

function ServicesAndRateCard({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { serviceCardRates, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [showAddService, setShowAddService] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isChecked, setIsChecked] = useState("time");
  const [selectedServiceCard, setSelectedServiceCard] = useState({});
  const [formData, setFormData] = useState({
    Name: "",
    Type: "",
    Price: "",
    Currency: "CHF",
    Quantity: 1,
    Times: 5,
    TimeUnit: "minutes",
  });
  const [errors, setErrors] = useState({
    Name: "",
    Type: "",
    Price: "",
    Currency: "",
    Quantity: "",
    Times: "",
    TimeUnit: "",
  });
  const [itemsToShow, setItemsToShow] = useState(7);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortCriteria, setSortCriteria] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleRadioChange = (event) => {
    setIsChecked(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSortChange = (event) => {
    const newSortCriteria = event.target.value;
    if (sortCriteria === newSortCriteria) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortCriteria(newSortCriteria);
      setSortOrder("asc");
    }
  };

  const sortServices = (services) => {
    return services.sort((a, b) => {
      let comparison = 0;
      if (sortCriteria === "Name") {
        comparison = a.Name.localeCompare(b.Name);
      } else if (sortCriteria === "Price") {
        comparison = a.Price - b.Price;
      } else if (sortCriteria === "SRCID") {
        comparison = a.SRCID - b.SRCID;
      }
      return sortOrder === "asc" ? comparison : -comparison;
    });
  };

  const filteredServices = sortServices(
    serviceCardRates?.filter(
      (service) =>
        service.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.ReimbursementCode.toLowerCase().includes(
          searchTerm.toLowerCase()
        )
    )
  );

  const increaseItems = () => {
    setItemsToShow(itemsToShow * 2);
  };

  const decreaseItems = () => {
    if (itemsToShow > 7) {
      setItemsToShow(itemsToShow / 2);
    }
  };

  const submitHandler = async (event) => {
    // event.preventDefault();

    const mandatoryFields = ["Name", "Price", "Currency"];

    // Add conditional fields based on the radio button selection
    if (isChecked === "time") {
      mandatoryFields.push("Times", "TimeUnit");
    } else if (isChecked === "quantity") {
      mandatoryFields.push("Quantity");
    }

    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", newErrors); // Use newErrors instead of errors to log the current state of validation errors
      return;
    }

    let body = {
      Name: formData.Name,
      Price: parseInt(formData.Price),
      Currency: formData.Currency,
      ...(isChecked === "time" && {
        Times: formData.Times,
        TimeUnit: formData.TimeUnit,
      }),
      ...(isChecked === "quantity" && { Quantity: formData.Quantity }),
    };
    dispatch(addServicesCardRate(token, body));
    handleCloseModal();
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "Price":
        maxLength = 6;
        break;
      case "Quantity":
        maxLength = 3;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  const handleCloseModal = () => {
    setShowAddService(false);
    setIsChecked("time");
    setFormData({
      Name: "",
      Type: "",
      Price: "",
      Currency: "CHF",
      Quantity: 1,
      Times: 5,
      TimeUnit: "minutes",
    });
    setErrors({
      Name: "",
      Type: "",
      Price: "",
      Currency: "",
      Quantity: "",
      Times: "",
      TimeUnit: "",
    });
    setSelectedServiceCard({});
  };

  const handleEditService = (service) => {
    setSelectedServiceCard(service);
    setIsEditing(true);
    setFormData({
      Name: service.Name,
      Price: service.Price,
      Currency: service.Currency,
      Quantity: service.Quantity,
      Times: service.Times,
      TimeUnit: service.TimeUnit,
    });
    if (service.Quantity === null) {
      setIsChecked("time");
    } else {
      setIsChecked("quantity");
    }
    setShowAddService(true);
  };

  const handleUpdateService = () => {
    dispatch(
      updateServicesCardRate(token, selectedServiceCard.SRCID, formData)
    );
    setIsEditing(false);
    handleCloseModal();
  };

  const handleDeleteService = () => {
    dispatch(deleteServicesCardRate(token, selectedServiceCard.SRCID));
    setSelectedServiceCard({});
    setShowDeleteModal(false);
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <>
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        outSideModalPress={false}
      >
        <div className="">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center text-black text-xl font-bold font-poppins">
              Are you Sure?
            </div>
            <div className=" text-center text-sky-400 text-xl font-semibold font-poppins mt-8">
              This will delete {selectedServiceCard?.Name} from <br /> Service
              Rate Card List from your Clinic.
            </div>
            <div className="flex flex-row items-center justify-center space-x-7 mt-8 w-full">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-white rounded-2xl border border-sky-400 w-1/3 py-2 shadow-2xl active:scale-95"
              >
                <div className="text-center text-sky-400 text-xl font-bold font-poppins">
                  Back
                </div>
              </button>
              <button
                onClick={() => handleDeleteService()}
                className="bg-white rounded-2xl border border-rose-500 w-1/3 py-2 shadow-2xl active:scale-95"
              >
                <div className="text-center text-rose-500 text-xl font-bold font-poppins">
                  Delete
                </div>
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showAddService}
        handleClose={() => handleCloseModal()}
        outSideModalPress={false}
      >
        <div className="w-[30vw]">
          <div className="text-black text-base font-semibold font-poppins">
            {isEditing ? "Edit Service" : "Add More Services"}
          </div>
          <div className="border border-b-0.5 border-gray-200 my-3"></div>
          <div className="mt-8">
            {/* Name of Services  */}
            <div className="mb-4">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Name of Services
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Name"
                  maxLength={30}
                  value={formData.Name}
                  onChange={handleOnChange}
                  placeholder="Enter Services here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Name ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
                <p className="text-sky-400 text-right text-xs font-semibold font-poppins my-2 ">
                  Maximum 30 character
                </p>
              </label>
            </div>
            <div className="flex flex-row justify-between">
              {/* Price of Services  */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Price of Services
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="number"
                    name="Price"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    value={formData.Price}
                    onChange={handleOnChange}
                    placeholder="Enter Price here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Price ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* Measured By */}
              <div className="mb-6">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-6">
                  Measured by?
                  <sup className="text-red-600">*</sup>
                </p>
                <div className="flex flex-row items-center space-x-4">
                  <div className="flex items-center justify-start">
                    <label
                      className="relative flex cursor-pointer items-center"
                      htmlFor="time"
                      // data-ripple-dark="true"
                    >
                      <input
                        type="radio"
                        value="time"
                        checked={isChecked === "time"}
                        onChange={handleRadioChange}
                        className="before:content[''] peer relative w-5 h-5 border-2 border-sky-400
             cursor-pointer appearance-none transition-all before:absolute
              before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8
               before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
               before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
                checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
                hover:before:opacity-10"
                      />
                      <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth={1}
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </label>

                    <p className="text-black text-md font-normal font-poppins ml-2">
                      Time
                    </p>
                  </div>
                  <div className="flex items-center justify-start">
                    <label
                      className="relative flex cursor-pointer items-center"
                      htmlFor="quantity"
                      // data-ripple-dark="true"
                    >
                      <input
                        type="radio"
                        value="quantity"
                        checked={isChecked === "quantity"}
                        onChange={handleRadioChange}
                        className="before:content[''] peer relative w-5 h-5 border-2 border-sky-400
             cursor-pointer appearance-none transition-all before:absolute
              before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8
               before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
               before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
                checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
                hover:before:opacity-10"
                      />
                      <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth={1}
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </label>

                    <p className="text-black text-md font-normal font-poppins ml-2">
                      Quantity
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {isChecked === "time" ? (
              <div className="grid grid-cols-3 gap-1 w-2/3">
                {TIME.map((item) => (
                  <div
                    className="cursor-pointer"
                    key={item.ID}
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        Times: item.TIME,
                      }))
                    }
                  >
                    <div
                      className={`w-20 h-8 px-4 py-2 ${
                        formData.Times === item.TIME
                          ? "bg-sky-100 text-sky-400"
                          : "bg-gray-100 text-neutral-400"
                      } rounded-2xl justify-center items-center gap-2 inline-flex`}
                    >
                      <div className=" text-xs font-semibold font-poppins">
                        {item.TIME} mins
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Enter Quantity
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="number"
                    name="Quantity"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    value={formData.Quantity}
                    onChange={handleOnChange}
                    placeholder="Enter Quantity here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Quantity ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
            )}
          </div>
          <div className="flex justify-center items-center pt-5">
            <button
              onClick={() => {
                isEditing ? handleUpdateService() : submitHandler();
              }}
              className={`w-full px-11 py-3 text-sky-400 text-sm font-semibold font-poppins shadow bg-sky-100 rounded-lg text-center active:scale-95`}
            >
              {isEditing ? "Update" : "Add Service"}
            </button>
          </div>
        </div>
      </ModalComponent>
      <div className="bg-white min-h-full p-6 scroll-smooth">
        {/* Header */}
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-4">
            <button
              className="active:scale-90"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaChevronLeft className="h-5" />
            </button>

            <div>
              <h1 className="text-black text-lg font-bold font-poppins">
                Services and Rate Card
              </h1>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                Here are list of services and their respective rate card
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-5">
            <label className=" w-80 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
              <IoIosSearch className="text-neutral-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search here"
                value={searchTerm}
                onChange={handleSearchChange}
                className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
              />
            </label>
            <button
              onClick={() => {
                setShowAddService(true);
              }}
              className="px-3 py-2 bg-sky-500 rounded shadow justify-center items-center gap-2.5 flex cursor-pointer active:scale-95 duration-200"
            >
              <FaPlus className="text-white text-sm" />
              <div className="text-white text-sm font-normal font-poppins">
                Add More Services
              </div>
            </button>
          </div>
        </div>

        <div className="flex mt-8">
          <div className="flex items-center justify-center space-x-2 flex-1">
            <div className="text-black text-sm font-semibold font-poppins">
              ID
            </div>
            <img
              onClick={() => setSortCriteria("SRCID")}
              src={sortIcon}
              alt=""
              className="w-6 h-6 cursor-pointer"
            />
          </div>
          <div className="flex items-center justify-center space-x-2 flex-1">
            <div className="text-black text-sm font-semibold font-poppins">
              Name of Services
            </div>
            <img
              onClick={() => setSortCriteria("Name")}
              src={sortIcon}
              alt=""
              className="w-6 h-6 cursor-pointer"
            />
          </div>
          <div className="flex items-center justify-center space-x-2 flex-1">
            <div className="text-black text-sm font-semibold font-poppins">
              Service Codes
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
          <div className="flex items-center justify-center space-x-2 flex-1">
            <div className="text-black text-sm font-semibold font-poppins">
              Price of Services
            </div>
            <img
              onClick={() => setSortCriteria("Price")}
              src={sortIcon}
              alt=""
              className="w-6 h-6 cursor-pointer"
            />
          </div>
          <div className="flex items-center justify-center flex-1">
            <div className="text-black text-sm font-semibold font-poppins">
              Manage
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
        </div>
        <div className="border border-b-0.5 border-gray-200 my-2"></div>
        <div className="flex flex-col space-y-3 divide-y-2 divide-gray-100">
          {filteredServices.slice(0, itemsToShow).map((item) => (
            <div key={item.SRCID} className="flex py-4 items-center">
              <div className="text-black text-sm font-normal font-poppins flex-1 flex items-center justify-center">
                {item?.SRCID}
              </div>
              <div className="text-black text-sm font-normal font-poppins flex-1 flex items-center justify-center">
                {item?.Name}
              </div>
              <div className="text-black text-sm font-normal font-poppins flex-1 flex items-center justify-center">
                {item?.ReimbursementCode}
              </div>
              <div className="text-black text-sm font-normal font-poppins flex-1 flex items-center justify-center">
                {item?.Currency === "USD" ? "$" : "CHF"} {item?.Price}
              </div>
              <div className="flex-1 flex flex-col items-center justify-center space-y-2">
                <button
                  onClick={() => handleEditService(item)}
                  className="w-20 h-8 rounded-2xl border border-sky-500 flex flex-row items-center justify-center space-x-1 active:scale-95"
                >
                  <img
                    src={editIcon}
                    alt=""
                    className="w-4 h-4 object-contain"
                  />
                  <div className="text-sky-400 text-xs font-normal font-poppins">
                    Edit
                  </div>
                </button>
                <button
                  onClick={() => {
                    setSelectedServiceCard(item);
                    setShowDeleteModal(true);
                  }}
                  className="w-20 h-8 rounded-2xl border border-rose-500 flex flex-row items-center justify-center space-x-1 active:scale-95"
                >
                  <img
                    src={deleteIcon}
                    alt=""
                    className="w-4 h-4 object-contain"
                  />
                  <div className="text-rose-500 text-xs font-normal font-poppins">
                    Delete
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="border border-b-0.5 border-gray-200 my-5"></div>
        <footer className="flex flex-row items-center justify-between">
          <div className="text-zinc-500 text-sm font-normal font-poppins">
            Showing {Math.min(itemsToShow, serviceCardRates.length)} items per
            page
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                itemsToShow < serviceCardRates.length
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={increaseItems}
              disabled={itemsToShow >= serviceCardRates.length}
            >
              <IoMdAdd />
            </button>
            <div className="text-center text-black text-xs font-normal font-poppins bg-gray-100 rounded w-12 h-10 flex items-center justify-center ">
              1 - {Math.min(itemsToShow, serviceCardRates.length)}
            </div>
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                itemsToShow > 7
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={decreaseItems}
              disabled={itemsToShow <= 7}
            >
              <RiSubtractLine />
            </button>
          </div>
        </footer>
      </div>
    </>
  );
}

export default ServicesAndRateCard;

const TIME = [
  {
    ID: 1,
    TIME: 5,
  },
  {
    ID: 2,
    TIME: 10,
  },
  {
    ID: 3,
    TIME: 15,
  },
];
