import { createSlice } from "@reduxjs/toolkit";

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState: {
    history: [], // Initialize with an empty array
  },
  reducers: {
    addBreadcrumb: (state, action) => {
      const { path, name, state: breadcrumbState } = action.payload;

      // If the name contains "Dashboard", clear the history and add only the Dashboard breadcrumb
      if (name.includes("Dashboard")) {
        state.history = [{ path, name, state: breadcrumbState }];
      } else {
        // Remove any existing breadcrumb with the same path
        state.history = state.history.filter(b => b.path !== path);

        // Add the new breadcrumb
        state.history = [...state.history, { path, name, state: breadcrumbState }];

        // Limit history to a maximum of 5 items
        if (state.history.length > 5) {
          state.history.shift(); // Remove the oldest item (left side)
        }
      }
    },
    resetBreadcrumbs: (state) => {
      state.history = []; // Clear the breadcrumbs
    },
  },
});

export const { addBreadcrumb, resetBreadcrumbs } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
