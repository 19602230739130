import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractLine } from "react-icons/ri";
import sortIcon from "../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import ModalComponent from "../components/Modal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addServicesCardRate,
  createClinicBranch,
  deleteClinicBranches,
  deleteServicesCardRate,
  updateServicesCardRate,
} from "../services/operations/masterDataAPI";
import editIcon from "../assets/Images/MasterDataManagement/editIcon.png";
import deleteIcon from "../assets/Images/MasterDataManagement/deleteIcon.png";
import { HiOutlineDotsVertical } from "react-icons/hi";
import manageIcon from "../assets/Images/Branches/manageIcon.png";
import noBranchesFound from "../assets/Images/Branches/branch.png";

function BranchManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allBranches, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [branches, setBranches] = useState(allBranches);
  const [itemsToShow, setItemsToShow] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddBranchModal, setShowAddBranchModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [formData, setFormData] = useState({
    Name: "",
    Type: "",
    Address: "",
    Latitude: "",
    Longitude: "",
  });

  const [errors, setErrors] = useState({
    Name: "",
    Type: "",
    Address: "",
    Latitude: "",
    Longitude: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    if (allBranches.length > 0) {
      setBranches(allBranches);
    }
  }, [allBranches]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredServices = branches?.filter((service) =>
    service.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const increaseItems = () => {
    setItemsToShow(itemsToShow * 2);
  };

  const decreaseItems = () => {
    setItemsToShow(itemsToShow / 2);
  };

  const getGeolocation = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks`
      );
      const data = await response.json();

      if (data.results && data.results[0]?.geometry?.location) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("Geolocation:", lat, lng);
        return { lat, lng };
      } else {
        console.error("Geolocation data not found in API response");
        return null;
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const mandatoryFields = ["Name", "Type", "Address"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    const geolocation = await getGeolocation(formData.Address);
    if (geolocation !== null) {
      const { lat, lng } = geolocation;
      const updatedFormData = {
        ...formData,
        Latitude: lat,
        Longitude: lng,
      };

      setFormData(updatedFormData);

      const basicData = {
        Name: updatedFormData.Name,
        Type: updatedFormData.Type,
        Address: updatedFormData.Address,
        Latitude: updatedFormData.Latitude,
        Longitude: updatedFormData.Longitude,
      };

      dispatch(createClinicBranch(token, basicData));
      setShowAddBranchModal(false);
      setFormData({
        Name: "",
        Type: "",
        Address: "",
        Latitude: "",
        Longitude: "",
      });
      setErrors({
        Name: "",
        Type: "",
        Address: "",
        Latitude: "",
        Longitude: "",
      });
    } else {
      toast.error("Please provide a valid address.");
      setErrors((prev) => ({
        ...prev,
        Address: "Invalid address",
      }));
    }
  };

  const handleDeleteService = (id) => {
    dispatch(deleteClinicBranches(token, id));
    // dispatch(deleteClinicBranches(token, selectedBranch.BranchID));
    // setSelectedBranch({});
    // setShowDeleteModal(false);
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (branches.length === 0) {
    return (
      <div className="py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
        <ModalComponent
          show={showAddBranchModal}
          handleClose={() => {
            setShowAddBranchModal(false);
            setFormData({
              Name: "",
              Type: "",
              Address: "",
              Latitude: "",
              Longitude: "",
            });
            setErrors({
              Name: "",
              Type: "",
              Address: "",
              Latitude: "",
              Longitude: "",
            });
          }}
          outSideModalPress={false}
        >
          <div className="w-[30vw]">
            <div className="flex items-center justify-between">
              <div className="text-black text-base font-semibold font-poppins">
                Add a New Clinic Branch
              </div>
            </div>
            <div className="border border-b-1 border-gray-200 my-6"></div>

            {/* Input field */}
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col justify-start items-center gap-4 mt-4">
                {/* Name of Clinics  */}
                <div className="w-full">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                      Name of Clinic
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="text"
                      name="Name"
                      maxLength={20}
                      value={formData.Name}
                      onChange={handleInputChange}
                      placeholder="Enter name of clinic here"
                      className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                        errors.Name ? "border-red-500" : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>

                {/* Type of Clinic */}
                <div className="w-full">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                      Type of Clinic
                      <sup className="text-red-600">*</sup>
                    </p>
                    <select
                      id="Type"
                      name="Type"
                      value={formData.Type}
                      onChange={handleInputChange}
                      className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                        errors.Type ? "border-red-500" : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    >
                      <option value="">Select Type of Clinic</option>
                      <option value="Pediatric Clinics">
                        Pediatric Clinics
                      </option>
                      <option value="Community Health Centers">
                        Community Health Centers
                      </option>
                      <option value="Urgent Care Clinics">
                        Urgent Care Clinics
                      </option>
                      <option value="Dental Clinics">Dental Clinics</option>
                    </select>
                  </label>
                </div>

                {/* Address */}
                <div className="w-full">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                      Address
                      <sup className="text-red-600">*</sup>
                    </p>
                    <textarea
                      id="Address"
                      name="Address"
                      value={formData.Address}
                      onChange={handleInputChange}
                      placeholder="Enter address here"
                      className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                        errors.Address ? "border-red-500" : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    ></textarea>
                  </label>
                </div>
              </div>

              <div className="flex justify-center items-center w-full mt-6">
                <button
                  type="submit"
                  className="px-6 py-4 bg-[#E5F2FF] rounded-lg w-80 flex justify-center items-center cursor-pointer active:scale-96"
                >
                  <div className="text-[#3CA5DC] text-sm font-semibold font-poppins">
                    Confirm
                  </div>
                </button>
              </div>
            </form>
          </div>
        </ModalComponent>
        <div className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center h-full p-20">
          <img
            src={noBranchesFound}
            alt=""
            className="w-[533px] h-72 object-contain"
          />
          <div className="text-black text-xl font-bold font-Inter">
            Add Branches to view them on this screen
          </div>
          <button
            className="w-1/2 mx-auto bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80"
            onClick={() => {
              setShowAddBranchModal(true);
            }}
          >
            Add New Branch
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 scroll-smooth min-h-full">
      <ModalComponent
        show={showAddBranchModal}
        handleClose={() => {
          setShowAddBranchModal(false);
          setFormData({
            Name: "",
            Type: "",
            Address: "",
            Latitude: "",
            Longitude: "",
          });
          setErrors({
            Name: "",
            Type: "",
            Address: "",
            Latitude: "",
            Longitude: "",
          });
        }}
        outSideModalPress={false}
      >
        <div className="w-[30vw]">
          <div className="flex items-center justify-between">
            <div className="text-black text-base font-semibold font-poppins">
              Add a New Clinic Branch
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 my-6"></div>

          {/* Input field */}
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-start items-center gap-4 mt-4">
              {/* Name of Clinics  */}
              <div className="w-full">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Name of Clinic
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="Name"
                    maxLength={20}
                    value={formData.Name}
                    onChange={handleInputChange}
                    placeholder="Enter name of clinic here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Name ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* Type of Clinic */}
              <div className="w-full">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Type of Clinic
                    <sup className="text-red-600">*</sup>
                  </p>
                  <select
                    id="Type"
                    name="Type"
                    value={formData.Type}
                    onChange={handleInputChange}
                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                      errors.Type ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  >
                    <option value="">Select Type of Clinic</option>
                    <option value="Pediatric Clinics">Pediatric Clinics</option>
                    <option value="Community Health Centers">
                      Community Health Centers
                    </option>
                    <option value="Urgent Care Clinics">
                      Urgent Care Clinics
                    </option>
                    <option value="Dental Clinics">Dental Clinics</option>
                  </select>
                </label>
              </div>

              {/* Address */}
              <div className="w-full">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Address
                    <sup className="text-red-600">*</sup>
                  </p>
                  <textarea
                    id="Address"
                    name="Address"
                    value={formData.Address}
                    onChange={handleInputChange}
                    placeholder="Enter address here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Address ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
            </div>

            <div className="flex justify-center items-center w-full mt-6">
              <button
                type="submit"
                className="px-6 py-4 bg-[#E5F2FF] rounded-lg w-80 flex justify-center items-center cursor-pointer active:scale-96"
              >
                <div className="text-[#3CA5DC] text-sm font-semibold font-poppins">
                  Confirm
                </div>
              </button>
            </div>
          </form>
        </div>
      </ModalComponent>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-4">
          <button
            className="active:scale-90"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaChevronLeft className="h-5" />
          </button>
          <div>
            <h1 className="text-black text-lg font-bold font-poppins">
              Branches of Clinics
            </h1>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              Here are list of Branches
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-3">
          <label className=" w-64 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
            <IoIosSearch className="text-neutral-400 w-4 h-4" />
            <input
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearchChange}
              className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
            />
          </label>
          <button
            onClick={() => {
              setShowAddBranchModal(true);
            }}
            className="px-3 py-2 bg-sky-500 rounded shadow justify-center items-center gap-2.5 flex cursor-pointer active:scale-95 duration-200"
          >
            <FaPlus className="text-white text-sm" />
            <div className="text-white text-sm font-normal font-poppins">
              Add More Branches
            </div>
          </button>
        </div>
      </div>
      <div className="rounded-2xl shadow-lg p-5 mt-8">
        <div className="mt-8 flex flex-col">
          <div className="flex border-b pb-5">
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Name of Branch
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Type of Branch
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Address
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Manage
            </div>
          </div>
          {filteredServices?.slice(0, itemsToShow).map((item, i) => (
            <div key={i} className="flex mt-5 border-b pb-5 items-center gap-2">
              <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                {item?.Name}
              </div>
              <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                {item?.Type}
              </div>
              <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                {item?.Address}
              </div>
              <div className="text-black text-sm font-normal font-poppins flex-1 flex flex-row items-center justify-center">
                <button
                  onClick={() => {
                    // handleDeleteService(item?.BranchID);
                  }}
                  className="flex flex-col justify-center items-center w-[40px] h-[40px]"
                >
                  <img
                    src={manageIcon}
                    className="w-full h-full object-contain"
                    alt="manage Icon"
                  />
                </button>
              </div>
            </div>
          ))}
          <footer className="flex flex-row items-center justify-between mt-4">
            <div className="text-zinc-500 text-sm font-normal font-poppins">
              Showing {Math.min(itemsToShow, branches.length)} items per page
            </div>
            <div className="flex flex-row items-center space-x-3">
              <button
                className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                  itemsToShow < branches.length
                    ? "text-sky-500 bg-sky-100"
                    : "text-gray-400 bg-gray-100"
                }`}
                onClick={increaseItems}
                disabled={itemsToShow >= branches.length}
              >
                <IoMdAdd />
              </button>
              <div className="text-center text-black text-xs font-normal font-poppins bg-gray-100 rounded w-12 h-10 flex items-center justify-center ">
                1 - {Math.min(itemsToShow, branches.length)}
              </div>
              <button
                className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                  itemsToShow > 6
                    ? "text-sky-500 bg-sky-100"
                    : "text-gray-400 bg-gray-100"
                }`}
                onClick={decreaseItems}
                disabled={itemsToShow <= 6}
              >
                <RiSubtractLine />
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default BranchManagement;
