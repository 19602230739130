import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import showInvoice from "../../assets/Images/Invoicing/showInvoice.png";
import createInvoice from "../../assets/Images/Invoicing/createInvoice.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoices,
  getSpecificInvoice,
  invoice,
} from "../../services/operations/masterDataAPI";
import moment from "moment";
import ModalComponent from "../../components/Modal";
import AHlogo from "../../assets/Images/logo.png";

function Invoicing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.profile);
  const { settings } = useSelector((state) => state.masterData);
  const { logo } = useSelector((state) => state.profile);
  const [invoices, setInvoices] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [lastIncrement, setLastIncrement] = useState(7);
  const [showModal, setShowModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [specificInvoice, setSpecificInvoice] = useState([]);
  const [address, setAddress] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);

  const increaseItemsPerPage = () => {
    const remainingItems = invoices.length - itemsPerPage;
    const increment = Math.min(remainingItems, 7);
    setItemsPerPage((prev) => prev + increment);
    setLastIncrement(increment);
  };

  const decreaseItemsPerPage = () => {
    setItemsPerPage((prev) => (prev > 7 ? prev - lastIncrement : 7));
    setLastIncrement(7); // Reset to default decrement value
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      if (token && user) {
        const body = {
          DID: user.DID,
        };
        try {
          const res = await dispatch(getInvoices(token, body));
          setInvoices(res.data);
        } catch (error) {
          console.error("Failed to fetch invoices", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchInvoices();
  }, [token, user, dispatch]);

  useEffect(() => {
    const preview = async () => {
      if (token && invoiceData) {
        const id = invoiceData.ID;
        try {
          const res = await dispatch(getSpecificInvoice(token, id));
          const invoiceDetails = res;
          setSpecificInvoice(invoiceDetails);

          // Calculate subtotal and tax
          const calculatedSubtotal = invoiceDetails?.ServicePerInvoices?.reduce(
            (sum, service) => sum + parseFloat(service?.Cost),
            0
          );
          const calculatedTax = parseFloat(
            (calculatedSubtotal * 0.18).toFixed(2)
          );

          setSubtotal(calculatedSubtotal);
          setTax(calculatedTax);
          setShowModal(true);
        } catch (error) {
          console.error("Failed to fetch specific invoice", error);
          setShowModal(false);
        }
      }
    };

    preview();
  }, [token, invoiceData, dispatch]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (invoices.length === 0) {
    return (
      <div className="bg-[#F3F3F3] min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex flex-col justify-center items-center gap-2">
            <h1 className="text-black text-xl font-bold font-poppins">
              Billing & Invoice
            </h1>
            <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
              No invoices found for this specific DID
            </h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F3F3F3] min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* HEADER */}
        <div className="flex flex-row justify-between items-center">
          {/* TITLE */}
          <div className="flex flex-row items-center space-x-4">
            <button
              className="active:scale-90"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaChevronLeft className="h-5" />
            </button>
            <div>
              <h1 className="text-black text-xl font-bold font-poppins">
                Billing & Invoice
              </h1>
              <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                Listed below are all of Invoice and Bills
              </h1>
            </div>
          </div>
        </div>

        {/* Show Preview Modal */}
        <ModalComponent
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSpecificInvoice([]);
            setInvoiceData(null);
            setAddress(null);
          }}
          outSideModalPress={false}
        >
          <div className="bg-[#FFFFFF] h-[70vh] p-4 w-[800px] overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            {/* Preview HEADER */}
            <div className="flex flex-col justify-center items-center w-full gap-2">
              <div className="flex flex-row justify-between items-center w-full gap-2">
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-xs font-regular font-poppins">
                    Appointment ID :
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-xs font-regular font-poppins">
                    Patient Name :
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-xs font-regular font-poppins">
                    Service :
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-xs font-regular font-poppins">
                    Date :
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-xs font-regular font-poppins">
                    Invoice Amount :
                  </h1>
                </div>
              </div>

              <div className="flex flex-row justify-between items-center w-full gap-2">
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    # {specificInvoice?.SessionID}
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    {specificInvoice?.AHMember?.FirstName}{" "}
                    {specificInvoice?.AHMember?.LastName}
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    {specificInvoice?.AppointmentType}
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    {moment(specificInvoice?.CreatedAt).format("DD/MM/YYYY")}
                  </h1>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    {specificInvoice?.TotalAmount} CHF
                  </h1>
                </div>
              </div>
            </div>

            <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

            {/* Preview Content */}
            <div className="flex justify-center px-16 pt-10 overflow-hidden items-end w-full bg-[#F1F1F1] rounded-lg mt-4">
              <div className="flex flex-col justify-center items-center bg-white rounded-lg shadow-lg p-6">
                {/* INVOICE HEADER */}
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col justify-center items-start gap-2 w-1/3">
                    <h1 className="text-black text-sm font-regular font-poppins">
                      Invoice Number
                    </h1>
                    <div className="flex flex-row justify-center items-center gap-2">
                      <h1 className="text-black text-sm font-regular font-poppins">
                        # {specificInvoice?.InvoiceNumber}
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-start gap-2 w-1/3">
                    <h1 className="text-black text-sm font-regular font-poppins">
                      Appointment ID
                    </h1>
                    <div className="flex flex-row justify-center items-center gap-2">
                      <h1 className="text-black text-sm font-regular font-poppins">
                        # {specificInvoice?.SessionID}
                      </h1>
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-1/3">
                    <img
                      src={
                        logo
                          ? logo
                          : settings?.OrgImage
                          ? settings?.OrgImage
                          : AHlogo
                      }
                      className="w-full h-auto object-contain"
                      alt="Company Logo"
                    />
                  </div>
                </div>

                <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

                {/* INVOICE CONTENT */}
                <div className="flex flex-col justify-center items-start w-full">
                  {/* Name */}
                  <div className="grid grid-cols-2 justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-center items-start mt-2 gap-2 w-full">
                      <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                        Patient Details
                      </h1>
                      <h1 className="text-black text-sm font-semibold font-poppins">
                        {specificInvoice?.AHMember?.FirstName}{" "}
                        {specificInvoice?.AHMember?.LastName} <br />
                        <span className="text-black text-md font-semibold font-poppins">
                          {specificInvoice?.AHMember?.Address}
                          {","}
                          <br />
                          {specificInvoice?.AHMember?.City}
                          {","}
                          {specificInvoice?.AHMember?.State} <br />
                          {specificInvoice?.AHMember?.Country}
                          {"-"}
                          {specificInvoice?.AHMember?.PinCode}
                        </span>
                        {/* {address?.split(',').map((line, index) => (
                          <span key={index} className="text-black text-md font-semibold font-poppins">
                            {line.trim()}
                            {index < address?.split(',').length - 1 && <br />}
                          </span>
                        ))} */}
                      </h1>
                    </div>

                    <div className="flex flex-col justify-center items-start mt-7 gap-1 w-full">
                      <h1 className="text-black text-sm font-semibold font-poppins w-1/2"></h1>
                      <h1 className="text-black text-sm font-semibold font-poppins">
                        DOB:{" "}
                        {moment(specificInvoice?.AHMember?.DOB).format(
                          "DD/MM/YYYY"
                        )}
                      </h1>
                      <h1 className="text-black text-sm font-semibold font-poppins">
                        AHV Nr.: {specificInvoice?.AHMember?.AHVNumber}
                      </h1>
                      <h1 className="text-black text-sm font-semibold font-poppins">
                        Patient ID: {specificInvoice?.AHMember?.AHUID}
                      </h1>
                      <h1 className="text-black text-sm font-semibold font-poppins">
                        Canton: {specificInvoice?.AHMember?.State}
                      </h1>
                    </div>
                  </div>

                  <div className="flex flex-row justify-start items-start gap-1 w-1/2 my-6">
                    <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                      Doctor Name:
                    </h1>
                    <h1 className="text-black text-sm font-regular font-poppins text-left">
                      Dr. {specificInvoice?.RelatedDoctor?.Name}
                    </h1>
                  </div>

                  <div className="grid grid-cols-2 justify-between items-start gap-2 w-full">
                    <div className="flex flex-row justify-start items-start gap-2 w-full mt-2">
                      <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                        Consultation Date:
                      </h1>
                      <h1 className="text-black text-sm font-regular font-poppins text-left">
                        {moment(specificInvoice?.CreatedAt).format(
                          "DD/MM/YYYY"
                        )}
                      </h1>
                    </div>

                    <div className="flex flex-row justify-start items-start gap-1 w-full mt-2">
                      <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                        Invoice Date:
                      </h1>
                      <h1 className="text-black text-sm font-regular font-poppins text-left">
                        {moment(new Date()).format("DD/MM/YYYY")}
                      </h1>
                    </div>
                  </div>

                  <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

                  {/* Table header */}
                  <div className="grid grid-cols-5 gap-2 justify-center items-center w-full mt-1">
                    <h1 className="text-black text-xs font-semibold font-poppins">
                      Service Code
                    </h1>
                    <h1 className="text-black text-xs font-semibold font-poppins">
                      Service Name
                    </h1>
                    <h1 className="text-black text-xs font-semibold font-poppins">
                      Time / QTY
                    </h1>
                    <h1 className="text-black text-xs font-semibold font-poppins">
                      Rate
                    </h1>
                    <h1 className="text-black text-xs font-semibold font-poppins">
                      Cost
                    </h1>
                  </div>

                  {/* Table Content */}
                  <div className="flex flex-col justify-center items-center gap-2 w-full mt-10">
                    {/* Service Data */}
                    {specificInvoice?.ServicePerInvoices?.map((service) => (
                      <div
                        key={service.ID}
                        className="grid grid-cols-5 gap-2 justify-between items-start w-full mb-2 relative"
                      >
                        <div className="flex flex-row justify-start items-center">
                          <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                            {service.ServiceCode}
                          </h1>
                        </div>
                        <div className="flex flex-row justify-start items-center">
                          <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                            {service.ServiceName}
                          </h1>
                        </div>
                        <div className="flex flex-row justify-start items-center">
                          <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                            {service.ServiceTime} Mins
                          </h1>
                        </div>
                        <div className="flex flex-row justify-start items-center">
                          <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                            CHF {service.Rate}
                          </h1>
                        </div>
                        <div className="flex flex-row justify-start items-center">
                          <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                            CHF {service.Cost}
                          </h1>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

                  <div className="flex flex-col justify-center items-center w-full mb-[10vh]">
                    <div className="flex flex-row self-end justify-center items-center w-1/2">
                      <h1 className="text-black text-xs font-regular font-poppins w-[60%]">
                        Subtotal
                      </h1>
                      <h1 className="text-black text-sm font-regular font-poppins w-[40%] text-right">
                        CHF {specificInvoice?.SubTotal}
                      </h1>
                    </div>
                    <div className="flex flex-row self-end justify-center items-center w-1/2 mt-1">
                      <h1 className="text-black text-xs font-regular font-poppins w-[60%]">
                        Tax
                      </h1>
                      <h1 className="text-black text-sm font-regular font-poppins w-[40%] text-right">
                        CHF {specificInvoice?.Tax}
                      </h1>
                    </div>
                    <div className="flex flex-row self-end justify-center items-center w-1/2 mt-7">
                      <h1 className="text-black text-sm font-bold font-poppins w-[60%]">
                        Total
                      </h1>
                      <h1 className="text-black text-sm font-bold font-poppins w-[40%] text-right">
                        CHF {specificInvoice?.TotalAmount}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>

        {/* Content */}
        <div className="flex flex-col justify-center items-center w-full mt-5">
          {/* Table Header */}
          <div
            div
            className="flex flex-row justify-between items-center w-full"
          >
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Appointment ID
              </h1>
              {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
 <img
 src={Vertical_switch_light}
 className="w-full h-full object-contain"
 alt="Vertical Arrows Icon"
 />
 </div> */}
            </div>
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Patient Name
              </h1>
              {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
 <img
 src={Vertical_switch_light}
 className="w-full h-full object-contain"
 alt="Vertical Arrows Icon"
 />
 </div> */}
            </div>
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Services
              </h1>
              {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
 <img
 src={Vertical_switch_light}
 className="w-full h-full object-contain"
 alt="Vertical Arrows Icon"
 />
 </div> */}
            </div>
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Date
              </h1>
              {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
 <img
 src={Vertical_switch_light}
 className="w-full h-full object-contain"
 alt="Vertical Arrows Icon"
 />
 </div> */}
            </div>
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Invoice Amount
              </h1>
              {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
 <img
 src={Vertical_switch_light}
 className="w-full h-full object-contain"
 alt="Vertical Arrows Icon"
 />
 </div> */}
            </div>
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Status
              </h1>
              {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
 <img
 src={Vertical_switch_light}
 className="w-full h-full object-contain"
 alt="Vertical Arrows Icon"
 />
 </div> */}
            </div>
            <div className="flex flex-row justify-start items-center gap-1 w-[14.28%]">
              <h1 className="text-black text-md font-semibold font-poppins">
                Action
              </h1>
            </div>
          </div>

          <div className="w-[102%] bg-[#F3F5F6] h-[1.5px] mt-5 my-3"></div>

          {/* Table Content */}
          {invoices.slice(0, itemsPerPage).map((invoice) => (
            <React.Fragment key={invoice.ID}>
              <div className="flex flex-row justify-start items-center w-full">
                <div className="w-[14.28%]">
                  <h1 className="text-black text-sm font-regular font-poppins">
                    {invoice.ID}
                  </h1>
                </div>
                <div className="w-[14.28%]">
                  <h1 className="text-black text-sm font-regular font-poppins">
                    {invoice.Name}
                  </h1>
                </div>
                <div className="w-[14.28%]">
                  <h1 className="text-black text-sm font-regular font-poppins">
                    {invoice.AppointmentType}
                  </h1>
                </div>
                <div className="w-[14.28%]">
                  <h1 className="text-black text-sm font-regular font-poppins">
                    {moment(invoice.CreatedAt).format("DD/MM/YYYY")}
                  </h1>
                </div>
                <div className="w-[14.28%]">
                  <h1 className="text-black text-sm font-regular font-poppins">
                    {invoice?.Invoice?.TotalAmount
                      ? `${invoice?.Invoice?.TotalAmount} CHF`
                      : null}
                  </h1>
                </div>
                {invoice?.Subscription_Status === "PAID" && (
                  <div className="flex flex-row justify-start items-center w-[14.28%]">
                    <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-4 py-1 w-20">
                      <h1 className="text-[#3CA5DC] text-sm font-semibold font-poppins">
                        {invoice.Subscription_Status}
                      </h1>
                    </div>
                  </div>
                )}
                {invoice.Subscription_Status === "UNPAID" && (
                  <div className="flex flex-row justify-start items-center w-[14.28%]">
                    <div className="flex flex-row justify-center items-center bg-[#FFC0CB] rounded-2xl px-4 py-1 w-20">
                      <h1 className="text-[#DF535F] text-sm font-semibold font-poppins">
                        {invoice.Subscription_Status}
                      </h1>
                    </div>
                  </div>
                )}
                {invoice?.Invoice ? (
                  <div
                    onClick={() => {
                      setInvoiceData(invoice);
                      setAddress(
                        [
                          invoice?.Address,
                          `${invoice?.City} ${invoice?.State}`,
                          `${invoice?.Country} - ${invoice?.PinCode}`,
                        ]
                          .filter(Boolean)
                          .join(", ")
                      );
                    }}
                    className="flex flex-row justify-start items-center w-[14.28%] gap-2 cursor-pointer hover:scale-105 duration-500"
                  >
                    <div className="flex flex-col justify-center items-center">
                      <img
                        src={showInvoice}
                        className="w-[20px] h-[20px] object-contain"
                        alt="Show Invoice Icon"
                      />
                    </div>
                    <h1 className="text-[#F7A025] text-sm font-regular font-poppins underline">
                      Show Preview
                    </h1>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      navigate("/AddInvoice", { state: { invoice } });
                    }}
                    className="flex flex-row justify-start items-center w-[14.28%] gap-2 cursor-pointer hover:scale-105 duration-500"
                  >
                    <div className="flex flex-col justify-center items-center">
                      <img
                        src={createInvoice}
                        className="w-[20px] h-[20px] object-contain"
                        alt="Create Invoice Icon"
                      />
                    </div>
                    <h1 className="text-[#3CA5DC] text-sm font-regular font-poppins underline">
                      Create Invoice
                    </h1>
                  </div>
                )}
              </div>

              <div className="w-[102%] bg-[#F3F5F6] h-[1.5px] my-3"></div>
            </React.Fragment>
          ))}

          <div className="flex flex-row justify-start items-center w-[102%]">
            <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins w-1/4">
              Showing {itemsPerPage} items per page
            </h1>
            <div className="flex flex-row justify-center items-center ml-auto gap-1">
              <button
                onClick={increaseItemsPerPage}
                disabled={itemsPerPage >= invoices.length}
                className={`flex flex-row justify-center items-center rounded-md bg-[#E5F2FF] p-2 ${
                  itemsPerPage >= invoices.length
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer hover:scale-110 duration-500"
                }`}
              >
                <FaPlus className={`text-[#3CA5DC] text-sm`} />
              </button>
              <div className="flex flex-row justify-center items-center rounded-md bg-[#F3F5F6] p-2 px-4">
                <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                  1-{itemsPerPage}
                </h1>
              </div>
              <button
                onClick={decreaseItemsPerPage}
                className={`flex flex-row justify-center items-center rounded-md bg-[#E5F2FF] p-2 ${
                  itemsPerPage <= 7
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer hover:scale-110 duration-500"
                }`}
              >
                <FaMinus className="text-[#3CA5DC] text-sm" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoicing;
