import React from "react";

const TabBar = ({ id, name, icon, icon2, selectedTab, onClick }) => {
  const isSelected = selectedTab === id;
  return (
    <button
      onClick={() => onClick(id)}
      className={` ${
        isSelected
          ? "bg-sky-50 text-sky-400 font-semibold rounded-tl-2xl rounded-tr-2xl"
          : "text-neutral-600 font-normal"
      } flex flex-col justify-center items-center`}
    >
      <div className="flex items-center justify-center px-3">
        <img
          src={isSelected ? icon2 : icon}
          alt={name}
          className="w-6 h-6"
        />
        <div className={`text-sm font-poppins leading-tight m-3`}>{name}</div>
      </div>
      <div
        className={`w-full h-0.5 ${
          isSelected ? "bg-sky-400" : "bg-gray-200"
        } rounded-xl`}
      />
    </button>
  );
};

export default TabBar;
