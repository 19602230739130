import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import reqDemoIcon from "../assets/Images/reqDemoIcon.png";
import emailSelectedIcon from "../assets/Images/emailSelectedIcon.png";
import emailUnselectedIcon from "../assets/Images/emailUnselectedIcon.png";
import callSelectedIcon from "../assets/Images/callSelectedIcon.png";
import callUnselectedIcon from "../assets/Images/callUnselectedIcon.png";
import emailIcon from "../assets/Images/icon/email.png";
import phoneIcon from "../assets/Images/icon/phone.png";
import toast from "react-hot-toast";
import { SlLogin } from "react-icons/sl";

function LoginForm() {
  const savedInfo = localStorage.getItem("savedInfo")
    ? JSON.parse(localStorage.getItem("savedInfo"))
    : "";
  const [loginType, setLoginType] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Email: savedInfo.Email ? savedInfo.Email : "",
    Phone: savedInfo.Phone ? savedInfo.Phone : "",
    Password: savedInfo ? savedInfo.Password : "",
    rememberMe: savedInfo ? savedInfo.RememberMe : false,
  });

  const handleLoginTypeChange = (type) => {
    setLoginType(type);
  };

  const handleOnChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (loginType === "0") {
      if (!formData.Email.includes("@")) {
        toast.error("Please enter a valid email");
        return;
      }
    } else if (loginType === "1") {
      if (formData.Phone.length !== 10) {
        toast.error("Please enter a valid phone number");
        return;
      }
    }

    if (loginType === "0") {
      dispatch(
        login(
          formData.Email,
          formData.Password,
          loginType,
          formData.rememberMe,
          navigate
        )
      );
    } else if (loginType === "1") {
      dispatch(
        login(
          formData.Phone,
          formData.Password,
          loginType,
          formData.rememberMe,
          navigate
        )
      );
    }
  };

  return (
    <div className="bg-white w-full md:w-[37vw] h-[90vh] rounded-[22px] py-10 px-20 flex flex-col justify-start">
      <div className="flex items-center justify-center gap-3 mt-6 w-full">
        <Link to="/partnerLogin" className="block w-44 bg-[#3ca5dc] rounded-2xl py-2 px-6 flex flex-row justify-between items-center gap-2">
          <h1 className="text-[#FFFFFF] text-[13px] font-semibold font-open font-poppins">
            Partner Login
          </h1>
          <SlLogin className="text-[#FFFFFF] font-semibold text-lg" />
        </Link>
        <button onClick={() => window.open("https://alleshealth.com/contactus", "_blank")} className="block w-44 bg-white border border-[#3ca5dc] rounded-2xl py-2 px-4 flex flex-row justify-between items-center gap-2">
          <h1 className="text-black text-[13px] font-semibold font-open font-poppins">
            Request a Demo
          </h1>
          <div className="">
            <img
              src={reqDemoIcon}
              alt="Demo Icon"
              className="w-5 h-5 object-contain"
            />
          </div>
        </button>
      </div>

      {/* login type - email/phone number  */}
      <h1 className="text-black text-[32px] font-semibold font-open font-poppins leading-relaxed mt-28">
        Clinic Login
      </h1>
      <h1 className="text-[#acacac] text-[10px] font-normal font-open font-poppins leading-relaxed mt-3">
        Login for Clinics & Organization Staff
      </h1>

      <div className="justify-start items-start gap-3.5 inline-flex mt-14">
        <button
          className={`py-1 px-4 rounded-3xl ${loginType === "0"
            ? "bg-sky-500 text-[#e5f2ff]"
            : "bg-sky-100 text-[#35abf4]"
            } justify-center items-center inline-flex w-[7.4rem] gap-2`}
          onClick={() => handleLoginTypeChange("0")}
        >
          <img
            src={loginType === "0" ? emailSelectedIcon : emailUnselectedIcon}
            alt="Email Icon"
            className="w-5 h-5 object-contain"
          />
          <div className="text-[10.49px] font-medium font-poppins">Email</div>
        </button>
        <button
          className={`py-1 px-4 rounded-3xl ${loginType === "1"
            ? "bg-sky-500 text-[#e5f2ff]"
            : "bg-sky-100 text-[#35abf4]"
            } justify-center items-center inline-flex w-[7.4rem] gap-2`}
          onClick={() => handleLoginTypeChange("1")}
        >
          <img
            src={loginType === "1" ? callSelectedIcon : callUnselectedIcon}
            alt="Call Icon"
            className="w-5 h-5 object-contain"
          />
          <div className="text-[10.49px] font-medium font-poppins">Phone</div>
        </button>
      </div>

      {/* login form */}
      <div className="mt-6">
        <form onSubmit={handleOnSubmit}>
          {loginType === "0" ? (
            <>
              {/* Email */}
              <div className="mb-9">
                <label>
                  <div className="flex items-center">
                    <div className="bg-[#ffffff] rounded-l-2xl p-3 border border-r-0 border-[#CFCFCF]">
                      <img
                        src={emailIcon}
                        alt="email"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="text"
                      name="Email"
                      value={loginType === "0" ? formData.Email : ""}
                      onChange={handleOnChange}
                      placeholder="Input your Email here"
                      // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                      className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-r-2xl p-4 border border-l-0 border-[#CFCFCF] focus:outline-none`}
                    />
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              {/* Phone Number */}
              <div className="mb-9">
                <label>
                  <div className="flex items-center">
                    <div className="bg-[#ffffff] rounded-l-2xl p-3 border border-r-0 border-[#CFCFCF]">
                      <img
                        src={phoneIcon}
                        alt="phone Icon"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="tel"
                      inputMode="tel"
                      maxLength={10}
                      name="Phone"
                      value={loginType === "1" ? formData.Phone : ""}
                      onChange={handleOnChange}
                      placeholder="Input your Phone Number here"
                      // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                      className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-r-2xl p-4 border border-l-0 border-[#CFCFCF] focus:outline-none`}
                    />
                  </div>
                </label>
              </div>
            </>
          )}

          {/* password */}
          <div className="relative">
            <label>
              <input
                className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-2xl p-4 border border-[#CFCFCF] focus:outline-none`}
                required
                type={showPassword ? "text" : "password"}
                name="Password"
                value={formData.Password}
                onChange={handleOnChange}
                placeholder="Input your password here"
              />
            </label>
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-4 top-6 transform -translate-y-1/2 z-10 cursor-pointer"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#3CA5DC" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#3CA5DC" />
              )}
            </span>
          </div>

          <div className="flex flex-row justify-between items-center w-full mt-3 mb-8">
            {/* remember me */}
            <div className="flex items-center justify-start ml-2">
              <label
                className="relative flex cursor-pointer items-center"
                htmlFor="rememberMe"
              // data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  defaultChecked={formData.rememberMe}
                  onChange={handleOnChange}
                  className="before:content[''] peer relative h-4 w-4 border-2 border-sky-400
             cursor-pointer appearance-none transition-all before:absolute
              before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8
               before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
               before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
                checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
                hover:before:opacity-10"
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth={1}
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </label>

              <p className="text-sky-400 text-xs font-medium font-poppins ml-2">
                Remember Me
              </p>
            </div>

            <div className="flex justify-start">
              <Link
                to="/forgot-password"
                className="text-sky-400 text-xs font-semibold font-poppins"
              >
                Forgot Password?
              </Link>
            </div>
          </div>

          {/* login button */}
          <div className="">
            <button
              type="submit"
              className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-2xl py-3 text-sky-100 text-sm font-medium font-poppins"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;