import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import micIcon from "../assets/Images/PatientProfile/micIcon.png";
import englishIcon from "../assets/Images/PatientProfile/englishIcon.png";
import italyIcon from "../assets/Images/PatientProfile/italyIcon.png";
import { useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import UserDetails from "../components/UserDetails";
import MentalHealthScore from "../components/MentalHealthScore";
import FamilyMember from "../components/FamilyMember";
import MedicalCondition from "../components/MedicalCondition";
import UploadedReports from "../components/UploadedReports";
import Consultation from "../components/Consultation";
import DosageAdherence from "../components/DosageAdherence";
import ModalComponent from "../components/Modal";
import checkWidget from "../assets/Images/TelehealthDashboard/checkWidget.png";
import {
  fetchMentalHealthDetails,
  getConsultationHistory,
  getMentalHealthAssessmentScore,
  getPatientProfile,
  getFamilyMembers,
  getDosageAdherence,
  getDiagnosisType,
  getAllMedicines,
  getAssignedTreatmentProgram,
  addAudioExtractedInfo,
  getAllAssignedTreatmentProgram,
  getAllAllergies,
  getAllergiesPerPatient,
  getPreMedicalConditons,
  getHealthReports,
  getHealthJourney,
} from "../services/operations/patientManagementAPI";
import HealthProgram from "../components/HealthProgram";
import voice from "../assets/Images/PatientProfile/voice.png";
import toast from "react-hot-toast";
import axios from "axios";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Allergies from "../components/Allergies";
import { FaEdit } from "react-icons/fa";
import Breadcrumb from "../components/Breadcrumb";
import FamilyHistory from "../components/FamilyHistory";
import TabBar from "../components/TabBar";
import patientProfileIcon from "../assets/Images/PatientProfile/TabBar/patientProfile.svg";
import patientProfileSelectedIcon from "../assets/Images/PatientProfile/TabBar/patientProfileSelected.svg";

import appointmentHistoryIcon from "../assets/Images/PatientProfile/TabBar/appointmentHistory.svg";
import appointmentHistorySelectedIcon from "../assets/Images/PatientProfile/TabBar/appointmentHistorySelected.svg";

import medicalCommitteeIcon from "../assets/Images/PatientProfile/TabBar/medicalCommittee.svg";
import medicalCommitteeSelectedIcon from "../assets/Images/PatientProfile/TabBar/medicalCommitteeSelected.svg";

import conceirgePlansIcon from "../assets/Images/PatientProfile/TabBar/conceirgePlans.svg";
import conceirgePlansSelectedIcon from "../assets/Images/PatientProfile/TabBar/conceirgePlansSelected.svg";
import requestsAndRemarksIcon from "../assets/Images/PatientProfile/TabBar/requestAndRemarks.svg";
import requestsAndRemarksSelectedIcon from "../assets/Images/PatientProfile/TabBar/requestAndRemarksSelected.svg";
import ConsultationHistory from "../components/ConsultationHistory";
import PatientConciergePlan from "../components/PatientConciergePlan";

function PatientProfile() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const patient = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const {
    loading: patientLoading,
    patientProfile,
    mentalHealth,
    familyMembers,
    medicalCondition,
    dosageAdherence,
    consultationHistory,
    diagnosisType,
    allMedicines,
    allPreMedicalConditions,
    healthProgram,
    allergies,
    allergiesType,
  } = useSelector((state) => state.patient);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAudioDictation, setShowAudioDictation] = useState(false);
  const [showTranscription, setShowTranscription] = useState(false);
  const [translatedLang, setTranslatedLang] = useState("it");
  const [selectedTab, setSelectedTab] = useState(0);
  const [transcriptedData, setTranscriptedData] = useState(() => {
    const savedData = localStorage.getItem("transcriptedData");
    if (savedData) {
      return JSON.parse(savedData);
    } else {
      return {};
    }
  });
  const [visibility, setVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem(
      "patientProfileWidgetsVisibility"
    );

    if (savedVisibility) {
      return JSON.parse(savedVisibility);
    } else {
      return {
        showMentalHealthScore: true,
        showFamilyMember: true,
        showAllergies: true,
        showMedicalCondition: true,
        showUploads: true,
        showConsultation: true,
        showDosageAdherence: true,
        showHealthProgram: true,
      };
    }
  });
  const [formData, setFormData] = useState({
    PatientName: "",
    Age: "",
    Gender: "",
    FamilyMedicalHistory: "",
    MedicalCondition: "",
    Allergies: "",
    Diagnosis: "",
    Prescription: "",
    Symptoms: "",
    Concerns: "",
    DoctorsRecommendations: "",
    CurrentMedication: "",
    FollowUpInstructions: "",
  });
  const [errors, setErrors] = useState({
    PatientName: "",
    Age: "",
    Gender: "",
    FamilyMedicalHistory: "",
    MedicalCondition: "",
    Allergies: "",
    Diagnosis: "",
    Prescription: "",
    Symptoms: "",
    Concerns: "",
    DoctorsRecommendations: "",
    CurrentMedication: "",
    FollowUpInstructions: "",
  });

  const submitHandler = async (event) => {
    event.preventDefault();

    const mandatoryFields = [
      // "PatientName",
      // "Age",
      // "Gender",
      // "FamilyMedicalHistory",
      // "MedicalCondition",
      // "Allergies",
      // "Diagnosis",
      // "Prescription",
      // "Symptoms",
      // "Concerns",
      // "DoctorsRecommendations",
      // "CurrentMedication",
      // "FollowUpInstructions",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    let diagnosisWords = formData?.Diagnosis.split(" ");

    let matchedDiagnosis = diagnosisType.find((diagnosis) =>
      diagnosisWords.includes(diagnosis.DiagnosisName)
    );

    let data = {
      PersonalNote:
        formData?.FamilyMedicalHistory +
        " " +
        formData?.MedicalCondition +
        " " +
        formData?.Concerns +
        formData?.CurrentMedication,
      DiagnosisInformation:
        formData?.DoctorsRecommendations + " " + formData?.FollowUpInstructions,
      SpecialInstructions: formData?.Prescription,
      AllergyName: formData?.Allergies,
    };

    if (matchedDiagnosis?.DiagnosisID) {
      data.DiagnosisID = matchedDiagnosis.DiagnosisID;
    }

    let ID = consultationHistory[0]?.AppointmentID;

    localStorage.setItem("transcriptedData", JSON.stringify(formData));
    dispatch(addAudioExtractedInfo(token, ID, data, navigate, patient));
    hideExtractedData();
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "PinCode":
        maxLength = 6;
        break;
      case "PolicyNumber":
        maxLength = 16;
        break;
      case "AHVNo":
        maxLength = 13; // Maximum characters without periods
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    if (name === "AHVNo") {
      // Remove periods to process the raw input
      const rawValue = value.replace(/\./g, "");

      // Add periods at the appropriate positions
      let formattedValue = rawValue.slice(0, 3);
      if (rawValue.length > 3) {
        formattedValue += "." + rawValue.slice(3, 7);
      }
      if (rawValue.length > 7) {
        formattedValue += "." + rawValue.slice(7, 11);
      }
      if (rawValue.length > 11) {
        formattedValue += "." + rawValue.slice(11, 13);
      }

      // Ensure the length of the raw value does not exceed maxLength
      if (rawValue.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: formattedValue,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      // For other types, handle validation without removing non-numeric characters
      if (value.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  }
  const [isRecording, setIsRecording] = useState(false);
  const [isExtracted, setIsExtracted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [elapsedTime, setElapsedTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const audioContextRef = useRef(
    new (window.AudioContext || window.webkitAudioContext)()
  );
  const analyserRef = useRef(audioContextRef.current.createAnalyser());

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "patientProfileWidgetsVisibility",
      JSON.stringify(visibility)
    );
  }, [visibility]);

  const fetchPatientProfile = () => {
    let body = {
      AHUID: patient?.AHUID,
    };
    dispatch(getPatientProfile(token, body));
  };

  const fetchMentalHealthInfo = () => {
    let body = {
      AHUID: patient?.AHUID,
    };
    dispatch(getMentalHealthAssessmentScore(token, body));
  };

  const fetchConsultationHistory = () => {
    let body = {
      AHUID: patient?.AHUID,
      Key: "All",
    };
    dispatch(getConsultationHistory(token, body));
  };

  const fetchPreMedicalConditions = () => {
    dispatch(getPreMedicalConditons(token, patient?.AHUID));
  };

  const fetchHealtReports = () => {
    dispatch(getHealthReports(token, patient?.AHUID));
  };

  const fetchFamilyMembers = () => {
    let body = {
      AHUID: patient?.AHUID,
    };
    dispatch(getFamilyMembers(token, body));
  };

  const fetchDosageAdherence = () => {
    let body = {
      AHUID: patient?.AHUID,
    };
    dispatch(getDosageAdherence(token, body));
  };

  const fetchDiagnosisType = () => {
    dispatch(getDiagnosisType(token));
  };

  const fetchMedicineList = () => {
    dispatch(getAllMedicines(token));
  };

  const fetchAllergiesType = () => {
    dispatch(getAllAllergies(token));
  };

  const fetchAllergies = () => {
    dispatch(getAllergiesPerPatient(token, patient?.AHUID));
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchPatientProfile(),
        fetchMentalHealthInfo(),
        fetchFamilyMembers(),
        fetchConsultationHistory(),
        fetchPreMedicalConditions(),
        fetchDosageAdherence(),
        fetchDiagnosisType(),
        fetchMedicineList(),
        fetchAllergiesType(),
        fetchAllergies(),
        fetchHealtReports(),
      ]);
    };

    fetchData();
  }, [patient]);

  useEffect(() => {
    const getAssignedTreatmentPrg = async () => {
      if (patient && token) {
        const id = patient.AHUID;
        try {
          const res = await dispatch(getAllAssignedTreatmentProgram(token, id));
        } catch (error) {
          console.error("Failed to fetch All Treatment Programs", error);
        }
      }
    };

    getAssignedTreatmentPrg();
  }, [patient, token]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time - hours * 3600 - minutes * 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const startRecording = async () => {
    console.log("Recording...");
    setAudioUrl("");
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    const source = audioContextRef.current.createMediaStreamSource(stream);
    source.connect(analyserRef.current);

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      audioChunksRef.current = [];
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);

      // Stop the timer and reset elapsed time
      clearInterval(timerRef.current);
      setElapsedTime(0);

      // Stop the animation
      cancelAnimationFrame(animationRef.current);

      // Optionally, save the blob to state to use later for sending to the server
      sendAudioFile(audioBlob);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
    setIsExtracted(false);

    // Start the timer
    timerRef.current = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    // Start the animation
    draw();
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    setIsExtracted(true);
  };

  const draw = () => {
    console.log("Drawing...");
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    const frequencyData = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(frequencyData);

    ctx.clearRect(0, 0, width, height);
    const barWidth = (width / frequencyData.length) * 4.0;
    let x = 0;

    for (let i = 0; i < frequencyData.length; i++) {
      const barHeight = frequencyData[i] / 2;
      ctx.fillStyle = i % 2 === 0 ? "#3CA5DC" : "white";
      ctx.fillRect(x, (height - barHeight) / 2, barWidth, barHeight);
      x += barWidth + 1;
    }

    animationRef.current = requestAnimationFrame(draw);
  };

  const sendAudioFile = async (audioBlob) => {
    const toastId = toast.loading("Extracting audio...");
    setIsLoading(true);
    const formData = new FormData();
    formData.append("audioFile", audioBlob, "audio.wav");
    formData.append("language", translatedLang);
    try {
      const response = await axios.post(
        "https://beta-audio-ai-api.alleshealth.com/v2/crownextractor",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setTranscriptedData(response?.data);
      toast.success("Audio extracted successfully");
    } catch (error) {
      toast.error("Failed to extract audio");
      console.error(error);
    } finally {
      setIsLoading(false);
      toast.dismiss(toastId);
    }
  };

  const showExtractedData = () => {
    setShowAudioDictation(false);
    setFormData({
      PatientName:
        transcriptedData?.patients_name || transcriptedData?.PatientName,
      Age: transcriptedData?.age || transcriptedData?.Age,
      Gender: transcriptedData?.gender || transcriptedData?.Gender,
      FamilyMedicalHistory:
        transcriptedData?.family_medical_history ||
        transcriptedData?.FamilyMedicalHistory,
      MedicalCondition:
        transcriptedData?.medical_history || transcriptedData?.MedicalCondition,
      Allergies: transcriptedData?.allergies || transcriptedData?.Allergies,
      Diagnosis:
        transcriptedData?.diagnosed_conditions || transcriptedData?.Diagnosis,
      Prescription:
        transcriptedData?.prescribed_medications ||
        transcriptedData?.Prescription,
      Symptoms: transcriptedData?.symptoms || transcriptedData?.Symptoms,
      Concerns: transcriptedData?.concerns || transcriptedData?.Concerns,
      DoctorsRecommendations:
        transcriptedData?.doctors_recommendations ||
        transcriptedData?.DoctorsRecommendations,
      CurrentMedication:
        transcriptedData?.current_medication ||
        transcriptedData?.CurrentMedication,
      FollowUpInstructions:
        transcriptedData?.follow_up_instructions ||
        transcriptedData?.FollowUpInstructions,
    });
    setShowTranscription(true);
  };

  const hideExtractedData = () => {
    setShowTranscription(false);
    setIsEditing(false);
    setFormData({
      PatientName: "",
      Age: "",
      Gender: "",
      FamilyMedicalHistory: "",
      MedicalCondition: "",
      Allergies: "",
      Diagnosis: "",
      Prescription: "",
      Symptoms: "",
      Concerns: "",
      DoctorsRecommendations: "",
      CurrentMedication: "",
      FollowUpInstructions: "",
    });
    setErrors({
      PatientName: "",
      Age: "",
      Gender: "",
      FamilyMedicalHistory: "",
      MedicalCondition: "",
      Allergies: "",
      Diagnosis: "",
      Prescription: "",
      Symptoms: "",
      Concerns: "",
      DoctorsRecommendations: "",
      CurrentMedication: "",
      FollowUpInstructions: "",
    });
    setIsExtracted(false);
  };

  const renderTabContent = (tab) => {
    switch (tab) {
      case 0:
        return (
          <div className="px-6">
            <section className="flex space-x-4">
              <div className="w-60 bg-white rounded-lg shadow py-7 flex flex-col items-center justify-center">
                <div className="bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5">
                  <img
                    src={patient.Image}
                    alt="PatientPic"
                    className="w-20 h-20 rounded-full"
                  />
                </div>
                <div class="w-full py-1 bg-yellow-50 flex items-center justify-center mt-4">
                  <div class="w-48 text-center text-yellow-600 text-sm font-semibold font-poppins leading-tight">
                    Conceirge Member since 24 July 2024
                  </div>
                </div>
                <div class="text-center text-black text-base font-semibold font-poppins mt-4">
                  {patient.Name || patient.FirstName + " " + patient.LastName}
                </div>
                <div class="text-center text-zinc-500 text-xs font-normal font-poppins my-2">
                  No : {patient.AHUID}
                </div>

                {patient.Age !== null && patient.Age !== undefined ? (
                  <div class="text-center text-zinc-900 text-xs font-normal font-poppins">
                    {patient.Age} Years Old
                  </div>
                ) : (
                  <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
                    {new Date().getFullYear() -
                      new Date(patient.DOB).getFullYear()}{" "}
                    Years Old
                  </div>
                )}
              </div>

              <div className={`flex-1 bg-white rounded-lg shadow p-4`}>
                <UserDetails
                  patient={patient}
                  patientProfile={patientProfile}
                />
              </div>
              {visibility.showMentalHealthScore && (
                <div className=" bg-white rounded-lg shadow p-4 transition-all duration-300">
                  <MentalHealthScore
                    patient={patient}
                    patientProfile={patientProfile}
                    mentalHealth={mentalHealth}
                  />
                </div>
              )}
            </section>

            <section className="flex mt-5 space-x-5">
              {visibility.showAllergies && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <Allergies
                    consultationHistory={consultationHistory}
                    patient={patient}
                    allergies={allergies}
                  />
                </div>
              )}
              {visibility.showMedicalCondition && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <MedicalCondition
                    medicalCondition={medicalCondition}
                    patient={patient}
                  />
                </div>
              )}
              {visibility.showUploads && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <UploadedReports
                    patient={patient}
                    consultationHistory={consultationHistory}
                    allMedicines={allMedicines}
                    diagnosisType={diagnosisType}
                  />
                </div>
              )}
            </section>

            <section className="flex mt-5 space-x-5">
              {visibility.showDosageAdherence && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <DosageAdherence dosageAdherence={dosageAdherence} />
                </div>
              )}
              {visibility.showConsultation && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <Consultation
                    consultationHistory={consultationHistory}
                    diagnosisType={diagnosisType}
                    patient={patient}
                    allMedicines={allMedicines}
                    allPreMedicalConditions={allPreMedicalConditions}
                  />
                </div>
              )}
            </section>

            <section className="flex mt-5 space-x-5">
              {visibility.showFamilyMember && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <FamilyHistory
                    familyMembers={familyMembers}
                    patient={patient}
                  />
                </div>
              )}
              {visibility.showFamilyMember && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <FamilyMember
                    familyMembers={familyMembers}
                    patient={patient}
                  />
                </div>
              )}
              {visibility.showFamilyMember && <div className="flex-1"></div>}
            </section>

            <section className="flex mt-5 space-x-5">
              {visibility.showHealthProgram && (
                <div className="flex-1 bg-white rounded-lg shadow p-4">
                  <HealthProgram
                    patient={patient}
                    consultationHistory={consultationHistory}
                    healthProgram={healthProgram}
                  />
                </div>
              )}
            </section>
          </div>
        );
      case 1:
        return (
          <ConsultationHistory
            consultationHistory={consultationHistory}
            diagnosisType={diagnosisType}
            patient={patient}
            allMedicines={allMedicines}
            allPreMedicalConditions={allPreMedicalConditions}
          />
        );
      case 2:
        return <PatientConciergePlan patient={patient} />;
      default:
        return null;
    }
  };

  if (patientLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-[#FCFCFC] min-h-full scroll-smooth">
      {/* Record Audio */}
      <ModalComponent
        show={showAudioDictation}
        handleClose={() => {
          // stopRecording();
          setShowAudioDictation(false);
          setIsRecording(false);
          setIsExtracted(false);
        }}
        outSideModalPress={false}
      >
        <div className="relative">
          <div className="flex flex-col items-center space-y-2">
            <div className="bg-sky-100 rounded-full w-20 h-20 p-4">
              <img src={voice} alt="" />
            </div>
            <div className="text-center text-black text-lg font-bold font-poppins">
              On Record
            </div>
            <div className="text-center text-black text-sm font-normal font-poppins">
              {formatTime(elapsedTime)}
            </div>
            <div className="bg-gray-100 rounded-lg p-2">
              {/* <canvas ref={canvasRef} width="500" height="100"></canvas> */}
              <canvas
                ref={canvasRef}
                id="myCanvas"
                width="500"
                height="100"
              ></canvas>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center gap-2 absolute top-0 right-0">
            <button
              onClick={() => setTranslatedLang("en")}
              className={`w-20 px-3 py-2 ${
                translatedLang === "en"
                  ? "bg-sky-100 active:bg-sky-200 active:scale-95"
                  : "bg-gray-100"
              }  rounded shadow flex justify-center items-center gap-2.5`}
            >
              <img
                src={englishIcon}
                className="w-4 h-4 object-contain"
                alt="English Icon"
              />
              <div className="text-[#000000] text-[0.6rem] font-normal font-poppins">
                English
              </div>
            </button>

            <button
              onClick={() => setTranslatedLang("it")}
              className={`w-20 px-3 py-2 ${
                translatedLang === "it"
                  ? "bg-sky-100 active:bg-sky-200 active:scale-95"
                  : "bg-gray-100"
              }  rounded shadow flex justify-center items-center gap-2.5`}
            >
              <img
                src={italyIcon}
                className="w-4 h-4 object-contain"
                alt="Italy Icon"
              />
              <div className="text-[#000000] text-[0.6rem] font-normal font-poppins">
                Italian
              </div>
            </button>
          </div>
          <div className="border border-b-0.5 border-gray-100 my-5"></div>
          {isLoading && (
            <div className="flex justify-center items-center pt-5">
              <button
                disabled={true}
                className={`w-2/3 px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-sky-400 rounded-lg flex items-center justify-center space-x-5 active:scale-95`}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 20 }}
                      className="text-sky-100"
                      spin
                    />
                  }
                />{" "}
                <div>Please Wait</div>
              </button>
            </div>
          )}
          {!isExtracted && !isLoading && (
            <div className="flex justify-center items-center pt-5">
              <button
                onClick={isRecording ? stopRecording : startRecording}
                className={`w-2/3 px-11 py-3 text-sky-400 text-sm font-semibold font-poppins shadow bg-sky-100 rounded-lg text-center active:scale-95`}
              >
                {isRecording ? "Stop Recording" : "Start Recording"}
              </button>
            </div>
          )}
          {isExtracted && !isLoading && (
            <div className="flex justify-center items-center pt-5">
              <button
                onClick={() => showExtractedData()}
                className={`w-2/3 px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-sky-400 rounded-lg text-center active:scale-95`}
              >
                Validate
              </button>
            </div>
          )}
          {/* <div className="flex justify-center items-center space-x-4 pt-5">
            <button
              onClick={stopRecording}
              className={`w-1/2 px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-sky-400 rounded-lg text-center active:scale-95`}
            >
             Stop
            </button>
            <button
              // onClick={submitHandler}
              className={`w-1/2 px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-neutral-400 rounded-lg text-center active:scale-95`}
            >
              Validate
            </button>
          </div> */}
        </div>
      </ModalComponent>
      {/* Widget Modal */}
      <ModalComponent
        show={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        outSideModalPress={false}
      >
        <div className="text-black text-lg font-bold font-poppins mb-2">
          Customize Patient Widgets
        </div>
        <div className="border border-b-0.5 border-gray-100 mb-5"></div>
        <div className="grid grid-cols-3 gap-5 relative">
          {WIDGET.map((widget) => (
            <button
              key={widget.id}
              onClick={() =>
                setVisibility((prevState) => ({
                  ...prevState,
                  [widget.name]: !prevState[widget.name],
                }))
              }
              className="relative shadow-lg rounded-lg bg-white active:bg-gray-100 transition-all duration-300"
            >
              <img
                src={widget.image}
                alt=""
                className="w-60 h-30 object-contain active:scale-95"
              />
              {visibility[widget.name] && (
                <img
                  src={checkWidget}
                  alt=""
                  className="w-7 h-7 absolute top-12 left-6"
                />
              )}
            </button>
          ))}
        </div>
      </ModalComponent>
      {/* Audio Dictation */}
      <ModalComponent
        show={showTranscription}
        handleClose={hideExtractedData}
        outSideModalPress={false}
      >
        <div className="w-[50vw]">
          <div className="flex items-center justify-between">
            <div className="text-black text-lg font-bold font-poppins">
              Audio Dictation Review
            </div>
            {!isEditing && (
              <button
                className=" active:scale-90"
                onClick={() => setIsEditing(true)}
              >
                <FaEdit className="w-10 h-5 text-sky-500" />
              </button>
            )}
          </div>
          <div className="border border-b-0.5 border-gray-100 my-5"></div>
          {audioUrl && (
            <div className="flex items-center justify-center mb-3">
              <audio controls>
                <source src={audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          <div className="px-4 max-h-[calc(100vh-400px)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            <div className="grid grid-cols-2 gap-4">
              {/* Patient Name  */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Patient Name
                  </p>
                  <input
                    type="text"
                    name="PatientName"
                    maxLength={20}
                    disabled={!isEditing} // Disable input when not in edit mode
                    value={formData.PatientName}
                    onChange={handleOnChange}
                    placeholder="Input patient name here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.PatientName ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Age  */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Age
                  </p>
                  <input
                    type="text"
                    name="Age"
                    // maxLength={2}
                    value={formData.Age}
                    onChange={handleOnChange}
                    placeholder="Input patient age here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Age ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Gender  */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Gender
                  </p>
                  <input
                    type="text"
                    name="Gender"
                    value={formData.Gender}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Gender ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Allergies */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Allergies
                  </p>
                  <select
                    name="Allergies"
                    value={formData.Allergies}
                    onChange={handleOnChange}
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Allergies ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    disabled={!isEditing} // Disable dropdown when not in edit mode
                  >
                    <option value="">Select an allergy</option>
                    {allergiesType?.map((allergy, index) => (
                      <option key={index} value={allergy.Name}>
                        {allergy.Name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {/* Family Background  */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Family Medical History
                  </p>
                  <textarea
                    type="text"
                    name="familyMedicalHistory"
                    value={formData.FamilyMedicalHistory}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.FamilyMedicalHistory
                        ? "border-red-500"
                        : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* MedicalCondition */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Medical Condition
                  </p>
                  <textarea
                    type="text"
                    name="MedicalCondition"
                    value={formData.MedicalCondition}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.MedicalCondition
                        ? "border-red-500"
                        : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>

              {/* Diagnosis */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Diagnosis
                  </p>
                  <textarea
                    type="text"
                    name="Diagnosis"
                    value={formData.Diagnosis}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Diagnosis ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* Prescription */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Prescription
                  </p>
                  <textarea
                    type="text"
                    name="Prescription"
                    value={formData.Prescription}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Prescription ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* Symptoms */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Symptoms
                  </p>
                  <textarea
                    type="text"
                    name="Symptoms"
                    value={formData.Symptoms}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Symptoms ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* Concerns */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Concerns
                  </p>
                  <textarea
                    type="text"
                    name="Concerns"
                    value={formData.Concerns}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.Concerns ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* DoctorsRecommendations */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Doctor's Recommendations
                  </p>
                  <textarea
                    type="text"
                    name="DoctorsRecommendations"
                    value={formData.DoctorsRecommendations}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.DoctorsRecommendations
                        ? "border-red-500"
                        : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* CurrentMedication */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Current Medication
                  </p>
                  <textarea
                    type="text"
                    name="CurrentMedication"
                    value={formData.CurrentMedication}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.CurrentMedication
                        ? "border-red-500"
                        : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* FollowUpInstructions */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Follow Up Instructions
                  </p>
                  <textarea
                    type="text"
                    name="FollowUpInstructions"
                    value={formData.FollowUpInstructions}
                    onChange={handleOnChange}
                    placeholder="Input here"
                    className={`w-full text-xs font-normal font-poppins rounded-md p-4 border ${
                      !isEditing
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white text-black"
                    } ${
                      errors.FollowUpInstructions
                        ? "border-red-500"
                        : "border-gray-300"
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pt-5">
            <button
              onClick={submitHandler}
              className={`w-1/2 mx-auto px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-sky-400 rounded-lg text-center active:scale-95`}
            >
              Save
            </button>
          </div>
        </div>
      </ModalComponent>

      <div className="p-6">
        {/* Header Section */}
        <div className="flex items-center justify-between">
          <div className="text-slate-800 text-2xl font-medium font-poppins leading-loose">
            {patient.Name || patient.FirstName + " " + patient.LastName}'s
            Profile
          </div>
          {selectedTab === 0 && (
            <div className="flex flex-row items-center space-x-6">
              <button
                onClick={toggleModal}
                className={`w-10 h-10 rounded-full bg-sky-100 border border-sky-500 text-sky-500 flex items-center justify-center active:scale-95`}
              >
                <RxDashboard />
              </button>
              {/* <button
            onClick={() => setShowAudioDictation(true)}
            className="px-3 py-2 bg-sky-400 active:bg-sky-500 active:scale-95 rounded shadow flex justify-center items-center gap-2.5"
          >
            <img
              src={micIcon}
              className="w-6 h-6 object-contain"
              alt="Mic Icon"
            />
            <div className="text-sky-100 text-sm font-normal font-poppins">
              Audio Dictation
            </div>
          </button> */}
            </div>
          )}
        </div>

        <Breadcrumb data={patient} />

        {/* Tabs & Add New Button */}
        <div className="flex flex-row items-center space-x-1 mt-6">
          {TABS.map((tab) => (
            <TabBar
              key={tab.id}
              id={tab.id}
              name={tab.name}
              icon={tab.icon}
              icon2={tab.icon2}
              selectedTab={selectedTab}
              onClick={setSelectedTab}
            />
          ))}
        </div>
      </div>

      {renderTabContent(selectedTab)}
    </div>
  );
}

export default PatientProfile;

const WIDGET = [
  {
    id: 1,
    name: "showMentalHealthScore",
    image: require("../assets/Images/PatientProfile/mentalHealthScore.png"),
  },
  {
    id: 2,
    name: "showFamilyMember",
    image: require("../assets/Images/PatientProfile/familyMember.png"),
  },
  {
    id: 3,
    name: "showAllergies",
    image: require("../assets/Images/PatientProfile/allergies.png"),
  },
  {
    id: 4,
    name: "showMedicalCondition",
    image: require("../assets/Images/PatientProfile/medicalCondition.png"),
  },
  {
    id: 5,
    name: "showUploads",
    image: require("../assets/Images/PatientProfile/upload.png"),
  },
  {
    id: 6,
    name: "showConsultation",
    image: require("../assets/Images/PatientProfile/consultation.png"),
  },
  {
    id: 7,
    name: "showDosageAdherence",
    image: require("../assets/Images/PatientProfile/dosageAdherence.png"),
  },
  {
    id: 8,
    name: "showHealthProgram",
    image: require("../assets/Images/PatientProfile/healthProgram.png"),
  },
];

const TABS = [
  {
    id: 0,
    name: "Patient Profile",
    icon: patientProfileIcon,
    icon2: patientProfileSelectedIcon,
  },
  {
    id: 1,
    name: "Appointment History",
    icon: appointmentHistoryIcon,
    icon2: appointmentHistorySelectedIcon,
  },
  {
    id: 2,
    name: "Conceirge Plans",
    icon: conceirgePlansIcon,
    icon2: conceirgePlansSelectedIcon,
  },
  {
    id: 3,
    name: "Requests And Remarks",
    icon: requestsAndRemarksIcon,
    icon2: requestsAndRemarksSelectedIcon,
  },
  {
    id: 4,
    name: "Medical Committee",
    icon: medicalCommitteeIcon,
    icon2: medicalCommitteeSelectedIcon,
  },
  {
    id: 5,
    name: "Chat Log",
    icon: medicalCommitteeIcon,
    icon2: medicalCommitteeSelectedIcon,
  },
];