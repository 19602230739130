import moment from "moment";
import React, { useState } from "react";
import { BiCollapseAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import File_dock from "../assets/Images/PatientProfile/File_dock.png";
import { useLocation, useNavigate } from "react-router-dom";
import ModalComponent from "../components/Modal";
import seeDocument from "../assets/Images/PatientProfile/seeDocument.png";
import toast from "react-hot-toast";
import { pdfjs } from "react-pdf";
import PdfComp from "../components/PdfComp";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import {
  addNotesToPatientProfile,
  addPrescription,
} from "../services/operations/patientManagementAPI";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function ConsultationHistory({
  consultationHistory,
  diagnosisType,
  patient,
  allMedicines,
  allPreMedicalConditions,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [filter, setFilter] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [isViewing, setIsViewing] = useState(false);
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const [conditionSuggestions, setconditionSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [medicines, setMedicines] = useState([
    {
      MedicineName: "",
      MedicineID: "",
      Frequency: "",
      DosageAmount: "",
      Meal: "",
      DurationCount: "",
      Duration: "",
      Details: "",
    },
  ]);
  const [formData, setFormData] = useState({
    DiseaseType: "",
    Stage: "",
    PreCondition: selectedConditions,
    PersonalNotes: "",
    DiagnosisInformation: "",
  });
  const [errors, setErrors] = useState({
    DiseaseType: "",
    Stage: "",
    PreCondition: "",
    PersonalNotes: "",
    DiagnosisInformation: "",
    medicines: [],
  });

  const submitHandler = async (event) => {
    event.preventDefault();

    const mandatoryFields = [
      "DiseaseType",
      "PersonalNotes",
      "DiagnosisInformation",
      // "Details",
    ];
    const newErrors = {
      DiseaseType: "",
      PersonalNotes: "",
      DiagnosisInformation: "",
      medicines: medicines.map(() => ({})), // Initialize medicines array with empty objects
    };

    // Validate main form fields
    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    // Validate medicine fields
    medicines.forEach((medicine, index) => {
      const medicineErrors = {};
      if (medicine.MedicineName) {
        // If MedicineName is provided, validate other fields
        if (!medicine.MedicineName || medicine.MedicineName.trim() === "") {
          medicineErrors.MedicineName = "This field is required";
        }
        if (!medicine.Frequency || medicine.Frequency.trim() === "") {
          medicineErrors.Frequency = "This field is required";
        }
        if (!medicine.DosageAmount || medicine.DosageAmount.trim() === "") {
          medicineErrors.DosageAmount = "This field is required";
        }
        if (!medicine.Meal || medicine.Meal.trim() === "") {
          medicineErrors.Meal = "This field is required";
        }
        if (!medicine.DurationCount || medicine.DurationCount <= 0) {
          medicineErrors.DurationCount =
            "This field is required and must be greater than 0";
        }
        if (!medicine.Duration || medicine.Duration.trim() === "") {
          medicineErrors.Duration = "This field is required";
        }
        // SpecialInstructions (Details) is not mandatory
      }
      if (Object.keys(medicineErrors).length > 0) {
        newErrors.medicines[index] = medicineErrors;
      }
    });

    if (
      mandatoryFields.some((key) => newErrors[key]) ||
      newErrors.medicines.some(
        (medicineError) => Object.keys(medicineError).length > 0
      )
    ) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    let transformedMedicines = medicines.map((medicine, index) => {
      return {
        SessionID: selectedItem?.AppointmentID,
        MedicationID: medicine.MedicineID,
        MedicineName: medicine?.MedicineName,
        AHUID: patient.AHUID,
        DosageAmount: medicine?.DosageAmount,
        Frequency: medicine.Frequency,
        StartDate: moment().format("YYYY-MM-DD"), // Replace with actual StartDate
        EndDate: moment()
          .add(medicine.DurationCount, medicine.Duration)
          .format("YYYY-MM-DD"), // Replace with actual EndDate
        Duration: medicine.DurationCount,
        DurationType: medicine.Duration,
        MealTime: medicine.Meal,
        SpecialInstructions: medicine.Details,
      };
    });

    let body = {
      SessionID: selectedItem?.AppointmentID,
      // SpecialInstructions: formData?.Details,
      DosageData: transformedMedicines,
    };

    let data = {
      DiagnosisID: formData?.DiseaseType,
      PersonalNote: formData?.PersonalNotes,
      DiagnosisInformation: formData?.DiagnosisInformation,
      AppointmentType: selectedItem?.AppointmentType,
      ...(selectedConditions.length > 0 && {
        PreMedicalCondition: selectedConditions.map(
          (condition) => condition.PMCID
        ),
      }),
      ...(formData?.Stage && {
        Stage: formData.Stage,
      }),
    };

    let ID = selectedItem?.AppointmentID;
    dispatch(addPrescription(token, body, navigate, patient));
    dispatch(addNotesToPatientProfile(token, ID, data, navigate, patient));
    handleModalClose();
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleAddMedicine = () => {
    setMedicines([
      ...medicines,
      {
        MedicineName: "",
        Frequency: "",
        DosageAmount: "",
        Meal: "",
        DurationCount: "",
        Duration: "",
        Details: "",
      },
    ]);
  };

  const handleRemoveMedicine = (index) => {
    setMedicines(medicines.filter((_, i) => i !== index));
  };

  const handleInputChange = (event, index) => {
    setActiveInputIndex(index);
    const { name, value } = event.target;
    const list = [...medicines];
    list[index][name] = value;
    setMedicines(list);

    if (value.length > 0) {
      const filteredSuggestions = allMedicines.filter((medication) =>
        medication.Name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }

    // clear errors
    const newErrors = { ...errors };
    if (newErrors.medicines[index]) {
      newErrors.medicines[index][name] = "";
      setErrors(newErrors);
    }
  };

  const handleOnChange2 = (event, index) => {
    const { name, value } = event.target;
    const list = [...medicines];

    // Check if the name is 'DurationCount' and the value length is more than 2
    if (name === "DurationCount" && value.length > 3) {
      return; // If so, don't update the state and exit the function
    }

    list[index][name] = value;
    setMedicines(list);

    // Remove error
    const newErrors = { ...errors };
    if (newErrors.medicines[index]) {
      newErrors.medicines[index][name] = "";
      setErrors(newErrors);
    }
  };

  const handleSuggestionClick = (medication) => {
    const list = [...medicines];
    list[list.length - 1].MedicineName = medication.Name;
    list[list.length - 1].MedicineID = medication.MedicationID;
    setMedicines(list);
    setSuggestions([]);

    // Remove error
    if (errors[list.length - 1] && errors[list.length - 1].MedicineName) {
      const newErrors = [...errors];
      newErrors[list.length - 1] = {
        ...newErrors[list.length - 1],
        MedicineName: undefined,
      };
      setErrors(newErrors);
    }
  };

  function handleOnChange(event) {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value) {
      setconditionSuggestions([]);
    } else {
      const filteredSuggestions = allPreMedicalConditions?.filter((condition) =>
        condition.Name.toLowerCase().includes(value.toLowerCase())
      );
      setconditionSuggestions(filteredSuggestions);
    }
  };

  const handleSelectCondition = (condition) => {
    const conditionExists = selectedConditions.find(
      (c) => c.PMCID === condition.PMCID
    );
    if (!conditionExists) {
      setSelectedConditions((prev) => [...prev, condition]);
    }
    setInputValue("");
    setconditionSuggestions([]);
  };

  const handleRemoveCondition = (pmcid) => {
    setSelectedConditions((prev) =>
      prev.filter((condition) => condition.PMCID !== pmcid)
    );
  };

  const handleModalOpen = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedItem({});
    setShowModal(false);
    setFormData({
      DiseaseType: "",
      PersonalNotes: "",
      DiagnosisInformation: "",
      Stage: "",
      PreCondition: selectedConditions,
    });
    setErrors({
      DiseaseType: "",
      PersonalNotes: "",
      DiagnosisInformation: "",
      Stage: "",
      PreCondition: "",
      medicines: [],
    });
    setMedicines([
      {
        MedicineName: "",
        Frequency: "",
        DosageAmount: "",
        Meal: "",
        DurationCount: "",
        Duration: "",
        Details: "",
      },
    ]);
    setIsViewing(false);
  };

  const handleSeeDetails = (item) => {
    setSelectedItem(item);
    setIsViewing(true);
    setShowModal(true);
    setFormData({
      DiseaseType: item.DiagnosisID,
      PersonalNotes: item.PersonalNote,
      DiagnosisInformation: item.DiagnosisInformation || "Not Provided",
      Details: item.SpecialInstructions || "Not Provided",
      Stage: item.Stage || "Not Provided",
      PreCondition: item.PreMedicalCondition || [],
    });
    setSelectedConditions(
      item.PreMedicalConditions?.map((conditionName) =>
        allPreMedicalConditions.find((c) => c.Name === conditionName)
      ) || []
    );

    setMedicines(
      item.DosageData?.map((medicine) => ({
        MedicineName: allMedicines.find(
          (med) => med.MedicationID === medicine.MedicationID
        )?.Name,
        MedicineID: medicine.MedicationID,
        Frequency: medicine.Frequency,
        DosageAmount: medicine.DosageAmount,
        Meal: medicine.MealTime,
        DurationCount: medicine.Duration,
        Duration: medicine.DurationType,
        Details: medicine.SpecialInstructions,
      }))
    );
  };

  return (
    <div className="px-6">
      <ModalComponent
        show={showModal}
        handleClose={handleModalClose}
        outSideModalPress={false}
      >
        <div className="w-[70vw]">
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center space-x-3">
              <img
                src={selectedItem.DocImage}
                alt=""
                className="w-12 h-12 rounded-full"
              />
              <div className="flex flex-col">
                <div className="text-black text-base font-semibold font-poppins">
                  {selectedItem.DoctorName}
                </div>
                <div className="text-black text-sm font-normal font-poppins">
                  {selectedItem.Speciality &&
                    selectedItem.Speciality.length > 0 &&
                    selectedItem.Speciality[0]}
                </div>
              </div>
            </div>
            <div>
              <div className="text-black text-base font-semibold font-poppins">
                Consultation ID : {selectedItem.AppointmentID}
              </div>
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 my-6"></div>
          <div className="px-4 max-h-[calc(100vh-400px)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            {/* Consultation Date and Appointment Type  */}
            <div className="flex space-x-16">
              <div>
                <div className="text-black text-sm font-semibold font-poppins">
                  Consultation Date
                </div>
                <div className=" text-black text-xs font-normal font-poppins leading-none mt-3">
                  {moment(selectedItem.ConsultationDate).format("DD MMMM YYYY")}
                </div>
              </div>
              <div>
                <div className="text-black text-sm font-semibold font-poppins">
                  Appointment Type
                </div>
                <div className=" text-black text-xs font-normal font-poppins leading-none mt-3">
                  {selectedItem.AppointmentType}
                </div>
              </div>
            </div>

            <div className="text-black text-sm font-semibold font-poppins mt-6">
              Consultation Form
            </div>

            <div className="w-1/2">
              <div className="flex flex-row items-center space-x-10 mt-4">
                {/* Disease Type  */}
                <div className="w-1/2">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                      Disease
                    </p>
                    <select
                      id="DiseaseType"
                      name="DiseaseType"
                      disabled={isViewing}
                      value={formData.DiseaseType}
                      className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                        errors.DiseaseType ? "border-red-500" : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      onChange={handleOnChange}
                    >
                      <option value="">Select Disease Type</option>
                      {diagnosisType.map((diagnosis) => (
                        <option key={diagnosis.ID} value={diagnosis.ID}>
                          {diagnosis.DiagnosisName}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                {/* Stage */}
                <div className="w-1/3">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                      Stage
                    </p>
                    <input
                      type="text"
                      id="Stage"
                      name="Stage"
                      disabled={isViewing}
                      value={formData.Stage}
                      maxLength={20}
                      onChange={handleOnChange}
                      placeholder="Enter Stage here"
                      className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                        errors.Stage ? "border-red-500" : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>
              </div>
              {/* Pre Medical Condition */}
              <div className="my-6 w-full relative">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Pre Medical Condition
                  </p>
                  <div className="relative w-1/2">
                    {!isViewing && (
                      <>
                        <input
                          type="text"
                          value={inputValue}
                          disabled={isViewing}
                          onChange={handleInputChange2}
                          placeholder="Enter Pre Medical Condition here"
                          className="w-full text-black text-xs bg-gray-100 rounded-md p-4 pl-4 pr-10 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent"
                          autoComplete="off"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                          <svg
                            className="h-4 w-4"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </div>
                      </>
                    )}
                  </div>
                  {conditionSuggestions.length > 0 && (
                    <div className="absolute w-1/2 bg-white border rounded-md mt-1">
                      {conditionSuggestions.map((condition) => (
                        <div
                          key={condition.PMCID}
                          onClick={() => handleSelectCondition(condition)}
                          className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                        >
                          {condition.Name}
                        </div>
                      ))}
                    </div>
                  )}
                </label>
                {selectedConditions.length !== 0 && (
                  <div className="w-full flex flex-row items-center flex-wrap gap-2 mt-4">
                    {selectedConditions.map((condition) => (
                      <div
                        key={condition.PMCID}
                        className={`px-4 py-1 bg-sky-100 rounded-2xl ${
                          !isViewing && "cursor-pointer"
                        } select-none`}
                        onClick={() =>
                          !isViewing && handleRemoveCondition(condition.PMCID)
                        }
                      >
                        <div className="text-sky-500 text-xs font-poppins">
                          {condition.Name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Personal Notes */}
              <div className="my-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Personal Notes (Not Shared with Patients)
                  </p>
                  <textarea
                    id="PersonalNotes"
                    name="PersonalNotes"
                    disabled={isViewing}
                    value={formData.PersonalNotes}
                    onChange={handleOnChange}
                    placeholder="Enter Personal Notes here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.PersonalNotes ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
              {/* DiagnosisInformation */}
              <div className="my-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Diagnosis Information
                  </p>
                  <textarea
                    id="DiagnosisInformation"
                    name="DiagnosisInformation"
                    disabled={isViewing}
                    value={formData.DiagnosisInformation}
                    onChange={handleOnChange}
                    placeholder="Enter Diagnosis Information here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.DiagnosisInformation ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  ></textarea>
                </label>
              </div>
            </div>
            {/* Input field */}
            <div className="flex flex-col">
              {medicines.map((medicine, index) => (
                <>
                  <div
                    key={index}
                    className="flex flex-row items-center space-x-3 mt-10"
                  >
                    {/* Medication  */}
                    <div className="w-40 relative">
                      <label>
                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                          Medicine {index + 1}
                          {/* <sup className="text-red-600">*</sup> */}
                        </p>
                        <input
                          type="text"
                          name="MedicineName"
                          autoComplete="off"
                          maxLength={20}
                          disabled={isViewing}
                          value={medicine.MedicineName}
                          onChange={(event) => handleInputChange(event, index)}
                          placeholder="Input name here"
                          className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                            errors.medicines[index] &&
                            errors.medicines[index].MedicineName
                              ? "border-red-500"
                              : ""
                          }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                      </label>
                      {activeInputIndex === index && suggestions.length > 0 && (
                        <div className="absolute w-full bg-white border rounded-md mt-1">
                          <div className="">
                            {suggestions.slice(0, 3).map((medication) => (
                              <div
                                key={medication.MedicationID}
                                onClick={() =>
                                  handleSuggestionClick(medication, index)
                                }
                                className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                              >
                                {medication.Name}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Frequency */}
                    <div className="">
                      <label>
                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                          Frequency
                        </p>
                        <select
                          id="Frequency"
                          name="Frequency"
                          disabled={isViewing}
                          value={medicine.Frequency}
                          onChange={(event) => handleOnChange2(event, index)}
                          className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                            errors.medicines[index] &&
                            errors.medicines[index].Frequency
                              ? "border-red-500"
                              : ""
                          } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        >
                          <option value="">Select Frequency</option>
                          <option value="Once">Once</option>
                          <option value="Twice">Twice</option>
                          <option value="Thrice">Thrice</option>
                        </select>
                      </label>
                    </div>
                    {/* Dosage Amount*/}
                    <div className="w-28">
                      <label>
                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                          Dosage Amount
                        </p>
                        <input
                          type="text"
                          name="DosageAmount"
                          disabled={isViewing}
                          maxLength={6}
                          value={medicine.DosageAmount}
                          onChange={(event) => handleOnChange2(event, index)}
                          placeholder="Eg. 5ml"
                          className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                            errors.medicines[index] &&
                            errors.medicines[index].DosageAmount
                              ? "border-red-500"
                              : ""
                          }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                      </label>
                    </div>
                    {/* Meal */}
                    <div className="">
                      <label>
                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                          Meal
                        </p>
                        <select
                          id="Meal"
                          name="Meal"
                          value={medicine.Meal}
                          disabled={isViewing}
                          onChange={(event) => handleOnChange2(event, index)}
                          className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                            errors.medicines[index] &&
                            errors.medicines[index].Meal
                              ? "border-red-500"
                              : ""
                          } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        >
                          <option value="">Select Meal</option>
                          <option value="Pre Meal">Pre Meal</option>
                          <option value="Post Meal">Post Meal</option>
                        </select>
                      </label>
                    </div>
                    {/* Duration Count*/}
                    <div className="w-24">
                      <label>
                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                          Duration
                        </p>
                        <input
                          type="number"
                          name="DurationCount"
                          disabled={isViewing}
                          onKeyDown={(e) => {
                            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          maxLength={2}
                          value={medicine.DurationCount}
                          onChange={(event) => handleOnChange2(event, index)}
                          placeholder="Input here"
                          className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                            errors.medicines[index] &&
                            errors.medicines[index].DurationCount
                              ? "border-red-500"
                              : ""
                          }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                      </label>
                    </div>
                    {/* Duration */}
                    <div className="pt-3">
                      <label>
                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2"></p>
                        <select
                          id="Duration"
                          name="Duration"
                          disabled={isViewing}
                          value={medicine.Duration}
                          onChange={(event) => handleOnChange2(event, index)}
                          className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                            errors.medicines[index] &&
                            errors.medicines[index].Duration
                              ? "border-red-500"
                              : ""
                          } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        >
                          <option value="">Select Duration</option>
                          <option value="Day">Day</option>
                          <option value="Week">Week</option>
                          <option value="Month">Month</option>
                        </select>
                      </label>
                    </div>
                    {!isViewing && (
                      <div className="flex">
                        {index < medicines.length - 1 ? (
                          <button
                            onClick={() => handleRemoveMedicine(index)}
                            className="bg-red-100 active:scale-90 w-8 h-8 mt-4 rounded-full flex items-center justify-center mr-2"
                          >
                            <IoMdRemove className="text-red-500" />
                          </button>
                        ) : null}
                        {index === medicines.length - 1 ? (
                          <>
                            {medicines.length > 1 ? (
                              <button
                                onClick={() => handleRemoveMedicine(index)}
                                className="bg-red-100 active:scale-90 w-8 h-8 mt-4 rounded-full flex items-center justify-center mr-2"
                              >
                                <IoMdRemove className="text-red-500" />
                              </button>
                            ) : null}
                            <button
                              onClick={handleAddMedicine}
                              className="bg-sky-100 active:scale-90 w-8 h-8 mt-4 rounded-full flex items-center justify-center"
                            >
                              <IoMdAdd className="text-sky-500" />
                            </button>
                          </>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {/* Details */}
                  <div className="my-6">
                    <label>
                      <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                        Special Instructions (if any)
                      </p>
                      <textarea
                        id="Details"
                        name="Details"
                        value={medicine.Details}
                        disabled={isViewing}
                        onChange={(event) => handleOnChange2(event, index)}
                        placeholder="Enter instruction here"
                        className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                          errors.medicines[index] &&
                          errors.medicines[index].Details
                            ? "border-red-500"
                            : ""
                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      ></textarea>
                    </label>
                  </div>
                </>
              ))}
            </div>
            {/* <div className="text-black text-sm font-semibold font-poppins">
              Prescription
            </div>
            <div className="flex flex-col items-center justify-center space-y-4 mt-4">
              {UPLOADEDREPORT.map((report) => (
                <div
                  key={report.id}
                  className="w-full h-20 bg-zinc-100 rounded-lg flex flex-row justify-between items-center p-4"
                >
                  <div>
                    <div className="text-right text-black text-xs font-semibold font-poppins">
                      {report?.title}
                    </div>
                    <div className="text-zinc-500 text-xs font-normal font-poppins mt-1">
                      {report?.date}
                    </div>
                  </div>
                  <div
                    className="flex flex-row items-center space-x-2 cursor-pointer"
                    onClick={() => {
                      handleOpenUploadedDocument(report);
                    }}
                  >
                    <img
                      src={seeDocument}
                      alt={report.title}
                      className="w-6 h-6 object-contain"
                    />
                    <div className="text-sky-500 text-xs font-normal font-poppins">
                      See Document
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
          {!isViewing && (
            <div className="flex justify-center items-center pt-5">
              <button
                onClick={submitHandler}
                className={`w-full px-11 py-3 text-sky-400 text-sm font-semibold font-poppins shadow bg-sky-100 rounded-lg text-center active:scale-95`}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </ModalComponent>
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex flex-row items-center space-x-5">
          <div className="text-black text-lg font-semibold font-poppins">
            Consultation
          </div>
          <div className="flex flex-row items center space-x-3">
            <button
              onClick={() => handleFilterChange("all")}
              className={`px-6 py-2 ${
                filter === "all" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <div
                className={`text-${
                  filter === "all" ? "sky" : "zinc"
                }-500 text-xs font-semibold font-poppins`}
              >
                All
              </div>
            </button>
            <button
              onClick={() => handleFilterChange("current")}
              className={`px-6 py-2 ${
                filter === "current" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <div
                className={`text-${
                  filter === "current" ? "sky" : "zinc"
                }-500 text-xs font-semibold font-poppins`}
              >
                Current
              </div>
            </button>
            <button
              onClick={() => handleFilterChange("previous")}
              className={`px-6 py-2 ${
                filter === "previous" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
            >
              <div
                className={`text-${
                  filter === "previous" ? "sky" : "zinc"
                }-500 text-xs font-semibold font-poppins`}
              >
                Previous
              </div>
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="flex border-b pb-5">
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Appointment ID
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Treating Doctor
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Consultation Date
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Notes
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Speciality
            </div>
            <div className="text-black text-sm font-semibold font-poppins text-center flex-1">
              Action
            </div>
          </div>
          {consultationHistory
            ?.filter((item) => {
              if (filter === "all") return true;
              if (filter === "current") return item.PersonalNote === null;
              if (filter === "previous") return item.PersonalNote !== null;
            })
            .map((item, index, arr) => (
              <div
                key={item.ID}
                className="flex mt-5 border-b pb-5 items-center gap-2"
              >
                <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                  {item?.AppointmentID}
                </div>
                <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                  {item?.DoctorName}
                </div>
                <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                  {moment(item?.ConsultationDate).format("DD/MM/YYYY")}
                </div>
                <div className="text-black text-sm font-normal font-poppins flex-1 text-center">
                  {item?.PersonalNote}
                </div>
                <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                  {item.Speciality &&
                    item.Speciality.length > 0 &&
                    item.Speciality[0]}
                  ...
                </div>
                <div className="text-black text-sm font-normal font-poppins text-center flex-1">
                  {item?.PersonalNote === null ? (
                    <button
                      onClick={() => {
                        handleModalOpen(item);
                      }}
                      className="p-2 bg-sky-400 rounded-lg active:scale-95"
                    >
                      <div className="text-white text-sm font-semibold font-poppins leading-tight">
                        Add Notes
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleSeeDetails(item);
                      }}
                      className="text-sky-400 text-sm font-semibold font-poppins leading-tight"
                    >
                      See Details
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ConsultationHistory;