import React, { useEffect, useRef, useState } from "react";

const MultiSelectDropdown = ({
  name,
  placeholder,
  options,
  onSelect,
  error,
  value,
  labelKey,
  valueKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSelect = (option, e) => {
    e.preventDefault();
    e.stopPropagation();
    const isSelected = selectedOptions.some(
      (item) =>
        (valueKey ? item[valueKey] : item) ===
        (valueKey ? option[valueKey] : option)
    );
    let updatedSelection;
    if (isSelected) {
      updatedSelection = selectedOptions.filter(
        (item) =>
          (valueKey ? item[valueKey] : item) !==
          (valueKey ? option[valueKey] : option)
      );
    } else {
      updatedSelection = [...selectedOptions, option];
    }
    setSelectedOptions(updatedSelection);
    onSelect(
      name,
      updatedSelection.map((item) => (valueKey ? item[valueKey] : item))
    );
  };

  const removeOption = (option, e) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedSelection = selectedOptions.filter(
      (item) =>
        (valueKey ? item[valueKey] : item) !==
        (valueKey ? option[valueKey] : option)
    );
    setSelectedOptions(updatedSelection);
    onSelect(
      name,
      updatedSelection.map((item) => (valueKey ? item[valueKey] : item))
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      const selectedValues = options.filter((option) =>
        value.includes(valueKey ? option[valueKey] : option)
      );
      setSelectedOptions(selectedValues);
    }
  }, [value, options, valueKey]);

  const getOptionLabel = (option) => {
    if (typeof option === "string") return option;
    return labelKey ? option[labelKey] : option.toString();
  };

  return (
    <div className="relative text-left" ref={dropdownRef}>
      <div
        className={`w-full ${
          error ? "border-red-500" : ""
        } min-h-[48px] text-xs font-normal font-poppins bg-gray-100 rounded-md p-2 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent flex flex-wrap items-center cursor-pointer`}
        onClick={toggleDropdown}
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map((option) => (
            <span
              key={valueKey ? option[valueKey] : option}
              className="bg-sky-100 text-sky-800 rounded-full px-2 py-1 m-1 flex items-center"
              onClick={(e) => e.stopPropagation()}
            >
              {getOptionLabel(option)}
              <button
                className="ml-1 text-sky-600 hover:text-sky-800"
                onClick={(e) => removeOption(option, e)}
              >
                ×
              </button>
            </span>
          ))
        ) : (
          <span className="text-gray-400">{placeholder}</span>
        )}
      </div>

      {isOpen && (
        <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 shadow-lg z-10 max-h-[22vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          {options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={valueKey ? option[valueKey] : index}
                className={`flex items-center justify-between w-full text-left px-4 py-2 ${
                  selectedOptions.some(
                    (item) =>
                      (valueKey ? item[valueKey] : item) ===
                      (valueKey ? option[valueKey] : option)
                  )
                    ? "text-sky-500 bg-sky-100"
                    : "text-zinc-900 hover:bg-gray-100"
                } text-sm font-normal font-poppins leading-tight cursor-pointer`}
                onClick={(e) => handleSelect(option, e)}
              >
                <span className="flex items-center w-full">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectedOptions.some(
                      (item) =>
                        (valueKey ? item[valueKey] : item) ===
                        (valueKey ? option[valueKey] : option)
                    )}
                    onChange={() => {}}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {getOptionLabel(option)}
                </span>
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-sm text-gray-500">
              No options available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;