import React, { useEffect, useState } from "react";
import { Button, message, Steps, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cal_Icon from "../assets/Images/PatientRegistration/cal_Icon.png";
import bgImage1 from "../assets/Images/PatientRegistration/bgImage1.png";
import bgImage2 from "../assets/Images/PatientRegistration/bgImage2.png";
import bgImage3 from "../assets/Images/PatientRegistration/bgImage3.png";
import bgImage4 from "../assets/Images/PatientRegistration/bgImage4.png";
import toast from "react-hot-toast";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../App.css";
import { addNewPatient } from "../services/operations/patientManagementAPI";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import { RxDashboard } from "react-icons/rx";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { FaChevronLeft } from "react-icons/fa6";
import Breadcrumb from "./Breadcrumb";
import { HealthManagerList } from "../data/healthManagerList";
import Dropdown from "./Dropdown/Dropdown";

const libraries = ["places"];

const steps = [
  {
    title: "Personal Details",
  },
  {
    title: "Address",
  },
  {
    title: "Administration",
  },
  {
    title: "Others",
  },
  {
    title: "Conceirge",
    // title: "Concirge Plans",
  },

  //   {
  //     title: "Health Manager",
  //   },
];

const images = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage3];

function AddNewConceirgePatient() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);
  const { token } = useSelector((state) => state.auth);
  const { branches } = useSelector((state) => state.masterData);
  const [current, setCurrent] = useState(3);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DateofBirth: "",
    PhoneNumber: "",
    Email: "",
    StreetName: "",
    HouseNumber: "",
    PinCode: "",
    State: "",
    City: "",
    Country: "",
    BranchID: "",
    Occupation: "",
    LivingArrangement: "",
    RelationshipStatus: "",
    Employment: "",
    InsuranceProvider: "",
    PolicyNumber: "",
    AHVNo: "",
    Latitude: "",
    Longitude: "",
    Plan: "",
    HealthManager: "",
  });
  const [errors, setErrors] = useState({
    FirstName: "",
    LastName: "",
    Gender: "",
    DateofBirth: "",
    PhoneNumber: "",
    Email: "",
    StreetName: "",
    HouseNumber: "",
    PinCode: "",
    State: "",
    City: "",
    Country: "",
    BranchID: "",
    Occupation: "",
    LivingArrangement: "",
    RelationshipStatus: "",
    Employment: "",
    InsuranceProvider: "",
    PolicyNumber: "",
    AHVNo: "",
    Plan: "",
    HealthManager: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks",
    libraries,
  });

  // if (loadError) return <div>Error loading maps</div>;
  // if (!isLoaded) return <div>Loading Maps...</div>;

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    getPlacePredictions(value);
  };

  const getPlacePredictions = (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input }, (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        console.error(status);
        return;
      }

      setSuggestions(predictions);
    });
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "PinCode":
        maxLength = 6;
        break;
      case "PolicyNumber":
        maxLength = 16;
        break;
      case "AHVNo":
        maxLength = 13; // Maximum characters without periods
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    if (name === "AHVNo") {
      // Remove periods to process the raw input
      const rawValue = value.replace(/\./g, "");

      // Add periods at the appropriate positions
      let formattedValue = rawValue.slice(0, 3);
      if (rawValue.length > 3) {
        formattedValue += "." + rawValue.slice(3, 7);
      }
      if (rawValue.length > 7) {
        formattedValue += "." + rawValue.slice(7, 11);
      }
      if (rawValue.length > 11) {
        formattedValue += "." + rawValue.slice(11, 13);
      }

      // Ensure the length of the raw value does not exceed maxLength
      if (rawValue.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: formattedValue,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else if (name === "DateofBirth" && new Date(value) > new Date()) {
      toast.error("Date of Birth cannot be in the future");
      setFormData((prev) => ({
        ...prev,
        [name]: "", // Reset the DateofBirth to empty or you can set it to current date
      }));
    } else {
      // For other types, handle validation without removing non-numeric characters
      if (value.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  }

  const validateStepFields = () => {
    let isValid = true;
    const newErrors = {};

    // Define required fields for each step
    const requiredFields = {
      0: [
        "FirstName",
        "LastName",
        "DateofBirth",
        "Gender",
        "PhoneNumber",
        "Email",
      ],
      1: [
        "StreetName",
        "HouseNumber",
        "PinCode",
        "State",
        "City",
        "Country",
        "BranchID",
      ],
      2: ["AHVNo"],
      3: [],
      4: ["Plan", "HealthManager"],
    };

    if (requiredFields[current]) {
      requiredFields[current].forEach((field) => {
        // Check if formData[field] is not undefined before calling trim()
        if (!formData[field] || !formData[field].trim()) {
          newErrors[field] = "This field is required";
          isValid = false;
        }
      });
    }

    // Update the errors state
    setErrors(newErrors);
    // Show error toast only if there is an error
    if (!isValid) {
      toast.error("Please fill all the required fields");
    }
    return isValid;
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submit behavior
      // Call the function to change the form or submit data
      // For example, if you want to move to the next form section
      next();
    }
  }

  const next = () => {
    if (validateStepFields()) {
      setCurrent(current + 1);
    }
    setSuggestions([]);
  };

  const prev = () => {
    setCurrent(current - 1);
    setSuggestions([]);
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    let data = {
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      DOB: moment(formData.DateofBirth).format("YYYY-MM-DD"),
      Gender: formData?.Gender,
      Phone: formData.PhoneNumber,
      Email: formData.Email,
      Address: formData.HouseNumber + " " + formData.StreetName,
      PinCode: formData.PinCode,
      State: formData.State,
      City: formData.City,
      Country: formData.Country,
      InsuranceProvider: formData.InsuranceProvider,
      PolicyNumber: formData.PolicyNumber,
      AHVNumber: formData?.AHVNo,
      Occupation: formData.Occupation,
      Employment: formData.Employment,
      LivingArrangement: formData.LivingArrangement,
      RelationshipStatus: formData.RelationshipStatus,
      BranchID: parseInt(formData.BranchID),
      Latitude: formData.Latitude || 0,
      Longitude: formData.Longitude || 0,
    };

    console.log("DATA", data);
    dispatch(addNewPatient(token, data, navigate));
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (x) => (x * Math.PI) / 180;

    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const handleSuggestionClick = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = place.address_components.reduce(
          (acc, component) => {
            const types = component.types;
            if (types.includes("sublocality_level_2"))
              acc.streetNumber = component.long_name;
            if (types.includes("sublocality")) acc.street = component.long_name;
            if (types.includes("postal_code"))
              acc.pinCode = component.long_name;
            if (types.includes("administrative_area_level_1"))
              acc.state = component.long_name;
            if (types.includes("country")) acc.country = component.long_name;
            if (types.includes("locality")) acc.city = component.long_name;
            return acc;
          },
          {}
        );
        const { lat, lng } = place.geometry.location;
        setFormData((prevFormData) => ({
          ...prevFormData,
          StreetName: `${addressComponents.streetNumber} ${addressComponents.street}`,
          PinCode: addressComponents.pinCode,
          State: addressComponents.state,
          Country: addressComponents.country.toUpperCase(),
          City: addressComponents.city,
          Latitude: lat(),
          Longitude: lng(),
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          StreetName: "",
          PinCode: "",
          State: "",
          Country: "",
          City: "",
        }));

        setInputValue(place.formatted_address);
        setSuggestions([]);
        // assignNearestBranch();
      } else {
        console.error("Place details request failed:", status);
      }
    });
  };

  const assignNearestBranch = () => {
    if (formData.Latitude && formData.Longitude) {
      let nearestBranch = null;
      let minDistance = Infinity;

      branches.forEach((branch) => {
        console.log("Debug 1 Branch: ", branch);
        if (branch.Latitude && branch.Longitude) {
          const distance = haversineDistance(
            formData.Latitude,
            formData.Longitude,
            parseFloat(branch.Latitude),
            parseFloat(branch.Longitude)
          );

          if (distance < minDistance) {
            minDistance = distance;
            nearestBranch = branch.BranchID;
          }
        }
      });

      if (nearestBranch !== null) {
        setFormData((prev) => ({
          ...prev,
          BranchID: nearestBranch.toString(),
        }));
      }
    }
  };

  useEffect(() => {
    assignNearestBranch();
  }, [formData.Latitude, formData.Longitude]);

  const renderInputFields = () => {
    switch (current) {
      case 0:
        return (
          <>
            <div className="mb-3 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  First Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="FirstName"
                  maxLength={20}
                  value={formData.FirstName}
                  onChange={handleOnChange}
                  placeholder="Input your first name here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.FirstName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Last Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="LastName"
                  maxLength={20}
                  value={formData.LastName}
                  onChange={handleOnChange}
                  placeholder="Input your last name here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.LastName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Date of Birth
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="date"
                  name="DateofBirth"
                  value={moment(formData.DateofBirth).format("YYYY-MM-DD")}
                  placeholder="Select Date of Birth"
                  onChange={handleOnChange}
                  max={moment().format("YYYY-MM-DD")}
                  className={`w-full accent-sky-500 text-sky-500 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.DateofBirth ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Gender
                  <sup className="text-red-600">*</sup>
                </p>
                <div className="flex space-x-2">
                  <button
                    className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-3 border ${
                      formData.Gender === "Male"
                        ? "border-sky-100 text-white bg-sky-500"
                        : "border-sky-500 text-sky-500  bg-white"
                    }`}
                    onClick={() => setFormData({ ...formData, Gender: "Male" })}
                  >
                    <IoMaleSharp />
                    <div className="text-sm font-medium font-poppins">Male</div>
                  </button>
                  <button
                    className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-3 border ${
                      formData.Gender === "Female"
                        ? "border-sky-100 text-white bg-rose-500"
                        : "border-rose-500 text-rose-500  bg-white"
                    }`}
                    onClick={() =>
                      setFormData({ ...formData, Gender: "Female" })
                    }
                  >
                    <IoFemaleSharp />
                    <div className="text-sm font-semibold font-poppins">
                      Female
                    </div>
                  </button>
                  <button
                    className={`w-full flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-3 border ${
                      formData.Gender === "Diverse"
                        ? "border-sky-100 text-white bg-lime-600"
                        : "border-lime-600 text-lime-600  bg-white"
                    }`}
                    onClick={() =>
                      setFormData({ ...formData, Gender: "Diverse" })
                    }
                  >
                    <LiaTransgenderSolid />
                    <div className="text-sm font-semibold font-poppins">
                      Diverse
                    </div>
                  </button>
                </div>
              </label>
            </div>
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="email"
                  name="Email"
                  // maxLength={50}
                  value={formData.Email}
                  onChange={handleOnChange}
                  placeholder="Input your email here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="mb-3 flex space-x-4">
              <label className="flex-1 relative">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Street Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="StreetName"
                  maxLength={50}
                  // value={formData.StreetName}
                  // onChange={handleOnChange}
                  value={inputValue}
                  onChange={handleChange}
                  placeholder="Input your street name here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.StreetName ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
                {suggestions.length > 0 && (
                  <div className="absolute left-0 mt-2 w-[25vw] bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                    {suggestions?.slice(0, 4).map((x, i) => (
                      <button
                        key={i}
                        onClick={() => handleSuggestionClick(x.place_id)}
                        className={`block w-full text-left px-4 py-2 text-xs rounded-lg bg-gray-100 text-black hover:bg-sky-500 hover:text-white duration-200`}
                      >
                        {x.description}
                      </button>
                    ))}
                  </div>
                )}
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  House Number
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="HouseNumber"
                  maxLength={10}
                  value={formData.HouseNumber}
                  onChange={handleOnChange}
                  placeholder="Input your house number here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.HouseNumber ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-3 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Pin Code
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="PinCode"
                  maxLength={6}
                  value={formData.PinCode}
                  onChange={handleOnChange}
                  placeholder="Input your pin code here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.PinCode ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  City
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="City"
                  maxLength={50}
                  value={formData.City}
                  onChange={handleOnChange}
                  placeholder="Input your city here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.City ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-3 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Region / State / Canton
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="State"
                  maxLength={50}
                  value={formData.State}
                  onChange={handleOnChange}
                  placeholder="Input your state here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.State ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Country
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="Country"
                  name="Country"
                  value={formData.Country}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.Country ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  onChange={handleOnChange}
                >
                  <option value="">Select Country</option>
                  <option value="AFGHANISTAN">Afghanistan</option>
                  <option value="ALBANIA">Albania</option>
                  <option value="ALGERIA">Algeria</option>
                  <option value="ANDORRA">Andorra</option>
                  <option value="ANGOLA">Angola</option>
                  <option value="ANTIGUA AND BARBUDA">
                    Antigua and Barbuda
                  </option>
                  <option value="ARGENTINA">Argentina</option>
                  <option value="ARMENIA">Armenia</option>
                  <option value="AUSTRALIA">Australia</option>
                  <option value="AUSTRIA">Austria</option>
                  <option value="AZERBAIJAN">Azerbaijan</option>
                  <option value="BAHAMAS">Bahamas</option>
                  <option value="BAHRAIN">Bahrain</option>
                  <option value="BANGLADESH">Bangladesh</option>
                  <option value="BARBADOS">Barbados</option>
                  <option value="BELARUS">Belarus</option>
                  <option value="BELGIUM">Belgium</option>
                  <option value="BELIZE">Belize</option>
                  <option value="BENIN">Benin</option>
                  <option value="BHUTAN">Bhutan</option>
                  <option value="BOLIVIA">Bolivia</option>
                  <option value="BOSNIA AND HERZEGOVINA">
                    Bosnia and Herzegovina
                  </option>
                  <option value="BOTSWANA">Botswana</option>
                  <option value="BRAZIL">Brazil</option>
                  <option value="BRUNEI">Brunei</option>
                  <option value="BULGARIA">Bulgaria</option>
                  <option value="BURKINA FASO">Burkina Faso</option>
                  <option value="BURUNDI">Burundi</option>
                  <option value="CABO VERDE">Cabo Verde</option>
                  <option value="CAMBODIA">Cambodia</option>
                  <option value="CAMEROON">Cameroon</option>
                  <option value="CANADA">Canada</option>
                  <option value="CENTRAL AFRICAN REPUBLIC">
                    Central African Republic
                  </option>
                  <option value="CHAD">Chad</option>
                  <option value="CHILE">Chile</option>
                  <option value="CHINA">China</option>
                  <option value="COLOMBIA">Colombia</option>
                  <option value="COMOROS">Comoros</option>
                  <option value="CONGO, DEMOCRATIC REPUBLIC OF THE">
                    Congo, Democratic Republic of the
                  </option>
                  <option value="CONGO, REPUBLIC OF THE">
                    Congo, Republic of the
                  </option>
                  <option value="COSTA RICA">Costa Rica</option>
                  <option value="CROATIA">Croatia</option>
                  <option value="CUBA">Cuba</option>
                  <option value="CYPRUS">Cyprus</option>
                  <option value="CZECH REPUBLIC">Czech Republic</option>
                  <option value="DENMARK">Denmark</option>
                  <option value="DJIBOUTI">Djibouti</option>
                  <option value="DOMINICA">Dominica</option>
                  <option value="DOMINICAN REPUBLIC">Dominican Republic</option>
                  <option value="ECUADOR">Ecuador</option>
                  <option value="EGYPT">Egypt</option>
                  <option value="EL SALVADOR">El Salvador</option>
                  <option value="EQUATORIAL GUINEA">Equatorial Guinea</option>
                  <option value="ERITREA">Eritrea</option>
                  <option value="ESTONIA">Estonia</option>
                  <option value="ESWATINI">Eswatini</option>
                  <option value="ETHIOPIA">Ethiopia</option>
                  <option value="FIJI">Fiji</option>
                  <option value="FINLAND">Finland</option>
                  <option value="FRANCE">France</option>
                  <option value="GABON">Gabon</option>
                  <option value="GAMBIA">Gambia</option>
                  <option value="GEORGIA">Georgia</option>
                  <option value="GERMANY">Germany</option>
                  <option value="GHANA">Ghana</option>
                  <option value="GREECE">Greece</option>
                  <option value="GRENADA">Grenada</option>
                  <option value="GUATEMALA">Guatemala</option>
                  <option value="GUINEA">Guinea</option>
                  <option value="GUINEA-BISSAU">Guinea-Bissau</option>
                  <option value="GUYANA">Guyana</option>
                  <option value="HAITI">Haiti</option>
                  <option value="HONDURAS">Honduras</option>
                  <option value="HUNGARY">Hungary</option>
                  <option value="ICELAND">Iceland</option>
                  <option value="INDIA">India</option>
                  <option value="INDONESIA">Indonesia</option>
                  <option value="IRAN">Iran</option>
                  <option value="IRAQ">Iraq</option>
                  <option value="IRELAND">Ireland</option>
                  <option value="ISRAEL">Israel</option>
                  <option value="ITALY">Italy</option>
                  <option value="JAMAICA">Jamaica</option>
                  <option value="JAPAN">Japan</option>
                  <option value="JORDAN">Jordan</option>
                  <option value="KAZAKHSTAN">Kazakhstan</option>
                  <option value="KENYA">Kenya</option>
                  <option value="KIRIBATI">Kiribati</option>
                  <option value="KOREA, NORTH">Korea, North</option>
                  <option value="KOREA, SOUTH">Korea, South</option>
                  <option value="KOSOVO">Kosovo</option>
                  <option value="KUWAIT">Kuwait</option>
                  <option value="KYRGYZSTAN">Kyrgyzstan</option>
                  <option value="LAOS">Laos</option>
                  <option value="LATVIA">Latvia</option>
                  <option value="LEBANON">Lebanon</option>
                  <option value="LESOTHO">Lesotho</option>
                  <option value="LIBERIA">Liberia</option>
                  <option value="LIBYA">Libya</option>
                  <option value="LIECHTENSTEIN">Liechtenstein</option>
                  <option value="LITHUANIA">Lithuania</option>
                  <option value="LUXEMBOURG">Luxembourg</option>
                  <option value="MADAGASCAR">Madagascar</option>
                  <option value="MALAWI">Malawi</option>
                  <option value="MALAYSIA">Malaysia</option>
                  <option value="MALDIVES">Maldives</option>
                  <option value="MALI">Mali</option>
                  <option value="MALTA">Malta</option>
                  <option value="MARSHALL ISLANDS">Marshall Islands</option>
                  <option value="MAURITANIA">Mauritania</option>
                  <option value="MAURITIUS">Mauritius</option>
                  <option value="MEXICO">Mexico</option>
                  <option value="MICRONESIA">Micronesia</option>
                  <option value="MOLDOVA">Moldova</option>
                  <option value="MONACO">Monaco</option>
                  <option value="MONGOLIA">Mongolia</option>
                  <option value="MONTENEGRO">Montenegro</option>
                  <option value="MOROCCO">Morocco</option>
                  <option value="MOZAMBIQUE">Mozambique</option>
                  <option value="MYANMAR">Myanmar</option>
                  <option value="NAMIBIA">Namibia</option>
                  <option value="NAURU">Nauru</option>
                  <option value="NEPAL">Nepal</option>
                  <option value="NETHERLANDS">Netherlands</option>
                  <option value="NEW ZEALAND">New Zealand</option>
                  <option value="NICARAGUA">Nicaragua</option>
                  <option value="NIGER">Niger</option>
                  <option value="NIGERIA">Nigeria</option>
                  <option value="NORTH MACEDONIA">North Macedonia</option>
                  <option value="NORWAY">Norway</option>
                  <option value="OMAN">Oman</option>
                  <option value="PAKISTAN">Pakistan</option>
                  <option value="PALAU">Palau</option>
                  <option value="PALESTINE">Palestine</option>
                  <option value="PANAMA">Panama</option>
                  <option value="PAPUA NEW GUINEA">Papua New Guinea</option>
                  <option value="PARAGUAY">Paraguay</option>
                  <option value="PERU">Peru</option>
                  <option value="PHILIPPINES">Philippines</option>
                  <option value="POLAND">Poland</option>
                  <option value="PORTUGAL">Portugal</option>
                  <option value="QATAR">Qatar</option>
                  <option value="ROMANIA">Romania</option>
                  <option value="RUSSIA">Russia</option>
                  <option value="RWANDA">Rwanda</option>
                  <option value="SAINT KITTS AND NEVIS">
                    Saint Kitts and Nevis
                  </option>
                  <option value="SAINT LUCIA">Saint Lucia</option>
                  <option value="SAINT VINCENT AND THE GRENADINES">
                    Saint Vincent and the Grenadines
                  </option>
                  <option value="SAMOA">Samoa</option>
                  <option value="SAN MARINO">San Marino</option>
                  <option value="SAO TOME AND PRINCIPE">
                    Sao Tome and Principe
                  </option>
                  <option value="SAUDI ARABIA">Saudi Arabia</option>
                  <option value="SENEGAL">Senegal</option>
                  <option value="SERBIA">Serbia</option>
                  <option value="SEYCHELLES">Seychelles</option>
                  <option value="SIERRA LEONE">Sierra Leone</option>
                  <option value="SINGAPORE">Singapore</option>
                  <option value="SLOVAKIA">Slovakia</option>
                  <option value="SLOVENIA">Slovenia</option>
                  <option value="SOLOMON ISLANDS">Solomon Islands</option>
                  <option value="SOMALIA">Somalia</option>
                  <option value="SOUTH AFRICA">South Africa</option>
                  <option value="SOUTH SUDAN">South Sudan</option>
                  <option value="SPAIN">Spain</option>
                  <option value="SRI LANKA">Sri Lanka</option>
                  <option value="SUDAN">Sudan</option>
                  <option value="SURINAME">Suriname</option>
                  <option value="SWEDEN">Sweden</option>
                  <option value="SWITZERLAND">Switzerland</option>
                  <option value="SYRIA">Syria</option>
                  <option value="TAIWAN">Taiwan</option>
                  <option value="TAJIKISTAN">Tajikistan</option>
                  <option value="TANZANIA">Tanzania</option>
                  <option value="THAILAND">Thailand</option>
                  <option value="TIMOR-LESTE">Timor-Leste</option>
                  <option value="TOGO">Togo</option>
                  <option value="TONGA">Tonga</option>
                  <option value="TRINIDAD AND TOBAGO">
                    Trinidad and Tobago
                  </option>
                  <option value="TUNISIA">Tunisia</option>
                  <option value="TURKEY">Turkey</option>
                  <option value="TURKMENISTAN">Turkmenistan</option>
                  <option value="TUVALU">Tuvalu</option>
                  <option value="UGANDA">Uganda</option>
                  <option value="UKRAINE">Ukraine</option>
                  <option value="UNITED ARAB EMIRATES">
                    United Arab Emirates
                  </option>
                  <option value="UNITED KINGDOM">United Kingdom</option>
                  <option value="UNITED STATES">United States</option>
                  <option value="URUGUAY">Uruguay</option>
                  <option value="UZBEKISTAN">Uzbekistan</option>
                  <option value="VANUATU">Vanuatu</option>
                  <option value="VATICAN CITY">Vatican City</option>
                  <option value="VENEZUELA">Venezuela</option>
                  <option value="VIETNAM">Vietnam</option>
                  <option value="YEMEN">Yemen</option>
                  <option value="ZAMBIA">Zambia</option>
                  <option value="ZIMBABWE">Zimbabwe</option>
                </select>
              </label>
            </div>
            <div className="">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-4">
                Assigned Main Branch
                <sup className="text-red-600">*</sup>
              </p>
              <div className="grid grid-cols-2 gap-4 max-h-[17vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                {branches.map((branch) => (
                  <label
                    key={branch.BranchID}
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="BranchID"
                      value={branch.BranchID}
                      checked={formData.BranchID === branch.BranchID.toString()}
                      onChange={handleOnChange}
                      className="form-radio"
                    />
                    <span className="ml-2 text-black text-xs font-normal font-poppins">
                      {branch.Name}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            {/* Insurance Provider */}
            <div className="mb-5">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Insurance Provider
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <select
                  id="InsuranceProvider"
                  name="InsuranceProvider"
                  value={formData.InsuranceProvider}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.InsuranceProvider ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Insurance Provider</option>
                  <option value="Agrisano">Agrisano</option>
                  <option value="Aquilana">Aquilana</option>
                  <option value="Concordia">Concordia</option>
                  <option value="Sanitas">Sanitas</option>
                  <option value="Swica">Swica</option>
                  <option value="Atupri">Atupri</option>
                  <option value="Helsana">Helsana</option>
                  <option value="KPT">KPT</option>
                  <option value="ÖKK">ÖKK</option>
                  <option value="Sana24">Sana24</option>
                  <option value="Visana">Visana</option>
                  <option value="Vivacare">Vivacare</option>
                  <option value="CSS">CSS</option>
                  <option value="EGK-Gesundheitskasse">
                    EGK-Gesundheitskasse
                  </option>
                  <option value="Sympany">Sympany</option>
                  <option value="Avenir">Avenir</option>
                  <option value="Easy Sana">Easy Sana</option>
                  <option value="Mutuel Assurance">Mutuel Assurance</option>
                  <option value="Philos">Philos</option>
                  <option value="Supra">Supra</option>
                  <option value="Assura">Assura</option>
                </select>
              </label>
            </div>
            {/* Policy Number */}
            <div className="mb-5">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Policy Number
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="number"
                  name="PolicyNumber"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.PolicyNumber}
                  onChange={handleOnChange}
                  placeholder="Input your Policy Number here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.PolicyNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* AHV No. */}
            <div className="mb-5">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  AHV No.
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="AHVNo"
                  value={formData.AHVNo}
                  onChange={handleOnChange}
                  placeholder="Input your AHV No. here"
                  maxLength={16}
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.AHVNo ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
                <p className="text-[#3CA5DC] text-xs font-semibold font-poppins my-2 ">
                  Maximum 13 character
                </p>
              </label>
            </div>
          </>
        );
      case 3:
        return (
          <>
            {/* Occupation */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Occupation
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="text"
                  name="Occupation"
                  maxLength={20}
                  value={formData.Occupation}
                  onChange={handleOnChange}
                  placeholder="Enter occupation here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Occupation ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Employment */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Employment
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <select
                  id="Employment"
                  name="Employment"
                  value={formData.Employment}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.Employment ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Employment Status</option>
                  <option value="Employed">Employed</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Self-Employed">Self-Employed</option>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Retired">Retired</option>
                  <option value="Student">Student</option>
                  <option value="Homemaker">Homemaker</option>
                  {/* <option value="Intern">Intern</option>
                    <option value="Apprentice">Apprentice</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Full-time">Full-time</option>
                    <option value="Contract">Contract</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Seasonal">Seasonal</option>
                    <option value="On Leave">On Leave</option> */}
                </select>
              </label>
            </div>
            {/* Relationship Status */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Relationship Status
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <select
                  id="RelationshipStatus"
                  name="RelationshipStatus"
                  value={formData.RelationshipStatus}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.RelationshipStatus ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Relationship Status</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Engaged">Engaged</option>
                  <option value="Separated">Separated</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                </select>
              </label>
            </div>
            {/* Living Arrangement */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Living Arrangement
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <select
                  id="LivingArrangement"
                  name="LivingArrangement"
                  value={formData.LivingArrangement}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.LivingArrangement ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  onChange={handleOnChange}
                >
                  <option value="">Select Living Arrangement</option>
                  <option value="Own House">Own House</option>
                  <option value="Rent">Rent</option>
                  <option value="Living with Parents">
                    Living with Parents
                  </option>
                  <option value="Living with Relatives">
                    Living with Relatives
                  </option>
                  <option value="Living with Friends">
                    Living with Friends
                  </option>
                  {/* <option value="Student Housing">Student Housing</option>
                    <option value="Military Housing">Military Housing</option>
                    <option value="Homeless">Homeless</option>
                    <option value="Temporary Shelter">Temporary Shelter</option>
                    <option value="Assisted Living">Assisted Living</option>
                    <option value="Nursing Home">Nursing Home</option>
                    <option value="Shared Accommodation">
                      Shared Accommodation
                    </option>
                    <option value="Cohabiting">Cohabiting</option>
                    <option value="Hostel">Hostel</option>
                    <option value="Boarding House">Boarding House</option>
                    <option value="Retirement Community">
                      Retirement Community
                    </option>
                    <option value="Hotel/Motel">Hotel/Motel</option> */}
                </select>
              </label>
            </div>
          </>
        );
      case 4:
        return (
          <>
            {/* Plan */}
            <div className="mb-5">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Select Plan
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="Plan"
                  name="Plan"
                  value={formData.Plan}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.Plan ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Plan</option>
                  <option value="OneOffPayment">One-Off Payment</option>
                  <option value="Individual">Individual Plan</option>
                  <option value="Family">Family</option>
                  <option value="Corporate">Corporate</option>
                </select>
              </label>
            </div>
            {/* Health Manager */}
            <div className="mb-5">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Assign Health Manager
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="HealthManager"
                  name="HealthManager"
                  value={formData.HealthManager}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.HealthManager ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Health Manager</option>
                  <option value="M1">John Doe</option>
                  <option value="M2">Mia L.</option>
                  <option value="M3">Noah Smith</option>
                </select>
              </label>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    // <div className="bg-[#F3F3F3] h-full p-5 sm:p-10 scroll-smooth">min-h-[calc(100vh-150px)]
    <div className="bg-white min-h-full relative">
      {/* Header */}
      <div className="flex flex-col p-6">
        {/* <button
            className="active:scale-90"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaChevronLeft className="h-5" />
          </button> */}
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Add a new Member
        </div>
        <Breadcrumb />
      </div>

      <div className="w-1/2 mx-auto flex items-center space-x-6">
        <button
          onClick={() => setTab(0)}
          className="flex items-center justify-center space-x-1"
        >
          {tab === 0 ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 12C18 15.3137 15.3137 18 12 18C8.68628 18 6 15.3137 6 12C6 8.68628 8.68628 6 12 6C15.3137 6 18 8.68628 18 12Z"
                fill="#3CA5DC"
              />
              <path
                d="M12 2C6.48725 2 2 6.48725 2 12C2 17.5128 6.48725 22 12 22C17.5147 22 22 17.5128 22 12C22 6.48725 17.5147 2 12 2ZM12 20.795C7.15225 20.795 3.205 16.8498 3.205 12C3.205 7.15025 7.15225 3.205 12 3.205C16.8495 3.205 20.795 7.15025 20.795 12C20.795 16.8498 16.8498 20.795 12 20.795Z"
                fill="#3CA5DC"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7734 21.7734C17.2875 21.7734 21.7734 17.2875 21.7734 11.7734C21.7734 6.25941 17.2875 1.77344 11.7734 1.77344C6.25941 1.77344 1.77344 6.25941 1.77344 11.7734C1.77344 17.2875 6.25941 21.7734 11.7734 21.7734ZM11.7734 3.16964C16.5184 3.16964 20.3781 7.02939 20.3781 11.7743C20.3781 16.5193 16.5184 20.379 11.7734 20.379C7.0285 20.3782 3.16874 16.5184 3.16874 11.7734C3.16874 7.0285 7.0285 3.16874 11.7734 3.16874V3.16964Z"
                fill="#D4D4D4"
              />
            </svg>
          )}
          <div
            className={` ${
              tab === 0
                ? "text-zinc-900  font-medium"
                : "text-slate-800 font-normal"
            } text-sm font-poppins leading-none`}
          >
            Existing Patient
          </div>
        </button>
        <button
          onClick={() => setTab(1)}
          className="flex items-center justify-center space-x-1"
        >
          {tab === 1 ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 12C18 15.3137 15.3137 18 12 18C8.68628 18 6 15.3137 6 12C6 8.68628 8.68628 6 12 6C15.3137 6 18 8.68628 18 12Z"
                fill="#3CA5DC"
              />
              <path
                d="M12 2C6.48725 2 2 6.48725 2 12C2 17.5128 6.48725 22 12 22C17.5147 22 22 17.5128 22 12C22 6.48725 17.5147 2 12 2ZM12 20.795C7.15225 20.795 3.205 16.8498 3.205 12C3.205 7.15025 7.15225 3.205 12 3.205C16.8495 3.205 20.795 7.15025 20.795 12C20.795 16.8498 16.8498 20.795 12 20.795Z"
                fill="#3CA5DC"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7734 21.7734C17.2875 21.7734 21.7734 17.2875 21.7734 11.7734C21.7734 6.25941 17.2875 1.77344 11.7734 1.77344C6.25941 1.77344 1.77344 6.25941 1.77344 11.7734C1.77344 17.2875 6.25941 21.7734 11.7734 21.7734ZM11.7734 3.16964C16.5184 3.16964 20.3781 7.02939 20.3781 11.7743C20.3781 16.5193 16.5184 20.379 11.7734 20.379C7.0285 20.3782 3.16874 16.5184 3.16874 11.7734C3.16874 7.0285 7.0285 3.16874 11.7734 3.16874V3.16964Z"
                fill="#D4D4D4"
              />
            </svg>
          )}
          <div
            className={` ${
              tab === 1
                ? "text-zinc-900  font-medium"
                : "text-slate-800 font-normal"
            } text-sm font-poppins leading-none`}
          >
            New Patient
          </div>
        </button>
      </div>
      {tab === 1 ? (
        <div className="w-1/2 mx-auto mt-4">
          <Steps
            size="small"
            labelPlacement="vertical"
            current={current}
            items={items}
            className="custom-steps"
            // onChange={(current) => setCurrent(current)}
          />
        </div>
      ) : null}
      {tab === 1 ? (
        <div className="w-1/2 mx-auto flex justify-between my-5">
          {/* Previous Button */}
          {current > 0 && current < steps.length - 1 ? (
            <button
              className="bg-sky-100 border border-sky-400 active:bg-sky-200 w-10 h-10 mt-44 rounded-full flex items-center justify-center"
              onClick={() => prev()}
            >
              <MdOutlineKeyboardArrowLeft
                className={`w-6 h-6 ${
                  current > 0 ? "text-sky-400" : "text-gray-400"
                }`}
              />
            </button>
          ) : (
            <div className="w-10 h-10"></div>
          )}

          <div className="w-2/3">
            <div className="bg-white rounded-xl shadow-xl border border-sky-400 p-6">
              {renderInputFields()}
            </div>

            {current < steps.length - 1 ? (
              <button
                onClick={() => next()}
                className={`w-full my-5 p-3 bg-sky-100 rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
              >
                <div
                  className={`text-sky-400 text-base font-bold font-poppins`}
                >
                  Next
                </div>
              </button>
            ) : (
              <button
                onClick={() => handleSubmit()}
                className={`w-full my-5 p-3 bg-sky-500 rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
              >
                <div className={`text-white text-base font-bold font-poppins`}>
                  Done
                </div>
              </button>
            )}
          </div>
          {current < steps.length - 1 ? (
            <button
              className="bg-sky-100 border border-sky-400 active:bg-sky-200 w-10 h-10 mt-44 rounded-full flex items-center justify-center"
              onClick={() => next()}
            >
              <MdOutlineKeyboardArrowRight
                className={`w-6 h-6 ${
                  current < steps.length ? "text-sky-400" : "text-gray-400"
                }`}
              />
            </button>
          ) : (
            <div className="w-10 h-10"></div>
          )}
          {/* Next Button */}
        </div>
      ) : null}
      <div className="absolute right-2 bottom-1">
        <img
          src={images[current]}
          alt=""
          className="w-72 h-72 object-contain"
        />
      </div>
    </div>
    // </div>
  );
}

export default AddNewConceirgePatient;
