import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    MeetingProvider,
    useMeeting,
    useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import expandModal from "../../assets/Images/TelehealthDashboard/expandModal.png";
import startIcon from "../../assets/Images/TelehealthDashboard/startIcon.png";
import stopIcon from "../../assets/Images/TelehealthDashboard/stopIcon.png";
import expandVid from "../../assets/Images/TelehealthDashboard/expandVid.png";
import Camera_off from "../../assets/Images/TelehealthDashboard/Camera_off.png";
import { useNavigate } from "react-router-dom";
import Controls from "./Controls"
import { useDispatch, useSelector } from "react-redux";
import { getAllDiagnosis, getAllMedicines, } from "../../services/operations/masterDataAPI";
import { BiExpandAlt } from "react-icons/bi";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { toast } from "react-hot-toast";
import ModalComponent from "../Modal";
import voice from "../../assets/Images/PatientProfile/voice.png";
import englishIcon from "../../assets/Images/PatientProfile/englishIcon.png";
import italyIcon from "../../assets/Images/PatientProfile/italyIcon.png";
import { Button, Progress, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { FaCheck } from "react-icons/fa6";

export default function ParticipantView(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { allPreMedicalConditions, allMedicines } = useSelector((state) => state.patient);
    const [diagnosisOptions, setDiagnosisOptions] = useState();
    const patient = props?.patient;
    const isExpanded = props.isExpanded;
    const [isLocalExpanded, setIsLocalExpanded] = useState(false)
    const [isModalExpanded, setIsModalExpanded] = useState(isExpanded);
    const [suggestions, setSuggestions] = useState([]);
    const [activeInputIndex, setActiveInputIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [prescriptionBody, setPrescriptionBody] = useState([]);
    const [notesBody, setNotesBody] = useState([]);
    const [sessID, setSessID] = useState(null);
    const [canEndCall, setCanEndCall] = useState(false);
    const [showAudioDictation, setShowAudioDictation] = useState(false);
    const [isAudioDone, setIsAudioDone] = useState(false);
    const [isRecordingOnceDone, setIsRecordingOnceDone] = useState(false);
    const [conditionSuggestions, setconditionSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedConditions, setSelectedConditions] = useState([]);

    const toggleAudioDictation = () => {
        setShowAudioDictation(prevState => prevState === true ? false : true);
    };

    const [formData1, setFormData1] = useState({
        PatientName: "",
        Age: "",
        Gender: "",
        FamilyMedicalHistory: "",
        MedicalCondition: "",
        Allergies: "",
        Diagnosis: "",
        Prescription: "",
        Symptoms: "",
        Concerns: "",
        DoctorsRecommendations: "",
        CurrentMedication: "",
        FollowUpInstructions: "",
    });
    const [errors1, setErrors1] = useState({
        PatientName: "",
        Age: "",
        Gender: "",
        FamilyMedicalHistory: "",
        MedicalCondition: "",
        Allergies: "",
        Diagnosis: "",
        Prescription: "",
        Symptoms: "",
        Concerns: "",
        DoctorsRecommendations: "",
        CurrentMedication: "",
        FollowUpInstructions: "",
    });

    const [progress, setProgress] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [translatedLang, setTranslatedLang] = useState("it");
    const [transcriptedData, setTranscriptedData] = useState(() => {
        const savedData = localStorage.getItem("transcriptedData");
        if (savedData) {
            return JSON.parse(savedData);
        } else {
            return {};
        }
    });
    const [isExtracted, setIsExtracted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState("");
    const [elapsedTime, setElapsedTime] = useState(0);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const timerRef = useRef(null);
    const canvasRef = useRef(null);
    const animationRef = useRef(null);
    const audioContextRef = useRef(
        new (window.AudioContext || window.webkitAudioContext)()
    );
    const analyserRef = useRef(audioContextRef.current.createAnalyser());

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const seconds = time - hours * 3600 - minutes * 60;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const startRecording = async () => {
        console.log("Recording...");
        setAudioUrl("");

        try {
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const speakerStream = await navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: true, // video must be requested, but we won't use it
            });

            const audioContext = audioContextRef.current;
            const micSource = audioContext.createMediaStreamSource(audioStream);
            const speakerSource = audioContext.createMediaStreamSource(speakerStream);
            const destination = audioContext.createMediaStreamDestination();

            micSource.connect(destination);
            speakerSource.connect(destination);
            micSource.connect(analyserRef.current);
            speakerSource.connect(analyserRef.current);

            const mediaRecorder = new MediaRecorder(destination.stream);

            mediaRecorder.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                audioChunksRef.current = [];
                const url = URL.createObjectURL(audioBlob);
                setAudioUrl(url);

                // Stop the timer and reset elapsed time
                clearInterval(timerRef.current);
                setElapsedTime(0);

                // Stop the animation
                cancelAnimationFrame(animationRef.current);

                // Stop screen sharing
                speakerStream.getTracks().forEach(track => track.stop());

                // Stop microphone use
                audioStream.getTracks().forEach(track => track.stop());

                // Optionally, save the blob to state to use later for sending to the server
                sendAudioFile(audioBlob);
            };

            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
            setIsRecording(true);
            setIsExtracted(false);

            // Start the timer
            timerRef.current = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000);

            // Start the animation
            draw();
        } catch (error) {
            console.error("Error accessing media devices:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setIsRecording(false);
    };

    useEffect(() => {
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);

    const draw = () => {
        console.log("Drawing...");
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const width = canvas.width;
        const height = canvas.height;
        const frequencyData = new Uint8Array(analyserRef.current.frequencyBinCount);
        analyserRef.current.getByteFrequencyData(frequencyData);

        ctx.clearRect(0, 0, width, height);
        const barWidth = (width / frequencyData.length) * 10.0;
        let x = 0;

        for (let i = 0; i < frequencyData.length; i++) {
            const barHeight = frequencyData[i] / 5;
            ctx.fillStyle = i % 2 === 0 ? "#3CA5DC" : "white";
            ctx.fillRect(x, (height - barHeight) / 2, barWidth, barHeight);
            x += barWidth + 1;
        }

        animationRef.current = requestAnimationFrame(draw);
    };

    const sendAudioFile = async (audioBlob) => {
        const toastId = toast.loading("Extracting audio...");
        setIsLoading(true);
        setProgress(10); // Set progress to 10% when starting the API call
        const formData = new FormData();
        formData.append("audioFile", audioBlob, "audio.wav");
        formData.append("language", translatedLang);
        try {
            const response = await axios.post(
                "https://beta-audio-ai-api.alleshealth.com/v2/crownextractor",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setProgress(50); // Set progress to 50% after receiving the response
            setTranscriptedData(response?.data);
            toast.success("Audio extracted successfully");
        } catch (error) {
            toast.error("Failed to extract audio");
            console.error(error);
        } finally {
            setProgress(100); // Set progress to 100% when the API call is done
            setIsLoading(false);
            setIsExtracted(true);
            toast.dismiss(toastId);
        }
    };

    const showExtractedData = () => {
        setShowAudioDictation(false);
        console.log("EXTRACTED", transcriptedData);

        let diagnosisWords = (transcriptedData?.diagnosed_conditions || transcriptedData?.Diagnosis).split(" ");
        let matchedWords = [];

        // Function to find matching diagnosis
        diagnosisWords.forEach(word => {
            diagnosisOptions.forEach(option => {
                // Create a regex pattern to account for singular/plural variations
                const pattern = new RegExp(`\\b${word.replace(/s$/, '')}\\b`, 'i');
                if (pattern.test(option.DiagnosisName)) {
                    matchedWords.push({ ID: option.ID, DiagnosisName: option.DiagnosisName });
                }
            });
        });

        // Function to filter out "Not Provided" or similar strings
        const filterRelevantData = (data) => {
            const relevantData = data.filter(item => !/(Not provided|Not mentioned)/i.test(item));
            return relevantData.length > 0 ? relevantData.join(" ") : "Not Provided";
        };

        let data = {
            PersonalNotes: filterRelevantData([
                (transcriptedData?.family_medical_history || transcriptedData?.FamilyMedicalHistory),
                (transcriptedData?.medical_history || transcriptedData?.MedicalCondition),
                (transcriptedData?.concerns || transcriptedData?.Concerns),
                (transcriptedData?.current_medication || transcriptedData?.CurrentMedication)
            ]),
            DiagnosisInformation: filterRelevantData([
                (transcriptedData?.doctors_recommendations || transcriptedData?.DoctorsRecommendations),
                (transcriptedData?.follow_up_instructions || transcriptedData?.FollowUpInstructions)
            ])
        };

        if (matchedWords.length > 0) {
            data.DiseaseType = matchedWords[0].ID;
        }

        setIsAudioDone(true);
        setFormData(data);
    };

    const [medicines, setMedicines] = useState([
        {
            MedicineName: "",
            MedicineID: "",
            DosageAmount: "",
            Frequency: "",
            Meal: "",
            DurationCount: "",
            Duration: "",
            Details: "",
        },
    ]);

    const [formData, setFormData] = useState({
        DiseaseType: "",
        Stage: "",
        PreCondition: selectedConditions,
        PersonalNotes: "",
        DiagnosisInformation: "",
    });

    const [errors, setErrors] = useState({
        DiseaseType: "",
        Stage: "",
        PreCondition: "",
        PersonalNotes: "",
        DiagnosisInformation: "",
        medicines: [],
    });

    const handleAddMedicine = () => {
        setMedicines([
            ...medicines,
            {
                MedicineName: "",
                DosageAmount: "",
                Frequency: "",
                Meal: "",
                DurationCount: "",
                Duration: "",
                Details: "",
            },
        ]);
    };

    const handleRemoveMedicine = (index) => {
        setMedicines(medicines.filter((_, i) => i !== index));
    };

    const handleSelectCondition = (condition) => {
        const conditionExists = selectedConditions.find(
            (c) => c.PMCID === condition.PMCID
        );
        if (!conditionExists) {
            setSelectedConditions((prev) => [...prev, condition]);
        }
        setInputValue("");
        setconditionSuggestions([]);
    };

    const handleRemoveCondition = (pmcid) => {
        setSelectedConditions((prev) =>
            prev.filter((condition) => condition.PMCID !== pmcid)
        );
    };

    const handleInputChange = (event, index) => {
        setActiveInputIndex(index);
        const { name, value } = event.target;
        const list = [...medicines];
        list[index][name] = value;
        setMedicines(list);

        if (value.length > 0) {
            const filteredSuggestions = allMedicines?.filter((medication) =>
                medication.Name.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }

        // clear errors
        const newErrors = { ...errors };
        if (newErrors.medicines[index]) {
            newErrors.medicines[index][name] = "";
            setErrors(newErrors);
        }
    };

    const handleInputChange2 = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (!value) {
            setconditionSuggestions([]);
        } else {
            const filteredSuggestions = allPreMedicalConditions.filter((condition) =>
                condition.Name.toLowerCase().includes(value.toLowerCase())
            );
            setconditionSuggestions(filteredSuggestions);
        }
    };

    const handleOnChange2 = (event, index) => {
        const { name, value } = event.target;
        const list = [...medicines];

        // Check if the name is 'DurationCount' and the value length is more than 3
        if (name === "DurationCount" && value.length > 3) {
            return; // If so, don't update the state and exit the function
        }

        list[index][name] = value;
        setMedicines(list);

        // Remove error
        const newErrors = { ...errors };
        if (newErrors.medicines[index]) {
            newErrors.medicines[index][name] = "";
            setErrors(newErrors);
        }
    };

    const handleSuggestionClick = (medication) => {
        const list = [...medicines];
        list[list?.length - 1].MedicineName = medication.Name;
        list[list?.length - 1].MedicineID = medication.MedicationID;
        setMedicines(list);
        setSuggestions([]);

        // Remove error
        if (errors[list?.length - 1] && errors[list?.length - 1].MedicineName) {
            const newErrors = [...errors];
            newErrors[list?.length - 1] = {
                ...newErrors[list?.length - 1],
                MedicineName: undefined,
            };
            setErrors(newErrors);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setFormData({
            DiseaseType: "",
            PersonalNotes: "",
            DiagnosisInformation: "",
        });
        setErrors({
            DiseaseType: "",
            PersonalNotes: "",
            DiagnosisInformation: "",
            medicines: [],
        });
        setMedicines([
            {
                MedicineName: "",
                DosageAmount: "",
                Frequency: "",
                Meal: "",
                DurationCount: "",
                Duration: "",
            },
        ]);
    };

    function handleOnChange(event) {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        const mandatoryFields = [
            "DiseaseType",
            "PersonalNotes",
            "DiagnosisInformation",
        ];
        const newErrors = {
            DiseaseType: "",
            PersonalNotes: "",
            DiagnosisInformation: "",
            medicines: medicines.map(() => ({})), // Initialize medicines array with empty objects
        };

        // Validate main form fields
        mandatoryFields.forEach((key) => {
            const value = formData[key];

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        // Validate medicine fields
        medicines.forEach((medicine, index) => {
            const medicineErrors = {};
            if (medicine.MedicineName) {
                // If MedicineName is provided, validate other fields
                if (!medicine.MedicineName || medicine.MedicineName.trim() === "") {
                    medicineErrors.MedicineName = "This field is required";
                }
                if (!medicine.Frequency || medicine.Frequency.trim() === "") {
                    medicineErrors.Frequency = "This field is required";
                }
                if (!medicine.DosageAmount || medicine.DosageAmount.trim() === "") {
                    medicineErrors.DosageAmount = "This field is required";
                }
                if (!medicine.Meal || medicine.Meal.trim() === "") {
                    medicineErrors.Meal = "This field is required";
                }
                if (!medicine.DurationCount || medicine.DurationCount <= 0) {
                    medicineErrors.DurationCount =
                        "This field is required and must be greater than 0";
                }
                if (!medicine.Duration || medicine.Duration.trim() === "") {
                    medicineErrors.Duration = "This field is required";
                }
                // SpecialInstructions (Details) is not mandatory
            }
            if (Object.keys(medicineErrors).length > 0) {
                newErrors.medicines[index] = medicineErrors;
            }
        });

        if (
            mandatoryFields.some((key) => newErrors[key]) ||
            newErrors.medicines.some(
                (medicineError) => Object.keys(medicineError).length > 0
            )
        ) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            console.log("ERRORS", newErrors);
            return;
        }

        let transformedMedicines = medicines.map((medicine, index) => {
            return {
                SessionID: patient?.sessionId,
                MedicationID: medicine?.MedicineID,
                MedicineName: medicine?.MedicineName,
                DosageAmount: medicine?.DosageAmount,
                AHUID: patient?.id,
                Frequency: medicine?.Frequency,
                StartDate: moment(new Date()).format("YYYY-MM-DD"),
                EndDate: moment(new Date())
                    .add(medicine?.DurationCount, medicine?.Duration)
                    .format("YYYY-MM-DD"),
                Duration: medicine?.DurationCount,
                DurationType: medicine?.Duration,
                MealTime: medicine?.Meal,
                SpecialInstructions: medicine?.Details,
            };
        });

        let body = {
            DosageData: transformedMedicines,
        };

        // Get the current time in UTC
        const endTime = moment().format("HH:mm:ss");

        let data = {
            DiagnosisID: formData?.DiseaseType,
            PersonalNote: formData?.PersonalNotes,
            DiagnosisInformation: formData?.DiagnosisInformation,
            AppointmentType: "TeleHealth",
            End_time: endTime,
            IsSessionEnd: 1,
            ...(selectedConditions.length > 0 && {
                PreMedicalCondition: selectedConditions.map(
                    (condition) => condition.PMCID
                ),
            }),
            ...(formData?.Stage && {
                Stage: formData.Stage,
            }),
        };

        let ID = patient?.sessionId;

        setCanEndCall(true)
        setSessID(ID)
        setNotesBody(data)
        setPrescriptionBody(body)

        handleModalClose();
    };

    const toggleExpand = () => {
        setIsModalExpanded(prevState => !prevState);
    };

    const handleExpand = () => {
        setIsLocalExpanded(!isLocalExpanded)
        props.onExpand(!isExpanded);
    };

    const control = props.controls;
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName, setQuality } =
        useParticipant(props.participantId);
    setQuality('high');

    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);

                micRef.current.srcObject = mediaStream;
                micRef.current
                    .play()
                    .catch((error) =>
                        console.error("videoElem.current.play() failed", error)
                    );
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    useEffect(() => {
        const fetchDiagnosis = async () => {
            if (token) {
                try {
                    const res = await dispatch(getAllDiagnosis(token));
                    setDiagnosisOptions(res.DiagnosisList);
                } catch (error) {
                    console.error("Failed to fetch Diagnosis", error);
                }
            }
        };

        fetchDiagnosis();
    }, [token, dispatch]);

    useEffect(() => {
        if (!control) {
            const css = `
                .video-player video {
                    object-fit: cover !important;
                    width: 100%;
                    height: ${isExpanded ? '25vh' : '12vh'} !important;
                    border-radius: 10px !important;
                }
                .video-player1 video {
                    object-fit: contain !important;
                    width: 100%;
                    height: ${isExpanded ? '85vh' : '43vh'} !important;
                    border-radius: 10px !important;
                }
            `;
            const style = document.createElement('style');
            style.innerHTML = css;
            document.head.appendChild(style);

            // Cleanup function to remove the style element when the component unmounts
            return () => {
                document.head.removeChild(style);
            };
        }
    }, [isExpanded, control, isLocal]);

    useEffect(() => {
        setIsAudioDone(props?.isAudioDone);
    }, [props?.isAudioDone]);

    useEffect(() => {
        setFormData(props?.formData);
    }, [props?.formData]);

    useEffect(() => {
        if (props?.onSaveFormData) {
            props?.onSaveFormData(formData);
        }
    }, [formData]);

    useEffect(() => {
        if (props?.setIsAudioDone) {
            props?.setIsAudioDone(isAudioDone);
        }
    }, [isAudioDone]);

    return (
        <div className="flex flex-col items-center justify-center w-full">
            {/* Expand Video Btn */}
            {control ? (
                <>
                    {isLocal && (
                        <div onClick={() => { handleExpand() }} className="absolute top-3 left-3 flex flex-row justify-center items-center gap-2 z-50">
                            <div className="flex justify-center items-center w-[40px] h-[40px] bg-transparent rounded-full cursor-pointer">
                                <img
                                    src={expandVid}
                                    className="w-full h-full object-contain"
                                    alt="expand Video"
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {!isLocal && (
                        <div onClick={() => { handleExpand() }} className="absolute top-3 left-3 flex flex-row justify-center items-center gap-2 z-50">
                            <div className="flex justify-center items-center w-[40px] h-[40px] bg-transparent rounded-full cursor-pointer">
                                <img
                                    src={expandVid}
                                    className="w-full h-full object-contain"
                                    alt="expand Video"
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            {webcamOn ? (
                <>
                    <ReactPlayer
                        playsinline // very very imp prop
                        pip={false}
                        light={false}
                        controls={false}
                        muted={true}
                        playing={true}
                        url={videoStream}
                        height={`h-auto`}
                        width={"w-full"}
                        className={`${isLocal ? 'video-player' : 'video-player1'} w-full object-cover bg-[#rgba(0, 0, 0, 0.17)] rounded-md border-4 border-solid border-[#FFFFFF]`}
                        style={{ marginLeft: '1em', marginRight: '1em', borderRadius: '10px', objectFit: 'cover', }}
                        onError={(err) => {
                            console.log(err, "participant video error");
                        }}
                    />
                </>
            ) : (
                <>
                    {/* Camera off */}
                    {isExpanded ? (
                        <>
                            {control ? (
                                <div className="flex justify-center items-center w-full h-[85vh] bg-[#ACACAC] rounded-lg">
                                    <img
                                        src={Camera_off}
                                        className="w-[40px] h-[40px] object-contain"
                                        alt="Camera Off"
                                    />
                                </div>
                            ) : (
                                <>
                                    {isLocal ? (
                                        <div className="flex justify-center items-center w-full h-[25vh] bg-[#ACACAC] border-2 border-white rounded-lg">
                                            <img
                                                src={Camera_off}
                                                className="w-[40px] h-[40px] object-contain"
                                                alt="Camera Off"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex justify-center items-center w-full h-[85vh] bg-[#ACACAC] rounded-lg">
                                            <img
                                                src={Camera_off}
                                                className="w-[40px] h-[40px] object-contain"
                                                alt="Camera Off"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {control ? (
                                <div className="flex justify-center items-center w-full h-[43vh] bg-[#ACACAC] rounded-lg">
                                    <img
                                        src={Camera_off}
                                        className="w-[40px] h-[40px] object-contain"
                                        alt="Camera Off"
                                    />
                                </div>
                            ) : (
                                <>
                                    {isLocal ? (
                                        <div className="flex justify-center items-center w-full h-[12vh] bg-[#ACACAC] border-2 border-white rounded-lg">
                                            <img
                                                src={Camera_off}
                                                className="w-[40px] h-[40px] object-contain"
                                                alt="Camera Off"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex justify-center items-center w-full h-[43vh] bg-[#ACACAC] rounded-lg">
                                            <img
                                                src={Camera_off}
                                                className="w-[40px] h-[40px] object-contain"
                                                alt="Camera Off"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )
            }

            {/* Medical History and Notes */}
            {isExpanded && !isLocal && patient && (
                <>
                    <div className="w-[23vw] absolute left-4 top-16 flex flex-col justify-center items-center bg-[#FFFFFF] rounded-lg p-4">
                        {/* Expand Icon */}
                        <button onClick={() => { setShowModal(true) }} className="absolute right-2 top-2 flex justify-center items-center w-[35px] h-[32px]">
                            <img
                                src={expandModal}
                                className="w-full h-full object-contain"
                                alt="expand Icons"
                            />
                        </button>
                        <div className="w-full flex flex-col justify-center items-center border-b-2 border-b-[#F3F5F6] pb-4">
                            <div className="flex flex-col justify-center items-center w-[55px] h-[55px] rounded-full">
                                <img
                                    src={patient.img}
                                    className="w-full h-full object-contain rounded-full"
                                    alt="Patient image"
                                />
                            </div>
                            <h1 className="text-black text-base text-sm font-bold font-poppins mt-1">
                                {patient.name}
                            </h1>
                            <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins">
                                {patient.id}
                            </h1>
                            <h1 className="text-[#000000] text-base text-xs font-semibold font-poppins mt-2">
                                {patient.age} Years Old
                            </h1>
                        </div>

                        {/* Diagnosis Type */}
                        <div className="mt-4 w-full">
                            <label>
                                <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                                    Diagnosis Type
                                </p>
                                <select
                                    id="DiseaseType"
                                    name="DiseaseType"
                                    value={formData?.DiseaseType}
                                    onChange={handleOnChange}
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}>
                                    <option value="">Select Diagnosis Type</option>
                                    {diagnosisOptions?.map(option => (
                                        <option key={option?.ID} value={option?.ID}>{option?.DiagnosisName}</option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        {/* Personal Notes */}
                        <div className="w-full flex flex-col justify-center items-start mt-2">
                            <div className="w-full flex flex-row justify-start items-start mb-2">
                                <p className="text-[#070808] text-xs font-semibold font-poppins text-left">
                                    Personal Notes
                                </p>
                                <p className="ml-1 text-[#070808] text-base text-xs font-regular font-poppins text-left">(Not shared with Patients)</p>
                            </div>
                            <textarea
                                type="text"
                                id="PersonalNotes"
                                name="PersonalNotes"
                                value={formData?.PersonalNotes}
                                onChange={handleOnChange}
                                placeholder="Input your Personal notes here"
                                className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                            />
                        </div>

                        {/* DiagnosisInformation */}
                        <div className="w-full flex flex-col justify-center items-start pb-4 mt-2">
                            <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                                Diagnosis Information
                            </p>
                            <textarea
                                type="text"
                                id="DiagnosisInformation"
                                name="DiagnosisInformation"
                                value={formData?.DiagnosisInformation}
                                onChange={handleOnChange}
                                placeholder="Enter Diagnosis Information here"
                                className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                            />
                        </div>
                    </div>
                </>
            )}

            {/* Audio Dictation Small Screen */}
            {!isExpanded && showAudioDictation && (
                <>
                    <div style={{
                        background: 'rgba(229, 242, 255, 0.62)',
                        backdropFilter: 'blur(1px)',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        border: '1px solid rgba(255, 255, 255, 0.3)'
                    }} className="w-[80%] absolute bottom-2 flex flex-row justify-between items-center rounded-xl px-4 py-1 gap-2">
                        {/* Canvas */}
                        <div className="w-20 bg-gray-100 rounded-lg p-2">
                            {/* <canvas ref={canvasRef} width="500" height="100"></canvas> */}
                            <canvas
                                ref={canvasRef}
                                id="myCanvas"
                                width="60"
                                height="20"
                            ></canvas>
                        </div>

                        {/* Timer */}
                        <div className="flex justify-center items-center p-2 bg-[#F9DDDF] rounded-2xl">
                            <h1 className="text-center text-[#DF535F] text-xs font-normal font-poppins">
                                {formatTime(elapsedTime)}
                            </h1>
                        </div>

                        {/* Message */}
                        {isLoading && (
                            <div className="text-[#FFFFFF] text-sm font-normal font-poppins w-[50%]">
                                Audio Dictation in Progress...
                            </div>
                        )}
                        {!isExtracted && !isLoading && (
                            <div className="text-[#FFFFFF] text-sm font-normal font-poppins w-[50%]">
                                {isRecording ? "Stop Recording" : "Start Recording"}
                            </div>
                        )}
                        {isExtracted && !isLoading && (
                            <div className="text-[#FFFFFF] text-sm font-normal font-poppins w-[50%]">
                                Validate
                            </div>
                        )}

                        {/* Lang Change */}
                        <div className="flex flex-row justify-center items-center gap-2">
                            <button
                                onClick={() => setTranslatedLang("en")}
                                className={`w-10 px-3 py-2 ${translatedLang === 'en' ? "bg-[#3CA5DC] border-2 border-white active:bg-sky-200 active:scale-95" : "bg-gray-100"}  rounded-lg shadow flex justify-center items-center gap-2.5`}
                            >
                                <img
                                    src={englishIcon}
                                    className="w-4 h-4 object-contain"
                                    alt="English Icon"
                                />
                                {/* <div className="text-[#000000] text-[0.6rem] font-normal font-poppins">
                                        English
                                    </div> */}
                            </button>

                            <button
                                onClick={() => setTranslatedLang("it")}
                                className={`w-10 px-3 py-2 ${translatedLang === 'it' ? "bg-[#3CA5DC] border-2 border-white active:bg-sky-200 active:scale-95" : "bg-gray-100"}  rounded-lg shadow flex justify-center items-center gap-2.5`}
                            >
                                <img
                                    src={italyIcon}
                                    className="w-4 h-4 object-contain"
                                    alt="Italy Icon"
                                />
                                {/* <div className="text-[#000000] text-[0.6rem] font-normal font-poppins">
                                        Italian
                                    </div> */}
                            </button>
                        </div>
                        {/* 
                        {audioUrl && (
                            <audio controls src={audioUrl}></audio>
                        )} */}

                        {/* Recording Btn */}
                        {isExtracted ? (
                            <>
                                <button
                                    onClick={() => showExtractedData()}
                                    disabled={!isExtracted}
                                    className={`w-10 rounded-full active:scale-95 hover:scale-105 duration-500`}
                                >
                                    <FaCheck className="text-[#FFFFFF] bg-[#86D511] rounded-full p-2 w-full h-auto" />
                                </button>
                            </>
                        ) : (
                            <>
                                {!isRecording ? (
                                    <button
                                        disabled={isRecordingOnceDone}
                                        onClick={() => {
                                            startRecording(); setIsAudioDone(true);
                                        }}
                                        className={`w-10 rounded-full shadow active:scale-95 border-none`}
                                    >
                                        <img
                                            src={startIcon}
                                            className="w-8 h-8 object-contain rounded-full"
                                            alt="Start Icon"
                                        />
                                    </button>
                                ) : (
                                    <button
                                        disabled={isRecordingOnceDone}
                                        onClick={() => { stopRecording(); setIsAudioDone(false); setIsRecordingOnceDone(true); }}
                                        className={`w-10 rounded-full shadow active:scale-95 border-none`}
                                    >
                                        <img
                                            src={stopIcon}
                                            className="w-8 h-8 object-contain rounded-full"
                                            alt="Stop Icon"
                                        />
                                    </button>
                                )}
                            </>
                        )}

                    </div>
                </>
            )
            }

            {/* Audio Dictation*/}
            {
                isExpanded && showAudioDictation && (
                    <>
                        <div className="w-[23vw] absolute right-4 bottom-16 flex flex-col justify-center items-center bg-[#FFFFFF] rounded-lg p-4">
                            {/* Record Audio */}
                            <div className="relative">
                                <div className="flex flex-col items-center space-y-2">
                                    <div className="bg-sky-100 rounded-full w-12 h-12 p-4">
                                        <img src={voice} alt="" />
                                    </div>
                                    <div className="text-center text-black text-sm font-bold font-poppins">
                                        On Record
                                    </div>
                                    <div className="text-center text-black text-xs font-normal font-poppins">
                                        {formatTime(elapsedTime)}
                                    </div>
                                    <div className="bg-gray-100 rounded-lg p-2">
                                        {/* <canvas ref={canvasRef} width="500" height="100"></canvas> */}
                                        <canvas
                                            ref={canvasRef}
                                            id="myCanvas"
                                            width="315"
                                            height="80"
                                        ></canvas>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center items-center gap-2 absolute top-0 right-0">
                                    <button
                                        onClick={() => setTranslatedLang("en")}
                                        className={`w-10 px-3 py-2 ${translatedLang === 'en' ? "bg-sky-100 active:bg-sky-200 active:scale-95" : "bg-gray-100"}  rounded shadow flex justify-center items-center gap-2.5`}
                                    >
                                        <img
                                            src={englishIcon}
                                            className="w-4 h-4 object-contain"
                                            alt="English Icon"
                                        />
                                        {/* <div className="text-[#000000] text-[0.6rem] font-normal font-poppins">
                                        English
                                    </div> */}
                                    </button>

                                    <button
                                        onClick={() => setTranslatedLang("it")}
                                        className={`w-10 px-3 py-2 ${translatedLang === 'it' ? "bg-sky-100 active:bg-sky-200 active:scale-95" : "bg-gray-100"}  rounded shadow flex justify-center items-center gap-2.5`}
                                    >
                                        <img
                                            src={italyIcon}
                                            className="w-4 h-4 object-contain"
                                            alt="Italy Icon"
                                        />
                                        {/* <div className="text-[#000000] text-[0.6rem] font-normal font-poppins">
                                        Italian
                                    </div> */}
                                    </button>
                                </div>
                                <div className="border border-b-0.5 border-gray-100 my-5"></div>
                                {isLoading && (
                                    <div className="flex justify-center items-center pt-5">
                                        <button
                                            disabled={true}
                                            className={`w-2/3 px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-sky-400 rounded-lg flex items-center justify-center space-x-5 active:scale-95`}
                                        >
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{ fontSize: 20 }}
                                                        className="text-sky-100"
                                                        spin
                                                    />
                                                }
                                            />{" "}
                                            <div>Please Wait</div>
                                        </button>
                                    </div>
                                )}
                                {!isExtracted && !isLoading && (
                                    <div className="flex justify-center items-center pt-5">
                                        <button
                                            onClick={isRecording ? stopRecording : startRecording}
                                            className={`w-2/3 px-11 py-3 text-sky-400 text-sm font-semibold font-poppins shadow bg-sky-100 rounded-lg text-center active:scale-95`}
                                        >
                                            {isRecording ? "Stop Recording" : "Start Recording"}
                                        </button>
                                    </div>
                                )}
                                {isExtracted && !isLoading && (
                                    <div className="flex justify-center items-center pt-5">
                                        <button
                                            onClick={() => showExtractedData()}
                                            className={`w-2/3 px-11 py-3 text-sky-100 text-sm font-semibold font-poppins shadow bg-sky-400 rounded-lg text-center active:scale-95`}
                                        >
                                            Validate
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )
            }

            {/* Video Call Done Modal */}
            <ModalComponent
                show={showModal}
                handleClose={() => { setShowModal(false); }}
                outSideModalPress={false}
            >
                <div className="w-[850px]">
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex items-center space-x-3">
                            <img
                                src={patient.img}
                                alt=""
                                className="w-12 h-12 rounded-full"
                            />
                            <div className="flex flex-col">
                                <div className="text-black text-base font-semibold font-open">
                                    {patient.name}
                                </div>
                                <div className="text-black text-sm font-normal font-open">
                                    Patient
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-black text-base font-semibold font-open">
                                Consultation ID : {patient.sessionId}
                            </div>
                        </div>
                    </div>
                    <div className="border border-b-1 border-gray-200 my-6"></div>
                    {/* Consultation Date and Appointment Type  */}
                    <div className="flex space-x-16">
                        <div>
                            <div className="text-black text-sm font-semibold font-open">
                                Consultation Date
                            </div>
                            <div className=" text-black text-xs font-normal font-open leading-none mt-3">
                                {moment(patient.time).format("DD MMMM YYYY")}
                            </div>
                        </div>
                        <div>
                            <div className="text-black text-sm font-semibold font-open">
                                Appointment Type
                            </div>
                            <div className=" text-black text-xs font-normal font-open leading-none mt-3">
                                TeleHealth
                            </div>
                        </div>
                    </div>

                    <div className="mt-2 max-h-[calc(100vh-400px)] overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                        <div className="text-black text-sm font-semibold font-open mt-4">
                            Consultation Form
                        </div>

                        <div className="px-1 w-[95%]">
                            <div className="flex flex-row justify-center items-center w-full gap-2">
                                {/* Diagnosis Type */}
                                <div className="w-1/2 my-4">
                                    <label>
                                        <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                                            Diagnosis Type
                                            <sup className="text-red-600">*</sup>
                                        </p>
                                        <select
                                            id="DiseaseType"
                                            name="DiseaseType"
                                            value={formData?.DiseaseType}
                                            onChange={handleOnChange}
                                            className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.DiseaseType ? "border-red-500" : ""
                                                } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}>
                                            <option value="">Select Diagnosis Type</option>
                                            {diagnosisOptions?.map(option => (
                                                <option key={option.ID} value={option.ID}>{option.DiagnosisName}</option>
                                            ))}
                                        </select>
                                    </label>
                                </div>

                                {/* Stage */}
                                <div className="w-1/2">
                                    <label>
                                        <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                            Stage
                                        </p>
                                        <input
                                            type="text"
                                            id="Stage"
                                            name="Stage"
                                            value={formData?.Stage}
                                            maxLength={20}
                                            onChange={handleOnChange}
                                            placeholder="Enter Stage here"
                                            className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.Stage ? "border-red-500" : ""
                                                } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Pre Medical Condition */}
                            <div className="my-6 w-full relative">
                                <label>
                                    <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                        Pre Medical Condition
                                    </p>
                                    <div className="relative w-full">
                                        <>
                                            <input
                                                type="text"
                                                value={inputValue}
                                                onChange={handleInputChange2}
                                                placeholder="Enter Pre Medical Condition here"
                                                className="w-full text-black text-xs bg-gray-100 rounded-md p-4 pl-4 pr-10 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent"
                                                autoComplete="off"
                                            />
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                                                <svg
                                                    className="h-4 w-4"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                                </svg>
                                            </div>
                                        </>
                                    </div>
                                    {conditionSuggestions.length > 0 && (
                                        <div className="absolute w-1/2 bg-white border rounded-md mt-1">
                                            {conditionSuggestions.map((condition) => (
                                                <div
                                                    key={condition.PMCID}
                                                    onClick={() => handleSelectCondition(condition)}
                                                    className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    {condition.Name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </label>
                                {selectedConditions.length !== 0 && (
                                    <div className="w-full flex flex-row items-center flex-wrap gap-2 mt-4">
                                        {selectedConditions.map((condition) => (
                                            <div
                                                key={condition.PMCID}
                                                className={`px-4 py-1 bg-sky-100 rounded-2xl cursor-pointer`}
                                                onClick={() =>
                                                    handleRemoveCondition(condition.PMCID)
                                                }
                                            >
                                                <div className="text-sky-500 text-xs font-poppins">
                                                    {condition.Name}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Personal Notes */}
                            <div className="my-4">
                                <label>
                                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                                        Personal Notes (Not Shared with Patients)
                                    </p>
                                    <textarea
                                        id="PersonalNotes"
                                        name="PersonalNotes"
                                        value={formData?.PersonalNotes}
                                        onChange={handleOnChange}
                                        placeholder="Enter Personal Notes here"
                                        className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${errors.PersonalNotes ? "border-red-500" : ""
                                            } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    ></textarea>
                                </label>
                            </div>

                            {/* DiagnosisInformation */}
                            <div className="my-4">
                                <label>
                                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                                        Diagnosis Information
                                    </p>
                                    <textarea
                                        id="DiagnosisInformation"
                                        name="DiagnosisInformation"
                                        value={formData?.DiagnosisInformation}
                                        onChange={handleOnChange}
                                        placeholder="Enter Diagnosis Information here"
                                        className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${errors.DiagnosisInformation ? "border-red-500" : ""
                                            } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    ></textarea>
                                </label>
                            </div>
                        </div>

                        {/* Input field */}
                        <div className="flex flex-col mb-10 px-1">
                            {medicines?.map((medicine, index) => (
                                <>
                                    <div
                                        key={index}
                                        className="flex flex-row items-center space-x-3 mt-4"
                                    >
                                        {/* Medication  */}
                                        <div className="w-40 relative">
                                            <label>
                                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                                    Medicine {index + 1}
                                                </p>
                                                <input
                                                    type="text"
                                                    name="MedicineName"
                                                    autoComplete="off"
                                                    maxLength={20}
                                                    value={medicine?.MedicineName}
                                                    onChange={(event) => handleInputChange(event, index)}
                                                    placeholder="Input name here"
                                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                        errors.medicines[index].MedicineName
                                                        ? "border-red-500"
                                                        : ""
                                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                                />
                                            </label>
                                            {activeInputIndex === index && suggestions?.length > 0 && (
                                                <div className="absolute w-full bg-white border rounded-md mt-1">
                                                    <div className="">
                                                        {suggestions?.slice(0, 3).map((medication) => (
                                                            <div
                                                                key={medication?.MedicationID}
                                                                onClick={() =>
                                                                    handleSuggestionClick(medication, index)
                                                                }
                                                                className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                                                            >
                                                                {medication?.Name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Frequency */}
                                        <div className="">
                                            <label>
                                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                                    Frequency
                                                </p>
                                                <select
                                                    id="Frequency"
                                                    name="Frequency"
                                                    value={medicine?.Frequency}
                                                    onChange={(event) => handleOnChange2(event, index)}
                                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                        errors.medicines[index].Frequency
                                                        ? "border-red-500"
                                                        : ""
                                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="Once">Once</option>
                                                    <option value="Twice">Twice</option>
                                                    <option value="Thrice">Thrice</option>
                                                </select>
                                            </label>
                                        </div>

                                        {/* Dosage Amount */}
                                        <div className="">
                                            <label>
                                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                                    Dosage Amount
                                                </p>
                                                <input
                                                    id="DosageAmount"
                                                    type="text"
                                                    name="DosageAmount"
                                                    maxLength={6}
                                                    value={medicine?.DosageAmount}
                                                    onChange={(event) => handleOnChange2(event, index)}
                                                    placeholder="Eg. 5ml"
                                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                        errors.medicines[index].DosageAmount
                                                        ? "border-red-500"
                                                        : ""
                                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                                ></input>
                                            </label>
                                        </div>

                                        {/* Meal */}
                                        <div className="">
                                            <label>
                                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                                    Meal
                                                </p>
                                                <select
                                                    id="Meal"
                                                    name="Meal"
                                                    value={medicine?.Meal}
                                                    onChange={(event) => handleOnChange2(event, index)}
                                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                        errors.medicines[index].Meal
                                                        ? "border-red-500"
                                                        : ""
                                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                                >
                                                    <option value="">Select Meal</option>
                                                    <option value="Pre Meal">Pre Meal</option>
                                                    <option value="Post Meal">Post Meal</option>
                                                </select>
                                            </label>
                                        </div>

                                        {/* Duration Count*/}
                                        <div className="w-24">
                                            <label>
                                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                                    Duration
                                                </p>
                                                <input
                                                    type="number"
                                                    name="DurationCount"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        e.target.blur();
                                                    }}
                                                    maxLength={2}
                                                    value={medicine?.DurationCount}
                                                    onChange={(event) => handleOnChange2(event, index)}
                                                    placeholder="Input here"
                                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                        errors.medicines[index].DurationCount
                                                        ? "border-red-500"
                                                        : ""
                                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                                />
                                            </label>
                                        </div>

                                        {/* Duration */}
                                        <div className="pt-3">
                                            <label>
                                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2"></p>
                                                <select
                                                    id="Duration"
                                                    name="Duration"
                                                    value={medicine?.Duration}
                                                    onChange={(event) => handleOnChange2(event, index)}
                                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                        errors.medicines[index].Duration
                                                        ? "border-red-500"
                                                        : ""
                                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                                >
                                                    <option value="">Select Duration</option>
                                                    <option value="Day">Day</option>
                                                    <option value="Week">Week</option>
                                                    <option value="Month">Month</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className="flex">
                                            {index < medicines?.length - 1 ? (
                                                <button
                                                    onClick={() => handleRemoveMedicine(index)}
                                                    className="bg-red-100 active:scale-90 w-8 h-8 mt-4 rounded-full flex items-center justify-center mr-2"
                                                >
                                                    <IoMdRemove className="text-red-500" />
                                                </button>
                                            ) : null}
                                            {index === medicines?.length - 1 ? (
                                                <>
                                                    {medicines?.length > 1 ? (
                                                        <button
                                                            onClick={() => handleRemoveMedicine(index)}
                                                            className="bg-red-100 active:scale-90 w-8 h-8 mt-4 rounded-full flex items-center justify-center mr-2"
                                                        >
                                                            <IoMdRemove className="text-red-500" />
                                                        </button>
                                                    ) : null}
                                                    <button
                                                        onClick={handleAddMedicine}
                                                        className="bg-sky-100 active:scale-90 w-8 h-8 mt-4 rounded-full flex items-center justify-center"
                                                    >
                                                        <IoMdAdd className="text-sky-500" />
                                                    </button>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div className="my-4" >
                                        <label>
                                            <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                                Special Instructions (if any)
                                            </p>
                                            <textarea
                                                id="Details"
                                                name="Details"
                                                value={medicine?.Details}
                                                onChange={(event) => handleOnChange2(event, index)}
                                                placeholder="Enter instruction here"
                                                className={`w-[95%] text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.medicines[index] &&
                                                    errors.medicines[index].Details
                                                    ? "border-red-500"
                                                    : ""
                                                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                            ></textarea>
                                        </label>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>

                    <div onClick={submitHandler} className={`cursor-pointer mt-2 active:scale-95 duration-500 flex justify-center items-center py-3 px-2 bg-[#E5F2FF] rounded-lg w-[95%]`}>
                        <h1 className={`text-[#3CA5DC] text-sm font-semibold font-poppins`}>
                            Save
                        </h1>
                    </div>
                </div>
            </ModalComponent >

            {/* Dr Video Controls */}
            {
                control ? (
                    <>
                        {isLocal && (
                            <Controls onExpand={props.onExpand} isExpanded={props.isExpanded} sessID={sessID} notesBody={notesBody} prescriptionBody={prescriptionBody} canEndCall={canEndCall} setShowModal={setShowModal} showAudioDictation={showAudioDictation} setShowAudioDictation={toggleAudioDictation} isAudioDone={isAudioDone} />
                        )}
                    </>
                ) : (
                    <>
                        {!isLocal && (
                            <Controls onExpand={props.onExpand} isExpanded={props.isExpanded} sessID={sessID} notesBody={notesBody} prescriptionBody={prescriptionBody} canEndCall={canEndCall} setShowModal={setShowModal} showAudioDictation={showAudioDictation} setShowAudioDictation={toggleAudioDictation} isAudioDone={isAudioDone} />
                        )}
                    </>
                )
            }
        </div >
    );
}