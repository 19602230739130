import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import RadioIcon from "../components/RadioIcon";
import toast from "react-hot-toast";
import iphoneFrame from "../assets/Images/MedicalConcierge/iphoneFrame.svg";
import planPreviewBackground from "../assets/Images/MedicalConcierge/planPreviewBackground.png";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import initialPayment from "../assets/Images/MedicalConcierge/initialPayment.svg";
import member from "../assets/Images/MedicalConcierge/member.svg";
import interaction from "../assets/Images/MedicalConcierge/interaction.svg";
import service from "../assets/Images/MedicalConcierge/features.svg";
import Dropdown from "../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  createMedicalConciergePlans,
  updateConciergePlan,
} from "../services/operations/medicalConciergeAPI";
import { useLocation, useNavigate } from "react-router-dom";

const PlanForm = ({
  showBackView,
  setShowBackView,
  formData,
  setFormData,
  errors,
  setErrors,
  interactions,
  setInteractions,
  features,
  setFeatures,
  conciergeFeatures,
  plan,
}) => {
  const [newFeature, setNewFeature] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [service, setService] = useState(conciergeFeatures.slice(0, 6));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "Price":
        maxLength = 6;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    if (name === "MembersIncluded") {
      setShowBackView(true);
    } else {
      setShowBackView(false);
    }
  }

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "MembersIncluded") {
      setShowBackView(true);
    } else {
      setShowBackView(false);
    }
  }

  const handleSuggestions = (input) => {
    const filtered = conciergeFeatures
      .slice(6, conciergeFeatures.length - 1)
      .map((feature) => feature)
      .filter((feature) =>
        feature.Feature.toLowerCase().includes(input.toLowerCase())
      );

    setSuggestions(filtered);
  };

  const submitHandler = async (event) => {
    // event.preventDefault();
    const mandatoryFields = [
      "PlanName",
      "PlanType",
      "Price",
      "MembersIncluded",
      "Details",
      "Features",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    if (features.length === 0) {
      toast.error("Add at least 1 feature");
      setErrors((prev) => ({
        ...prev,
        Features: "Add at least 1 feature",
      }));
      return;
    }
    const body = {
      Name: formData.PlanName,
      PlanType: formData.PlanType,
      Price: parseInt(formData.Price),
      Currency: "CHF",
      MembersIncluded: parseInt(formData.MembersIncluded),
      Interactions: interactions,
      CoverDetails: formData.Details,
      Features: conciergeFeatures.filter((feature) =>
        features.includes(feature.MCFID)
      ),
    };
    if (plan?.MCPID) {
      dispatch(updateConciergePlan(token, plan?.MCPID, body, navigate));
    } else {
      dispatch(createMedicalConciergePlans(token, body, navigate));
    }
  };

  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
        {/* Plan Name */}
        <div className="mb-6">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Plan Name
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.PlanName.length}/25
              </p>
            </div>
            <input
              type="text"
              name="PlanName"
              value={formData.PlanName}
              onChange={handleOnChange}
              maxLength={25}
              placeholder="Ex: Yearly subscription individual"
              className={`w-full ${
                errors.PlanName ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Plan Type */}
        <div className="mb-6">
          <label>
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Plan Type
              <sup className="text-red-600">*</sup>
            </p>
            <Dropdown
              error={errors.PlanType}
              name="PlanType"
              placeholder={"Select Plan Type"}
              options={["One-Off", "Yearly"]}
              onSelect={handleSelect}
              value={formData.PlanType}
            />
          </label>
        </div>
        {/* Price, Members Included */}
        <div className="mb-6 flex space-x-4">
          {/* Price */}
          <label className="flex-1">
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Set Price
              <sup className="text-red-600">*</sup>
            </p>
            <input
              type="number"
              name="Price"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => {
                e.preventDefault();
                e.target.blur();
              }}
              value={formData.Price}
              onChange={handleOnChange}
              placeholder="Ex:  CHF 1500"
              className={`w-full ${
                errors.Price ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>

          {/* Members Included */}
          <label className="flex-1">
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Members Included
              <sup className="text-red-600">*</sup>
            </p>

            <Dropdown
              // placeholder={"Select Members Count"}
              error={errors.MembersIncluded}
              name="MembersIncluded"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              onSelect={handleSelect}
              value={formData.MembersIncluded}
            />
          </label>
        </div>

        {/* Interaction */}
        <div className="mb-6">
          <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
            Interaction
            <sup className="text-red-600">*</sup>
          </p>
          <div className="mb-6 flex items-center space-x-4">
            {["Limited", "Unlimited"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  checked={interactions === option}
                  onChange={() => {
                    setInteractions(option);
                    setShowBackView(true);
                  }}
                />
                <RadioIcon selected={interactions === option} />
                <span className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                  {/* {option.charAt(0).toUpperCase() + option.slice(1)} */}
                  {option}
                </span>
              </label>
            ))}
          </div>
        </div>

        {/* Cover Details */}
        <div className="mb-6">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Cover Details
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.Details.length}/100
              </p>
            </div>
            <textarea
              type="text"
              name="Details"
              value={formData.Details}
              onChange={handleOnChange}
              maxLength={100}
              placeholder="Write briefly about the plan"
              className={`w-full ${
                errors.Details ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        <div className="mb-6">
          <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
            Features Included
            <sup className="text-red-600">*</sup>
          </p>
          <div className="flex flex-row items-center flex-wrap gap-2">
            {service?.map((feature) => (
              <button
                key={feature?.MCFID}
                onClick={() => {
                  if (features.includes(feature?.MCFID)) {
                    setFeatures(features.filter((id) => id !== feature?.MCFID));
                  } else {
                    setFeatures([...features, feature?.MCFID]);
                  }
                  setErrors((prev) => ({
                    ...prev,
                    Features: "",
                  }));
                  setShowBackView(true);
                }}
                className={`px-4 py-2.5 ${
                  features.includes(feature?.MCFID)
                    ? "bg-sky-100 border border-sky-400 "
                    : "bg-gray-100"
                }  rounded-3xl flex items-center justify-center space-x-2.5`}
              >
                <div
                  className={`text-sm  font-poppins leading-none ${
                    features.includes(feature?.MCFID)
                      ? "text-sky-500 font-semibold"
                      : "text-zinc-900 font-normal"
                  }`}
                >
                  {feature?.Feature}
                </div>
                {features.includes(feature?.MCFID) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8327 4.1665L4.16602 15.8332M4.16602 4.1665L15.8327 15.8332"
                      stroke="#3CA5DC"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3.3335V16.6668"
                      stroke="#1C1C1C"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.33398 10H16.6673"
                      stroke="#1C1C1C"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            ))}
          </div>
          {/* Add new button / input field */}
          {showInput ? (
            <div className="relative mt-5">
              <input
                type="text"
                value={newFeature}
                onChange={(e) => {
                  setNewFeature(e.target.value);
                  handleSuggestions(e.target.value);
                }}
                className="text-black text-xs font-normal font-poppins bg-gray-100 rounded-full p-4 border caret-sky-400 focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent"
                placeholder="Add new feature"
              />
              {suggestions.length > 0 && (
                <ul className="absolute left-0 mt-1 w-1/2 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[17vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  {suggestions.map((suggestion, index) => {
                    return (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setService([...service, suggestion]);
                          setFeatures([...features, suggestion?.MCFID]);
                          setNewFeature("");
                          setSuggestions([]);
                          setShowInput(false);
                          setShowBackView(true);
                        }}
                      >
                        {suggestion?.Feature}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          ) : (
            <button
              onClick={() => setShowInput(true)}
              className="px-4 py-2.5 bg-gray-100 rounded-3xl border border-neutral-600 flex justify-center items-center gap-2.5 mt-5 active:scale-95"
            >
              <div className="text-center text-zinc-900 text-sm font-normal font-poppins leading-none">
                Add new
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3.3335V16.6668"
                  stroke="#1C1C1C"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.33398 10H16.6673"
                  stroke="#1C1C1C"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}

          {errors.Features && (
            <p className="text-rose-500 text-xs font-semibold font-poppins leading-none mt-4">
              Add at least 1 feature
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        <button className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95">
          <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
            {plan?.MCPID ? "Cancel" : "Save as Draft"}
          </div>
        </button>
        <button
          onClick={submitHandler}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            {plan?.MCPID ? "Save Changes" : "Save & Publish"}
          </div>
        </button>
      </div>
    </div>
  );
};

const PhoneMockup = ({ children, showBackView, setShowBackView }) => {
  return (
    <div
      onClick={() => setShowBackView && setShowBackView(!showBackView)}
      className="relative mx-auto w-[300px] h-[600px]"
    >
      <img
        src={iphoneFrame}
        alt="iPhone Frame"
        className="w-full h-full object-contain"
      />
      <div className="absolute inset-[9%] overflow-hidden">{children}</div>
    </div>
  );
};

const PreviewSection = ({
  showBackView,
  setShowBackView,
  formData,
  interactions,
  features,
  conciergeFeatures,
}) => {
  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
          Preview for Patient
        </div>
        <div className="relative flex items-center justify-center h-[650px]">
          <div>
            <div
              className={`absolute top-1 left-1/3 transition-all duration-300`}
            >
              <PhoneMockup
                showBackView={showBackView}
                setShowBackView={setShowBackView}
              >
                {showBackView ? (
                  <FrontView
                    formData={formData}
                    interactions={interactions}
                    features={features}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                  />
                ) : (
                  <BackView
                    formData={formData}
                    interactions={interactions}
                    features={features}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    conciergeFeatures={conciergeFeatures}
                  />
                )}
              </PhoneMockup>
            </div>
            <div
              className={`absolute top-12 left-1/4 transition-all duration-300`}
            >
              <PhoneMockup>
                {showBackView ? (
                  <BackView
                    formData={formData}
                    interactions={interactions}
                    features={features}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    conciergeFeatures={conciergeFeatures}
                  />
                ) : (
                  <FrontView
                    formData={formData}
                    interactions={interactions}
                    features={features}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                  />
                )}
              </PhoneMockup>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-10 mt-5">
          <div
            onClick={() => setShowBackView(false)}
            className={`w-10 h-10 ${
              !showBackView
                ? "bg-white border border-neutral-300"
                : "bg-sky-100 border border-sky-400"
            } rounded-full flex items-center justify-center`}
          >
            <IoChevronBack
              className={`${
                !showBackView
                  ? "text-black cursor-not-allowed"
                  : "text-sky-500 cursor-pointer"
              } `}
            />
          </div>
          <div
            onClick={() => setShowBackView(true)}
            className={`w-10 h-10 ${
              showBackView
                ? "bg-white border border-neutral-300"
                : "bg-sky-100 border border-sky-400"
            } rounded-full flex items-center justify-center`}
          >
            <IoChevronForward
              className={`${
                showBackView
                  ? "text-black cursor-not-allowed"
                  : "text-sky-500 cursor-pointer"
              } `}
            />
          </div>
        </div>
        <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
          <div className="w-96 text-center text-black text-xl font-normal font-poppins">
            This is how your offer will look to Patients on the App
          </div>
        </div>
      </div>
    </div>
  );
};

const FrontView = ({ formData, showBackView, setShowBackView }) => (
  <div
    // onClick={() => setShowBackView(false)}
    className="h-full flex flex-col justify-between"
  >
    <div>
      {/* FlatTopBar */}
      <div className="flex items-center space-x-14">
        <IoChevronBack
          onClick={() => setShowBackView(!showBackView)}
          className="cursor-pointer"
        />
        <div className="text-zinc-950 text-xs font-semibold font-poppins select-none ">
          Concierge Plans
        </div>
      </div>

      {/* Title */}
      <div className="text-black text-xs font-semibold font-poppins leading-none mt-6">
        Available Concierge Plans:
      </div>

      {/* Card */}
      <div className="bg-white rounded-3xl shadow border border-sky-500 mt-5 p-4 mx-1">
        <div className="text-zinc-900 text-sm font-bold font-poppins leading-relaxed">
          {formData?.PlanName || "Your plan here"}
        </div>
        <div className="text-sky-500 text-4xl font-bold font-poppins leading-relaxed">
          {formData?.Price || 0}
          <span>
            <sup className="text-slate-500">*</sup>
          </span>
          <span className="text-slate-500 text-xl font-semibold font-poppins leading-snug">
            CHF
          </span>
        </div>
        <div className="text-sky-500 text-base font-medium font-poppins leading-relaxed">
          Initial Payment
        </div>
        <div>
          <span className="text-zinc-900 text-2xl font-bold font-poppins leading-relaxed">
            {formData?.Price || 0}{" "}
          </span>
          <span classNameƒ="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
            CHF
          </span>
        </div>
        <div className="text-sky-500 text-base font-medium font-poppins leading-relaxed">
          Cover Details
        </div>
        <div className="text-zinc-900 text-xs font-normal font-poppins">
          {formData?.Details || "lorem"}
        </div>
        <button className="py-2 w-full bg-amber-500 rounded-xl mt-5">
          <div className="text-white text-sm font-semibold font-poppins leading-6 text-center">
            Learn More
          </div>
        </button>
      </div>

      {/* Dots */}
      <div className="flex items-center justify-center space-x-1 mt-4">
        <div className="w-3 h-1.5 bg-sky-500 rounded-full" />
        <div className="w-1.5 h-1.5 bg-gray-200 rounded-full" />
        <div className="w-1.5 h-1.5 bg-gray-200 rounded-full" />
      </div>
    </div>

    {/* Button */}
    <button className="py-2 bg-sky-500 rounded-lg">
      <div className="text-white text-sm font-semibold font-poppins leading-6 text-center">
        Subscribe
      </div>
    </button>
  </div>
);

const BackView = ({
  formData,
  interactions,
  features,
  showBackView,
  setShowBackView,
  conciergeFeatures,
}) => {
  const getFeatureNameById = (id) => {
    const feature = conciergeFeatures?.find((feature) => {
      return feature.MCFID === id;
    });

    return feature ? feature?.Feature : "Unknown Feature";
  };

  return (
    <div
      // onClick={() => setShowBackView(true)}
      className="h-full flex flex-col justify-between"
    >
      <div>
        {/* FlatTopBar */}
        <div className="flex items-center space-x-10">
          <IoChevronBack
            onClick={() => setShowBackView(!showBackView)}
            className="cursor-pointer"
          />
          <div className="text-zinc-950 text-xs font-semibold font-poppins select-none">
            Concierge Plans Details
          </div>
        </div>

        {/* Image with Plan Details */}
        <div className="relative mt-4">
          <img src={planPreviewBackground} alt="" className="w-full" />
          <div className="absolute left-3 top-10">
            <div className="text-white text-sm font-bold font-poppins leading-normal">
              {formData?.PlanName || "Your"} Plan
            </div>
            <div className="text-white text-2xl font-bold font-poppins leading-normal mt-2">
              {formData?.Price || 0}
              <span>
                <sup className="text-white">*</sup>
              </span>
              <span className="text-white text-xl font-semibold font-poppins leading-tight">
                CHF
              </span>
            </div>
          </div>
        </div>

        {/* Arrows and Plan Title */}
        <div className="flex items-center justify-between mt-5">
          <div className="w-6 h-6 bg-sky-100 rounded-full flex items-center justify-center">
            <IoChevronBack className="text-sky-500 cursor-pointer" />
          </div>
          <div className="text-center text-slate-700 text-xs font-bold font-poppins ml-4">
            {formData?.PlanName || "Your"} Plan
          </div>
          <div className="w-6 h-6 bg-sky-100 rounded-full flex items-center justify-center">
            <IoChevronForward className="text-sky-500 cursor-pointer" />
          </div>
        </div>
        <div className="ml-4">
          {/* Initial Payment */}
          <div className="flex items-center mt-4">
            <div className="w-6 h-6 rounded-full bg-orange-100 flex items-center justify-center">
              <img
                src={initialPayment}
                alt="Initial Payment"
                className="w-3 h-3 object-contain"
              />
            </div>
            <p className="ml-2 text-xs font-semibold text-black">
              Initial Payment of {formData?.Price || 0} CHF
            </p>
          </div>

          {/* Members */}
          <div className="flex items-center mt-4">
            <div className="w-6 h-6 rounded-full bg-orange-100 flex items-center justify-center">
              <img
                src={member}
                alt="Members"
                className="w-3 h-3 object-contain"
              />
            </div>
            <p className="ml-2 text-xs font-semibold text-black">
              Number of Members: {formData.MembersIncluded}
            </p>
          </div>

          {/* Interactions */}
          <div className="flex items-center mt-4">
            <div className="w-6 h-6 rounded-full bg-orange-100 flex items-center justify-center">
              <img
                src={interaction}
                alt="Interactions"
                className="w-3 h-3 object-contain"
              />
            </div>
            <p className="ml-2 text-xs font-semibold text-black">
              Interactions:{" "}
              {/* {interactions.charAt(0).toUpperCase() + interactions.slice(1)} */}
              {interactions}
            </p>
          </div>

          {/* Service Includes */}
          <div className="flex mt-4">
            <div className="w-6 h-6 rounded-full bg-orange-100 flex items-center justify-center">
              <img
                src={service}
                alt="Service"
                className="w-3 h-3 object-contain"
              />
            </div>
            <div className="flex flex-wrap ml-2 w-[80%] max-h-[10vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              {features?.map((service, index) => (
                <div
                  key={index}
                  className="bg-sky-100 py-1 px-2.5 m-1 rounded-full"
                >
                  <p className="text-[10px] font-semibold text-sky-500">
                    {getFeatureNameById(service)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Button */}
      <button className="py-2 bg-sky-500 rounded-lg">
        <div className="text-white text-sm font-semibold font-poppins leading-6 text-center">
          Subscribe
        </div>
      </button>
    </div>
  );
};

function AddNewConceirgePlan() {
  const location = useLocation();
  const plan = location.state || {};
  const { conciergeFeatures } = useSelector((state) => state.medicalConcierge);
  const [showBackView, setShowBackView] = useState(false);
  const [interactions, setInteractions] = useState("Limited");
  const [features, setFeatures] = useState([]);
  const [formData, setFormData] = useState({
    PlanName: "",
    PlanType: "",
    Price: "",
    MembersIncluded: "1",
    Details: "",
    Features: [],
  });
  const [errors, setErrors] = useState({
    PlanName: "",
    PlanType: "",
    Price: "",
    MembersIncluded: "",
    Details: "",
    Features: "",
  });

  useEffect(() => {
    setFormData({
      PlanName: plan?.Name || "",
      PlanType: plan?.PlanType || "",
      Price: plan?.Price || "",
      MembersIncluded: plan?.MembersIncluded || "",
      Details: plan?.CoverDetails || "",
      Features: plan?.FeaturesPerPlans?.map((feature) => feature.MCFID) || [],
    });
    setInteractions(plan?.Interactions || "Limited");
    setFeatures(plan?.FeaturesPerPlans?.map((feature) => feature.MCFID) || []);
  }, [plan?.MCPID]);

  return (
    <div className="bg-[#FCFCFC] min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {plan?.MCPID ? "Edit Plan" : "Add a New Plan"}
      </div>
      <Breadcrumb />
      <div className="flex gap-8 p-8">
        <PlanForm
          showBackView={showBackView}
          setShowBackView={setShowBackView}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          interactions={interactions}
          setInteractions={setInteractions}
          features={features}
          setFeatures={setFeatures}
          conciergeFeatures={conciergeFeatures}
          plan={plan}
        />
        <PreviewSection
          showBackView={showBackView}
          setShowBackView={setShowBackView}
          formData={formData}
          interactions={interactions}
          features={features}
          conciergeFeatures={conciergeFeatures}
        />
      </div>
      {plan?.MCPID && (
        <div className="flex justify-end pr-8">
          <button className="text-rose-700 text-base font-normal font-poppins leading-tight active:scale-95">
            Delete Plan
          </button>
        </div>
      )}
    </div>
  );
}

export default AddNewConceirgePlan;
