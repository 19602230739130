import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { clinicianDashboardEndpoints } from "../apis";
import { setLoading } from "../../slices/profileSlice";
import { setDashboard } from "../../slices/dashboardSlice";

const { GET_ALL_BOOKED_SLOTS_API, GET_DATE_ID, GET_ORGANIZATION_DOCTORS, GET_AVAILABLE_SLOTS_FOR_SPECIFIED_DATE, GET_SLOT_AVAILABILITY_STATUS, GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE, BOOK_APPOINTMENT, GET_ORGANIZATION_LOGO } = clinicianDashboardEndpoints;

// GET ALL BOOKED SLOTS
export function getAllBookedSlots(token, body) {
  return async (dispatch, getState) => {

    const { showMeetingView } = getState().dashboard;
    if (showMeetingView) return; // Skip fetching if showMeetingView is true
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        GET_ALL_BOOKED_SLOTS_API,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // console.log("GET_BOOKED_SLOT_API RESPONSE............", response);
      dispatch(setDashboard(response?.data?.PatientList));
    } catch (error) {
      console.log("GET_BOOKED_SLOT_API ERROR............", error);
      // toast.error("Could Not Get Booked Slot Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET ALL BOOKED SLOTS for BOOK APPOINTMENT
export function getAllBookedSlotsForBookAppointment(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result = [];
    try {
      const response = await apiConnector(
        "POST",
        GET_ALL_BOOKED_SLOTS_API,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.PatientList;

      console.log("GET_BOOKED_SLOT_API RESPONSE............", response);
      // dispatch(setDashboard(response?.data?.PatientList));
    } catch (error) {
      console.log("GET_BOOKED_SLOT_API ERROR............", error);
      // toast.error("Could Not Get Booked Slot Data");
    }
    dispatch(setLoading(false));
    return result;
    // toast.dismiss(toastId);
  };
}

// GET DATE ID
export function getDateID(requestBody) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("POST", GET_DATE_ID, requestBody);
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.DateID;
    } catch (error) {
      console.log("DATE ID API ERROR :", error);
      toast.error(error?.response?.data?.message || "ERROR WHILE FETCHING DATE ID");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET Available Slots For Specified Date
export function getAvailableSlotsForSpecifiedDate(token, requestBody) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("POST", GET_AVAILABLE_SLOTS_FOR_SPECIFIED_DATE, requestBody, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.AvailableTimes;
    } catch (error) {
      console.log("AVAILABLE SLOTS FOR SPECIFIED DATE API ERROR :", error);
      toast.error(error?.response?.data?.message || "ERROR WHILE FETCHING AVAILABLE SLOTS FOR SPECIFIED DATE");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET Available Slots For Specified Date
export function getAvailableSlotsForDoctorOnSpecifiedDate(token, requestBody) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const params = new URLSearchParams({
        DID: requestBody?.DID,
        Date: requestBody?.Date,
      });

      const url = `${GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE}?${params.toString()}`;

      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("AVAILABLE SLOTS FOR SPECIFIED DATE API SUCCESS :", response);
      result = response?.data;
    } catch (error) {
      console.log("AVAILABLE SLOTS FOR SPECIFIED DATE API ERROR :", error);
      toast.error(error?.response?.data?.message || "ERROR WHILE FETCHING AVAILABLE SLOTS FOR SPECIFIED DATE");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// Book Appointment
export function bookAppointment(token, requestBody, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("POST", BOOK_APPOINTMENT, requestBody, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data;
      dispatch(getAllBookedSlots(token, body))
    } catch (error) {
      console.log("BOOK APPOINTMENT API ERROR :", error);
      toast.error(error?.response?.data?.message || "ERROR WHILE FETCHING BOOK APPOINTMENT");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET Slot Availibility Status For Calendar
export function getSlotAvailabilityStatus(token, did) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {

      const url = `${GET_SLOT_AVAILABILITY_STATUS}/${did.toString()}`;

      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("SLOT AVAILABILITY STATUS API SUCCESS :", response);
      result = response?.data;
    } catch (error) {
      console.log("SLOT AVAILABILITY STATUS API ERROR :", error);
      toast.error(error?.response?.data?.message || "ERROR WHILE FETCHING SLOT AVAILABILITY STATUS");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}