import React, { useState } from "react";
import background from "../assets/Images/PatientProfile/backgroundVector.png";
import currentPlan from "../assets/Images/PatientProfile/ConceirgePlan/currentPlan.png";
import checkmark from "../assets/Images/PatientProfile/ConceirgePlan/checkmark.svg";
import MedicalConceirgePlanCard from "./MedicalConceirgePlanCard";

const SubscriptionCard = ({ setShowAllPlans }) => {
  return (
    <div className="bg-white p-6 rounded-2xl shadow max-w-5xl mx-auto">
      <div className="flex justify-between space-x-6">
        {/* Column 1 */}
        <div className="flex-1 flex flex-col items-center">
          <div className="w-12 h-12 p-1 bg-sky-500 rounded-full flex items-center justify-center">
            <img src={currentPlan} alt="" />
          </div>
          <div className="px-3 py-1 bg-purple-50 rounded-2xl text-center text-zinc-900 text-sm font-semibold font-poppins leading-tight my-5">
            Current Plan
          </div>
          <div className="text-center text-zinc-900 text-2xl font-bold font-poppins leading-loose">
            One-off Payment
          </div>
          <div className="text-center my-2">
            <span className="text-neutral-600 text-2xl font-normal font-poppins leading-10">
              Initial Fee:{" "}
            </span>
            <span className="text-gray-900 text-2xl font-semibold font-poppins leading-10">
              CHF 1500
            </span>
          </div>
          <div className="text-center">
            <span className="text-neutral-600 text-2xl font-normal font-poppins leading-10">
              Daily:{" "}
            </span>
            <span className="text-gray-900 text-2xl font-semibold font-poppins leading-10">
              100 CHF/ day
            </span>
          </div>
        </div>
        {/* Column 2 */}
        <div className="flex-1 flex flex-col space-y-4">
          <div className="text-gray-900 text-2xl font-normal font-poppins leading-9">
            Start Date:{" "}
            <span className="text-gray-900 text-2xl font-semibold font-poppins leading-9">
              20 June, 2024
            </span>
          </div>
          <div className="text-zinc-900 text-base font-semibold font-poppins leading-loose">
            Duration:{" "}
            <span className="text-zinc-900 text-sm font-normal font-poppins leading-loose">
              From the start of the assignment to the end (either final release
              from the clinic or from the rehabilitation recovery facility)
            </span>
          </div>
          <div className="flex space-x-3">
            <img src={checkmark} alt="" className="w-6 h-6 " />
            <div className="text-neutral-600 text-base font-normal font-poppins leading-normal">
              Pay an initial fee & a daily cumulative fee for the duration of
              the service
            </div>
          </div>
        </div>
        {/* Column 3 */}
        <div className="flex-0.5 flex-grow-1 flex flex-col justify-between">
          <div>
            <div className="text-gray-900 text-2xl font-normal font-poppins leading-9">
              Payment Details
            </div>
            <div className="flex items-center space-x-4 my-2">
              <div className="text-gray-900 text-base font-normal font-poppins leading-tight">
                Payment status:{" "}
              </div>
              <div className="px-4 py-2.5 bg-emerald-100 rounded-3xl text-center text-green-700 text-xs font-semibold font-poppins leading-none">
                Paid
              </div>
            </div>
            <div className="text-neutral-600 text-sm font-semibold font-poppins leading-tight">
              via Card XXXX9032
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => setShowAllPlans(true)}
              className="text-sky-500 text-base font-semibold font-poppins leading-tight active:scale-95"
            >
              Change Plan
            </button>
            <button className="text-sky-500 text-base font-semibold font-poppins leading-tight active:scale-95">
              Cancel Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


function PatientConciergePlan() {
  const [showAllPlans, setShowAllPlans] = useState(false);

  return (
    <div className="mt-2 relative min-h-full">
      <img
        src={background}
        alt=""
        className="absolute left-0 top-0 w-full h-[55vh]"
      />
      {!showAllPlans ? (
        <div className="container mx-auto absolute mt-10 z-50">
          <SubscriptionCard setShowAllPlans={setShowAllPlans} />
        </div>
      ) : (
        <div className="container mx-auto absolute mt-10 z-50">
          <div className="flex flex-col">
            {PLANS.map((plan, index) => (
              <MedicalConceirgePlanCard key={index} plan={plan} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientConciergePlan;

const PLANS = [
  {
    name: "Premium Plan",
    type: "Yearly",
    price: 5000,
    members: 2,
    interactions: "Unlimited",
    coverDetails:
      "Get unlimited interactions year-round with your dedicated point person.",
    features: [
      "Transport Assistance",
      "Medical Advocacy",
      "Personal Health Manager",
      "24/7 Support",
    ],
    isCurrentPlan: true,
  },
  {
    name: "Individual Plan",
    type: "Monthly",
    price: 500,
    members: 1,
    interactions: "10 per month",
    coverDetails:
      "Receive up to 10 interactions per month with your dedicated point person.",
    features: ["Medical Advocacy", "Personal Health Manager", "24/7 Support"],
    isCurrentPlan: false,
  },
  {
    name: "Family Plan",
    type: "Yearly",
    price: 8000,
    members: 4,
    interactions: "Unlimited",
    coverDetails:
      "Get unlimited interactions year-round for your entire family with a dedicated point person.",
    features: [
      "Transport Assistance",
      "Medical Advocacy",
      "Personal Health Manager",
      "24/7 Support",
      "Family Wellness Programs",
    ],
    isCurrentPlan: false,
  },
];
