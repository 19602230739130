import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBreadcrumb, resetBreadcrumbs } from "../slices/breadcrumbSlice";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";

const routeNameMapping = {
  "/clinicianDashboard": "Clinician Dashboard",
  "/clinicianDashboard/todaysPatientList": "Patient List",
  "/clinicianDashboard/nextSchedule": "Weekly Calendar",
  "/clinicianDashboard/payouts": "Clinic Performance",
  "/clinicianDashboard/fullscreenVideoCall": "Fullscreen Video Call",
  "/patientDashboard": "Patient Management",
  "/patientDashboard/patientRegistration": "Patient Registration",
  "/patientDashboard/bookAppointment": "Book Appointment",
  "/patientDashboard/patientProfile": "Patient Profile",
  "/patientDashboard/patientProfile/healthJourney": "Health Journey",
  "/patientDashboard/patientProfile/mentalHealthAssessment": "Mental Health Assessment",
  "/patientDashboard/patientProfile/consultationHistory": "Consultation History",
  "/patientDashboard/patientProfile/healthProgram": "Health Program",
  "/patientDashboard/patientProfile/addTreatmentProgram": "Add Treatment Program",
  "/patientDashboard/managePatient": "Manage Patient",
  "/patientDashboard/managePatient/reschedulePatient": "Reschedule Appointment",
  "/comingSoon": "Coming Soon"
};

const Breadcrumb = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const breadcrumbs = useSelector((state) => state.breadcrumb.history);

  useEffect(() => {
    const paths = location.pathname.split("/").filter(Boolean);
    const currentPath = location.pathname;
    const currentState = location.state || {}; // Retrieve state if available

    // Define the name using the mapping structure
    const breadcrumbName = routeNameMapping[currentPath] || "Home";

    // Add or update the breadcrumb
    dispatch(
      addBreadcrumb({
        path: currentPath,
        name: breadcrumbName,
        state: currentState,
      })
    );
  }, [location.pathname, location.state, dispatch]);

  return (
    <nav className="flex items-center space-x-2">
      {breadcrumbs
        .filter(({ path }) => path !== "/") // Exclude the root "/"
        .map(({ path, name }, index) => (
          <div key={path} className="flex items-center">
            {index !== breadcrumbs.length - 1 ? (
              <Link
                to={path}
                state={breadcrumbs.find(b => b.path === path)?.state}
                className={`text-sm font-poppins ${index !== breadcrumbs.length - 1 ? "text-[#3ca5dc] font-semibold" : "text-[#545454] font-normal"
                  }`}
              >
                {name}
              </Link>
            ) : (
              <span
                className={`text-sm font-poppins text-[#545454] font-normal`}
              >
                {name}
              </span>
            )}
            {index < breadcrumbs.length - 1 && (
              <img
                src={breadcrumbsArrow}
                className="w-4 h-4 mx-2"
                alt="breadcrumb arrow"
              />
            )}
          </div>
        ))}
    </nav>
  );
};

export default Breadcrumb;