import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import healthProgramDesign from "../assets/Images/PatientProfile/healthProgramDesign.png";
import { FaCheck } from "react-icons/fa6";
import { FaExclamation } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ModalComponent from "./Modal";
import { IoIosSearch } from "react-icons/io";
import trash from "../assets/Images/PatientProfile/trash.png";
import { IoMdRemove } from "react-icons/io";
import { getAllTreatmentProgramWeekData } from "../services/operations/masterDataAPI";
import { useDispatch, useSelector } from "react-redux";
import RadioButton from "./RadioButton/RadioButton";
import { assignTreatmentProgram, deleteAssignedTreatmentProgram, editAssignedTreatmentProgram } from "../services/operations/patientManagementAPI";
import toast from "react-hot-toast";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiExpandAlt } from "react-icons/bi";
import LABTEST from "../assets/Images/TreatmentProgram/LABTESTimg.png";
import ATHOME from "../assets/Images/TreatmentProgram/ATHOMEimg.png";
import INCLINIC from "../assets/Images/TreatmentProgram/INCLINICimg.png";

function HealthProgram({ healthProgram, consultationHistory, patient }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const { token } = useSelector((state) => state.auth);
  const [filter, setFilter] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [treatmentPrg, setTreatmentPrg] = useState(null);
  const [treatmentProgramWeekData, setTreatmentProgramWeekData] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentProgramIndex, setCurrentProgramIndex] = useState(0);
  const [filteredTreatmentProgramWeekData, setFilteredTreatmentProgramWeekData] = useState([]);
  const [revertToOrginalWeekData, setRevertToOrginalWeekData] = useState(false);
  const [weekData, setWeekData] = useState(treatmentPrg?.WeekData || {});
  const weeks = Array.from({ length: 12 }, (_, index) => index + 1);
  const [weekDataForEdit, setWeekDataForEdit] = useState({});

  const handleDelete = (week) => {
    const weekNumber = week.replace(/\D/g, '');
    const updatedWeekData = { ...weekData };
    delete updatedWeekData[week];

    setWeekData(updatedWeekData);
  };

  const handleDeleteForEdit = (week) => {
    const updatedWeekData = { ...weekDataForEdit };
    delete updatedWeekData[`Week${week}`];

    setWeekDataForEdit(updatedWeekData);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value
    setSearchInput(value);

    const filteredPrograms = treatmentProgramWeekData.filter(prog =>
      prog.Name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTreatmentProgramWeekData(filteredPrograms);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const assignTreatmentPrg = async (event) => {
    const selectedWeeks = Object.entries(weekData)
      .filter(([week, value]) => week.startsWith("Week") && !week.endsWith("Type") && value !== null)
      .map(([week]) => parseInt(week.replace(/\D/g, ''), 10));

    // Ensure unique and sorted week numbers
    const uniqueSelectedWeeks = [...new Set(selectedWeeks)].sort((a, b) => a - b);

    // Construct WeekContent with selected weeks and fill up to Week12 with null
    const weekContent = Array.from({ length: 12 }).reduce((acc, _, index) => {
      const weekIndex = index + 1;
      const selectedWeekNumber = uniqueSelectedWeeks[index]; // Use the index directly to fetch the selected week number

      if (selectedWeekNumber !== undefined) {
        acc[`Week${weekIndex}`] = treatmentPrg.WeekData[`Week${selectedWeekNumber}`] || null;
        acc[`Week${weekIndex}Type`] = treatmentPrg.WeekData[`Week${selectedWeekNumber}Type`] || null;
      } else {
        acc[`Week${weekIndex}`] = null;
        acc[`Week${weekIndex}Type`] = null;
      }

      return acc;
    }, {});

    const sessionDetails = {
      SessionID: consultationHistory[0]?.AppointmentID,
      TPID: treatmentPrg?.TPID,
      ProgramName: treatmentPrg?.Name,
      WeekNumber: selectedWeeks.length,
      WeekContent: weekContent,
      DiagnosisID: treatmentPrg?.DiagnosisID,
      DiagnosisType: treatmentPrg?.DiagnosisType,
    };

    try {
      await dispatch(assignTreatmentProgram(token, sessionDetails, navigate, patient));
    } catch (error) {
      console.error("Error Assigning Program:", error);
    }
    setShowModal(false);
  };

  const editTreatmentPrg = async (event) => {
    const selectedWeeks = Object.entries(weekDataForEdit)
      .filter(([week, value]) => week.startsWith("Week") && !week.endsWith("Type") && value !== null)
      .map(([week]) => parseInt(week.replace(/\D/g, ''), 10));

    // Ensure unique and sorted week numbers
    const uniqueSelectedWeeks = [...new Set(selectedWeeks)].sort((a, b) => a - b);

    // Construct WeekContent with selected weeks and fill up to Week12 with null
    const weekContent = Array.from({ length: 12 }).reduce((acc, _, index) => {
      const weekIndex = index + 1;
      const selectedWeekNumber = uniqueSelectedWeeks[index]; // Use the index directly to fetch the selected week number

      if (selectedWeekNumber !== undefined) {
        acc[`Week${weekIndex}`] = weekDataForEdit[`Week${selectedWeekNumber}`] || null;
        acc[`Week${weekIndex}Type`] = weekDataForEdit[`Week${selectedWeekNumber}Type`] || null;
      } else {
        acc[`Week${weekIndex}`] = null;
        acc[`Week${weekIndex}Type`] = null;
      }

      return acc;
    }, {});

    const sessionDetails = {
      WeekNumber: selectedWeeks.length,
      WeekContent: weekContent,
    };

    const atpid = healthProgram[currentProgramIndex]?.ATPID;

    try {
      await dispatch(editAssignedTreatmentProgram(token, atpid, sessionDetails, navigate, patient));
    } catch (error) {
      console.error("Error Assigning Program:", error);
    }
    setShowEditModal(false);
    setShowDetailsModal(false);
  };

  const deleteAssignedTreatmentPrg = async (event) => {
    event.preventDefault();
    const currentProgram = healthProgram[currentProgramIndex];

    // Create an initial WeekContent object
    let weekContent = {
      Week1: null, Week2: null, Week3: null, Week4: null,
      Week5: null, Week6: null, Week7: null, Week8: null,
      Week9: null, Week10: null, Week11: null, Week12: null
    };

    // Populate WeekContent based on IsWeekComplete
    for (let i = 1; i <= 12; i++) {
      if (currentProgram[`IsWeek${i}Complete`] === 1) {
        weekContent[`Week${i}`] = currentProgram[`Week${i}`]
      }
    }

    // Calculate WeekNumber based on non-null weeks
    let weekNumber = Object.values(weekContent).filter(content => content !== null).length;

    if (weekNumber === 0) {
      // Call deleteAssignedTreatmentPrg again if no weeks are complete
      try {
        await dispatch(deleteAssignedTreatmentProgram(token, currentProgram?.ATPID, navigate, patient));
      } catch (error) {
        console.error("Error Deleting Assigned Treatment Program:", error);
      }
    } else {
      // Log the data if there are weeks with IsWeekComplete set to 1
      const weekData = { WeekNumber: weekNumber, WeekContent: weekContent };
      try {
        await dispatch(editAssignedTreatmentProgram(token, currentProgram?.ATPID, weekData, navigate, patient));
      } catch (error) {
        console.error("Error Deleting Assigned Treatment Program:", error);
      }
    }
  };

  const handlePrevProgram = () => {
    if (currentProgramIndex > 0) {
      setCurrentProgramIndex(currentProgramIndex - 1);
    }
  };

  const handleNextProgram = () => {
    if (currentProgramIndex < healthProgram.length - 1) {
      setCurrentProgramIndex(currentProgramIndex + 1);
    }
  };

  useEffect(() => {
    if (healthProgram.length > 0) {
      const initialWeekData = weeks.reduce((acc, week) => {
        const weekContent = healthProgram[currentProgramIndex][`Week${week}`];
        const weekImg = healthProgram[currentProgramIndex][`Week${week}Type`];
        if (weekContent) {
          acc[`Week${week}`] = weekContent;
          acc[`Week${week}Type`] = weekImg;
        }
        return acc;
      }, {});
      setWeekDataForEdit(initialWeekData);
    }
  }, [healthProgram, currentProgramIndex, revertToOrginalWeekData]);

  useEffect(() => {
    const fetchTreatmentProgramWeekData = async () => {
      if (token) {
        try {
          const res = await dispatch(getAllTreatmentProgramWeekData(token));
          setTreatmentProgramWeekData(res || []);
          setFilteredTreatmentProgramWeekData(res || []);
        } catch (error) {
          console.error("Failed to fetch Treatment Programs", error);
        }
      }
    };

    fetchTreatmentProgramWeekData();
  }, [token, dispatch]);

  if (consultationHistory?.length === 0 || healthProgram?.length === 0) {
    return (
      <div>
        <div className="w-44 text-black text-lg font-semibold font-poppins">
          Health Programs
        </div>
        <div className="flex flex-row items center space-x-3 mt-5">
          <button
            onClick={() => handleFilterChange("all")}
            className={`px-6 py-2 ${filter === "all" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
          >
            <div
              className={`text-${filter === "all" ? "sky" : "zinc"
                }-500 text-xs font-semibold font-poppins`}
            >
              All
            </div>
          </button>
          <button
            onClick={() => handleFilterChange("program")}
            className={`px-6 py-2 ${filter === "program" ? "bg-sky-100" : "bg-gray-100"
              } rounded-3xl`}
          >
            <div
              className={`text-${filter === "program" ? "sky" : "zinc"
                }-500 text-xs font-semibold font-poppins`}
            >
              Program (0)
            </div>
          </button>
        </div>
        <div className="flex flex-row-reverse items-center justify-between">
          <img
            src={healthProgramDesign}
            alt=""
            className="w-44 h-44 object-contain"
          />
          <button onClick={() => setShowModal(true)} className="bg-sky-100 rounded-xl border-2 border-sky-400 border-dashed flex flex-row items-center justify-center space-x-2 px-6 py-1 active:scale-95 duration-200">
            <IoMdAdd className="text-sky-400 text-lg" />
            <div className="text-center text-sky-400 text-lg font-semibold font-poppins">
              Add New Program
            </div>
          </button>
          <div></div>
        </div>

        {/* Select Treatment Program MODAL */}
        <ModalComponent
          show={showModal}
          handleClose={() => { setShowModal(false); setTreatmentPrg(null); setWeekData({}); }}
          outSideModalPress={false}
        >
          <div className="w-[50vw]">
            <div className="flex items-center justify-between">
              <div className="text-black text-base font-semibold font-poppins">
                Search Treatment Program
              </div>

              <div className="flex flex-row items-center justify-center ml-auto gap-2">
                <label className=" w-60 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
                  <IoIosSearch className="text-neutral-400 w-4 h-4" />
                  <input
                    type="text"
                    placeholder="Search here"
                    value={searchInput}
                    onChange={handleSearchChange}
                    className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
                  />
                </label>
                <button
                  onClick={() => {
                    setShowModal(false);
                    // setShowAssignTreatmentProgram(true);
                    navigate("/createTreatmentProgram");
                  }}
                  className="px-3 py-2 bg-sky-500 active:bg-sky-500 active:scale-95 rounded shadow flex justify-center items-center gap-2.5"
                >
                  <IoMdAdd className="text-sky-100 text-lg" />
                  <div className="text-sky-100 text-sm font-normal font-poppins">
                    New Program
                  </div>
                </button>
              </div>
            </div>
            <div className="border border-b-1 border-gray-200 my-6"></div>

            {/* Select Treatment Program */}
            <div className="w-full max-h-[60vh] overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div className="grid grid-cols-2 justify-center items-center gap-5 w-[80%]">
                {filteredTreatmentProgramWeekData?.map(prog => (
                  <div key={prog.TPID} className="flex flex-col justify-center items-start w-full gap-2">
                    <RadioButton
                      name="TreatmentProgram"
                      id={prog.Name}
                      value={prog.Name}
                      text={prog.Name}
                      textFont="font-semibold"
                      editText={true}
                      onEdit={() => { navigate("/createTreatmentProgram", { state: { prog } }) }}
                      onChange={() => { setTreatmentPrg(prog); setWeekData(prog.WeekData); }}
                      checked={treatmentPrg?.Name === prog.Name}
                    />
                  </div>
                ))}
              </div>
              {(filteredTreatmentProgramWeekData && Object.keys(weekData).length !== 0) && (
                <div className="flex flex-col justify-center items-center w-full">
                  <div className="grid grid-cols-6 gap-2 items-center justify-between w-full mt-10">
                    {Object.entries(weekData).map(([week, value]) => {
                      if (week.startsWith("Week") && value !== null && !week.endsWith("Type")) {
                        const weekNumber = week.replace(/\D/g, '');
                        const weekTypeKey = `Week${weekNumber}Type`;
                        const weekType = weekData[weekTypeKey];

                        return (
                          <div key={week} className="flex flex-col items-center space-y-4">
                            <div className="px-6 py-2 bg-sky-100 rounded-2xl">
                              <div className="text-sky-400 text-sm font-semibold font-poppins">
                                {week}
                              </div>
                            </div>
                            <div className="flex justify-center items-center bg-sky-400 rounded-full w-16 h-16 relative">
                              {weekType === 'INCLINIC' && (
                                <img
                                  src={INCLINIC}
                                  alt={weekType}
                                  className="w-16 h-16 object-contain"
                                />
                              )}
                              {weekType === 'LABTEST' && (
                                <img
                                  src={LABTEST}
                                  alt={weekType}
                                  className="w-16 h-16 object-contain"
                                />
                              )}
                              {weekType === 'ATHOME' && (
                                <img
                                  src={ATHOME}
                                  alt={weekType}
                                  className="w-16 h-16 object-contain"
                                />
                              )}
                            </div>
                            <div className="text-center text-black text-xs font-normal font-poppins">
                              {value}
                            </div>
                            <button
                              className="bg-red-100 rounded-full p-1 active:scale-95"
                              onClick={() => handleDelete(week)}
                            >
                              <img src={trash} alt="" className="w-5 h-5" />
                            </button>
                          </div>
                        );
                      } else {
                        return null; // Skip rendering if the week is null
                      }
                    })}
                  </div>
                  <div onClick={() => assignTreatmentPrg()} className="px-6 py-2 bg-[#5EAC00] rounded-lg my-5 w-52 flex justify-center items-center cursor-pointer active:scale-96">
                    <div className="text-[#F2FAE6] text-sm font-semibold font-poppins">
                      Assign to Patient
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalComponent>
      </div >
    );
  }
  return (
    <div className="relative">
      {/* Assigned Treatment Program MODAL */}
      <ModalComponent
        show={showDetailsModal}
        handleClose={() => setShowDetailsModal(false)}
        outSideModalPress={false}
      >
        <div className="w-[30vw]">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="text-black text-base font-semibold font-poppins">
              Assigned Treatment Program
            </div>
            <div className="flex flex-row justify-center items-center gap-2">
              <button onClick={() => { setShowEditModal(true) }}>
                <FiEdit className="text-[#3CA5DC] text-lg" />
              </button>
              <button onClick={() => setShowDeleteModal(true)}>
                <RiDeleteBin6Line className="text-[#DF535F] text-lg" />
              </button>
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 mt-6 mb-3"></div>

          {healthProgram && (
            <div className="flex flex-col justify-start items-start h-[40vh] w-full overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div className="mt-2 flex flex-col justify-start items-start w-[90%]">
                <div className="flex flex-row justify-between items-center w-full">
                  <h1 className="text-black text-xs font-regular font-poppins">Disease</h1>
                  <h1 className="text-black text-xs font-semibold font-poppins">#{healthProgram[currentProgramIndex]?.SessionID}</h1>
                </div>
                <h1 className="text-black text-xs font-semibold font-poppins mt-2">
                  {healthProgram[currentProgramIndex]?.ProgramName}
                </h1>
              </div>

              <div className="grid grid-cols-1 gap-2 justify-start items-start w-full my-4">
                {weeks.map(week => {
                  const weekContent = healthProgram[currentProgramIndex][`Week${week}`];
                  if (weekContent) {
                    // const isCompleted = healthProgram[currentProgramIndex][`IsWeek${week}Complete`] !== null;

                    return (
                      <div key={week} className="w-[90%] flex flex-row items-center justify-start space-x-4 bg-sky-100 rounded-lg py-2 px-4">
                        <div className="text-sky-400 text-sm font-semibold font-poppins">
                          Week {week}
                        </div>
                        <div className="text-center text-black text-xs font-normal font-poppins">
                          {weekContent}
                        </div>
                      </div>
                    )
                  }
                  return null;
                })}
              </div>
            </div>
          )}
        </div>
      </ModalComponent>

      {/* Edit Treatment Program MODAL */}
      <ModalComponent
        show={showEditModal}
        handleClose={() => { setShowEditModal(false); setRevertToOrginalWeekData(true) }}
        outSideModalPress={false}
      >
        <div className="w-[50vw] flex flex-col justify-center items-center">
          <div className="flex items-center justify-between">
            <h1 className="text-black text-base font-semibold font-poppins">
              Edit Treatment Program
            </h1>
          </div>
          <div className="border border-b-1 border-gray-200 my-6 w-full"></div>

          {/* Edit Treatment Program */}
          <div className="flex flex-col items-start justify-center my-5 px-10 gap-4 w-full max-h-[60vh] overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">

            {/* Program Headers */}
            <div className="flex flex-col justify-center items-start gap-2">
              <div className="text-black text-xs font-semibold font-poppins">
                <span className="text-black text-xs font-normal">Appointment ID :</span>{" "}
                #{healthProgram[currentProgramIndex]?.SessionID}
              </div>
              <div className="text-black text-xs font-semibold font-poppins">
                <span className="text-black text-xs font-normal">Disease :</span>{" "}
                {healthProgram[currentProgramIndex]?.ProgramName}
              </div>
            </div>

            {/* Program Weeks */}
            <div className="grid grid-cols-6 gap-2 items-start w-full justify-between">
              {Object.entries(weekDataForEdit).map(([week, value]) => {
                if (week.startsWith("Week") && value !== null && !week.endsWith("Type")) {
                  const weekNumber = week.replace(/\D/g, '');
                  const weekTypeKey = `Week${weekNumber}Type`;
                  const weekType = weekDataForEdit[weekTypeKey];
                  const isCompleted = healthProgram[currentProgramIndex][`IsWeek${weekNumber}Complete`] !== 0;

                  return (
                    <div key={weekNumber} className="flex flex-col items-center space-y-4">
                      <div className="px-6 py-2 bg-sky-100 rounded-2xl">
                        <div className="text-sky-400 text-sm font-semibold font-poppins">
                          Week {weekNumber}
                        </div>
                      </div>
                      <div className="flex justify-center items-center bg-sky-400 rounded-full w-14 h-14 relative">
                        {weekType === 'INCLINIC' && (
                          <img
                            src={INCLINIC}
                            alt={weekType}
                            className="w-16 h-16 object-contain"
                          />
                        )}
                        {weekType === 'LABTEST' && (
                          <img
                            src={LABTEST}
                            alt={weekType}
                            className="w-16 h-16 object-contain"
                          />
                        )}
                        {weekType === 'ATHOME' && (
                          <img
                            src={ATHOME}
                            alt={weekType}
                            className="w-16 h-16 object-contain"
                          />
                        )}
                        <div className="absolute bottom-0 transform translate-y-1/2">
                          {isCompleted ? (
                            <div className="text-white text-xs font-semibold font-poppins bg-green-400 rounded-full p-1">
                              <FaCheck />
                            </div>
                          ) : (
                            <button
                              className="bg-red-100 rounded-full p-1 active:scale-95"
                              onClick={() => handleDeleteForEdit(weekNumber)}
                            >
                              <img src={trash} alt="" className="w-4 h-4" />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="text-center text-black text-xs font-normal font-poppins">
                        {value}
                      </div>
                    </div>
                  );
                } else {
                  return null; // Skip rendering if the week is null or a type entry
                }
              })}
            </div>
          </div>
          <div onClick={editTreatmentPrg} className="px-6 py-2 bg-[#5EAC00] rounded-lg my-5 w-52 flex justify-center items-center cursor-pointer active:scale-96">
            <div className="text-[#F2FAE6] text-sm font-semibold font-poppins">
              Assign to Patient
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Delete Program MODAL */}
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        outSideModalPress={false}
      >
        <div className="w-[20vw]">
          <div className="flex flex-row items-center justify-center w-full">
            <div className="text-black text-base font-semibold font-poppins">
              Are you Sure?
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-full mt-8">
            <h1 className="text-[#3CA5DC] text-md font-regular font-poppins text-center">
              This will delete <span className="font-bold">{healthProgram[currentProgramIndex]?.ProgramName}</span> from this Patient's Profile.
            </h1>

            <div className="flex flex-row justify-center items-center w-full gap-10 mt-8">
              <button onClick={() => setShowDeleteModal(false)} className="w-28 text-center text-[#3CA5DC] border-2 border-solid bg-[#FFFFFF] border-[#3CA5DC] rounded-lg text-sm font-semibold font-poppins py-2">
                Back
              </button>
              <button onClick={deleteAssignedTreatmentPrg} className="w-28 text-center text-[#DF535F] border-2 border-[#DF535F] border-solid bg-[#FFFFFF] rounded-lg text-sm font-semibold font-poppins py-2">
                Delete
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Select Treatment Program MODAL */}
      <ModalComponent
        show={showModal}
        handleClose={() => { setShowModal(false); setTreatmentPrg(null); setWeekData({}); }}
        outSideModalPress={false}
      >
        <div className="w-[50vw]">
          <div className="flex items-center justify-between">
            <div className="text-black text-base font-semibold font-poppins">
              Search Treatment Program
            </div>

            <div className="flex flex-row items-center justify-center ml-auto gap-2">
              <label className=" w-60 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
                <IoIosSearch className="text-neutral-400 w-4 h-4" />
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchInput}
                  onChange={handleSearchChange}
                  className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
                />
              </label>
              <button
                onClick={() => {
                  setShowModal(false);
                  // setShowAssignTreatmentProgram(true);
                  navigate("/createTreatmentProgram");
                }}
                className="px-3 py-2 bg-sky-500 active:bg-sky-500 active:scale-95 rounded shadow flex justify-center items-center gap-2.5"
              >
                <IoMdAdd className="text-sky-100 text-lg" />
                <div className="text-sky-100 text-sm font-normal font-poppins">
                  New Program
                </div>
              </button>
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 my-6"></div>

          {/* Select Treatment Program */}
          <div className="w-full max-h-[60vh] overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            <div className="grid grid-cols-2 justify-center items-center gap-5 w-[80%]">
              {filteredTreatmentProgramWeekData?.map(prog => (
                <div key={prog.TPID} className="flex flex-col justify-center items-start w-full gap-2">
                  <RadioButton
                    name="TreatmentProgram"
                    id={prog.Name}
                    value={prog.Name}
                    text={prog.Name}
                    textFont="font-semibold"
                    editText={true}
                    onEdit={() => { navigate("/createTreatmentProgram", { state: { prog } }) }}
                    onChange={() => { setTreatmentPrg(prog); setWeekData(prog.WeekData) }}
                    checked={treatmentPrg?.Name === prog.Name}
                  />
                </div>
              ))}
            </div>
            {(filteredTreatmentProgramWeekData && Object.keys(weekData).length !== 0) && (
              <>
                <div className="flex flex-col justify-center items-center w-full">
                  <div className="grid grid-cols-6 gap-2 items-center justify-between w-full mt-10">
                    {Object.entries(weekData).map(([week, value]) => {
                      if (week.startsWith("Week") && value !== null && !week.endsWith("Type")) {
                        const weekNumber = week.replace(/\D/g, '');
                        const weekTypeKey = `Week${weekNumber}Type`;
                        const weekType = weekData[weekTypeKey];

                        return (
                          <div key={week} className="flex flex-col items-center space-y-4">
                            <div className="px-6 py-2 bg-sky-100 rounded-2xl">
                              <div className="text-sky-400 text-sm font-semibold font-poppins">
                                {week}
                              </div>
                            </div>
                            {/* img */}
                            <div className="flex justify-center items-center bg-sky-400 rounded-full w-16 h-16 relative">
                              {weekType === 'INCLINIC' && (
                                <img
                                  src={INCLINIC}
                                  alt={weekType}
                                  className="w-16 h-16 object-contain"
                                />
                              )}
                              {weekType === 'LABTEST' && (
                                <img
                                  src={LABTEST}
                                  alt={weekType}
                                  className="w-16 h-16 object-contain"
                                />
                              )}
                              {weekType === 'ATHOME' && (
                                <img
                                  src={ATHOME}
                                  alt={weekType}
                                  className="w-16 h-16 object-contain"
                                />
                              )}
                            </div>
                            {/* Text */}
                            <div className="text-center text-black text-xs font-normal font-poppins">
                              {value}
                            </div>
                            <button
                              className="bg-red-100 rounded-full p-1 active:scale-95"
                              onClick={() => handleDelete(week)}
                            >
                              <img src={trash} alt="" className="w-5 h-5" />
                            </button>
                          </div>
                        );
                      } else {
                        return null; // Skip rendering if the week is null or a type entry
                      }
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex justify-center items-center w-full">
            <div onClick={() => assignTreatmentPrg()} className="px-6 py-2 bg-[#5EAC00] rounded-lg mt-5 w-52 flex justify-center items-center cursor-pointer active:scale-96">
              <div className="text-[#F2FAE6] text-sm font-semibold font-poppins">
                Assign to Patient
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Full screen Icon */}
      <button
        className="text-sky-500 text-2xl active:scale-90 transition-transform absolute top-0 right-0"
        onClick={() => {
          navigate("/patientDashboard/patientProfile/healthProgram", {
            state: {
              healthProgram, consultationHistory, patient
            },
          });
        }}
      >
        <BiExpandAlt />
      </button>

      <div className="flex flex-row items-center justify-between">
        {/* Header */}
        <div className="w-44 text-black text-lg font-bold font-poppins">
          Health Programs
        </div>
        {consultationHistory[0]?.AppointmentID !== healthProgram[0]?.SessionID && (
          <button
            className="px-3 py-2 mr-8 bg-sky-400 active:bg-sky-500 active:scale-95 rounded shadow flex justify-center items-center gap-2.5"
            onClick={() => setShowModal(true)}
          >
            <IoMdAdd className="text-sky-100 text-lg" />
            <div className="text-sky-100 text-sm font-normal font-poppins">
              Add New Program
            </div>
          </button>
        )}
      </div>

      {/* Content */}
      <div className="flex flex-row items-center justify-start my-5 px-10 gap-4">

        {/* Program Headers */}
        <div className="flex flex-col justify-center items-start gap-2">
          <div className="text-black text-xs font-semibold font-poppins">
            <span className="text-black text-xs font-normal">Appointment ID :</span>{" "}
            #{healthProgram[currentProgramIndex]?.SessionID}
          </div>
          <div className="text-black text-xs font-semibold font-poppins">
            <span className="text-black text-xs font-normal">Disease :</span>{" "}
            {healthProgram[currentProgramIndex]?.ProgramName}
          </div>
        </div>

        {/* Program Weeks */}
        <div className="grid grid-cols-6 gap-2 items-start w-full justify-between">
          {weeks.map(week => {
            const weekContent = healthProgram[currentProgramIndex][`Week${week}`];
            const weekImg = healthProgram[currentProgramIndex][`Week${week}Type`];
            if (weekContent) {
              const isCompleted = healthProgram[currentProgramIndex][`IsWeek${week}Complete`] !== 0;

              return (
                <div className="flex flex-col items-center space-y-4">
                  <div className="px-6 py-2 bg-sky-100 rounded-2xl">
                    <div className="text-sky-400 text-sm font-semibold font-poppins">
                      Week {week}
                    </div>
                  </div>
                  <div className="flex justify-center items-center rounded-full w-14 h-14 bg-sky-400 relative">
                    {weekImg === 'INCLINIC' && (
                      <img
                        src={INCLINIC}
                        alt="INCLINIC"
                        className="w-14 h-14 object-contain"
                      />
                    )}
                    {weekImg === 'LABTEST' && (
                      <img
                        src={LABTEST}
                        alt="LABTEST"
                        className="w-14 h-14 object-contain"
                      />
                    )}
                    {weekImg === 'ATHOME' && (
                      <img
                        src={ATHOME}
                        alt="ATHOME"
                        className="w-14 h-14 object-contain"
                      />
                    )}
                    <div className="absolute bottom-0 transform translate-y-1/2">
                      {isCompleted ? (
                        <div className="text-white text-xs font-semibold font-poppins bg-green-400 rounded-full p-1">
                          <FaCheck />
                        </div>
                      ) : (
                        <div className="text-sky-100 text-xs font-semibold font-poppins bg-red-500 rounded-full p-1">
                          <FaExclamation />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center text-black text-xs font-normal font-poppins">
                    {weekContent}
                  </div>
                </div>
              )
            } return null;
          })}
        </div>

        <div className="flex flex-col justify-between items-center gap-10">
          {/* Previous Program */}
          <button
            className={`text-[#3CA5DC] text-md font-semibold font-poppins rounded-full p-1 active:scale95 ${currentProgramIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handlePrevProgram}
            disabled={currentProgramIndex === 0}
          >
            <IoIosArrowUp />
          </button>

          {/* Next Program */}
          <button
            className={`text-[#3CA5DC] text-md font-semibold font-poppins rounded-full p-1 active:scale95 ${currentProgramIndex === healthProgram.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleNextProgram}
            disabled={currentProgramIndex === healthProgram.length - 1}
          >
            <IoIosArrowDown />
          </button>
        </div>

        <button
          className="cursor-pointer px-6 py-2 bg-sky-400 rounded-2xl flex justify-center items-center gap-2 active:bg-sky-500 active:scale-95"
          onClick={() => setShowDetailsModal(true)}
        >
          <div className="text-sky-100 text-sm font-semibold font-poppins">
            Details
          </div>
        </button>
      </div>
    </div>
  );
}

export default HealthProgram;

const DATA = [
  {
    id: 1,
    name: "Drugs",
    icon: require("../assets/Images/PatientProfile/medicine.png"),
    isCompleted: true,
  },
  {
    id: 2,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: true,
  },
  {
    id: 3,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/grapes.png"),
    isCompleted: true,
  },
  {
    id: 4,
    name: "Scan",
    icon: require("../assets/Images/PatientProfile/stethoscope.png"),
    isCompleted: false,
  },
  {
    id: 5,
    name: "Scan",
    icon: require("../assets/Images/PatientProfile/stethoscope.png"),
    isCompleted: false,
  },
  {
    id: 6,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 7,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 8,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 9,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 10,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 11,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 12,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
  {
    id: 13,
    name: "Therapy",
    icon: require("../assets/Images/PatientProfile/dumbbell.png"),
    isCompleted: false,
  },
];
