import React from "react";
import TitleWithAddButton from "./TitleWithAddButton";

function FamilyHistory() {
  return (
    <div>
      <TitleWithAddButton title="Family History" showAddButton={true} />
      <div className="w-full bg-neutral-50 rounded-lg p-4  mt-4">
        <div className="flex items-center">
          <div className="flex-1">
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              Condition
            </p>
            <p className="text-black text-base font-semibold font-poppins mt-1">
              Heart Disease
            </p>
          </div>
          <div className="flex-1">
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              Relation
            </p>
            <p className="text-black text-base font-semibold font-poppins mt-1">
              Father
            </p>
          </div>
        </div>
        <div className="text-black text-sm font-normal font-poppins mt-3">
          Notes: Father had heart attack at 45
        </div>
      </div>
    </div>
  );
}

export default FamilyHistory;
