import React, { useState } from "react";
import UserDetails from "../components/UserDetails";
import MentalHealthScore from "../components/MentalHealthScore";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractLine } from "react-icons/ri";
import ModalComponent from "../components/Modal";
function MentalHealthAssessment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { patient, patientProfile, mentalHealth } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [limit, setLimit] = useState(
    mentalHealth.length > 4 ? 4 : mentalHealth.length
  );

  console.log("mentalHealth 🍒", patientProfile);

  const increaseLimit = () => {
    if (limit < mentalHealth.length) {
      setLimit(limit + 1);
    }
  };

  const decreaseLimit = () => {
    if (limit > 1) {
      setLimit(limit - 1);
    }
  };

  const handleSeeDetails = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem([]);
    setIsModalOpen(false);
  };
  return (
    <div className="sm:py-5 sm:px-10">
      <ModalComponent
        show={isModalOpen}
        handleClose={handleCloseModal}
        outSideModalPress={false}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center space-x-3">
            <img
              src={patient.Image}
              alt="PatientPic"
              className="w-12 h-12 rounded-full"
            />
            <div className="flex flex-col">
              <div className="text-black text-xs font-semibold font-poppins">
                {patient.Name}
              </div>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                No : {patient.AHUID}
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-xs font-normal font-poppins leading-none">
              Latest Update :
            </div>
            <div className=" text-black text-xs font-semibold font-poppins leading-none">
              {moment(mentalHealth[0]?.CreatedAt).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div className="border border-b-0.5 border-gray-100 my-5"></div>
        <div className="overflow-y-auto max-h-[400px] flex flex-col space-y-3 ">
          {selectedItem?.map((item, index) => (
            <div
              key={item.ID}
              className="flex flex-row items-center justify-between space-x-32 bg-sky-100 rounded-lg px-8 py-5"
            >
              <div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Question {index + 1}
                </div>
                <div className="text-black text-xs font-semibold font-poppins mt-1">
                  {item.Question}
                </div>
              </div>
              <div>
                <div className="text-zinc-500 text-right text-xs font-normal font-poppins">
                  Answer
                </div>
                <div className="text-sky-400 text-right text-xs font-semibold font-poppins mt-1">
                  {item.Answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </ModalComponent>
      <section className="flex mt-5 space-x-4">
        <div className="bg-white rounded-lg p-10 flex flex-col items-center justify-center space-y-2">
          <img
            src={patient.Image}
            alt="PatientPic"
            className="w-20 h-20 rounded-full"
          />
          <div className="text-center">
            <div className="text-black text-base font-semibold">
              {patient.Name}
            </div>
            <div className="text-zinc-500 text-xs font-normal mt-1">
              No : {patient.AHUID}
            </div>
          </div>
          <div className="text-black text-xs font-normal">
            {new Date().getFullYear() - new Date(patient?.DOB).getFullYear()}{" "}
            Years Old
          </div>
        </div>
        <div className={`flex-1 bg-white rounded-lg p-4`}>
          <UserDetails
            patient={patient}
            patientProfile={patientProfile}
            showEditButton={false}
          />
        </div>
        <div
          className="flex-0.5 flex flex-col items-center justify-center bg-white rounded-lg p-4 transition-all duration-300 cursor-pointer transform active:scale-95"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeftLong className="w-14 h-14 text-sky-500" />
          <div className="text-center text-sky-500 text-lg font-semibold font-poppins">
            Back to Patient Profile
          </div>
        </div>
      </section>
      <section className="bg-white rounded-lg p-6 mt-4">
        <div className="text-black text-lg font-bold font-poppins">
          Mental Health Assesment Score List
        </div>
        <div className="text-zinc-500 text-xs font-normal font-poppins mt-3">
          Listed below are all of Mental health Assesment Score
        </div>
        <div className=" mt-7 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {mentalHealth.slice(0, limit).map((item) => (
            <div
              key={item.ID}
              className="flex flex-col p-4 bg-neutral-50 rounded-lg shadow-md"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-1">
                  <img
                    src={patient.Image}
                    alt="PatientPic"
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex flex-col">
                    <div className="text-black text-sm font-semibold font-poppins">
                      {patient.Name}
                    </div>
                    <div className="text-zinc-500 text-xs font-normal font-poppins">
                      No : {patient.AHUID}
                    </div>
                  </div>
                </div>
                <button
                  className="text-sky-400 text-xs font-semibold font-poppins underline"
                  onClick={() => {
                    handleSeeDetails(item?.AnswerPerQuestion);
                  }}
                >
                  See Details
                </button>
              </div>
              <div className="border border-b-0.5 border-gray-100 mb-5"></div>
              <div className="flex justify-between text-xs mb-4">
                <div className="text-zinc-500 font-normal font-poppins">
                  Assessment Score
                </div>
                <div className="text-black font-semibold font-poppins">
                  {item.Score}/10
                </div>
              </div>
              <div className="flex justify-between text-xs mb-4">
                <div className="text-zinc-500 font-normal font-poppins">
                  Submission Date
                </div>
                <div className="text-black font-semibold font-poppins">
                  {moment(item?.CreatedAt).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="border border-b-5 border-gray-100 mt-6 mb-5"></div>
        <footer className="flex flex-row items-center justify-between">
          <div className="text-zinc-500 text-sm font-normal font-poppins">
            Showing {limit} items per page
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                limit <= mentalHealth.length
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={increaseLimit}
              disabled={limit >= mentalHealth.length}
            >
              <IoMdAdd />
            </button>
            <div className="text-center text-black text-xs font-normal font-poppins bg-gray-100 rounded w-12 h-10 flex items-center justify-center ">
              1 - {limit}
            </div>
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                limit > 1
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={decreaseLimit}
              disabled={limit <= 1}
            >
              <RiSubtractLine />
            </button>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default MentalHealthAssessment;
