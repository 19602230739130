import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import PhoneInput from "react-phone-input-2";
import { MultiSelect } from "react-multi-select-component";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import register from "../assets/Images/MasterDataManagement/register.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addNewNurse,
  updateNurse,
  uploadOrganizationLogo,
} from "../services/operations/masterDataAPI";
import imageCompression from "browser-image-compression";
import uploadProfile from "../assets/Images/MasterDataManagement/uploadImage.png";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { FaChevronLeft } from "react-icons/fa6";

function AddNewNurse() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const nurse = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const [image, setImage] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isViewing, setIsViewing] = useState(false);

  const [formData, setFormData] = useState({
    ImageLocation: "",
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DateofJoining: "",
    PhoneNumber: "",
    Email: "",
    Speciality: "",
    // SpokenLanguage: [],
  });
  const [errors, setErrors] = useState({
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DateofJoining: "",
    PhoneNumber: "",
    Email: "",
    Speciality: "",
    // SpokenLanguage: "",
  });
  const options = [
    { label: "Nurse Practitioner", value: 6 },
    { label: "Oncology Nurse", value: 7 },
    { label: "Emergency Room Nurse", value: 8 },
    { label: "Psychiatric Nurse", value: 9 },
  ];
  const submitHandler = async (event) => {
    // event.preventDefault();
    const mandatoryFields = [
      "ImageLocation",
      "FirstName",
      "LastName",
      "Gender",
      "DateofJoining",
      "PhoneNumber",
      "Email",
      "Speciality",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }
    if (selectedLanguages.length === 0) {
      toast.error("Please select a language");
      return;
    }

    const body = {
      Name: formData.FirstName + " " + formData.LastName,
      Gender: formData.Gender,
      Email: formData.Email,
      PhoneNumber: formData.PhoneNumber,
      ORGID: user.ORGID,
      Speciality: [parseInt(formData.Speciality)],
      PreferredLanguage: selectedLanguages,
      JoiningDate: moment(formData.DateofJoining).format("YYYY-MM-DD"),
      ProfileImageUrl: formData.ImageLocation,
    };

    if (nurse.DID) {
      dispatch(updateNurse(token, nurse.DID, body, navigate));
    } else {
      dispatch(addNewNurse(token, body, navigate));
    }
  };

  const handleLanguageChange = (event) => {
    const { value, checked } = event.target;
    const languageId = parseInt(value, 10); // Convert value back to number
    if (checked) {
      setSelectedLanguages((prev) => [...prev, languageId]);
    } else {
      setSelectedLanguages((prev) =>
        prev.filter((langId) => langId !== languageId)
      );
    }
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        ImageLocation: res?.ProfileImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        ImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (nurse.DID) {
      setImage(nurse.Image);
      setFormData({
        ImageLocation: nurse.ProfileImageUrl,
        FirstName: nurse.Name.split(" ")[0],
        LastName: nurse.Name.split(" ")[1],
        Gender: nurse.Gender,
        DateofJoining: nurse.JoiningDate,
        PhoneNumber: nurse.Phone,
        Email: nurse.Email,
        Speciality: nurse.RelatedSpecialities[0]?.SID,
      });
      setSelectedLanguages(
        nurse.RelatedLanguages.map((lang) => lang.LanguageID)
      );
    }
  }, [nurse]);

  useEffect(() => {
    if (nurse.isViewing) setIsViewing(true);
  }, [nurse]);

  return (
    <div className="bg-[#F3F3F3] py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="bg-white rounded-lg shadow-lg p-6 relative">
        {/* Header Section */}
        <div className="flex flex-row items-center justify-between">
          {!isViewing ? (
            <>
              <div className="flex flex-row items-center space-x-4">
                <button
                  className="active:scale-90"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaChevronLeft className="h-5" />
                </button>

                <div>
                  <h1 className="text-black text-lg font-bold font-poppins">
                    {nurse.DID ? "Edit Nurse" : "Add New Nurse"}
                  </h1>
                  <p className="text-zinc-500 text-xs font-normal font-poppins">
                    Here is the form to add a new doctor.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-row items-center space-x-4">
              <button
                className="active:scale-90"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaChevronLeft className="h-5" />
              </button>

              <div>
                <h1 className="text-black text-lg font-bold font-poppins">
                  {nurse.Name}
                </h1>
                <p className="text-zinc-500 text-xs font-normal font-poppins">
                  ID : {nurse.DID}
                </p>
              </div>
            </div>
          )}
          {isViewing && (
            <button
              onClick={() => setIsViewing(false)}
              className="bg-sky-500 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins flex flex-row items-center justify-center px-5 py-2"
            >
              <FiEdit className="mr-1 w-4 h-4" />
              Edit
            </button>
          )}
        </div>
        {/* form */}
        <div className="flex flex-row p-6 space-x-10">
          <div className="w-1/3">
            {/* Profile Picture */}
            <div className="">
              <label
                className={` ${
                  errors.ImageLocation
                    ? "border-dashed border-red-500 border-2"
                    : ""
                } rounded-lg p-4  flex flex-col items-center justify-center`}
              >
                {formData?.ImageLocation ? (
                  <>
                    <div className="w-20 h-20 shadow-lg rounded-full shadow-neutral-400">
                      <img
                        src={image}
                        alt="upload icon"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    {!isViewing && (
                      <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex cursor-pointer mt-3">
                        <p className="text-sky-400 text-xs font-semibold font-open">
                          Change Image
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <img
                      src={uploadProfile}
                      alt=""
                      className="w-20 h-20 object-contain cursor-pointer"
                    />
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="ImageLocation"
                  disabled={isViewing}
                  value={undefined}
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>

            {/* First Name  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  First Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="FirstName"
                  maxLength={20}
                  value={formData.FirstName}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input first name here"
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.FirstName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Last Name  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Last Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="LastName"
                  maxLength={20}
                  value={formData.LastName}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input last name here"
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.LastName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Date of Joining */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Date of Joining
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="date"
                  name="DateofJoining"
                  value={moment(formData.DateofJoining).format("YYYY-MM-DD")}
                  placeholder="Select Date of Joining"
                  onChange={handleOnChange}
                  disabled={isViewing}
                  min={moment().format("YYYY-MM-DD")}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } accent-sky-500 text-sky-500 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.DateofJoining ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Gender */}
            <div className="mb-6">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                Gender
                <sup className="text-red-600">*</sup>
              </p>
              <div className="flex space-x-2">
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Male"
                      ? "border-sky-100 text-white bg-sky-500"
                      : "border-sky-500 text-sky-500  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Male" })}
                >
                  <IoMaleSharp />
                  <div className="text-sm font-medium font-poppins">Male</div>
                </button>
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Female"
                      ? "border-sky-100 text-white bg-rose-500"
                      : "border-rose-500 text-rose-500  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Female" })}
                >
                  <IoFemaleSharp />
                  <div className="text-sm font-semibold font-poppins">Female</div>
                </button>
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-4 border ${
                    formData.Gender === "Diverse"
                      ? "border-sky-100 text-white bg-lime-500"
                      : "border-lime-500  text-lime-500  bg-white"
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, Gender: "Diverse" })
                  }
                >
                  <LiaTransgenderSolid />
                  <div className="text-sm font-semibold font-poppins">
                    Diverse
                  </div>
                </button>
              </div>
            </div>

            {/* Phone Number */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  disabled={isViewing}
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins rounded-lg p-4 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent `}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>

            {/* Email */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Email"
                  // maxLength={40}
                  value={formData.Email}
                  onChange={handleOnChange}
                  disabled={isViewing || nurse?.DID}
                  placeholder="example@yourmail.com"
                  className={`w-full ${
                    (isViewing || nurse?.DID) && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {!isViewing && (
              <div className="flex flex-row items-center justify-center space-x-8 mt-10">
                <button
                  onClick={submitHandler}
                  className="w-52 h-11 shadow-md shadow-sky-400 bg-sky-400 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins "
                >
                  {nurse.DID ? "Update" : "Finish"}
                </button>
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="w-52 h-11 shadow-md shadow-neutral-400 bg-neutral-400 rounded-lg  active:scale-95 active:shadow-none  text-center text-stone-50 text-sm font-semibold font-poppins "
                >
                  Back
                </button>
              </div>
            )}
          </div>
          <div className="w-2/3">
            {/* Nurse's Speciality */}
            <div className="mb-6 w-1/2">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Nurse's Speciality
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="Speciality"
                  name="Speciality"
                  value={formData.Speciality}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.Speciality ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Specialty</option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* Spoken Languages */}
            <div className="mb-6 w-1/3">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Spoken Languages<sup className="text-red-600">*</sup>
              </p>
              <div className="grid grid-cols-2 gap-3">
                {[
                  {
                    LanguageID: 1,
                    Name: "English",
                  },
                  {
                    LanguageID: 2,
                    Name: "Spanish",
                  },
                  {
                    LanguageID: 3,
                    Name: "French",
                  },
                  {
                    LanguageID: 4,
                    Name: "Hindi",
                  },
                ].map((language) => (
                  <div key={language.LanguageID} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`language-${language.LanguageID}`}
                      name="spokenLanguages"
                      value={language.LanguageID}
                      disabled={isViewing}
                      className="w-4 h-4 text-sky-500 bg-gray-100 rounded border-gray-300"
                      onChange={handleLanguageChange}
                      checked={selectedLanguages.includes(language.LanguageID)}
                    />
                    <label
                      htmlFor={`language-${language.LanguageID}`}
                      className="ml-2 text-black text-xs font-normal font-poppins"
                    >
                      {language.Name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-10 right-8">
          <img src={register} alt="" className="w-72 h-72 object-contain" />
        </div>
      </div>
    </div>
  );
}

export default AddNewNurse;
