import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { patientManagementEndpoints } from "../apis";
import {
  setLoading,
  setAllPatients,
  setPatientProfile,
  setMentalHealthDetails,
  setConsultationHistory,
  setFamilyMembers,
  setMedicalCondition,
  setDosageAdherence,
  setAllMedicines,
  setDiagnosisType,
  setHealthProgram,
  setAllergiesType,
  setAllergies,
  setHealthReports,
  setHealthJourney,
} from "../../slices/patientSlice";
import { getBucketURL } from "../../utils/regex";

const {
  GET_ALL_PATIENTS_API,
  ADD_NEW_PATIENT_API,
  GET_PATIENT_PROFILE,
  UPDATE_PATIENT_PROFILE,
  GET_MENTAL_HEALTH_ASSESSMENT_SCORE,
  GET_MENTAL_HEALTH_QUESTION_ANSWERS,
  GET_FAMILY_MEMBER,
  ADD_FAMILY_MEMBER,
  GET_CONSULTATION_HISTORY,
  GET_PRE_MEDICAL_CONDITIONS,
  GET_HEALTH_REPORTS,
  UPLOAD_HEALTH_REPORT,
  SAVE_BIOMARKERS,
  DECRYPT_REPORT,
  GET_HEALTH_JOURNEY,
  GET_DOSAGE_ADHERENCE,
  GET_ALL_MEDICINES,
  GET_ALL_ALLERGIES,
  GET_ALLERGIES_PER_PATIENT,
  GET_DIAGNOSIS_TYPE,
  ADD_NOTES,
  ADD_AUDIO_EXTRACTED_DATA,
  ADD_PRESCRIPTION,
  ASSIGN_TREATMENT_PROGRAM,
  GET_ASSIGN_TREATMENT_PROGRAM,
  GET_ALL_ASSIGN_TREATMENT_PROGRAMS,
  DELETE_ASSIGNED_HEALTH_TREATMENT_PROGRAM,
  EDIT_ASSIGN_TREATMENT_PROGRAMS,
  GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS,
  DELETE_APPOINTMENT,
} = patientManagementEndpoints;

// GET ALL PATIENTS
export function getAllPatients(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_PATIENTS_API, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // console.log("GET_PATIENT_API RESPONSE............", response);

      const patient = response?.data?.Patients.map(async (patient) => {
        const Image = await getBucketURL(patient?.ProfileImageUrl);
        patient.Image = Image;
        return patient;
      });

      const updatedPatientList = await Promise.all(patient);
      dispatch(setAllPatients(updatedPatientList));
    } catch (error) {
      console.log("GET_PATIENT_API ERROR............", error);
      // toast.error("Could Not Get Patient Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// ADD NEW PATIENT
export function addNewPatient(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_NEW_PATIENT_API, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // console.log("ADD_PATIENT_API RESPONSE............", response);
      toast.success("Patient Added Successfully");
      dispatch(getAllPatients(token));
      navigate("/patientDashboard");
    } catch (error) {
      console.log("ADD_PATIENT_API ERROR............", error);
      toast.error(error?.response?.data?.message || "Could Not Add Patient");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET PATIENT PROFILE
export function getPatientProfile(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", GET_PATIENT_PROFILE, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_PATIENT_PROFILE_API RESPONSE............", response);
      dispatch(setPatientProfile(response?.data?.Patient));
    } catch (error) {
      console.log("GET_PATIENT_PROFILE_API ERROR............", error);
      toast.error("Could Not Get Patient Profile");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//PUT UPDATE PATIENT PROFILE
export function updatePatientProfile(token, ID, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_PATIENT_PROFILE + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("UPDATE_PATIENT_PROFILE_API RESPONSE............", response);
      dispatch(getAllPatients(token));
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("UPDATE_PATIENT_PROFILE_API ERROR............", error);
      toast.error("Could Not Update Patient Profile");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET MENTAL HEALTH ASSESMENT SCORE
export function getMentalHealthAssessmentScore(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        GET_MENTAL_HEALTH_ASSESSMENT_SCORE,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const combinedData = await Promise.all(
        response?.data?.PreviousAssessMentData.map(async (item) => {
          const questionAnswer = await getMentalHealthQuestionAnswer(token, {
            AHUID: body.AHUID,
            ScoreID: item.ID,
          });
          return { ...item, ...questionAnswer };
        })
      );

      dispatch(setMentalHealthDetails(combinedData));
    } catch (error) {
      console.log("GET MENTAL HEALTH ASSESMENT SCORE ERROR............", error);
      toast.error("Could Not Get Mental Health Score");
    } finally {
      dispatch(setLoading(false));
    }
  };
}
//GET MENTAL HEALTH QUESTION ANSWER
export const getMentalHealthQuestionAnswer = async (token, body) => {
  // const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      GET_MENTAL_HEALTH_QUESTION_ANSWERS,
      body,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("GET MENTAL HEALTH QUESTION ANSWER API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  // toast.dismiss(toastId);
  return result;
};

//GET FAMILY MEMBER
export function getFamilyMembers(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", GET_FAMILY_MEMBER, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET FAMILY MEMBER RESPONSE............", response);
      const members = response?.data?.FamilyMembers.filter(
        (member) => member.AHUID !== body.AHUID
      );
      const updatedMembers = await Promise.all(
        members.map(async (member) => {
          const Image = await getBucketURL(member?.ProfileImageUrl);
          return { ...member, Image };
        })
      );

      dispatch(setFamilyMembers(updatedMembers));
    } catch (error) {
      console.log("GET FAMILY MEMBER ERROR............", error);
      toast.error("Could Not Get Consultation History");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//ADD FAMILY MEMBER
export function addFamilyMember(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_FAMILY_MEMBER, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("ADD FAMILY MEMBER RESPONSE............", response);
      toast.success("Family Member Added Successfully");
      dispatch(getAllPatients(token));
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ADD FAMILY MEMBER ERROR............", error);
      toast.error("Could Not Add Family Member");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET CONSULTATION HISTORY
export function getConsultationHistory(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        GET_CONSULTATION_HISTORY,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET CONSULTATION HISTORY RESPONSE............", response);
      const history = response?.data?.consultationData.map(async (item) => {
        const Image = await getBucketURL(item?.DoctorProfile);
        item.DocImage = Image;
        return item;
      });

      const updatedHistory = await Promise.all(history);
      dispatch(setConsultationHistory(updatedHistory));
    } catch (error) {
      console.log("GET CONSULTATION HISTORY ERROR............", error);
      toast.error("Could Not Get Consultation History");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//GET PRE MEDICAL CONDITIONS
export function getPreMedicalConditons(token, ID) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_PRE_MEDICAL_CONDITIONS + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET PRE MEDICAL CONDITIONS RESPONSE............", response);
      // const history = response?.data?.consultationData.map(async (item) => {
      //   const Image = await getBucketURL(item?.DoctorProfile);
      //   item.DocImage = Image;
      //   return item;
      // });

      // const updatedHistory = await Promise.all(history);
      dispatch(setMedicalCondition(response?.data?.Data));
    } catch (error) {
      console.log("GET PRE MEDICAL CONDITIONS ERROR............", error);
      toast.error("Could Not Get Pre Medical Conditions");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//GET DOSAGE ADHERENCE
export function getDosageAdherence(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", GET_DOSAGE_ADHERENCE, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_DOSAGE_ADHERENCE RESPONSE............", response);
      dispatch(setDosageAdherence(response?.data?.PreviousRecords));
    } catch (error) {
      console.log("GET_DOSAGE_ADHERENCE ERROR............", error);
      toast.error("Could Not Get Dosage Adherence History");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//GET ALL DIAGNOSIS TYPE
export function getDiagnosisType(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_DIAGNOSIS_TYPE, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_DIAGNOSIS_TYPE RESPONSE............", response);
      dispatch(setDiagnosisType(response?.data?.DiagnosisList));
    } catch (error) {
      console.log("GET_DIAGNOSIS_TYPE ERROR............", error);
      toast.error("Could Not Get Disease Type");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//GET ALL MEDICINES
export function getAllMedicines(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_MEDICINES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_ALL_MEDICINES RESPONSE............", response);
      dispatch(setAllMedicines(response?.data?.Medicines));
    } catch (error) {
      console.log("GET_ALL_MEDICINES ERROR............", error);
      toast.error("Could Not Get Medicines");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//GET ALL ALLERGIES
export function getAllAllergies(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_ALLERGIES, null, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_ALL_ALLERGIES RESPONSE............", response);
      dispatch(setAllergiesType(response?.data?.AllergiesData));
    } catch (error) {
      console.log("GET_ALL_ALLERGIES ERROR............", error);
      toast.error("Could Not Get Allergies");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//GET ALLERGIES PER PATIENT
export function getAllergiesPerPatient(token, ID) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALLERGIES_PER_PATIENT + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET ALLERGIES PER PATIENT RESPONSE............", response);
      dispatch(setAllergies(response?.data?.AllergiesData));
    } catch (error) {
      console.log("GET ALLERGIES PER PATIENT ERROR............", error);
      // toast.error("Could Not Get Allergies Per Patient");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//ADD NOTES TO PATIENT PROFILE
export function addNotesToPatientProfile(token, ID, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("PUT", ADD_NOTES + "/" + ID, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("ADD_NOTES RESPONSE............", response);
      // toast.success("Notes AddedSuccessfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ADD_NOTES ERROR............", error);
      toast.error("Could Not Add Notes");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//ADD PRESCRIPTION
export function addPrescription(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_PRESCRIPTION, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("ADD_PRESCRIPTION RESPONSE............", response);
      toast.success("Data Added Successfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ADD_PRESCRIPTION ERROR............", error);
      toast.error("Could Not Add Prescription");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ASSIGN TREATMENT PROGRAM
export function assignTreatmentProgram(token, body, navigate, patient) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ASSIGN_TREATMENT_PROGRAM,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ASSIGN_TREATMENT_PROGRAM RESPONSE............", response);
      toast.success("Treatment Program Added Successfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ASSIGN_TREATMENT_PROGRAM ERROR............", error);
      toast.error("Need Consultation to be Done");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET ASSIGNED TREATMENT PROGRAM
export function getAllAssignedTreatmentProgram(token, id) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    let result;
    try {
      const url = `${GET_ALL_ASSIGN_TREATMENT_PROGRAMS}/${id}`;
      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(
        setHealthProgram(response?.data?.AssignedTreatmentPrograms || [])
      );
      console.log(
        "GET_ALL_ASSIGN_TREATMENT_PROGRAM RESPONSE............",
        response
      );
    } catch (error) {
      console.log("GET_ALL_ASSIGN_TREATMENT_PROGRAM ERROR............", error);
      // toast.error("Could Not Fetch Assigned Treatment Program");
      dispatch(setHealthProgram([]));
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET ASSIGNED TREATMENT PROGRAM
export function getAssignedTreatmentProgram(token, sessionId, tpid) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    try {
      const params = new URLSearchParams({
        SessionID: sessionId,
        TPID: tpid,
      });

      const url = `${GET_ASSIGN_TREATMENT_PROGRAM}?${params.toString()}`;

      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setHealthProgram(response?.data || []));
      console.log(
        "GET_ASSIGN_TREATMENT_PROGRAM RESPONSE............",
        response
      );
    } catch (error) {
      console.log("GET_ASSIGN_TREATMENT_PROGRAM ERROR............", error);
      // toast.error("Could Not Fetch Assigned Treatment Program");
      dispatch(setHealthProgram([]));
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// EDIT ASSIGNED HEALTH TREATMENT PROGRAM
export function editAssignedTreatmentProgram(
  token,
  ID,
  body,
  navigate,
  patient
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        EDIT_ASSIGN_TREATMENT_PROGRAMS + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log(
        "EDIT ASSIGNED HEALTH TREATMENT PROGRAM RESPONSE............",
        response
      );
      toast.success("Assigned Health Program Updated");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log(
        "EDIT ASSIGNED HEALTH TREATMENT PROGRAM ERROR............",
        error
      );
      toast.error("Could Not Update the Assigned Health Program");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// DELETE ASSIGNED HEALTH TREATMENT PROGRAM
export function deleteAssignedTreatmentProgram(token, ID, navigate, patient) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_ASSIGNED_HEALTH_TREATMENT_PROGRAM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log(
        "DELETE ASSIGNED HEALTH TREATMENT PROGRAM API RESPONSE",
        response
      );
      toast.success(
        response?.data?.message || "Assigned Treatment Program Deleted"
      );
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log(
        "DELETE ASSIGNED HEALTH TREATMENT PROGRAM API ERROR :",
        error
      );
      toast.error(
        error?.response?.data?.message ||
        "ERROR WHILE DELETING ASSIGNED HEALTH TREATMENT PROGRAM"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//ADD AUDIO EXTRACTED INFO
export function addAudioExtractedInfo(token, ID, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        ADD_AUDIO_EXTRACTED_DATA + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ADD_AUDIO_EXTRACTED_DATA RESPONSE............", response);
      toast.success("Saved Successfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ADD_AUDIO_EXTRACTED_DATA ERROR............", error);
      toast.error("Could Not Add Extracted Information");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET HEALTH REPORTS
export function getHealthReports(token, ID, filterDays = "all") {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const url = `${GET_HEALTH_REPORTS}/${ID}?filterDays=${filterDays}`;
      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET_HEALTH_REPORTS RESPONSE............", response);
      dispatch(setHealthReports(response?.data?.healthEpisodes));
    } catch (error) {
      console.log("GET_HEALTH_REPORTS ERROR............", error);
      toast.error("Could Not Get Health Reports");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//UPLOAD HEALTH REPORT
export function uploadHealthReport(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", UPLOAD_HEALTH_REPORT, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPLOAD_HEALTH_REPORT RESPONSE............", response);
      // toast.success("Report Uploaded Successfully");
      // navigate("/patientDashboard/patientProfile", { state: patient });
      dispatch(saveKeyBioMarkers(token, response?.data, navigate, patient));
    } catch (error) {
      console.log("UPLOAD_HEALTH_REPORT ERROR............", error);
      toast.error("Could Not Upload Report");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// SAVE KEY BIO MARKERS
export function saveKeyBioMarkers(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const data = {
        AHUID: patient.AHUID,
        diagnosisReportsID: body?.diagnosisReports[0]?.ReportID,
        healthEpisodeID: body?.healthEpisode?.ID,
        ConsentGiven: 1,
        PathReportType: body?.diagnosisReports?.DocumentType,
      };
      const response = await apiConnector("POST", SAVE_BIOMARKERS, data, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPLOAD_HEALTH_REPORT RESPONSE............", response);
      toast.success("Report Uploaded Successfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("UPLOAD_HEALTH_REPORT ERROR............", error);
      toast.error("Could Not Upload Report");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET DECRYPTED PDF
export const getDecryptedReport = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", DECRYPT_REPORT, data, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("GET DECRYPTED API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};

//GET HEALTH JOURNEY
// export function getHealthJourney(token, ID, year) {
//   return async (dispatch) => {
//     dispatch(setLoading(true));
//     let result = [];
//     try {
//       const url = `${GET_HEALTH_JOURNEY}/${ID}?year=${year}`;
//       const response = await apiConnector("GET", url, null, {
//         Authorization: `Bearer ${token}`,
//       });

//       if (!response?.status === 200) {
//         throw new Error(response?.data?.message);
//       }
//       console.log("GET_HEALTH_JOURNEY RESPONSE............", response);
//       dispatch(setHealthJourney(response?.data?.healthEpisodes));
//       result = response?.data?.healthEpisodes;
//     } catch (error) {
//       console.log("GET_HEALTH_JOURNEY ERROR............", error);
//       toast.error("Could Not Get Health Journey");
//     } finally {
//       dispatch(setLoading(false));
//       return result;
//     }
//   };
// }
export const getHealthJourney = async (token, ID, year) => {
  // const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const url = `${GET_HEALTH_JOURNEY}/${ID}?year=${year}`;
    const response = await apiConnector("GET", url, null, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data?.healthEpisodes;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log("GET_HEALTH_JOURNEY ERROR............", error);
    toast.error("Could Not Get Health Journey");
  }
  // toast.dismiss(toastId);
  return result;
};


// GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS
export function getPatientsUpcomingTelehealthSession(token, id) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    let result = [];
    try {
      const url = `${GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS}/${id}`;

      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      if (response?.data?.Sessions)
        result = response?.data?.Sessions;
      // dispatch(setHealthProgram(response?.data || []));
      console.log(
        "GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS RESPONSE............",
        response
      );
    } catch (error) {
      console.log("GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS ERROR............", error);
      // toast.error("Could Not Fetch Assigned Treatment Program");
      // dispatch(setHealthProgram([]));
    }
    dispatch(setLoading(false));
    return result;
    // toast.dismiss(toastId);
  };
}

// DELETE_APPOINTMENT
export function deletePatientAppointment(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        DELETE_APPOINTMENT,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("DELETE_APPOINTMENT API RESPONSE", response);
    } catch (error) {
      console.log("DELETE_APPOINTMENT API ERROR :", error);
      toast.error(error?.response?.data?.message || "ERROR WHILE DELETING APPOINTMENT");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}