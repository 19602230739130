import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import imageCompression from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSettings,
  uploadOrganizationLogo,
} from "../services/operations/masterDataAPI";
import { MdOutlineFileUpload } from "react-icons/md";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { settings, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [checkedLanguageIds, setCheckedLanguageIds] = useState([]);
  const [checkedHealthBlockIds, setCheckedHealthBlockIds] = useState([]);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    LogoLocation: "",
  });
  const [errors, setErrors] = useState({
    LogoLocation: "",
  });

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if a file is selected
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    // setImage(file);
    // setImage(URL.createObjectURL(file));
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        LogoLocation: res?.ProfileImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        LogoLocation: "",
      }));
      setIsImageChanged(true);
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };
  const onHealthBlockChange = (checked, id) => {
    if (checked) {
      setCheckedHealthBlockIds((prev) => [...prev, id]);
    } else {
      setCheckedHealthBlockIds((prev) =>
        prev.filter((blockId) => blockId !== id)
      );
    }
  };

  const onLanguageChange = (checked, id) => {
    if (checked) {
      setCheckedLanguageIds((prev) => [...prev, id]);
    } else {
      setCheckedLanguageIds((prev) => prev.filter((langId) => langId !== id));
    }
  };

  const renderLanguage = (item) => {
    return (
      <div key={item.id} className="flex flex-row items-center space-x-3">
        <Switch
          disabled={[0].includes(item.id)}
          checked={checkedLanguageIds.includes(item.id)}
          onChange={(checked) => onLanguageChange(checked, item.id)}
        />
        <div className="text-black text-base font-semibold font-open">
          {item.language}
        </div>
      </div>
    );
  };

  const renderHealthBlock = (item) => {
    return (
      <div key={item.id} className="flex flex-col items-center space-y-3">
        <div
          className={`${
            item?.bg ? item?.bg : "bg-sky-500"
          } w-40 h-28 rounded-lg shadow-lg relative`}
        >
          <div className="absolute top-0 left-0">
            <img src={item?.e1} alt="" className="h-16 object-contain" />
          </div>
          <div className="absolute bottom-0 right-0">
            <img src={item?.e2} alt="" className="h-16 object-contain" />
          </div>
          <div
            className={`${
              item?.color ? item?.color : "text-sky-100"
            } w-20 m-4 text-xs font-extrabold font-poppins leading-tight`}
          >
            {item?.name}
          </div>
          <div className="absolute bottom-0 right-1">
            <img
              src={item?.image}
              alt=""
              className="w-16 h-16 object-contain"
            />
          </div>
        </div>
        <Switch
          disabled={[0, 1, 19].includes(item.id)}
          checked={checkedHealthBlockIds.includes(item.id)}
          onChange={(checked) => onHealthBlockChange(checked, item.id)}
        />
      </div>
    );
  };
  const submitHandler = async () => {
    const body = {
      HealthBlocks: checkedHealthBlockIds.join(","),
      Languages: checkedLanguageIds.join(","),
      Profile_Image: isImageChanged
        ? formData.LogoLocation
        : settings?.Profile_Image,
    };
    dispatch(updateSettings(token, body, settings?.ORGID));
    setIsImageChanged(false);
  };
  useEffect(() => {
    if (settings) {
      setCheckedLanguageIds(settings?.Languages || []);
      setCheckedHealthBlockIds(settings?.HealthBlocks || []);
      setFormData({
        LogoLocation: settings?.OrgImage || "",
      });
      setImage(settings?.OrgImage);
    }
  }, [settings]);

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="text-black text-lg font-bold font-poppins">Settings</div>
      <div className="bg-white rounded-lg shadow-lg p-6 mt-4">
        <div className="text-zinc-950 text-lg font-bold font-open mb-4">
          Organization Logo
        </div>
        <div className="flex items-center space-x-10 mb-4">
          <label
            className={`w-1/3 border-dashed ${
              errors.LogoLocation ? "border-red-500" : "border-sky-400"
            }  border-2 rounded-md p-4 flex flex-col items-center justify-center cursor-pointer`}
          >
            {formData?.LogoLocation ? (
              <>
                <div className="relative w-32 h-20">
                  <img
                    src={image}
                    alt="upload icon"
                    className="absolute top-0 left-0 w-full h-full object-contain"
                    onLoadStart={() => setIsLoading(true)}
                    onLoad={() => setIsLoading(false)}
                  />
                  {isLoading && (
                    <div className="absolute top-0 left-0 w-full h-full bg-gray-100 rounded-md animate-pulse"></div>
                  )}
                </div>
                <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center indivne-flex mt-3">
                  <p className="text-sky-400 text-xs font-semibold font-open">
                    Change Logo
                  </p>
                </div>
              </>
            ) : (
              <>
                <MdOutlineFileUpload className="w-10 h-10 text-sky-400" />

                <span className="text-sky-400 text-xs font-normal font-open mt-1">
                  Upload Here
                </span>
                <span className="text-neutral-400 text-[11px] font-normal font-open mt-1">
                  File : JPG, PNG, SVG Max 20 Mb
                </span>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              name="LogoLocation"
              value={undefined}
              onChange={(e) => handleImageChange(e)}
              style={{ display: "none" }}
            />
          </label>
          {isImageChanged && (
            <div className="flex items-center space-x-4">
              <button
                onClick={submitHandler}
                className="bg-sky-100 rounded-lg px-5 py-4 active:scale-95"
              >
                <div className="text-center text-sky-400 text-xs font-semibold font-poppins">
                  Upload Image
                </div>
              </button>
              <button
                className="rounded-lg border border-sky-400 px-5 py-4 active:scale-95"
                onClick={() => {
                  setIsImageChanged(false);
                  setFormData({
                    LogoLocation: settings?.OrgImage || "",
                  });
                  setImage(settings?.OrgImage);
                }}
              >
                <div className="text-center text-sky-400 text-xs font-semibold font-poppins">
                  Delete Image
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="text-zinc-950 text-lg font-bold font-open">
          Add Language
        </div>
        <div className="flex flex-row items-center space-x-10 mt-4 ">
          {LANGUAGES.map((item) => {
            return renderLanguage(item);
          })}
        </div>
        <div className="text-zinc-950 text-lg font-bold font-open mt-8">
          Health Block Activation:
        </div>
        <div className="grid grid-cols-5 gap-4 mt-4 ">
          {HEALTHBLOCKS.map((item) => {
            return renderHealthBlock(item);
          })}
        </div>
        <div className="flex items-center justify-center space-x-4 mt-10 w-1/3 mx-auto">
          <button
            onClick={submitHandler}
            className="flex-1 bg-sky-400 rounded-lg px-5 py-4 active:scale-95"
          >
            <div className="text-center text-sky-100 text-xs font-semibold font-poppins">
              Apply
            </div>
          </button>
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="flex-1 rounded-lg border border-sky-400 px-5 py-4 active:scale-95"
          >
            <div className="text-center text-sky-400 text-xs font-semibold font-poppins">
              Cancel
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Settings;

const LANGUAGES = [
  {
    id: 0,
    language: "English",
  },
  {
    id: 1,
    language: "German",
  },
  {
    id: 2,
    language: "French",
  },
  {
    id: 3,
    language: "Italian",
  },
];

const HEALTHBLOCKS = [
  {
    id: 0,
    name: "Check your Symptoms",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/checkYourSymptoms.png"),
  },
  {
    id: 1,
    name: "Video Call with our Specialists",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/videoCallWithOurSpecialists.png"),
  },
  {
    id: 2,
    name: "Women's Health Insights",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    bg: "bg-rose-300",
    color: "text-red-600",
    image: require("../assets/Images/Settings/womensHealthInsights.png"),
  },
  {
    id: 3,
    name: "Stem Cell Storage",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/stemCellStorage.png"),
  },
  {
    id: 4,
    name: "Health Record Management",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/healthRecordManagement.png"),
  },
  {
    id: 5,
    name: "Send Report to Doctor",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/sendReportToDoctor.png"),
  },
  {
    id: 6,
    name: "Dosage Reminders",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/dosageReminders.png"),
  },
  {
    id: 7,
    name: "Heart Rate Monitor",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/heartRateMonitor.png"),
  },
  {
    id: 8,
    name: "Upgrade Insurance Cover",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/upgradeInsuranceCover.png"),
  },
  {
    id: 9,
    name: "Lungs Monitor",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/lungsMonitor.png"),
  },
  {
    id: 10,
    name: "Join Community Groups",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/joinCommunityGroups.png"),
  },
  {
    id: 11,
    name: "Health Quiz",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/healthQuiz.png"),
  },
  {
    id: 12,
    name: "IVF Treatment",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/ivfTreatment.png"),
  },
  {
    id: 13,
    name: "EPD Integration",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/epdIntegration.png"),
  },
  {
    id: 14,
    name: "Wearables Integrations",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/wearablesIntegrations.png"),
  },
  {
    id: 15,
    name: "Clinical Trials Mapping",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/clinicalTrialsMapping.png"),
  },
  {
    id: 16,
    name: "Make an Appointment",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/makeAnAppointment.png"),
  },
  {
    id: 17,
    name: "Men's Health Insights",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    bg: "bg-blue-200",
    color: "text-sky-600",
    image: require("../assets/Images/Settings/mensHealthInsights.png"),
  },
  {
    id: 18,
    name: "Emergency Services",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/emergencyServices.png"),
  },
  {
    id: 19,
    name: "Mental Health Assessment",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/mentalHealthAssessment.png"),
  },
  {
    id: 20,
    name: "Health Programs",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/healthProgram.png"),
  },
  {
    id: 21,
    name: "Our Services",
    e1: require("../assets/Images/Settings/e1.png"),
    e2: require("../assets/Images/Settings/e2.png"),
    image: require("../assets/Images/Settings/healthProgram.png"),
  },
];
