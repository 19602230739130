import React, { useEffect, useState } from "react";
import mentalHealthScoreIcon from "../assets/Images/PatientProfile/mentalHealthScoreIcon.png";
import { FaArrowRightLong } from "react-icons/fa6";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function MentalHealthScore({ patient, patientProfile, mentalHealth }) {
  const navigate = useNavigate();
  const [requestSent, setRequestSent] = useState(false);
  const [requestTime, setRequestTime] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem(`request_${patient?.AHUID}`);
    if (storedData) {
      setRequestSent(true);
      setRequestTime(storedData);
    }
  }, [patient?.AHUID]);

  const handleSendRequest = () => {
    const currentTime = moment().format("DD.MM.YYYY");
    localStorage.setItem(`request_${patient?.AHUID}`, currentTime);
    setRequestSent(true);
    setRequestTime(currentTime);
    toast.success("Request Sent Successfully");
  };

  if (mentalHealth.length === 0) {
    return (
      <div className="max-w-sm mx-auto p-6 flex items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center">
            <img src={mentalHealthScoreIcon} alt="" className="w-16 h-16" />
          </div>
          <div class="text-center text-sky-500 text-lg font-semibold font-poppins mt-4">
            No Mental Health Assessment <br /> Taken
          </div>

          {requestSent ? (
            <div className="text-zinc-500 text-xs font-normal font-poppins mt-2">
              Request sent on Date {requestTime}
            </div>
          ) : (
            <button
              onClick={handleSendRequest}
              className="w-full py-2.5 bg-sky-100 rounded-lg mt-8 active:scale-95"
            >
              <div className="text-sky-500 text-sm font-normal font-poppins ml-2">
                Send Request
              </div>
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-sm mx-auto">
      <div className="flex justify-between items-center mb-4 p-6">
        <div className="flex items-center">
          <img src={mentalHealthScoreIcon} alt="" className="w-16 h-16" />
        </div>
        <div class="text-center text-sky-500 text-5xl font-bold font-poppins">
          7/10
        </div>
      </div>
      <div class="text-center text-sky-500 text-lg font-semibold font-poppins">
        Mental Health Assessment <br />
        Recent Score
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={() =>
            navigate("/mentalHealthAssessment", {
              state: { patient, patientProfile, mentalHealth },
            })
          }
          className="bg-gray-100 active:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
        >
          <FaArrowRightLong className={`w-6 h-6 text-sky-500`} />
        </button>
      </div>
    </div>
  );
}

export default MentalHealthScore;
