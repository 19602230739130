import { ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState } from "react";
import AHlogo from "../assets/Images/logo.png";
import logoutImg from "../assets/Images/Sidebar/logout.svg";
import { logout } from "../services/operations/authAPI";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import navbarCollapseIcon from "../assets/Images/Sidebar/navbarCollapseIcon.png";
import navbarExpandIcon from "../assets/Images/Sidebar/navbarExpandIcon.png";
import { toggleSidebar, setSidebarExpanded } from '../slices/sidebarSlice';

const SidebarContext = createContext();

export default function Sidebar({ children }) {
  const { settings } = useSelector((state) => state.masterData);
  const { logo } = useSelector((state) => state.profile);
  const expanded = useSelector((state) => state.sidebar.expanded);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <aside className="h-[calc(100vh - 4rem)]">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        {/* {!expanded && ( */}
        {/* <div className={`${expanded ? "p-2 self-end" : "p-4 pb-2"} flex justify-between items-center`}> */}
          {/* <img
            src={settings?.OrgImage ? settings?.OrgImage : logo}
            className={`overflow-hidden transition-all ${expanded ? "w-40 h-11 object-contain" : "w-0"
              } cursor-pointer`}
            alt=""
            onClick={() => navigate("/clinicianDashboard")}
          /> */}

          {/* <button
            onClick={() => dispatch(toggleSidebar())}
            className={`${expanded ? "rounded-lg" : "rounded-lg"} bg-gray-50`}
          >
            {expanded
              ? <button className="flex flex-row justify-center items-center w-12 h-10 bg-[#F3F5F7] hover:bg-[#F0F8FF] border-none rounded-md">
                <img src={navbarCollapseIcon} alt="Navbar Collapse Icon" className="w-5 h-5 object-contain" />
              </button>
              : <button className="flex flex-row justify-center items-center w-12 h-10 bg-[#F3F5F7] hover:bg-[#F0F8FF] border-none rounded-md">
                <img src={navbarExpandIcon} alt="Navbar Expand Icon" className="w-5 h-5 object-contain" />
              </button>
            }
          </button>
        </div> */}
            {/* {expanded ? <ChevronFirst /> : <ChevronLast />} */}
        {/* )} */}

        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3 mt-4">{children}</ul>
        </SidebarContext.Provider>

        <button
          onClick={() => {
            dispatch(logout(navigate));
          }}
          className="flex items-center px-5 mb-5"
        >
          <img src={logoutImg} alt="" className="w-6 h-6" />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-40 ml-2" : "w-0"}
          `}
          >
            <h1 className="text-rose-500 text-sm font-normal font-poppins">
              Log Out
            </h1>
          </div>
        </button>
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, to, img, imgSelected }) {
  const { expanded } = useContext(SidebarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isActive = () => {
    if (to) {
      const match = location.pathname === to;
      return match;
    }
    return false;
  };

  const handleClick = () => {
    if (to) {
      // setExpanded(false);
      dispatch(setSidebarExpanded(false));
      navigate(to);
    }
  };

  return (
    <li
      className={`relative flex h-10 items-center justify-center py-2 px-3 rounded-md cursor-pointer transition-colors group
        ${isActive()
          ? "text-sky-400 bg-sky-100 font-semibold"
          : "text-black hover:bg-gray-100 font-normal"
        }
      ${expanded ? "mb-3" : "mb-3"}
      `}
      onClick={handleClick}
    >
      <img
        src={isActive() ? imgSelected : img}
        alt=""
        className="w-6 h-6 object-contain"
      />
      <div
        className={`overflow-hidden text-sm  font-poppins ${expanded ? " w-52 ml-3" : "w-0"
          }`}
      >
        {text}
      </div>

      {!expanded && (
        <div
          className={`
      absolute left-full rounded-md px-2 py-1 ml-6 text-xs font-normal font-poppins z-50 text-white bg-sky-400
      invisible opacity-20 -translate-x-10 transition-all
      group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
    `}
        >
          {text}
        </div>
      )}
    </li>
  );
}