import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { FaList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PatientListView from "../components/PatientListView";
import PatientCardView from "../components/PatientCardView";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";
import { deletePatientAppointment, getPatientProfile } from "../services/operations/patientManagementAPI";
import UserDetails from "../components/UserDetails";
import videoCallIcon from "../assets/Images/BookAppointment/videoCallIcon.png";
import inPersonIcon from "../assets/Images/BookAppointment/inPersonIcon.png";
import SuccessCancel from "../assets/Images/PatientDashboard/SuccessCancel.png";
import ModalComponent from "../components/Modal";
import RadioButton from "../components/RadioButton/RadioButton";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import moment from "moment";
import { bookAppointment, getAvailableSlotsForDoctorOnSpecifiedDate, getAvailableSlotsForSpecifiedDate, getDateID } from "../services/operations/dashboardAPI";
import toast from "react-hot-toast";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";
import Breadcrumb from "../components/Breadcrumb";
import TimeSlotBgImage from "../assets/Images/BookAppointment/TimeSlotBgImage.png";

function RescheduleAppointment() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const history = useSelector((state) => state.breadcrumb.history);
    console.log("HISTORY", history);
    
    const prevApp = location.state.selectedAppointment || {};
    const patient = location.state.patient || {};
    const [selected, setSelected] = useState(null);
    const [dateID, setDataID] = useState(null);
    const { token } = useSelector((state) => state.auth);
    const [timeSlots, setTimeSlots] = useState([]);
    const [displaySlots, setDisplaySlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);

    // const generateCallbackSlots = (availableTimes, selectedDate) => {
    //     const slots = [];
    //     const now = moment();
    //     const selectedDay = moment(selectedDate).startOf('day');
    //     const today = moment().startOf('day');
    //     const isToday = selectedDay.isSame(today);

    //     availableTimes?.forEach(timeRange => {
    //         const [start, end] = timeRange.split(' - ');
    //         let startTime = moment(start, 'HH:mm').set({
    //             'year': selectedDay.year(),
    //             'month': selectedDay.month(),
    //             'date': selectedDay.date()
    //         });
    //         const endTime = moment(end, 'HH:mm').set({
    //             'year': selectedDay.year(),
    //             'month': selectedDay.month(),
    //             'date': selectedDay.date()
    //         });

    //         // Generate 30-minute slots within the time range
    //         while (startTime.isBefore(endTime)) {
    //             const slotEnd = moment.min(
    //                 startTime.clone().add(30, 'minutes'),
    //                 endTime,
    //             );

    //             if (slotEnd.isAfter(startTime) && (!isToday || startTime.isAfter(now))) {
    //                 slots.push({
    //                     Slots: `${startTime.format('HH:mm')} - ${slotEnd.format('HH:mm')}`,
    //                 });
    //             }
    //             startTime = slotEnd;
    //         }
    //     });

    //     if (slots.length === 0) {
    //         toast.error("No Time Slots Left for Today");
    //     }
    //     return slots;
    // };

    const deleteAppointment = async () => {
        let body = {
            "SessionID": prevApp?.SessionID,
            "AHUID": prevApp?.AHUID,
            "CancellationReason": ""
        };
        try {
            await dispatch(deletePatientAppointment(token, body));
        } catch (error) {
            console.error("Failed to delete patient appointment", error);
        }
    }

    const generateCallbackSlots = (availableTimes) => {
        const slots = [];
        const now = moment();

        availableTimes?.forEach(timeRange => {
            const [start, end] = timeRange.split(' - ');
            let startTime = moment(start, 'HH:mm');
            const endTime = moment(end, 'HH:mm');

            // Generate 30-minute slots within the time range
            while (startTime.isBefore(endTime)) {
                const slotEnd = moment.min(
                    startTime.clone().add(30, 'minutes'),
                    endTime,
                );
                if (slotEnd.isAfter(startTime) && startTime.isAfter(now)) {
                    slots.push({
                        Slots: `${startTime.format('HH:mm')} - ${slotEnd.format('HH:mm')}`,
                    });
                }
                startTime = slotEnd;
            }
        });
        if (slots.length === 0)
            toast.error("No Time Slots Left for Today")
        return slots;
    };

    const bookNewAppointment = async () => {
        // Split the AppointmentTime to get Start_time and End_time
        let [startTime, endTime] = selectedSlot?.split(' - ');
        startTime = `${startTime}:00`;
        endTime = `${endTime}:00`;
        const basicData = {
            "DateID": dateID,
            "Start_time": startTime,
            "End_time": endTime,
            "AHUID": prevApp?.AHUID,
            "DID": prevApp?.DID,
            "AppointmentType": prevApp?.AppointmentType
        }
        await dispatch(bookAppointment(token, basicData, {
            "DID": prevApp?.DID
        }))
    };

    const confirmReschedule = async () => {
        if (selected && selectedSlot) {
            await deleteAppointment();
            await bookNewAppointment();
            toast.success("Appointment Rescheduled")
            navigate("/patientDashboard/managePatient", { state: patient });
        }
        else if (!selected) toast.error("Select Date")
        else if (!selectedSlot) toast.error("Select Time")
    }

    useEffect(() => {
        // const getAvailableSlots = async (dateID, did) => {
        const getAvailableSlots = async (did, date) => {
            if (token) {
                // const body = {
                //     "DateID": dateID,
                //     "DID": did
                // }
                const body1 = {
                    "Date": moment(date).format("YYYY-MM-DD"),
                    "DID": did
                }
                // const availableSlotss = await dispatch(getAvailableSlotsForSpecifiedDate(token, body));
                const dataSlot = await dispatch(getAvailableSlotsForDoctorOnSpecifiedDate(token, body1));
                const availableSlots = dataSlot?.AvailableTimes;
                setDataID(dataSlot?.DateID)
                const slots = generateCallbackSlots(availableSlots);
                setTimeSlots(slots);
            }
        }

        // const fetchDateID = async (date, did) => {
        //     const body = {
        //         "OriginalDate": date
        //     }
        //     const dateID = await dispatch(getDateID(body));
        //     setDataID(dateID)
        //     getAvailableSlots(dateID, did, date)
        // }

        let date1 = '';
        if (selected && prevApp) {
            date1 = moment(selected).format("YYYY-MM-DD")
            // fetchDateID(date1, prevApp?.DID)
            getAvailableSlots(prevApp?.DID, date1)
        }
    }, [selected, prevApp?.DID])

    useEffect(() => {
        setDisplaySlots(timeSlots.slice(0, 10)) // Display at most 10 time slots
    }, [timeSlots])

    return (
        <div className="bg-[#F3F3F3] h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
            <div className="bg-white rounded-lg shadow-lg p-6">
                {/* Header Section */}
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col items-start gap-4">
                        <h1 onClick={() => { }} className="text-black text-xl font-semibold font-poppins leading-3 tracking-wide">
                            Reschedule Appointment
                        </h1>

                        <Breadcrumb currentState={location.state} />
                    </div>
                </div>

                <div className="flex flex-row justify-center items-start space-x-3 mt-4">
                    <div className="w-[40%] flex flex-col justify-center items-start">
                        {/* Patient Card */}
                        <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide mb-8 text-left pt-5 w-[85%]">
                            Basic Details
                        </h1>

                        <div className="bg-white shadow rounded-lg p-8 w-[85%] flex flex-row items-center justify-start space-x-4">
                            <img
                                src={patient.Image}
                                alt="PatientPic"
                                className="w-20 h-20 rounded-full"
                            />
                            <div className="text-center flex-col justify-center items-center ml-4">
                                <div className="text-black text-base font-semibold font-poppins text-left">
                                    {patient.Name || patient.FirstName + " " + patient.LastName}
                                </div>
                                <div className="text-[#1c1c1c] text-xs font-medium mt-1 font-poppins text-left">
                                    {patient.Gender}
                                </div>
                                <div className="text-[#1c1c1c] text-xs font-normal mt-1 font-poppins text-left">
                                    DOB : {moment(patient.DOB).format("DD.MM.YYYY")}
                                </div>
                                <div className="text-zinc-500 text-xs font-normal mt-1 font-poppins text-left">
                                    No : {patient.AHUID}
                                </div>
                                {patient.Age !== null && patient.Age !== undefined ? (
                                    <>
                                        <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                                            {patient.Age} Years Old
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                                            {new Date().getFullYear() - new Date(patient.DOB).getFullYear()}{" "}
                                            Years Old
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Prev Appointment */}
                        <div className={`flex flex-col justify-center items-start mt-8`}>
                            <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide mb-5">
                                Previous Appointment
                            </h1>

                            <div
                                key={prevApp.SessionID}
                                className={`flex flex-col justify-center items-center shadow rounded-lg w-full mb-5 px-4 py-3 bg-[#E5F2FF]`}
                            >
                                <div className="grid grid-cols-3 w-full gap-2">
                                    <div className="flex flex-col justify-center items-start gap-1">
                                        <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                                            Scheduled Time
                                        </h1>
                                        <h1 className="text-black text-base text-sm font-semibold font-poppins">
                                            {prevApp.Start_time}
                                        </h1>
                                    </div>

                                    <div className="flex flex-col justify-center items-start gap-1">
                                        <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                                            Scheduled Date
                                        </h1>
                                        <h1 className="text-black text-base text-sm font-semibold font-poppins">
                                            {prevApp.Start_time}
                                        </h1>
                                    </div>

                                    <div className="flex flex-row justify-center items-center w-full">
                                        {prevApp.AppointmentType === "TeleHealth" ? (
                                            <div className="flex flex-col justify-center items-center w-[35px] h-[35px] rounded-full bg-[#3CA5DC] p-2">
                                                <img
                                                    src={videoCallIcon}
                                                    className="w-full h-full object-contain text-white"
                                                    alt="Video Call Icon"
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex flex-col justify-center items-center w-[35px] h-[35px] rounded-full bg-[#F7A025] p-2">
                                                <img
                                                    src={inPersonIcon}
                                                    className="w-full h-full object-contain text-white"
                                                    alt="In person Icon"
                                                />
                                            </div>
                                        )}
                                        <div className="flex flex-col justify-center items-start gap-1 ml-3">
                                            <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                                                Type Appointment
                                            </h1>
                                            <h1 className="text-black text-base text-sm font-semibold font-poppins">
                                                {prevApp.AppointmentType === "TeleHealth" ? "TeleHealth" : prevApp.AppointmentType}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Patient Appointments */}
                    <div className="w-[60%] flex flex-col justify-center items-start rounded-lg shadow p-4 py-5">
                        <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide">
                            Rescheduling Calendar
                        </h1>

                        <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                        <div className="flex flex-row justify-between items-start w-full gap-2">
                            {/* Calendar */}
                            <div className="flex-1 flex flex-col justify-center items-start w-full">
                                <h1 className="text-black text-xs font-semibold font-poppins mb-5 leading-3 tracking-wide text-left">
                                    Select Date
                                </h1>
                                <DayPicker
                                    mode="single"
                                    style={{ '--rdp-accent-color': '#3CA5DC', '--rdp-cell-size': '40px', backgroundColor: '#E5F2FF' }}
                                    className="p-2 shadow rounded-lg"
                                    selected={selected}
                                    onSelect={(date) => { setSelected(date); setSelectedSlot(null); }}
                                    fromDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                                    modifiers={{
                                        disabled: { before: new Date() }
                                    }}
                                    modifiersClassNames={{
                                        disabled: 'disabled_day',
                                    }}
                                />
                            </div>

                            {/* Time */}
                            {displaySlots.length !== 0 ? (
                                <div className="flex-1 flex flex-col justify-center items-start w-full">
                                    <h1 className="text-black text-xs font-semibold font-poppins mb-5 leading-3 tracking-wide">
                                        Select Time
                                    </h1>
                                    <div className="grid grid-cols-3 gap-5">
                                        {displaySlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                className={`p-2 py-3 rounded-2xl text-[10px] uppercase leading-3 tracking-wider font-semibold font-poppins ${selectedSlot === slot.Slots ? 'bg-[#3CA5DC] text-white' : 'bg-[#E5F2FF] text-[#3CA5DC]'}`}
                                                onClick={() => {
                                                    setSelectedSlot(slot.Slots);
                                                }}
                                            >
                                                {slot.Slots}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="flex-1 flex flex-col self-end mb-5 justify-center items-start w-full">
                                        <img
                                            src={TimeSlotBgImage}
                                            alt="Select Date To Show Time Slots Bg Image"
                                            className="w-64 h-48"
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Selected Date and Time */}
                        {(selected || selectedSlot) && (
                            <div className="mt-4 p-4 w-full bg-[#F3F3F3] flex flex-col justify-center items-start rounded-lg">
                                <p className="text-black text-[15px] font-semibold font-poppins leading-3 tracking-wide">
                                    {selected && moment(selected).format('dddd, MMMM D YYYY')}
                                </p>
                                <p className="text-[13px] font-semibold font-poppins text-[#ACACAC] leading-3 tracking-wide mt-2">
                                    {selectedSlot && `${selectedSlot} CET`}
                                </p>
                            </div>
                        )}

                        {/* Btns */}
                        <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
                            <button
                                style={{ backgroundColor: "#3CA5DC" }}
                                className="w-full bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 py-3 hover:bg-sky-500 transition duration-200 ease-in active:bg-[#3CA5DC] focus:outline-none focus:ring focus:ring-[#3CA5DC]"
                                onClick={() => {
                                    confirmReschedule();
                                }}
                            >
                                Confirm & Reschedule
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RescheduleAppointment;
