import moment from "moment";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function PatientListView({ patient }) {
  const navigate = useNavigate();
  return (
    <div className="mt-10">
      {patient.map((p, index) => (
        <>
          <div key={index} className="flex flex-row items-center">
            <div className="flex flex-row items-center mb-6 justify-between flex-1">
              <div
                className="flex flex-row items-center space-x-3 cursor-pointer"
                onClick={() => {
                  navigate("patientProfile", { state: p });
                }}
              >
                <img
                  src={p.Image}
                  alt="PatientPic"
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex flex-col w-32">
                  <div className="text-black text-sm font-semibold font-poppins">
                    {p.Name}
                  </div>
                  <div className="text-zinc-500 text-xs font-normal font-poppins">
                    No : {p.AHUID}
                  </div>
                </div>
                <div className="border border-r-0.5 border-gray-100 h-10"></div>
              </div>
              <div className="flex flex-col w-32">
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Date of Birth
                </div>
                <div className=" text-black text-xs font-semibold font-poppins">
                  {moment(p.DOB).format("DD MMMM YYYY")}
                </div>
              </div>
              <div className="flex flex-col w-24">
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Phone Number
                </div>
                <div className=" text-black text-xs font-semibold font-poppins">
                  {p.Phone.startsWith("+") ? p.Phone : "+" + p.Phone}
                </div>
              </div>
              <div className="flex flex-col w-40">
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Email
                </div>
                <div className=" text-black text-xs font-semibold font-poppins">
                  {p.Email}
                </div>
              </div>
              <div className="flex flex-col w-24">
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Last Activity
                </div>
                <div className=" text-black text-xs font-semibold font-poppins">
                  {moment(p.LastActivity).format("DD MMMM YYYY")}
                </div>
              </div>
              <div className="border border-r-0.5 border-gray-100 h-10"></div>
              <button
                className="text-sky-400 text-xs font-semibold font-poppins underline"
                onClick={() => {
                  navigate("managePatient", { state: p });
                }}
              >
                Manage
              </button>
              <button
                className="px-6 py-2 bg-sky-100 rounded-2xl"
                onClick={() => {
                  // if (p.TotalSession === 0) {
                  //   toast.error("No sessions left for this patient.");
                  // } else {
                  navigate("bookAppointment", { state: p });
                  // }
                }}
              >
                <div className="text-sky-400 text-xs font-semibold font-poppins">
                  Add Appointment
                </div>
              </button>
            </div>
          </div>
          {index < patient.length - 1 && (
            <div className="border border-b-0.5 border-gray-100 mb-5"></div>
          )}
        </>
      ))}
    </div>
  );
}

export default PatientListView;
