import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { FaList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PatientListView from "../components/PatientListView";
import PatientCardView from "../components/PatientCardView";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";
import Breadcrumb from "../components/Breadcrumb";

function PatientDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isListView, setIsListView] = useState(
    sessionStorage.getItem("isListView") !== "false"
  );
  const [searchInput, setSearchInput] = useState("");
  const { allPatients, loading: patientLoading } = useSelector(
    (state) => state.patient
  );
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  const filteredPatients = allPatients.filter((p) => {
    const dob = new Date(p.DOB);
    const month = dob.toLocaleString("default", { month: "long" });
    return `${p.Name} ${p.DOB} ${month}`
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const currentPatients = filteredPatients.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    sessionStorage.setItem("isListView", isListView);
  }, [isListView]);

  if (patientLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (allPatients.length === 0) {
    return (
      <div className="bg-[#F3F3F3] py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
        <div className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center h-full p-20">
          <img
            src={noPatientFound}
            alt=""
            className="w-[533px] h-72 object-contain"
          />
          <div className="text-black text-xl font-bold font-Inter">
            Add New Patients to view them on this screen
          </div>
          <button
            className="w-1/2 mx-auto bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80"
            onClick={() => {
              navigate("/patientRegistration");
            }}
          >
            Add New Patient
          </button>
        </div>
      </div>
    );
  }

  // const handleSearchChange = (e) => {
  //   e.preventDefault();
  //   setSearchInput(e.target.value);
  // };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="bg-[#F3F3F3] min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Header Section */}
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-5">
            <h1 className="text-black text-lg font-bold font-poppins">
              Patient Dashboard
            </h1>
            <label className=" w-80 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
              <IoIosSearch className="text-neutral-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search here"
                value={searchInput}
                onChange={handleSearchChange}
                className={`w-full bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
              />
            </label>
            <button
              onClick={() => {
                navigate("patientRegistration");
              }}
              className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
            >
              <FaPlus className="text-white text-sm" />
              <div className="text-white text-sm font-normal font-poppins">
                Create New Patient
              </div>
            </button>

            <div className="flex flex-row items-center space-x-2">
              <button
                onClick={() => setIsListView(true)}
                className={`w-10 h-10 rounded-full ${isListView ? "bg-sky-400" : "bg-sky-100"
                  } flex items-center justify-center active:scale-95`}
              >
                <FaList
                  className={`${isListView ? "text-white" : "text-sky-400"}`}
                />
              </button>
              <button
                onClick={() => setIsListView(false)}
                className={`w-10 h-10 rounded-full ${!isListView ? "bg-sky-400" : "bg-sky-100"
                  } flex items-center justify-center active:scale-95`}
              >
                <RxDashboard
                  className={`${!isListView ? "text-white" : "text-sky-400"}`}
                />
              </button>
            </div>
          </div>

          <div className="flex flex-row items-center space-x-3">
            <button
              className="bg-gray-100 active:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
            >
              <MdOutlineKeyboardArrowLeft
                className={`w-6 h-6 ${currentPage > 1 ? "text-sky-400" : "text-gray-400"
                  }`}
              />
            </button>
            <div className="text-center text-black text-xs font-normal font-poppins">
              {`${(currentPage - 1) * pageSize + 1} - ${Math.min(
                currentPage * pageSize,
                allPatients.length
              )} of ${allPatients.length}`}
            </div>
            <button
              className="bg-gray-100 active:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < Math.ceil(allPatients.length / pageSize)
                    ? prevPage + 1
                    : prevPage
                )
              }
            >
              <MdOutlineKeyboardArrowRight
                className={`w-6 h-6 ${currentPage < Math.ceil(allPatients.length / pageSize)
                  ? "text-sky-400"
                  : "text-gray-400"
                  }`}
              />
            </button>
          </div>
        </div>
        <div className="mt-4">
          <Breadcrumb />
        </div>
        {isListView ? (
          <PatientListView patient={currentPatients} />
        ) : (
          <PatientCardView patient={currentPatients} />
        )}
      </div>
    </div>
  );
}

export default PatientDashboard;
