import moment from "moment";
import React, { useState } from "react";
import { LuPen } from "react-icons/lu";
import ModalComponent from "./Modal";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { updatePatientProfile } from "../services/operations/patientManagementAPI";
function UserDetails({ patient, patientProfile, showEditButton = true }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    DateofBirth: "",
    PhoneNumber: "",
    Email: "",
    Occupation: "",
    Employment: "",
    LivingArrangement: "",
    RelationshipStatus: "",
    Details: "",
  });
  const [errors, setErrors] = useState({
    DateofBirth: "",
    PhoneNumber: "",
    Email: "",
    Occupation: "",
    Employment: "",
    LivingArrangement: "",
    RelationshipStatus: "",
    Details: "",
  });

  const submitHandler = async (event) => {
    event.preventDefault();

    const mandatoryFields = [
      "DateofBirth",
      "PhoneNumber",
      "Email",
      "Occupation",
      "Employment",
      "LivingArrangement",
      "RelationshipStatus",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    let ID = patient?.AHUID;

    let data = {
      DateofBirth: moment(formData.DateofBirth).format("YYYY-MM-DD"),
      PhoneNumber: formData.PhoneNumber,
      Email: formData.Email,
      Occupation: formData.Occupation,
      Employment: formData.Employment,
      LivingArrangement: formData.LivingArrangement,
      RelationshipStatus: formData.RelationshipStatus,
      Details: formData.Details,
    };
    dispatch(updatePatientProfile(token, ID, data, navigate, patient));
    handleCloseModal();
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      DateofBirth: "",
      PhoneNumber: "",
      Email: "",
      Occupation: "",
      Employment: "",
      LivingArrangement: "",
      RelationshipStatus: "",
      Details: "",
    });
    setErrors({
      DateofBirth: "",
      PhoneNumber: "",
      Email: "",
      Occupation: "",
      Employment: "",
      LivingArrangement: "",
      RelationshipStatus: "",
      Details: "",
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setFormData({
      DateofBirth: patientProfile.DOB,
      PhoneNumber: patientProfile.Phone,
      Email: patientProfile.Email,
      Occupation: patientProfile.Occupation,
      Employment: patientProfile.Employment,
      LivingArrangement: patientProfile.LivingArrangement,
      RelationshipStatus: patientProfile.RelationshipStatus,
      Details: patientProfile.Details,
    });
  };
  return (
    <div>
      <ModalComponent
        show={isModalOpen}
        handleClose={handleCloseModal}
        outSideModalPress={false}
      >
        <div className="w-[25vw] h-[80vh] flex flex-col">
          <div className="flex flex-col items-center justify-center">
            <img
              src={patient.Image}
              alt="PatientPic"
              className="w-20 h-20 rounded-full"
            />
            <div className="text-center text-black text-base font-semibold font-poppins mt-4">
              {patient.Name || patient.FirstName + " " + patient.LastName}
            </div>
            <div className="text-center text-zinc-500 text-xs font-normal font-poppins mt-2">
              No : {patient.AHUID}
            </div>
            <div className="text-black text-sm font-normal font-poppins mt-2">
              {new Date().getFullYear() -
                new Date(patientProfile.DOB).getFullYear()}{" "}
              Years Old
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 my-6"></div>
          <div className="max-h-[60vh] overflow-y-auto px-2">
            {/* Date of Birth */}
            <div className="mb-4">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Date of Birth
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="date"
                  name="DateofBirth"
                  value={moment(formData.DateofBirth).format("YYYY-MM-DD")}
                  placeholder="Select Date of Birth"
                  onChange={handleOnChange}
                  // style={{
                  //   WebkitAppearance: "none",
                  //   MozAppearance: "none",
                  //   appearance: "none",
                  // }}
                  max={moment().format("YYYY-MM-DD")}
                  className={`w-full accent-sky-500 text-sky-500 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.DateofBirth ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* Phone Number */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-4 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>

            {/* Email */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Email"
                  // maxLength={40}
                  value={formData.Email}
                  onChange={handleOnChange}
                  placeholder="example@yourmail.com"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* Occupation */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Occupation
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="text"
                  name="Occupation"
                  maxLength={20}
                  value={formData.Occupation}
                  onChange={handleOnChange}
                  placeholder="Enter occupation here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Occupation ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Employment */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Employment
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="Employment"
                  name="Employment"
                  value={formData.Employment}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.Employment ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Employment Status</option>
                  <option value="Employed">Employed</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Self-Employed">Self-Employed</option>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Retired">Retired</option>
                  <option value="Student">Student</option>
                  <option value="Homemaker">Homemaker</option>
                  {/* <option value="Intern">Intern</option>
                    <option value="Apprentice">Apprentice</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Full-time">Full-time</option>
                    <option value="Contract">Contract</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Seasonal">Seasonal</option>
                    <option value="On Leave">On Leave</option> */}
                </select>
              </label>
            </div>
            {/* Living Arrangement */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Living Arrangement
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="LivingArrangement"
                  name="LivingArrangement"
                  value={formData.LivingArrangement}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.LivingArrangement ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  onChange={handleOnChange}
                >
                  <option value="">Select Living Arrangement</option>
                  <option value="Own House">Own House</option>
                  <option value="Rent">Rent</option>
                  <option value="Living with Parents">
                    Living with Parents
                  </option>
                  <option value="Living with Relatives">
                    Living with Relatives
                  </option>
                  <option value="Living with Friends">
                    Living with Friends
                  </option>
                  {/* <option value="Student Housing">Student Housing</option>
                    <option value="Military Housing">Military Housing</option>
                    <option value="Homeless">Homeless</option>
                    <option value="Temporary Shelter">Temporary Shelter</option>
                    <option value="Assisted Living">Assisted Living</option>
                    <option value="Nursing Home">Nursing Home</option>
                    <option value="Shared Accommodation">
                      Shared Accommodation
                    </option>
                    <option value="Cohabiting">Cohabiting</option>
                    <option value="Hostel">Hostel</option>
                    <option value="Boarding House">Boarding House</option>
                    <option value="Retirement Community">
                      Retirement Community
                    </option>
                    <option value="Hotel/Motel">Hotel/Motel</option> */}
                </select>
              </label>
            </div>
            {/* Relationship Status */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Relationship Status
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="RelationshipStatus"
                  name="RelationshipStatus"
                  value={formData.RelationshipStatus}
                  onChange={handleOnChange}
                  className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                    errors.RelationshipStatus ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option value="">Select Relationship Status</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Engaged">Engaged</option>
                  <option value="Separated">Separated</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                </select>
              </label>
            </div>
            {/* Details */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Details
                </p>
                <textarea
                  id="Details"
                  name="Details"
                  value={formData.Details}
                  onChange={handleOnChange}
                  placeholder="Enter details here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                ></textarea>
              </label>
            </div>
          </div>
          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              onClick={submitHandler}
              className="w-full bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80 active:scale-95"
            >
              Update Profile
            </button>
          </div>
        </div>
      </ModalComponent>
      <div className="max-w-2xl">
        <h1 class=" text-gray-900 text-base font-medium font-poppins leading-normal">
          User Detail
        </h1>
        <div className="grid grid-cols-4 gap-2 my-4">
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">
              Date of Birth
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {moment(patientProfile?.DOB).format("DD MMM YYYY")}
            </p>
          </div>
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">
              Phone Number
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Phone}
            </p>
          </div>
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">Email</p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Email}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-2 mb-4">
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">
              Occupation
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Occupation || "-"}
            </p>
          </div>
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">
              Employment
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.Employment || "-"}
            </p>
          </div>
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">
              Living Arrangement
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.LivingArrangement || "-"}
            </p>
          </div>
          <div>
            <p class="text-zinc-500 text-xs font-normal font-poppins">
              Relationship Status
            </p>
            <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
              {patientProfile?.RelationshipStatus || "-"}
            </p>
          </div>
        </div>

        <div className="mb-4">
          <p class="text-zinc-500 text-xs font-normal font-poppins">
            Recent Activity
          </p>
          <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
            {patientProfile?.LastConsultationDate
              ? moment(patientProfile.LastConsultationDate).format(
                  "DD MMM YYYY"
                )
              : "-"}
          </p>
        </div>
        <div className="">
          <p class="text-zinc-500 text-xs font-normal font-poppins">Details</p>
          <p className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
            {patientProfile?.Details || "-"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
