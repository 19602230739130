import React, { useEffect, useRef, useState } from "react";

const Dropdown = ({
  name,
  placeholder,
  options,
  onSelect,
  error,
  value,
  labelKey,
  valueKey,
  enableSearch = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    placeholder ? placeholder : options[0]
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setSearchTerm("");
    setTimeout(() => setIsOpen(false), 100);
    onSelect(name, valueKey ? option[valueKey] : option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value) {
      const selectedValue = options.find((option) =>
        valueKey ? option[valueKey] === value : option === value
      );
      setSelectedOption(selectedValue || placeholder || options[0]);
    }
  }, [value, options, valueKey]);

  useEffect(() => {
    const filtered = options.filter((option) =>
      getOptionLabel(option).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  const getOptionLabel = (option) => {
    if (typeof option === "string") return option;
    return labelKey ? option[labelKey] : option.toString();
  };

  return (
    <div className="relative text-left" ref={dropdownRef}>
      <div>
        <button
          className={`w-full ${error ? "border-red-500" : ""}  ${
            placeholder ? "text-gray-400" : "text-black"
          }  text-xs font-normal font-poppins bg-gray-100 rounded-md p-3 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent flex items-center justify-between`}
          onClick={toggleDropdown}
        >
          {selectedOption ? getOptionLabel(selectedOption) : placeholder}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`w-6 h-6 ${
              isOpen
                ? "transform rotate-180 transition-transform duration-200"
                : "transform rotate-0 transition-transform duration-200"
            }`}
          >
            <path
              d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 shadow-lg z-10">
          {enableSearch && (
            <div className="px-3 py-2">
              <input
                ref={searchInputRef}
                type="text"
                className="w-full p-2 border rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-sky-400 focus:border-transparent"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}
          <div className="max-h-[22vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={valueKey ? option[valueKey] : index}
                  className={`flex items-center justify-between w-full text-left px-4 py-2 ${
                    option === selectedOption
                      ? "text-sky-500 bg-sky-100"
                      : "text-zinc-900 hover:bg-gray-100"
                  } text-sm font-normal font-poppins leading-tight cursor-pointer`}
                  onClick={() => handleSelect(option)}
                >
                  {getOptionLabel(option)}
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-sm text-gray-500">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

