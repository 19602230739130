import Constant from "../config/Constant";
const BASE_URL = Constant.DEFAULT_APP_URL;
// AUTH ENDPOINTS
export const authEndpoints = {
  LOGIN_API: BASE_URL + "/clinicianPortal/doctorUserLogin",
  PARTNER_LOGIN_API: BASE_URL + "/masterDataManagement/labPartnerLogin",
  CHANGE_PASSWORD_API:
    BASE_URL + "/clinicianPortal/changeOrganizationUserPassword",
  VERIFY_OTP_API: BASE_URL + "/newPartnerManagement/verifyMerchantOTP",
  UPLOAD_IMAGE_API: BASE_URL + "/newPartnerManagement/upload-company-logo",
  FORGOT_PASSWORD_API: BASE_URL + "/clinicianPortal/sendClinicianForgotPasswordOTP",
  VERIFY_FORGOT_PASSWORD_OTP_API:
    BASE_URL + "/clinicianPortal/verifyClinicianForgotPasswordOTP",
  RESET_PASSWORD_API:
    BASE_URL + "/clinicianPortal/changeClinicianPassword",
};

// USER ENDPOINTS
export const userEndpoints = {
  GET_USER_DETAILS_API:
    BASE_URL + "/patientManagement/getCurrentUserAccountInfo",
  GET_ORGANIZATION_LOGO: BASE_URL + "/masterDataManagement/getPageLogo",
};

// PATIENT MANAGEMENT ENDPOINST
export const patientManagementEndpoints = {
  GET_ALL_PATIENTS_API: BASE_URL + "/patientManagement/getAllPatients",
  ADD_NEW_PATIENT_API: BASE_URL + "/patientManagement/registerPatient",
  GET_PATIENT_PROFILE: BASE_URL + "/patientManagement/getUserDetails",
  UPDATE_PATIENT_PROFILE: BASE_URL + "/patientManagement/updateUserDetails",
  GET_MENTAL_HEALTH_ASSESSMENT_SCORE:
    BASE_URL +
    "/patientManagement/getPaginateMentalHealthAssessmentScoreByDoctor",
  GET_MENTAL_HEALTH_QUESTION_ANSWERS:
    BASE_URL + "/patientManagement/checkMentalHealthSubmitedAnswer",
  GET_CONSULTATION_HISTORY:
    BASE_URL + "/patientManagement/getAllConsultationForSpecifiedPatient",
  GET_PRE_MEDICAL_CONDITIONS:
    BASE_URL + "/patientManagement/viewPreMedicalConditions",
  GET_HEALTH_REPORTS:
    BASE_URL + "/patientManagement/getReportDataByDiseaseByOrganization",
  UPLOAD_HEALTH_REPORT:
    BASE_URL + "/patientManagement/UploadReportFromDoctorPortal",
  SAVE_BIOMARKERS:
    BASE_URL + "/patientManagement/savebiomarkersdataFromDoctorPortal",
  DECRYPT_REPORT: BASE_URL + "/patientManagement/getDecryptReportByAHUID",
  GET_HEALTH_JOURNEY:
    BASE_URL + "/patientManagement/getHealthJourneyDataFromDoctorPortal",
  GET_FAMILY_MEMBER: BASE_URL + "/patientManagement/viewFamilyMember",
  ADD_FAMILY_MEMBER:
    BASE_URL + "/patientManagement/addFamilyMemberByOrganization",
  GET_DOSAGE_ADHERENCE:
    BASE_URL + "/patientManagement/getPreviousDosageAdherenceRecords",
  ADD_PRESCRIPTION: BASE_URL + "/patientManagement/addDosageAdherence",
  GET_ALL_MEDICINES: BASE_URL + "/patientManagement/getAllMedicines",
  GET_ALL_ALLERGIES: BASE_URL + "/clinicianPortal/getAllAllergies",
  GET_ALLERGIES_PER_PATIENT:
    BASE_URL + "/clinicianPortal/getAllergiesPerSessions",
  GET_DIAGNOSIS_TYPE: BASE_URL + "/patientManagement/getAllDiagnosis",
  ADD_NOTES: BASE_URL + "/clinicianPortal/storeAdditionalInformation",
  ADD_AUDIO_EXTRACTED_DATA:
    BASE_URL + "/clinicianPortal/storeAdditionalInformation",
  AUDIO_TRANSCRIPTION:
    "https://beta-audio-ai-api.alleshealth.com/v1/crownextractor",
  ASSIGN_TREATMENT_PROGRAM:
    BASE_URL + "/patientManagement/assignTreatmentProgram",
  GET_ASSIGN_TREATMENT_PROGRAM:
    BASE_URL + "/patientManagement/getAssignedTreatmentProgram",
  GET_ALL_ASSIGN_TREATMENT_PROGRAMS:
    BASE_URL + "/patientManagement/getAllAssignedTreatmentPrograms",
  EDIT_ASSIGN_TREATMENT_PROGRAMS:
    BASE_URL + "/patientManagement/updateAssignedTreatmentProgram",
  DELETE_ASSIGNED_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/patientManagement/deleteAssignedTreatmentProgram",
  GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS:
    BASE_URL + "/clinicianPortal/getUpcomingTeleHealthSession",
  DELETE_APPOINTMENT: BASE_URL + "/clinicianPortal/cancelAppointment",
};

//DASHBOARD END POINTS
export const clinicianDashboardEndpoints = {
  GET_ALL_BOOKED_SLOTS_API: BASE_URL + "/clinicianPortal/getBookedPatientList",
  GET_DATE_ID: BASE_URL + "/clinicianPortal/getDateID",
  GET_AVAILABLE_SLOTS_FOR_SPECIFIED_DATE:
    BASE_URL + "/clinicianPortal/getAvailableSlotsForSpecifiedDate",
  GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE:
    BASE_URL +
    "/clinicianPortal/getAvailableWindowsForSpecifiedDoctorFromDoctorPortal",
  GET_ORGANIZATION_DOCTORS:
    BASE_URL + "/patientManagement/getOrganizationDoctors",
  BOOK_APPOINTMENT: BASE_URL + "/clinicianPortal/bookSessionByOrganization",
  GET_SLOT_AVAILABILITY_STATUS:
    BASE_URL + "/clinicianPortal/getSlotAvailabilityStatus",
};

// MASTER DATA END POINTS
export const masterDataEndpoints = {
  INVOICING: BASE_URL + "/masterDataManagement/getBillingScreen",
  GET_ALL_INVOICES: BASE_URL + "/masterDataManagement/getAllInvoices",
  CREATE_INVOICE: BASE_URL + "/masterDataManagement/createInvoice",
  GET_SPECIFIC_INVOICES: BASE_URL + "/masterDataManagement/getSpecificInvoice",
  GET_ALL_TREATMENT_PROGRAMS:
    BASE_URL + "/masterDataManagement/getAllTreatmentProgram",
  GET_ALL_TREATMENT_PROGRAM_WEEK_DATA:
    BASE_URL + "/masterDataManagement/getAllTreatmentWeekData",
  CREATE_NEW_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/masterDataManagement/createNewTreatmentProgram",
  UPDATE_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/masterDataManagement/updateTreatmentProgram",
  DELETE_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/masterDataManagement/deleteTreatmentProgram",
  GET_ALL_DIAGNOSIS: BASE_URL + "/patientManagement/getAllDiagnosis",
  GET_ALL_MEDICINES: BASE_URL + "/patientManagement/getAllMedicines",
  GET_ALL_PRE_MEDICAL_CONDITIONS:
    BASE_URL + "/patientManagement/getAllPreMedicalConditions",
  GET_ALL_SERVICE_RATE_ENTRY:
    BASE_URL + "/masterDataManagement/getAllServiceRateEntry",
  GET_ALL_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/getAllServiceRateEntry",
  ADD_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/createNewServiceRateEntry",
  UPDATE_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/updateServiceRateCard",
  DELETE_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/deleteServiceRateCard",
  GET_ALL_SETTINGS: BASE_URL + "/masterDataManagement/getAllSetting",
  UPLOAD_ORGANIZATION_LOGO: BASE_URL + "/patientManagement/uploadProfileImage",
  UPDATE_SETTINGS: BASE_URL + "/masterDataManagement/updateSetting",
  GET_CLINIC_CAPACITY: BASE_URL + "/masterDataManagement/getClinicCapacity",
  GET_ALL_NURSES: BASE_URL + "/clinicianPortal/getAllOrganizationNurseDetails",
  ADD_NURSE: BASE_URL + "/clinicianPortal/registerNurse",
  DELETE_NURSE: BASE_URL + "/clinicianPortal/deleteNurseDetails",
  UPDATE_NURSE: BASE_URL + "/clinicianPortal/updateNurseDetails",
  GET_ALL_DOCTOR: BASE_URL + "/clinicianPortal/getAllOrganizationDoctorDetails",
  ADD_DOCTOR: BASE_URL + "/clinicianPortal/registerDoctor",
  DELETE_DOCTOR: BASE_URL + "/clinicianPortal/deleteDoctorDetails",
  UPDATE_DOCTOR: BASE_URL + "/clinicianPortal/updateDoctorDetails",
  GET_KEY_PERFORMANCE_INDICATOR:
    BASE_URL + "/clinicianPortal/getKeyPerformanceIndicator",
  GET_CUSTOM_KEY_PERFORMANCE_INDICATOR:
    BASE_URL + "/clinicianPortal/getKeyPerformanceIndicatorForCustomDate",
  GET_CLINIC_BRANCHES: BASE_URL + "/masterDataManagement/getAllClinicBranches",
  CREATE_CLINIC_BRANCH: BASE_URL + "/masterDataManagement/createClinicBranch",
  DELETE_CLINIC_BRANCH: BASE_URL + "/masterDataManagement/deleteClinicBranch",
  GET_ALL_LAB_PARTNERS:
    BASE_URL + "/masterDataManagement/getAllLabPartnersWithLabTypes",
  DELETE_LAB_PARTNER: BASE_URL + "/masterDataManagement/deleteLabPartnerRecord",
  GET_LAB_TYPES: BASE_URL + "/masterDataManagement/getALLLabTypes",
  ADD_LAB_PARTNER: BASE_URL + "/masterDataManagement/createLabPartner",
  UPDATE_LAB_PARTNER: BASE_URL + "/masterDataManagement/updateLabPartnerRecord",
  GET_ALL_ORGANIZATION_USER_DETAILS:
    BASE_URL + "/masterDataManagement/getAllOrganizationUserDetails",
  UPDATE_ORGANIZATION_USER_DETAILS:
    BASE_URL + "/masterDataManagement/changeOrganizationUserType",
};

// MEDICAL CONCIERGE END POINTS
export const medicalConciergeEndpoints = {
  GET_MEDICAL_CONCIERGE_SPECIALITY:
    BASE_URL + "/medicalConcierge/getAllHealthManagerSpeciality",
  GET_MEDICAL_CONCIERGE_FEATURES:
    BASE_URL + "/medicalConcierge/getMedicalConciergeFeatures",
  GET_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/getAllMedicalConciergePlanDetails",
  CREATE_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/createNewMedicalConciergePlan ",
  DELETE_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/deleteMedicalConciergePlan",
  UPDATE_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/updateMedicalConciergePlan",
  GET_HEALTH_MANAGERS: BASE_URL + "/medicalConcierge/getAllHealthManagerWithAssignedClients",
};
