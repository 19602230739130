import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import tile1 from "../assets/Images/MasterDataManagement/tile1.png";
import tile2 from "../assets/Images/MasterDataManagement/tile2.png";
import tile3 from "../assets/Images/MasterDataManagement/tile3.png";
import tile4 from "../assets/Images/MasterDataManagement/tile4.svg";
import tile5 from "../assets/Images/MasterDataManagement/tile5.png";
import tile6 from "../assets/Images/MasterDataManagement/tile6.png";
import tile7 from "../assets/Images/MasterDataManagement/tile7.png";
import tile8 from "../assets/Images/MasterDataManagement/tile8.png";
import tile9 from "../assets/Images/MasterDataManagement/tile9.png";
import tile10 from "../assets/Images/MasterDataManagement/tile10.png";
import tile11 from "../assets/Images/MasterDataManagement/tile11.png";
import tile12 from "../assets/Images/MasterDataManagement/tile12.png";
import tile13 from "../assets/Images/MasterDataManagement/tile13.png";
import tile14 from "../assets/Images/MasterDataManagement/tile14.png";
import tile15 from "../assets/Images/MasterDataManagement/tile15.png";

function ServiceManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col ">
      {/* HEADER */}
      <div className="flex flex-row justify-between items-center">
        {/* TITLE */}
        <div>
          <h1 className="text-black text-xl font-bold font-poppins">
            Service Management
          </h1>
          <p className="text-neutral-400 text-xs font-normal font-poppins mt-2">
            Manage various Patient & Employee data
          </p>
        </div>
      </div>

      {/* Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-5">
        {TILES.map((tile) => (
          <div
            key={tile.id}
            className="relative h-40 bg-sky-100 p-6 rounded-2xl shadow-lg cursor-pointer hover:scale-105 duration-200 transition-all active:scale-100"
            onClick={() => navigate(tile.route)}
          >
            <div className="text-[#011370] text-base font-bold font-poppins relative z-10">
              {tile.title}
            </div>
            <ul className="list-disc mt-5 relative z-10">
              {tile.moreInfo.map((info, index) => (
                <li
                  key={index}
                  className="text-black text-xs font-normal font-poppins"
                >
                  {info}
                </li>
              ))}
            </ul>
            <img
              src={tile.image}
              alt=""
              className="h-24 object-contain absolute bottom-5 right-4"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceManagement;

const TILES = [
  {
    id: 1,
    image: tile1,
    route: "/branchManagement",
    title: "Maintain Address",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 2,
    image: tile2,
    route: "/comingSoon",
    title: "TeleHealth Data",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 3,
    image: tile3,
    route: "/doctorManagement",
    title: "Maintain Doctors",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 4,
    image: tile4,
    route: "/nurseMangement",
    title: "Maintain Nurses",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },

  {
    id: 5,
    image: tile5,
    route: "/labPartners",
    title: "Maintain Lab Partners",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 6,
    image: tile6,
    route: "/comingSoon",
    title: "Maintain Pharmacy",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 7,
    image: tile7,
    route: "/servicesAndRateCard",
    title: "Service Rate Card",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 8,
    image: tile8,
    route: "/accessManagement",
    title: "Access Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 9,
    image: tile9,
    route: "/treatmentProgram",
    title: "Patient Treatment Program",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 10,
    image: tile10,
    route: "/clinicCapacity",
    title: "Clinic Capacity Manager",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 11,
    image: tile11,
    route: "/comingSoon",
    title: "In App Services",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 12,
    image: tile12,
    route: "/comingSoon",
    title: "Licensing & Subscription",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 13,
    image: tile13,
    route: "/comingSoon",
    title: "Notification Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 14,
    image: tile14,
    route: "/comingSoon",
    title: "Payments & Billings",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 15,
    image: tile15,
    route: "/settings",
    title: "Settings",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
];
