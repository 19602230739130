import Modal from "react-modal";
import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Filter } from "lucide-react";

export default function ModalComponent({
  show,
  handleClose,
  children,
  outSideModalPress,
  showCloseButton = true,
}) {
  return (
    <Modal
      isOpen={show}
      shouldCloseOnOverlayClick={outSideModalPress}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Example Modal"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center"
    >
      <div className="relative bg-white rounded-2xl p-8">
        <div>{children}</div>
        {showCloseButton && (
          <button
            className="absolute top-0 right-[-50px] bg-white border text-neutral-500 border-gray-300 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer"
            onClick={handleClose}
          >
            <IoCloseOutline />
          </button>
        )}
      </div>
    </Modal>
  );
}

const customStyles = {
  content: {
    position: "relative",
    padding: 0,
    border: "none",
    background: "none",
    overflow: "visible",
  },
};
