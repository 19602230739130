import React, { useState } from "react";
import arrowUp from "../assets/Images/MedicalConcierge/arrowUp.svg";
import arrowDown from "../assets/Images/MedicalConcierge/arrowDown.svg";
import viewIcon from "../assets/Images/MedicalConcierge/view.svg";
import editIcon from "../assets/Images/MedicalConcierge/edit.svg";
import deleteIcon from "../assets/Images/MedicalConcierge/delete.svg";
import settingSelected from "../assets/Images/Sidebar/settingSelected.svg";
import addHealthManagerIcon from "../assets/Images/MedicalConcierge/addHealthManagerIcon.svg";
import appointment from "../assets/Images/MedicalConcierge/appointment.svg";
import call from "../assets/Images/MedicalConcierge/call.svg";
import moment from "moment";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import ConciergeMembers from "../components/ConciergeMembers";
import HealthManagers from "../components/HealthManagers";
import ModalComponent from "../components/Modal";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import Tooltip from "../components/Tooltip";
import { useSelector } from "react-redux";

function HealthManagerDashboard() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 7;
  const [showAssignManagerModal, setShowAssignManagerModal] = useState(false);
  const [showAddNotesModal, setShowAddNotesModal] = useState(false);

  const [showHealthManagerList, setShowHealthManagerList] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedHealthManager, setSelectedHealthManager] = useState(null);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [users, setUsers] = useState([
    {
      id: 1,
      name: "Emma Johnson",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Emma Johnson`,
      gender: "Female",
      age: 32,
      joinDate: "15 July 2024",
      healthManager: null,
      notes: null,
      request: "Callback Requested",
      email: "emma.johnson@example.com",
      phone: "+1-234-567-8901",
      status: "pending",
    },
    {
      id: 2,
      name: "Olivia Martinez",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Olivia Martinez`,
      gender: "Female",
      age: 39,
      joinDate: "8 August 2024",
      healthManager: null,
      notes: null,
      request: null,
      email: "olivia.martinez@example.com",
      phone: "+1-234-567-8902",
      status: null,
    },
    {
      id: 3,
      name: "Liam Thompson",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Liam Thompson`,
      gender: "Male",
      age: 45,
      joinDate: "22 June 2024",
      healthManager: "Dr. Sarah Chen",
      notes:
        "Regular check-up scheduled and follow-up for medication required, diet plan review",
      request: "Meeting Requested",
      email: "liam.thompson@example.com",
      phone: "+1-234-567-8903",
      status: "completed",
    },
    {
      id: 4,
      name: "Sophia Patel",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Sophia Patel`,
      gender: "Female",
      age: 28,
      joinDate: "3 September 2024",
      healthManager: null,
      notes: null,
      request: "Assign Health Manager",
      email: "sophia.patel@example.com",
      phone: "+1-234-567-8904",
      status: "pending",
    },
    {
      id: 5,
      name: "Noah Rodriguez",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Noah Rodriguez`,
      gender: "Male",
      age: 55,
      joinDate: "17 July 2024",
      healthManager: "Dr. James Wilson",
      notes: "Follow-up on medication",
      request: "Follow-Up Ongoing",
      email: "noah.rodriguez@example.com",
      phone: "+1-234-567-8905",
      status: "ongoing",
    },
    {
      id: 6,
      name: "Ava Nguyen",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Ava Nguyen`,
      gender: "Female",
      age: 41,
      joinDate: "30 August 2024",
      healthManager: null,
      notes: null,
      request: null,
      email: "ava.nguyen@example.com",
      phone: "+1-234-567-8906",
      status: null,
    },
    {
      id: 7,
      name: "Ethan Kim",
      image: `https://api.dicebear.com/5.x/initials/svg?seed=Ethan Kim`,
      gender: "Male",
      age: 36,
      joinDate: "12 July 2024",
      healthManager: "Dr. Emily Thompson",
      notes: "Dietary plan review",
      request: "Callback Requested",
      email: "ethan.kim@example.com",
      phone: "+1-234-567-8907",
      status: "completed",
    },
  ]);

  const handleStatusSelect = (userId, status) => {
    setShowStatusDropdown(null);
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? { ...user, status: status === "none" ? null : status }
          : user
      )
    );
  };

  const toggleStatusDropdown = (userId) => {
    setShowStatusDropdown((prevDropdown) =>
      prevDropdown === userId ? null : userId
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) => {
    return user.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  console.log("====================================");
  console.log(user);
  console.log("====================================");

  return (
    <div className="bg-[#FCFCFC] min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        outSideModalPress={false}
      >
        <div className="text-slate-800 text-2xl font-semibold font-poppins leading-loose">
          My Profile
        </div>
        <div className="border border-b-1 border-gray-100 my-3" />
        <div className="flex items-center">
          <img
            src="https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1723420800&semt=ais_hybrid"
            alt={user?.Name}
            className="w-24 h-24 rounded-full mr-3 ring-2 ring-sky-100 ring-offset-0 object-cover"
          />
          <div className="flex flex-col space-y-1">
            <div className="text-zinc-900 text-sm font-semibold font-poppins leading-tight">
              {/* {user?.Name} */}
              Giana L.
            </div>
            <div className="text-center text-zinc-500 text-xs font-normal font-['Open Sans']">
              Added by Ron Walter on 12 Aug 2024
            </div>
          </div>
        </div>
        <div className="container w-[30vw] mx-auto mt-6 py-4 px-6 shadow rounded-md">
          <div className="flex flex-row items-center">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Contact
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              +91- 7543457895 ; gianaL@gmail.com
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Education 1
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              M.Sc. Psychology
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Education 2
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              Diploma, Health Management
            </div>
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <div className="flex flex-row items-center border-b-1 border-gray-100">
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins leading-tight">
              Languages
            </div>
            <div className="flex-1 text-slate-800 text-sm font-normal font-poppins  leading-tight">
              French, German
            </div>
          </div>
        </div>
        <button className="px-3 py-2 mt-4 rounded-lg shadow border border-sky-500 flex items-center justify-center space-x-2">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0149 5.23239L17.4166 3.83063C18.1908 3.05646 19.446 3.05646 20.2202 3.83063C20.9943 4.6048 20.9943 5.85998 20.2202 6.63415L18.8184 8.03591M16.0149 5.23239L10.781 10.4663C9.73571 11.5116 9.21304 12.0342 8.85715 12.6711C8.50125 13.308 8.14318 14.8119 7.80078 16.25C9.23887 15.9076 10.7428 15.5495 11.3797 15.1936C12.0166 14.8377 12.5392 14.3151 13.5845 13.2698L18.8184 8.03591M16.0149 5.23239L18.8184 8.03591"
              stroke="#3CA5DC"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.8008 12.25C20.8008 16.4926 20.8008 18.614 19.4828 19.932C18.1648 21.25 16.0434 21.25 11.8008 21.25C7.55814 21.25 5.43682 21.25 4.1188 19.932C2.80078 18.614 2.80078 16.4926 2.80078 12.25C2.80078 8.00736 2.80078 5.88604 4.1188 4.56802C5.43682 3.25 7.55814 3.25 11.8008 3.25"
              stroke="#3CA5DC"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <div className="text-sky-500 text-sm font-semibold font-poppins">
            Edit Profile Info
          </div>
        </button>
      </ModalComponent>
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-3">
          <div className="text-zinc-900 text-2xl font-medium font-poppins">
            Health Manager's Dashboard
          </div>
          <div class="text-neutral-600 text-sm font-semibold font-poppins leading-none">
            Health Manager
          </div>
        </div>
        <button
          onClick={() => setShowProfileModal(true)}
          className="text-sky-500 text-sm font-semibold font-poppins leading-none active:scale-95"
        >
          View my Profile
        </button>
      </div>
      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Name
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Contact Details
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Concierge Plan
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Notes
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Requests/ Remarks
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {currentUsers?.map((user) => (
              <tr
                key={user.id}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <div className="flex items-center">
                    <img
                      src={user.image}
                      alt={user.name}
                      className="w-10 h-10 rounded-full mr-3 ring-2 ring-[#ffa800] ring-offset-1"
                    />
                    <div>
                      <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                        {user.name}
                      </p>
                      <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">{`${user.gender}, ${user.age}`}</p>
                    </div>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip
                      message={user?.phone || "No phone number available"}
                      // activation="click"
                      showCopyButton={true}
                    >
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M9.15826 5.71223L8.7556 4.80625C8.49232 4.21388 8.36068 3.91768 8.16381 3.69101C7.91708 3.40694 7.59547 3.19794 7.23568 3.08785C6.94859 3 6.62446 3 5.97621 3C5.02791 3 4.55376 3 4.15573 3.18229C3.68687 3.39702 3.26344 3.86328 3.09473 4.3506C2.95151 4.76429 2.99254 5.18943 3.07458 6.0397C3.94791 15.0902 8.90982 20.0521 17.9603 20.9254C18.8106 21.0075 19.2358 21.0485 19.6494 20.9053C20.1368 20.7366 20.603 20.3131 20.8178 19.8443C21 19.4462 21 18.9721 21 18.0238C21 17.3755 21 17.0514 20.9122 16.7643C20.8021 16.4045 20.5931 16.0829 20.309 15.8362C20.0824 15.6393 19.7862 15.5077 19.1938 15.2444L18.2878 14.8417C17.6463 14.5566 17.3255 14.4141 16.9996 14.3831C16.6876 14.3534 16.3731 14.3972 16.0811 14.5109C15.776 14.6297 15.5064 14.8544 14.967 15.3038C14.4302 15.7512 14.1618 15.9749 13.8338 16.0947C13.543 16.2009 13.1586 16.2403 12.8524 16.1951C12.5069 16.1442 12.2424 16.0029 11.7133 15.7201C10.0673 14.8405 9.15953 13.9328 8.27987 12.2867C7.99714 11.7577 7.85578 11.4931 7.80487 11.1477C7.75974 10.8414 7.79908 10.457 7.9053 10.1663C8.02512 9.83828 8.24881 9.56986 8.69619 9.033C9.14562 8.49368 9.37034 8.22402 9.48915 7.91891C9.60285 7.62694 9.64662 7.3124 9.61695 7.00048C9.58594 6.67452 9.44338 6.35376 9.15826 5.71223Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip
                      message={user?.email || "No email available"}
                      // activation="click"
                      showCopyButton={true}
                    >
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                    {/* <Tooltip
                      message={user?.email}
                      // activation="click"
                      showCopyButton={true}
                    > */}
                    <button className="p-1 rounded-full group group-hover:bg-sky-100">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                      >
                        <path
                          d="M22 11.5667C22 16.8499 17.5222 21.1334 12 21.1334C11.3507 21.1343 10.7032 21.0742 10.0654 20.9545C9.60633 20.8682 9.37678 20.8251 9.21653 20.8496C9.05627 20.8741 8.82918 20.9948 8.37499 21.2364C7.09014 21.9197 5.59195 22.161 4.15111 21.893C4.69874 21.2194 5.07275 20.4112 5.23778 19.5448C5.33778 19.0148 5.09 18.5 4.71889 18.1231C3.03333 16.4115 2 14.1051 2 11.5667C2 6.28357 6.47778 2 12 2C17.5222 2 22 6.28357 22 11.5667Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.9955 12H12.0045M15.991 12H16M8 12H8.00897"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    {/* </Tooltip> */}
                  </div>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  Individual
                </td>
                <td className="p-4 w-60">
                  {user.notes === null ? (
                    <button
                      onClick={() => {
                        setSelectedPatient(user);
                        setShowAddNotesModal(true);
                      }}
                      className="text-sky-400 text-sm font-semibold font-poppins leading-none active:scale-95"
                    >
                      Add Notes
                    </button>
                  ) : (
                    <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight line-clamp-2">
                      {user.notes}
                    </div>
                  )}
                </td>
                {/* <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip message="View Profile">
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip message="Edit">
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                    <Tooltip message="Delete">
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M9.30078 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M14.3008 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td> */}
                <td className="p-4">
                  {user.request === "Meeting Requested" ? (
                    <div className="flex flex-row items-center space-x-2">
                      <img src={appointment} alt="" />
                      <div className="text-gray-900 text-sm font-normal font-poppins leading-tight">
                        Meeting Requested
                      </div>
                    </div>
                  ) : user.request === "Callback Requested" ? (
                    <div className="flex flex-row items-center space-x-1">
                      <img src={call} alt="" />
                      <div className="text-gray-900 text-sm font-normal font-poppins leading-tight">
                        Callback Requested
                      </div>
                    </div>
                  ) : (
                    <div className="text-neutral-400 text-sm font-normal font-poppins leading-tight">
                      No requests/remarks yet!
                    </div>
                  )}
                </td>
                <td className="p-4 relative">
                  <div
                    onClick={() => toggleStatusDropdown(user.id)}
                    className="p-3 w-36 h-10 bg-white rounded-2xl border border-sky-500 flex items-center justify-between cursor-pointer"
                  >
                    <div className="flex items-center space-x-1">
                      {user?.status !== null && (
                        <div
                          className={`w-2 h-2 ${
                            user.status === "pending"
                              ? "bg-neutral-300"
                              : user.status === "completed"
                              ? "bg-emerald-300"
                              : user.status === "ongoing"
                              ? "bg-amber-500"
                              : "None"
                          }  rounded-full animate-pulse`}
                        />
                      )}
                      <div className="text-center text-slate-700 text-sm font-normal font-poppins leading-tight">
                        {user.status === "pending"
                          ? "Pending"
                          : user.status === "completed"
                          ? "Completed"
                          : user.status === "ongoing"
                          ? "Ongoing"
                          : "None"}
                      </div>
                    </div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.50004C15 7.50004 11.3176 12.5 10 12.5C8.68233 12.5 5 7.5 5 7.5"
                        stroke="black"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  {showStatusDropdown === user.id && (
                    <div className="absolute left-2 mt-1 w-40 bg-white border rounded-lg p-2 space-y-1 shadow-lg z-10">
                      {STATUS?.map((x) => (
                        <button
                          key={x.value}
                          onClick={() => handleStatusSelect(user.id, x.value)}
                          className={`w-full flex flex-row items-center justify-between px-4 py-2 text-sm rounded-lg ${
                            user.status === x.value
                              ? "bg-slate-50 text-sky-500 hover:bg-sky-200 hover:text-sky-600"
                              : "text-black hover:bg-gray-100 hover:text-gray-800"
                          }`}
                        >
                          <div className="flex flex-row items-center space-x-1">
                            {x?.value !== null && (
                              <div
                                className={`w-2 h-2 ${
                                  x.value === "pending"
                                    ? "bg-neutral-300"
                                    : x.value === "completed"
                                    ? "bg-emerald-300"
                                    : x.value === "ongoing"
                                    ? "bg-amber-500"
                                    : "None"
                                }  rounded-full animate-pulse`}
                              />
                            )}
                            <div>{x.label}</div>
                          </div>
                          {user.status === x.value && (
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.3353 5.5L8.16862 14.6667L4.00195 10.5"
                                stroke="#3CA5DC"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </button>
                      ))}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="w-full mx-auto mt-4 px-6 py-2 shadow rounded-md border-t-2 border-gray-200">
        <div className="flex flex-row items-center justify-between">
          <div className="text-slate-700 text-sm font-normal font-poppins leading-tight">
            Total Records: {users.length}
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="px-4 py-2 bg-white rounded-lg border border-neutral-300 flex flex-row items-center active:scale-95"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 6C15 6 9 10.4189 9 12C9 13.5812 15 18 15 18"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                Previous
              </div>
            </button>
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < Math.ceil(users.length / pageSize)
                    ? prevPage + 1
                    : prevPage
                )
              }
              className="px-4 py-2 bg-white rounded-lg border border-neutral-300 flex flex-row items-center active:scale-95"
            >
              <div className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                Next
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default HealthManagerDashboard;

const STATUS = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Ongoing",
    value: "ongoing",
  },
  {
    label: "None",
    value: "none",
  },
];

const users = [
  {
    id: 1,
    name: "Emma Johnson",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Emma Johnson`,
    gender: "Female",
    age: 32,
    joinDate: "15 July 2024",
    healthManager: null,
    notes: null,
    request: "Callback Requested",
    email: "emma.johnson@example.com",
    phone: "+1-234-567-8901",
    status: "pending",
  },
  {
    id: 2,
    name: "Olivia Martinez",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Olivia Martinez`,
    gender: "Female",
    age: 39,
    joinDate: "8 August 2024",
    healthManager: null,
    notes: null,
    request: null,
    email: "olivia.martinez@example.com",
    phone: "+1-234-567-8902",
    status: null,
  },
  {
    id: 3,
    name: "Liam Thompson",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Liam Thompson`,
    gender: "Male",
    age: 45,
    joinDate: "22 June 2024",
    healthManager: "Dr. Sarah Chen",
    notes:
      "Regular check-up scheduled and follow-up for medication required, diet plan review",
    request: "Meeting Requested",
    email: "liam.thompson@example.com",
    phone: "+1-234-567-8903",
    status: "completed",
  },
  {
    id: 4,
    name: "Sophia Patel",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Sophia Patel`,
    gender: "Female",
    age: 28,
    joinDate: "3 September 2024",
    healthManager: null,
    notes: null,
    request: "Assign Health Manager",
    email: "sophia.patel@example.com",
    phone: "+1-234-567-8904",
    status: "pending",
  },
  {
    id: 5,
    name: "Noah Rodriguez",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Noah Rodriguez`,
    gender: "Male",
    age: 55,
    joinDate: "17 July 2024",
    healthManager: "Dr. James Wilson",
    notes: "Follow-up on medication",
    request: "Follow-Up Ongoing",
    email: "noah.rodriguez@example.com",
    phone: "+1-234-567-8905",
    status: "ongoing",
  },
  {
    id: 6,
    name: "Ava Nguyen",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Ava Nguyen`,
    gender: "Female",
    age: 41,
    joinDate: "30 August 2024",
    healthManager: null,
    notes: null,
    request: null,
    email: "ava.nguyen@example.com",
    phone: "+1-234-567-8906",
    status: null,
  },
  {
    id: 7,
    name: "Ethan Kim",
    image: `https://api.dicebear.com/5.x/initials/svg?seed=Ethan Kim`,
    gender: "Male",
    age: 36,
    joinDate: "12 July 2024",
    healthManager: "Dr. Emily Thompson",
    notes: "Dietary plan review",
    request: "Callback Requested",
    email: "ethan.kim@example.com",
    phone: "+1-234-567-8907",
    status: "completed",
  },
];
