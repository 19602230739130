import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function NurseCardView({ nurses }) {
  const navigate = useNavigate();
  return (
    <div className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {nurses.map((n) => (
        <div
          key={n.DID}
          className="flex flex-col p-4 bg-neutral-50 rounded-lg shadow-md"
        >
          <div className="flex items-center justify-between mb-4">
            <div
              className="flex items-center space-x-3 cursor-pointer"
              onClick={() => {
                navigate("/addNewNurse", { state: { ...n, isViewing: true } });
              }}
            >
              <img
                src={n.Image}
                alt="PatientPic"
                className="w-10 h-10 rounded-full object-cover"
              />
              <div className="flex flex-col">
                <div className="text-black text-sm font-semibold font-poppins">
                  {n.Name}
                </div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  ID : {n.DID}
                </div>
              </div>
            </div>
            <button
              className=" bg-sky-100 rounded-full text-sky-400 text-xs font-medium font-poppins px-1.5"
              onClick={() => {
                navigate("/addNewNurse", { state: { ...n, isViewing: true } });
              }}
            >
              See Details
            </button>
          </div>
          <div className="border border-b-0.5 border-gray-100 mb-5"></div>
          <div className="flex justify-between text-xs mb-4">
            <div className="text-zinc-500 font-normal font-poppins">
              Join Date
            </div>
            <div className="text-black font-semibold font-poppins">
              {moment(n.JoiningDate).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="flex justify-between text-xs mb-4">
            <div className="text-zinc-500 font-normal font-poppins">
              Speciality
            </div>
            <div className="text-black font-semibold font-poppins">
              {n?.RelatedSpecialities[0]?.Speciality}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NurseCardView;
