import React, { useEffect, useState } from "react";

function Weather({ weatherData }) {

    const { weather, main, name } = weatherData;
    const iconCode = weather[0].icon;
    const iconUrl = `http://openweathermap.org/img/wn/${iconCode}@2x.png`;

    return (
        <div className="flex flex-row justify-center items-center gap-2">
            <img src={iconUrl} alt={weather[0].description} className="w-12 h-12 object-contain" />
            <p className="text-black text-sm font-poppins font-medium">{main.temp}°C</p>
        </div>
    );
}

export default Weather;
