import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import bgImage from "../../assets/Images/TreatmentProgram/bgImage.png";
import LABTEST from "../../assets/Images/TreatmentProgram/LABTESTimg.png";
import ATHOME from "../../assets/Images/TreatmentProgram/ATHOMEimg.png";
import INCLINIC from "../../assets/Images/TreatmentProgram/INCLINICimg.png";
import Checkbox from "../../components/CheckBox/Checkbox";
import {
  createTreatmentProgram,
  updateTreatmentProgram,
  getAllDiagnosis,
} from "../../services/operations/masterDataAPI";
import RadioButton from "../../components/RadioButton/RadioButton";
import RadioButton1 from "../../components/RadioButton/RadioButton1";
import { FaChevronLeft } from "react-icons/fa6";

function CreateTreatmentProgram() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { prog } = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const [isEdit, setIsEdit] = useState(false);
  const [TPID, setTPID] = useState(null);
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);

  const [formData, setFormData] = useState({
    NameofProgram: "",
    DiseaseType: "",
    TargetAudience: "",
    NumberofWeek: "",
    PediatricUse: false,
    WeekData: [],
  });

  const [errors, setErrors] = useState({
    NameofProgram: "",
    DiseaseType: "",
    TargetAudience: "",
    NumberofWeek: "",
    WeekData: [],
  });

  const handleCheckbox = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      PediatricUse: !prevFormData.PediatricUse,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "NumberofWeek") {
      const numberOfWeeks = value ? parseInt(value) : 0;
      setFormData((prevData) => {
        const newWeekData = prevData.WeekData.slice(0, numberOfWeeks).concat(
          Array(Math.max(0, numberOfWeeks - prevData.WeekData.length)).fill({
            weekText: "",
            weekType: "INCLINIC",
          })
        );
        return {
          ...prevData,
          [name]: value,
          WeekData: newWeekData,
        };
      });
      setErrors((prevData) => ({
        ...prevData,
        NumberofWeek: "",
        WeekData: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
  };

  const handleWeekChange = (index, value) => {
    setFormData((prevData) => {
      const newWeekData = [...prevData.WeekData];
      newWeekData[index] = {
        ...newWeekData[index],
        weekText: value,
      };
      return {
        ...prevData,
        WeekData: newWeekData,
      };
    });
    setErrors((prevData) => ({
      ...prevData,
      WeekData: "",
    }));
  };

  const handleWeekTypeChange = (index, type) => {
    setFormData((prevData) => {
      const newWeekData = [...prevData.WeekData];
      newWeekData[index] = {
        ...newWeekData[index],
        weekType: type,
      };
      return {
        ...prevData,
        WeekData: newWeekData,
      };
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const mandatoryFields = [
      "NameofProgram",
      "DiseaseType",
      "TargetAudience",
      "NumberofWeek",
      "WeekData",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (
        !value ||
        (Array.isArray(value) && value.some((v) => v.weekText.trim() === ""))
      ) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    const weekData = {};
    for (let i = 0; i < 12; i++) {
      const week = formData.WeekData[i];
      weekData[`Week${i + 1}`] = week ? week.weekText : null;
      weekData[`Week${i + 1}Type`] = week ? week.weekType : null;
    }

    const basicData = {
      Name: formData.NameofProgram,
      DiagnosisID: parseInt(formData.DiseaseType),
      TargetAudience: formData.TargetAudience,
      PediatricUse: formData.PediatricUse ? 1 : 0,
      WeekNumber: parseInt(formData.NumberofWeek),
      WeekData: weekData,
    };

    try {
      if (isEdit) {
        const id = TPID;
        await dispatch(updateTreatmentProgram(token, id, basicData));
      } else {
        await dispatch(createTreatmentProgram(token, basicData));
      }
      navigate("/treatmentProgram");
    } catch (error) {
      console.error("Error creating/updating treatment program:", error);
    }
  };

  useEffect(() => {
    if (prog && diagnosisOptions.length > 0) {
      const matchedDiagnosis = diagnosisOptions.find(
        (option) => option.DiagnosisName === prog.DiagnosisType
      );
      const weekDataArray = [];
      for (let i = 1; i <= prog.WeekNumber; i++) {
        const weekText =
          prog.WeekData[`Week${i}`] !== null ? prog.WeekData[`Week${i}`] : "";
        const weekType =
          prog.WeekData[`Week${i}Type`] !== null
            ? prog.WeekData[`Week${i}Type`]
            : "";
        weekDataArray.push({ weekText, weekType });
      }
      setFormData({
        NameofProgram: prog.Name,
        DiseaseType: matchedDiagnosis ? matchedDiagnosis.ID.toString() : "",
        TargetAudience: prog.TargetAudience,
        NumberofWeek: prog.WeekNumber.toString(),
        PediatricUse: prog.PediatricUse === 1,
        WeekData: weekDataArray,
      });
      setIsEdit(true);
      setTPID(prog.TPID);
    }
  }, [prog, diagnosisOptions]);

  useEffect(() => {
    const fetchDiagnosis = async () => {
      if (token) {
        try {
          const res = await dispatch(getAllDiagnosis(token));
          setDiagnosisOptions(res.DiagnosisList);
        } catch (error) {
          console.error("Failed to fetch Diagnosis", error);
        }
      }
    };

    fetchDiagnosis();
  }, [token, dispatch]);

  return (
    <div className="bg-[#F3F3F3] min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* HEADER */}
        <div className="flex flex-row justify-between items-center">
          {/* TITLE */}
          <div className="flex flex-row items-center space-x-4">
            <button
              className="active:scale-90"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaChevronLeft className="h-5" />
            </button>
            <div>
              <h1 className="text-black text-xl font-bold font-poppins">
                {isEdit
                  ? "Edit Treatment Program"
                  : "Create New Treatment Program"}
              </h1>
              <p className="text-[#8A8A8A] text-xs font-regular font-poppins">
                {isEdit
                  ? "Edit the form to update treatment program"
                  : "Here are the form for creating treatment program"}
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="flex flex-row justify-start items-center w-full mt-5 gap-2">
          {/* Left Form */}
          <form className="w-[40%]">
            {/* Name of Program */}
            <div className="mb-4">
              <label>
                <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                  Name of Program
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="NameofProgram"
                  value={formData.NameofProgram}
                  onChange={handleChange}
                  placeholder="Input name of program here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.NameofProgram ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* Disease Type */}
            <div className="mb-4">
              <label>
                <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                  Disease Type
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="DiseaseType"
                  name="DiseaseType"
                  value={formData.DiseaseType}
                  onChange={handleChange}
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.DiseaseType ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                >
                  <option value="">Select Disease Type</option>
                  {diagnosisOptions.map((option) => (
                    <option key={option.ID} value={option.ID}>
                      {option.DiagnosisName}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* Target Audience */}
            <div className="mb-4 flex flex-row justify-center items-center gap-5 w-full">
              <label className="w-1/2">
                <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                  Target Audience
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="TargetAudience"
                  name="TargetAudience"
                  value={formData.TargetAudience}
                  onChange={handleChange}
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.TargetAudience ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                >
                  <option value="">Select Target Audience</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Both">Both</option>
                </select>
              </label>

              <div className="w-1/2">
                <Checkbox
                  name="PediatricUse"
                  id="PediatricUse"
                  value="PediatricUse"
                  text="Pediatric Use"
                  onChange={handleCheckbox}
                  checked={formData.PediatricUse}
                />
              </div>
            </div>

            {/* Number of Week */}
            <div className="mb-4">
              <label>
                <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                  Number of Week
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  id="NumberofWeek"
                  name="NumberofWeek"
                  value={formData.NumberofWeek}
                  onChange={handleChange}
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.NumberofWeek ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                >
                  <option value="">Select Number of Weeks</option>
                  <option value="3">3 weeks</option>
                  <option value="4">4 weeks</option>
                  <option value="6">6 weeks</option>
                  <option value="8">8 weeks</option>
                  <option value="12">12 weeks</option>
                </select>
              </label>
            </div>

            {/* Week Input Fields */}
            {formData.WeekData.map((week, index) => (
              <div
                className="mb-4 flex flex-col justify-center items-start w-full gap-2"
                key={index}
              >
                <label className="flex flex-row justify-center items-stretch w-full gap-2">
                  <div className="w-[20%] flex flex-row justify-center items-center bg-[#E5F2FF] rounded-md px-1">
                    <p className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                      Week {index + 1}
                      <sup className="text-red-600">*</sup>
                    </p>
                  </div>
                  <input
                    type="text"
                    name={`WeekData${index}`}
                    value={week.weekText}
                    onChange={(e) => handleWeekChange(index, e.target.value)}
                    placeholder={`Input treatment program here`}
                    className={`w-[80%] text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.WeekData && errors.WeekData[index]
                        ? "border-red-500"
                        : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
                <div className="flex flex-row self-end justify-center items-center gap-2">
                  {["INCLINIC", "ATHOME", "LABTEST"].map((type) => (
                    <RadioButton1
                      key={type}
                      name={`weekType-${index}`}
                      id={`${index}-${type}`}
                      value={type}
                      text={type}
                      checked={week.weekType === type}
                      onChange={() => handleWeekTypeChange(index, type)}
                      imageSrc={
                        type === "INCLINIC"
                          ? INCLINIC
                          : type === "ATHOME"
                          ? ATHOME
                          : type === "LABTEST"
                          ? LABTEST
                          : null
                      }
                    />
                  ))}
                </div>
              </div>
            ))}

            {/* Submit Button */}
            <button
              type="submit"
              style={{ backgroundColor: "#3CA5DC" }}
              className="mt-4 w-full bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 sm:px-44 hover:bg-sky-500 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300"
              onClick={submitHandler}
            >
              Finish
            </button>
          </form>

          {/* Right Img */}
          <div className="flex w-full self-end justify-end items-end px-16">
            <img
              src={bgImage}
              className="w-[70%] h-auto object-contain"
              alt="Create Treatment Program Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTreatmentProgram;
