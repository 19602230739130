import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  registerMerchant,
  resetPassword,
  verifyMerchantOTP,
} from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import resetLogo from "../assets/Images/resetPasswordLogo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { SlLogin } from "react-icons/sl";
import reqDemoIcon from "../assets/Images/reqDemoIcon.png";

function ResetPasswordForm({ type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = localStorage.getItem("resetToken");
  const Otp = localStorage.getItem("resetOtp");
  const tempData = localStorage.getItem("tempData")
    ? JSON.parse(localStorage.getItem("tempData"))
    : "";
  console.log("ESERSERSER", tempData);

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = formData;

  useEffect(() => {
    if (!token || !tempData) {
      navigate("/forgot-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle Form Submission
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match");
      return;
    }

    let data;
    if (tempData?.LoginType === '0') {
      data = {
        "Email": tempData.Email,
        "Otp": Otp,
        "NewPassword": password,
      };
    }
    else if (tempData?.LoginType === '1') {
      data = {
        "Phone": tempData.Phone,
        "Otp": Otp,
        "NewPassword": password,
      };
    }
    // navigate("/reset-password-success")

    console.log("DATA", data);
    

    dispatch(resetPassword(data, navigate, type));
  };
  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white w-full md:w-[37vw] h-[90vh] rounded-[22px] py-10 px-20 flex flex-col justify-start">
          <div className="flex items-center justify-center gap-3 mt-6 w-full">
            <Link to={type === 'clinic' ? "/partnerLogin" : "/login"} className="block w-44 bg-[#3ca5dc] rounded-2xl py-2 px-6 flex flex-row justify-between items-center gap-2">
              <h1 className="text-[#FFFFFF] text-[13px] font-semibold font-open font-poppins">
                {type === 'clinic' ? "Partner Login" : "Clinic Login"}
              </h1>
              <SlLogin className="text-[#FFFFFF] font-semibold text-lg" />
            </Link>
            <button onClick={() => window.open("https://alleshealth.com/contactus", "_blank")} className="block w-44 bg-white border border-[#3ca5dc] rounded-2xl py-2 px-4 flex flex-row justify-between items-center gap-2">
              <h1 className="text-black text-[13px] font-semibold font-open font-poppins">
                Request a Demo
              </h1>
              <div className="">
                <img
                  src={reqDemoIcon}
                  alt="Demo Icon"
                  className="w-5 h-5 object-contain"
                />
              </div>
            </button>
          </div>

          <h1 className="text-black text-[32px] font-semibold font-open font-poppins leading-relaxed mt-28">
            New Password
          </h1>
          <h1 className="text-[#acacac] text-[10px] font-normal font-open font-poppins leading-relaxed mt-3">
            Input your new password here
          </h1>

          <form onSubmit={handleOnSubmit} className="mt-8">
            {/* password */}
            <div className="flex flex-col">
              {/* Enter New Password */}
              <div className="relative flex-1 mb-4">
                <label>
                  <input
                    className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-2xl p-4 border border-[#CFCFCF] focus:outline-none`}
                    required
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleOnChange}
                    placeholder="Enter New Password"
                  />
                  <span
                    onClick={() => setShowPassword((prev) => !prev)}
                    className="absolute right-4 top-6 transform -translate-y-1/2 z-10 cursor-pointer"
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#3CA5DC" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#3CA5DC" />
                    )}
                  </span>
                </label>
              </div>

              {/* Confirm Password */}
              <div className="relative flex-1">
                <label>
                  <input
                    className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-2xl p-4 border border-[#CFCFCF] focus:outline-none`}
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleOnChange}
                    placeholder="Confirm Password"
                  />
                  <span
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                    className="absolute right-4 top-6 transform -translate-y-1/2 z-10 cursor-pointer"
                  >
                    {showConfirmPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#3CA5DC" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#3CA5DC" />
                    )}
                  </span>
                </label>
              </div>
            </div>

            <div className="mt-20">
              <button
                type="submit"
                className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-2xl py-3 text-sky-100 text-sm font-medium font-poppins"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordForm;