import moment from "moment";
import React from "react";
import addPrescription from "../assets/Images/PatientProfile/addPrescription.svg";
import FooterWithExpandButton from "./FooterWithExpandButton";

function DosageAdherence({ dosageAdherence }) {
  if (dosageAdherence?.length === 0) {
    return (
      <div>
        <div className="text-black text-lg font-bold font-poppins">
          Dosage Adherence
        </div>
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex flex-row items-center justify-between">
          <div className="text-black text-lg font-semibold font-poppins">
            Dosage Adherence
          </div>
          <button className="px-4 py-2 bg-sky-400 rounded-lg border border-sky-400 flex justify-center items-center gap-2 active:scale-95">
            <img src={addPrescription} alt="" />

            <div className="text-white text-base font-semibold font-poppins leading-tight">
              Add Prescription
            </div>
          </button>
        </div>
        <div className="mt-5 flex flex-col space-y-4">
          {dosageAdherence.slice(0, 4).map((item) => (
            <div
              key={item.ID}
              className="bg-neutral-50 rounded-lg p-4 flex flex-row items-center justify-between"
            >
              <div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Medicine Name
                </div>
                <div className="text-zinc-900 text-xs font-semibold font-poppins mt-1">
                  {item.MedicineName}
                </div>
              </div>
              <div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Dosage Duration
                </div>
                <div className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
                  {item.DosageDuration} a Day
                </div>
              </div>
              <div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Start Date
                </div>
                <div className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
                  {moment(item.StartDate).format("DD/MM/YYYY")}
                </div>
              </div>
              <div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  End Date
                </div>
                <div className="text-zinc-900 text-xs font-semibold font-poppins mt-1">
                  {moment(item.EndDate).format("DD/MM/YYYY")}
                </div>
              </div>
              <div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Taking this Dose
                </div>
                <div className="text-zinc-900 text-xs font-semibold font-poppins mt-1">
                  {item.IsTakingDose ? "Yes" : "No"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FooterWithExpandButton />
    </div>
  );
}

export default DosageAdherence;
