import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  conciergePlans: [],
  conciergeFeatures: [],
  healthManagers: [],
  healthManagerSpeciality: [],
};

const medicalConciergeSlice = createSlice({
  name: "medicalConcierge",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setConciergePlans(state, action) {
      state.conciergePlans = action.payload;
    },
    setConciergeFeatures(state, action) {
      state.conciergeFeatures = action.payload;
    },
    setHealthManagers(state, action) {
      state.healthManagers = action.payload;
    },
    setHealthManagerSpeciality(state, action) {
      state.healthManagerSpeciality = action.payload;
    },
  },
});

export const {
  setLoading,
  setConciergePlans,
  setConciergeFeatures,
  setHealthManagers,
  setHealthManagerSpeciality,
} = medicalConciergeSlice.actions;

export default medicalConciergeSlice.reducer;
