import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import postInvoiceIcon from "../../assets/Images/Invoicing/postInvoiceIcon.png";
import mailInvoiceIcon from "../../assets/Images/Invoicing/mailInvoiceIcon.png";
import cal_Icon from "../../assets/Images/BookAppointment/cal_Icon.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import AHlogo from "../../assets/Images/logo.png";
import ModalComponent from "../../components/Modal";
import { IoIosSearch } from "react-icons/io";
import { DayPicker } from "react-day-picker";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import "react-day-picker/dist/style.css";
import {
  createInvoice,
  getAllServiceRateEntry,
} from "../../services/operations/masterDataAPI";
import { RiDeleteBinLine } from "react-icons/ri";

function AddInvoice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const invoice = location?.state?.invoice || {};
  const { logo } = useSelector((state) => state.profile);
  const { settings } = useSelector((state) => state.masterData);
  const { token } = useSelector((state) => state.auth);
  const [showCalendar, setShowCalendar] = useState(false);
  // const [selected, setSelected] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [serviceRateEntry, setServiceRateEntry] = useState([]);
  const [deleteTempService, setDeleteTempService] = useState(false);
  const [filteredServices, setFilteredServices] = useState([]);

  const [tempServiceData, setTempServiceData] = useState({
    ServiceCode: "",
    ServiceID: "",
    ServiceName: "",
    Time: "",
    Rate: "",
    Cost: "",
    Quantity: "",
  });
  const [tempServiceErrors, setTempServiceErrors] = useState({
    ServiceCode: "",
    ServiceID: "",
    ServiceName: "",
    Time: "",
    Rate: "",
    Cost: "",
    Quantity: "",
  });

  const [formData, setFormData] = useState({
    AppointmentID: "",
    InvoiceID: "",
    PatientName: "",
    Services: "",
    Date: "",
    Address: "",
    DrName: "",
    AHVNumber: "",
    DOB: "",
    Canton: "",
    AHUID: "",
    Tax: "",
    InvoiceAmount: "",
    Currency: "CHF",
    ServiceData: [],
    Subtotal: "0.00",
    Total: "0.00",
  });

  const [errors, setErrors] = useState({
    AppointmentID: "",
    InvoiceID: "",
    PatientName: "",
    Services: "",
    Date: "",
    InvoiceAmount: "",
    ServiceData: "",
    Subtotal: "",
    Total: "",
  });

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchInput(value);

    if (value.trim() === "") {
      setFilteredServices([]);
    } else {
      const filtered = serviceRateEntry.filter((service) =>
        service.Name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredServices(filtered);
    }
  };

  const handleServiceClick = (service) => {
    setTempServiceData((prevData) => ({
      ...prevData,
      ServiceName: service.Name,
      ServiceCode: service.ReimbursementCode,
      Rate: service.Price,
      Time: service.Times,
      Quantity: "",
      Cost: "",
      ServiceID: service?.SRCID,
      Currency: service?.Currency,
    }));
    setTempServiceErrors((prevData) => ({
      ...prevData,
      ServiceName: "",
      ServiceCode: "",
      Rate: "",
      ServiceID: "",
      Currency: "",
    }));
    setSearchInput("");
    setFilteredServices([]);

    const { Times, Price } = service;
    if (Times && Price) {
      const serviceAmount = parseFloat(Price);
      const Cost = serviceAmount.toFixed(2);

      setTempServiceData((prevData) => ({
        ...prevData,
        Cost: Cost,
      }));
      setTempServiceErrors((prevData) => ({
        ...prevData,
        Time: "",
        Cost: "",
        Rate: "",
      }));

      // Check if formData.ServiceData is empty
      if (!formData.ServiceData || formData.ServiceData.length === 0) {
        setFormData((prevData) => {
          const subtotal = parseFloat(serviceAmount.toFixed(2));
          const tax = parseFloat((subtotal * 0.18).toFixed(2));
          const total = parseFloat((subtotal + tax).toFixed(2));
          return {
            ...prevData,
            InvoiceAmount: serviceAmount.toFixed(2),
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      } else {
        const updatedServiceData = [...(formData.ServiceData || [])];
        // Calculate the total and subtotal amounts
        let totalAmount = updatedServiceData.reduce(
          (sum, service) => sum + parseFloat(service.Cost || 0),
          0
        );
        totalAmount += parseFloat(serviceAmount);

        // Update formData with the new service data and calculated amounts
        setFormData((prevData) => {
          const subtotal = parseFloat(totalAmount.toFixed(2));
          const tax = parseFloat((subtotal * 0.18).toFixed(2));
          const total = parseFloat((subtotal + tax).toFixed(2));

          return {
            ...prevData,
            InvoiceAmount: totalAmount.toFixed(2),
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      }
    }
  };

  const handleAddMoreService = (e) => {
    e.preventDefault();

    if (deleteTempService) {
      setDeleteTempService(false);
    } else {
      const mandatoryFields = [
        "ServiceCode",
        "ServiceID",
        "ServiceName",
        "Time",
        "Rate",
        "Cost",
      ];
      const newErrors = {};

      mandatoryFields.forEach((key) => {
        const value = tempServiceData[key];

        if (
          !value ||
          (Array.isArray(value) && value.some((v) => v.trim() === ""))
        ) {
          newErrors[key] = "This field is required";
        } else if (typeof value === "string" && value.trim() === "") {
          newErrors[key] = "This field cannot be empty";
        }
      });

      if (Object.keys(newErrors).length > 0) {
        toast.error("Please fill all the required fields");
        setTempServiceErrors(newErrors);
        return;
      }

      setDeleteTempService(false);

      // Add tempServiceData to formData.ServiceData
      const updatedServiceData = [
        ...(formData.ServiceData || []),
        { ...tempServiceData },
      ];

      // Calculate the total and subtotal amounts
      const totalAmount = updatedServiceData.reduce(
        (sum, service) => sum + parseFloat(service.Cost || 0),
        0
      );

      // Update formData with the new service data and calculated amounts
      setFormData((prevData) => {
        const subtotal = parseFloat(totalAmount.toFixed(2));
        const tax = parseFloat((subtotal * 0.18).toFixed(2));
        const total = parseFloat((subtotal + tax).toFixed(2));

        return {
          ...prevData,
          ServiceData: updatedServiceData,
          InvoiceAmount: totalAmount.toFixed(2),
          Subtotal: subtotal,
          Tax: tax,
          Total: total,
        };
      });
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }

    // Reset tempServiceData and tempServiceErrors
    setTempServiceData((prevData) => ({
      ...Object.keys(prevData).reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      ),
      Currency: "CHF",
    }));
    setTempServiceErrors((prevData) => ({
      ...Object.keys(prevData).reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      ),
      Currency: "",
    }));
  };

  const handleTempDeleteService = (e) => {
    e.preventDefault();
    // Reset tempServiceData and tempServiceErrors
    setTempServiceData((prevData) => ({
      ...Object.keys(prevData).reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      ),
      Currency: "CHF",
    }));
    setTempServiceErrors((prevData) => ({
      ...Object.keys(prevData).reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      ),
      Currency: "",
    }));
    setDeleteTempService(true);

    // Ensure formData.ServiceData exists and is an array before reducing
    if (formData.ServiceData && Array.isArray(formData.ServiceData)) {
      // Calculate the total and subtotal amounts
      const totalAmount = formData.ServiceData.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );

      const subtotal = parseFloat(totalAmount.toFixed(2));
      const tax = parseFloat((subtotal * 0.18).toFixed(2));
      const total = parseFloat((subtotal + tax).toFixed(2));

      // Update the formData with the new ServiceData
      setFormData({
        ...formData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      });
      setErrors((prevData) => ({
        ...prevData,
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }
  };

  const handleDeleteService = (e, serviceIdToRemove) => {
    e.preventDefault();

    // Check if formData and ServiceData exist
    if (formData && formData.ServiceData) {
      // Filter out the service with the given ServiceID
      const updatedServiceData = formData.ServiceData.filter(
        (service) => service.ServiceID !== serviceIdToRemove
      );

      // Calculate the total and subtotal amounts
      let totalAmount = updatedServiceData.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );
      totalAmount += parseFloat(tempServiceData?.Cost || 0);

      const subtotal = parseFloat(totalAmount.toFixed(2));
      const tax = parseFloat((subtotal * 0.18).toFixed(2));
      const total = parseFloat((subtotal + tax).toFixed(2));

      // Update the formData with the new ServiceData
      setFormData({
        ...formData,
        ServiceData: updatedServiceData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      });
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }
  };

  // const handleTimeChange = (e) => {
  //     const { name, value } = e.target;

  //     let updatedFormData = {
  //         ...tempServiceData,
  //         [name]: value,
  //     };

  //     const { Time, Rate } = updatedFormData;
  //     if (Time && Rate) {
  //         const serviceAmount =
  //             parseFloat(Rate) * parseFloat(Time);
  //         updatedFormData.Cost = serviceAmount.toFixed(2);

  //         setTempServiceData(updatedFormData);
  //         setTempServiceErrors((prevData) => ({
  //             ...prevData,
  //             Cost: "",
  //             Time: "",
  //             Rate: "",
  //         }));

  //         // Check if formData.ServiceData is empty
  //         if (!formData.ServiceData || formData.ServiceData.length === 0) {
  //             setFormData((prevData) => ({
  //                 ...prevData,
  //                 InvoiceAmount: serviceAmount.toFixed(2),
  //                 Subtotal: serviceAmount.toFixed(2),
  //                 Total: serviceAmount.toFixed(2),
  //             }));
  //             setErrors((prevData) => ({
  //                 ...prevData,
  //                 InvoiceAmount: "",
  //                 Subtotal: "",
  //                 Total: "",
  //             }));
  //         } else {
  //             const updatedServiceData = [...(formData.ServiceData || [])];
  //             // Calculate the total and subtotal amounts
  //             let totalAmount = updatedServiceData.reduce((sum, service) => sum + parseFloat(service.Cost || 0), 0);
  //             totalAmount += parseFloat(serviceAmount);

  //             // Update formData with the new service data and calculated amounts
  //             setFormData((prevData) => ({
  //                 ...prevData,
  //                 InvoiceAmount: totalAmount.toFixed(2),
  //                 Subtotal: totalAmount.toFixed(2),
  //                 Total: totalAmount.toFixed(2),
  //             }));
  //             setErrors((prevData) => ({
  //                 ...prevData,
  //                 InvoiceAmount: "",
  //                 Subtotal: "",
  //                 Total: "",
  //             }));
  //         }
  //     }
  //     setTempServiceData(updatedFormData);
  //     setTempServiceErrors((prevData) => ({
  //         ...prevData,
  //         Time: "",
  //     }));
  // };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = {
      ...tempServiceData,
      [name]: value,
    };

    if (name === "ServiceName") {
      const selectedService = serviceRateEntry.find(
        (service) => service.Name === value
      );
      updatedFormData = {
        ...updatedFormData,
        ServiceName: selectedService?.Name || "",
        ServiceCode: selectedService?.ReimbursementCode || "",
        Rate: selectedService?.Price || "",
        Time: selectedService?.Times || "",
        ServiceID: selectedService?.SRCID || "",
        Currency: selectedService?.Currency || "CHF",
      };
      setTempServiceErrors((prevData) => ({
        ...prevData,
        ServiceName: "",
        ServiceCode: "",
        Rate: "",
        Time: "",
        ServiceID: "",
        Currency: "",
      }));
    }

    const { Time, Rate } = updatedFormData;
    if (Time && Rate) {
      const serviceAmount = parseFloat(Rate);
      updatedFormData.Cost = serviceAmount.toFixed(2);

      setTempServiceErrors((prevData) => ({
        ...prevData,
        Cost: "",
        Time: "",
        Rate: "",
      }));

      // Check if formData.ServiceData is empty
      if (!formData.ServiceData || formData.ServiceData.length === 0) {
        setFormData((prevData) => {
          const subtotal = parseFloat(serviceAmount.toFixed(2));
          const tax = parseFloat((subtotal * 0.18).toFixed(2));
          const total = parseFloat((subtotal + tax).toFixed(2));

          return {
            ...prevData,
            InvoiceAmount: total,
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      } else {
        const updatedServiceData = [...(formData.ServiceData || [])];
        // Calculate the total and subtotal amounts
        let totalAmount = updatedServiceData.reduce(
          (sum, service) => sum + parseFloat(service.Cost || 0),
          0
        );
        totalAmount += parseFloat(serviceAmount);

        // Update formData with the new service data and calculated amounts
        setFormData((prevData) => {
          const subtotal = parseFloat(totalAmount.toFixed(2));
          const tax = parseFloat((subtotal * 0.18).toFixed(2));
          const total = parseFloat((subtotal + tax).toFixed(2));

          return {
            ...prevData,
            InvoiceAmount: total,
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      }
    }

    setTempServiceData(updatedFormData);
    setTempServiceErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (name === "ServiceName") {
      const selectedService = serviceRateEntry.find(
        (service) => service.Name === value
      );
      updatedFormData = {
        ...updatedFormData,
        ServiceName: selectedService?.Name || "",
        ServiceCode: selectedService?.ServiceCode || "",
        Rate: selectedService?.Price || "",
        ServiceID: selectedService?.SRCID || "",
        Currency: selectedService?.Currency || "",
      };
      setErrors((prevData) => ({
        ...prevData,
        ServiceName: "",
        ServiceCode: "",
        Rate: "",
        ServiceID: "",
        Currency: "",
      }));
    }
    setFormData(updatedFormData);
    setErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  const submitHandler = async (event, msg) => {
    event.preventDefault();
    let updatedServiceData = [...(formData.ServiceData || [])];
    let totalAmount;
    let total;

    // Add the Temporary Service Data state in formData
    if (!deleteTempService) {
      const mandatoryFields1 = [
        "ServiceCode",
        "ServiceID",
        "ServiceName",
        "Time",
        "Rate",
        "Cost",
      ];
      const newErrors1 = {};

      mandatoryFields1.forEach((key) => {
        const value = tempServiceData[key];

        if (
          !value ||
          (Array.isArray(value) && value.some((v) => v.trim() === ""))
        ) {
          newErrors1[key] = "This field is required";
        } else if (typeof value === "string" && value.trim() === "") {
          newErrors1[key] = "This field cannot be empty";
        }
      });

      if (Object.keys(newErrors1).length > 0) {
        toast.error("Please fill all the required fields");
        setTempServiceErrors(newErrors1);
        return;
      }

      // Add tempServiceData to formData.ServiceData
      updatedServiceData = [
        ...(formData.ServiceData || []),
        { ...tempServiceData },
      ];

      // Calculate the total and subtotal amounts
      totalAmount = updatedServiceData.reduce(
        (sum, service) => sum + parseFloat(service.Cost || 0),
        0
      );

      const subtotal = parseFloat(totalAmount.toFixed(2));
      const tax = parseFloat((subtotal * 0.18).toFixed(2));
      total = parseFloat((subtotal + tax).toFixed(2));

      // Update formData with the new service data and calculated amounts
      setFormData((prevData) => ({
        ...prevData,
        ServiceData: updatedServiceData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      }));
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));

      // Reset tempServiceData and tempServiceErrors
      setTempServiceData((prevData) => ({
        ...Object.keys(prevData).reduce(
          (acc, key) => ({ ...acc, [key]: "" }),
          {}
        ),
        Currency: "CHF",
      }));
      setTempServiceErrors((prevData) => ({
        ...Object.keys(prevData).reduce(
          (acc, key) => ({ ...acc, [key]: "" }),
          {}
        ),
        Currency: "",
      }));
    } else {
      // Calculate the total and subtotal amounts
      totalAmount = updatedServiceData.reduce(
        (sum, service) => sum + parseFloat(service.Cost || 0),
        0
      );
      const subtotal = parseFloat(totalAmount.toFixed(2));
      const tax = parseFloat((subtotal * 0.18).toFixed(2));
      total = parseFloat((subtotal + tax).toFixed(2));

      // Update formData with the new service data and calculated amounts
      setFormData((prevData) => ({
        ...prevData,
        ServiceData: updatedServiceData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      }));
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }

    const mandatoryFields = ["AppointmentID", "InvoiceID", "Services", "Total"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (
        !value ||
        (Array.isArray(value) && value.some((v) => v.trim() === ""))
      ) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    // Fill basicData with formData
    const basicData = {
      SessionID: formData.AppointmentID,
      InvoiceNumber: formData.InvoiceID,
      TotalAmount: total,
      Currency: "CHF",
      InvoiceFile: null,
      SubTotal: formData.Subtotal,
      Tax: formData.Tax,
      ServiceData: updatedServiceData.map((service) => ({
        ServiceID: service.ServiceID,
        ServiceTime: service.Time,
        ServiceCode: service.ServiceCode,
        ServiceName: service.ServiceName,
        Rate: service.Rate.toString(), // Convert Rate to string
        Cost: service.Cost,
        Quantity: parseInt(service.Quantity, 10) || 1,
      })),
    };

    try {
      await dispatch(createInvoice(token, basicData, navigate, msg));
    } catch (error) {
      console.error("Error creating invoice:", error);
    }
  };

  useEffect(() => {
    if (invoice) {
      const invoiceID = `INV-${String(invoice?.ID).padStart(3, "0")}`;
      setFormData({
        AppointmentID: invoice?.ID,
        InvoiceID: invoiceID,
        PatientName: invoice?.Name,
        Services: invoice?.AppointmentType,
        Address: [
          invoice?.Address,
          `${invoice?.City} ${invoice?.State}`,
          `${invoice?.Country} - ${invoice?.PinCode}`,
        ]
          .filter(Boolean)
          .join(", "),
        Date: invoice?.CreatedAt,
        DrName: invoice?.RelatedDoctor?.Name,
        AHVNumber: invoice?.AHVNumber,
        DOB: invoice?.DOB,
        Canton: invoice?.State,
        AHUID: invoice?.AHUID,
      });
    }
  }, [invoice]);

  useEffect(() => {
    const fetchServiceEntry = async () => {
      if (token) {
        try {
          const res = await dispatch(getAllServiceRateEntry(token));
          setServiceRateEntry(res);
        } catch (error) {
          console.error("Failed to fetch service rate entries", error);
        }
      }
    };

    fetchServiceEntry();
    setDeleteTempService(false);
  }, [token, dispatch]);

  return (
    <div className="bg-[#F3F3F3] min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      <div className="bg-white rounded-lg shadow-lg p-6 flex flex-row justify-between items-start w-full gap-[10vw]">
        {/* Left Content */}
        <div className="flex flex-col justify-between items-start w-[40%]">
          {/* HEADER */}
          <div className="flex flex-row justify-between items-center">
            {/* TITLE */}
            <div className="flex flex-row items-center space-x-4">
              <button
                className="active:scale-90"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaChevronLeft className="h-5" />
              </button>
              <div>
                <h1 className="text-black text-xl font-bold font-poppins">
                  Add New Invoice
                </h1>
                <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                  Here are form for new invoice
                </h1>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-row justify-between items-center w-full mt-5">
            {/* Left Form */}
            <form className="w-full">
              <div className="grid grid-cols-3 justify-center items-center w-full gap-2">
                {/* Appointment ID */}
                <div className="mb-4">
                  <label>
                    <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                      Appointment ID
                      <sup className="text-red-600">*</sup>
                    </p>
                    <p className="text-[#070808] text-xs font-regular font-poppins mb-2 text-left">
                      # {formData.AppointmentID}
                    </p>
                  </label>
                </div>

                {/* Patient Name */}
                <div className="mb-4">
                  <label>
                    <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                      Patient Name
                      <sup className="text-red-600">*</sup>
                    </p>
                    <p className="text-[#070808] text-xs font-regular font-poppins mb-2 text-left">
                      {formData.PatientName}
                    </p>
                  </label>
                </div>

                {/* Invoice Number */}
                <div className="mb-4">
                  <label>
                    <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                      Invoice Number
                      <sup className="text-red-600">*</sup>
                    </p>
                    <p className="text-[#070808] text-xs font-regular font-poppins mb-2 text-left">
                      {formData.InvoiceID}
                    </p>
                  </label>
                </div>
              </div>

              {/* Consultation Date */}
              <div className="mb-4">
                <label>
                  <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                    Consultation Date
                    <sup className="text-red-600">*</sup>
                  </p>
                  <div className="flex flex-row">
                    <input
                      placeholder="DD/MM/YYYY"
                      disabled={true}
                      onClick={() => {
                        setShowCalendar(!showCalendar);
                      }}
                      value={
                        formData?.Date
                          ? moment(formData?.Date).format("DD/MM/YYYY")
                          : "Select Date"
                      }
                      id="Date"
                      name="Date"
                      className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                        errors.Date ? "border-red-500" : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    ></input>
                    <div className="flex justify-center items-center bg-[#E5F2FF] px-4 rounded-r-lg">
                      <img
                        src={cal_Icon}
                        className="w-12 h-12 object-contain"
                        alt="Calendar Icon"
                      />
                    </div>
                  </div>
                </label>
                {/* {showCalendar && (
                                    <div
                                        style={{}}
                                        className="flex flex-col justify-center items-center"
                                    >
                                        <DayPicker
                                            mode="single"
                                            style={{
                                                "--rdp-accent-color": "#3CA5DC",
                                                "--rdp-cell-size": "40px",
                                            }}
                                            selected={selected}
                                            onSelect={setSelected}
                                            fromDate={
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate()
                                                )
                                            }
                                        />
                                    </div>
                                )} */}
              </div>

              {/* Services */}
              <div className="mb-4">
                <label>
                  <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                    Services
                    <sup className="text-red-600">*</sup>
                  </p>
                  <select
                    id="Services"
                    name="Services"
                    value={formData.Services}
                    onChange={handleChange}
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Services ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                  >
                    <option value="">Select Services</option>
                    <option value="HospitalVisit">Hospital Visit</option>
                    <option value="HomeVisit">Home Visit</option>
                    <option value="InClinicConsultation">
                      In Clinic Consultation
                    </option>
                    <option value="TeleHealth">TeleHealth</option>
                  </select>
                </label>
              </div>

              {/* Search Services */}
              <div className="mb-6 relative">
                <div className="flex flex-row justify-center items-center">
                  <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left w-[40%]">
                    Most Used Services
                  </p>
                  <div className="flex flex-row justify-center items-center bg-[#F3F5F6] rounded-3xl px-5 gap-2 py-2 w-[60%] relative">
                    <IoIosSearch className="text-neutral-400 w-4 h-4" />
                    <input
                      type="text"
                      className="bg-gray-100 text-xs text-neutral-400 w-full focus:outline-none focus:border-transparent"
                      placeholder="Search here"
                      onChange={handleSearchChange}
                      value={searchInput}
                    />
                  </div>
                </div>
                {searchInput && filteredServices?.length > 0 && (
                  <div className="absolute bg-white border border-gray-300 rounded-md mt-1 w-[60%] left-[40%] z-10">
                    {filteredServices.map((service) => (
                      <div
                        key={service.SRCID}
                        onClick={() => handleServiceClick(service)}
                        className={`text-[#3CA5DC] w-full text-xs font-semibold font-poppins bg-[#E5F2FF] px-1 py-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer truncate`}
                      >
                        {`${service.Name} - (${service.ReimbursementCode})`}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex flex-col justify-center items-center gap-4 w-full">
                {/* Service Header */}
                <div className="grid grid-cols-4 justify-center items-start w-full gap-2">
                  <p className="text-[#070808] text-xs font-regular font-poppins text-left">
                    Service Name
                    <sup className="text-red-600">*</sup>
                  </p>

                  <p className="text-[#070808] text-xs font-regular font-poppins text-left">
                    Service Code
                    <sup className="text-red-600">*</sup>
                  </p>

                  <p className="text-[#070808] text-xs font-regular font-poppins text-left">
                    Rate
                    <sup className="text-red-600">*</sup>
                  </p>

                  <p className="text-[#070808] text-xs font-regular font-poppins text-left">
                    Time / QTY
                    <sup className="text-red-600">*</sup>
                  </p>
                </div>

                {/* Previous Service Data */}
                {formData?.ServiceData &&
                  formData.ServiceData.length !== 0 &&
                  formData.ServiceData.map((service, index) => (
                    <div
                      key={service.ServiceID}
                      className="grid grid-cols-4 justify-center items-start w-full gap-2 relative"
                    >
                      {/* Service Name */}
                      <div className="mb-2">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div className="flex flex-row justify-center items-center w-full gap-2">
                            <div
                              className={`flex w-full flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-2 py-1`}
                            >
                              <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins text-wrap truncate">
                                {service.ServiceName}
                              </h1>
                            </div>
                          </div>
                        </label>
                      </div>

                      {/* Service Code */}
                      <div className="mb-2">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div className="flex flex-row justify-center items-center w-full gap-2">
                            <div
                              className={`flex w-full flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-2 py-1`}
                            >
                              <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                                {service.ServiceCode}
                              </h1>
                            </div>
                          </div>
                        </label>
                      </div>

                      {/* Rate */}
                      <div className="mb-2">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div className="flex flex-row justify-center items-center w-full gap-2">
                            <div
                              name="Rate"
                              className={`flex w-full flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-2 py-1`}
                            >
                              <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                                CHF {service.Rate}
                              </h1>
                            </div>
                          </div>
                        </label>
                      </div>

                      {/* Time */}
                      <div className="mb-2">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div className="flex flex-row justify-center items-center w-full gap-2">
                            <div
                              className={`flex w-full flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-2 py-1`}
                            >
                              <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                                {service.Time} Mins
                              </h1>
                            </div>
                          </div>
                        </label>
                      </div>

                      {deleteTempService &&
                      formData?.ServiceData &&
                      index === formData.ServiceData.length - 1 ? (
                        <>
                          {formData.ServiceData.length === 1 ? (
                            <>
                              <div
                                className={`flex flex-row justify-center items-center gap-2 absolute right-[-2rem] top-0`}
                              >
                                {/* If tempService Deleted then Show Add Icon in Last Service Data but no Delete Btn */}
                                <button
                                  onClick={handleAddMoreService}
                                  className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1"
                                >
                                  <FaPlus className="text-[#3CA5DC] text-md" />
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={`flex flex-row justify-center items-center gap-2 absolute right-[-4rem] top-0`}
                              >
                                {/* Delete Icon */}
                                <button
                                  onClick={(e) => {
                                    handleDeleteService(e, service.ServiceID);
                                  }}
                                  className="cursor-pointer flex justify-center items-center bg-[#F9DDDF] rounded-full p-1"
                                >
                                  <RiDeleteBinLine className="text-[#DF535F] text-md" />
                                </button>

                                {/* If tempService Deleted then Show Add Icon in Last Service Data */}
                                <button
                                  onClick={handleAddMoreService}
                                  className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1"
                                >
                                  <FaPlus className="text-[#3CA5DC] text-md" />
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* Delete Icon */}
                          <button
                            onClick={(e) => {
                              handleDeleteService(e, service.ServiceID);
                            }}
                            className="absolute top-0 right-[-2rem] cursor-pointer flex justify-center items-center bg-[#F9DDDF] rounded-full p-1"
                          >
                            <RiDeleteBinLine className="text-[#DF535F] text-md" />
                          </button>
                        </>
                      )}
                    </div>
                  ))}

                {/* Service Data being Added */}
                {!deleteTempService && (
                  <div className="grid grid-cols-4 justify-center items-start w-full gap-2 relative">
                    {/* Service Name */}
                    <div className="mb-2">
                      <label className="flex flex-col justify-start items-start gap-4">
                        <select
                          id="ServiceName"
                          name="ServiceName"
                          value={tempServiceData.ServiceName}
                          onChange={handleServiceChange}
                          className={`text-[#3CA5DC] w-full text-xs font-semibold font-poppins bg-[#E5F2FF] rounded-2xl px-1 py-1 border ${
                            tempServiceErrors.ServiceName
                              ? "border-red-500"
                              : ""
                          } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer truncate`}
                        >
                          <option value="">Select</option>
                          {serviceRateEntry.map((option) => (
                            <option key={option.Name} value={option.Name}>
                              {`${option.Name} - (${option.ReimbursementCode})`}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>

                    {/* Service Code */}
                    <div className="mb-2">
                      <label className="flex flex-col justify-start items-start gap-4">
                        <div className="flex flex-row justify-center items-center w-full gap-2">
                          <div
                            className={`flex w-full flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-2 py-1 cursor-pointer hover:scale-110 duration-500 ${
                              tempServiceErrors.ServiceCode && "border-red-500"
                            }`}
                          >
                            <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                              {tempServiceData.ServiceCode || "-"}
                            </h1>
                          </div>
                        </div>
                      </label>
                    </div>

                    {/* Rate */}
                    <div className="mb-2">
                      <label className="flex flex-col justify-start items-start gap-4">
                        <div className="flex flex-row justify-center items-center w-full gap-2">
                          <div
                            name="Rate"
                            className={`flex w-full flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-2 py-1 cursor-pointer hover:scale-110 duration-500 ${
                              tempServiceErrors.Rate && "border-red-500"
                            }`}
                          >
                            <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                              {tempServiceData.Rate
                                ? `CHF ${tempServiceData.Rate}`
                                : "-"}
                            </h1>
                          </div>
                        </div>
                      </label>
                    </div>

                    {/* Time */}
                    <div className="mb-2">
                      <label className="flex flex-col justify-start items-start gap-4">
                        <div
                          id="Time"
                          name="Time"
                          value={tempServiceData.Time}
                          // onChange={handleTimeChange}
                          className={`flex flex-row justify-center items-center w-full text-[#3CA5DC] text-xs font-semibold font-poppins bg-[#E5F2FF] rounded-2xl px-1 py-1 border ${
                            tempServiceErrors.Time ? "border-red-500" : ""
                          } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                        >
                          <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
                            {tempServiceData.Time
                              ? `${tempServiceData.Time} Mins`
                              : "-"}
                          </h1>
                        </div>
                      </label>
                    </div>

                    <div
                      className={`flex flex-row justify-center items-center gap-2 absolute ${
                        formData?.ServiceData &&
                        formData.ServiceData.length !== 0
                          ? "right-[-4rem]"
                          : "right-[-2rem]"
                      } top-0`}
                    >
                      {/* Delete Service */}
                      {formData?.ServiceData &&
                        formData.ServiceData.length !== 0 && (
                          <button
                            onClick={handleTempDeleteService}
                            className="cursor-pointer flex justify-center items-center bg-[#F9DDDF] rounded-full p-1"
                          >
                            <RiDeleteBinLine className="text-[#DF535F] text-md" />
                          </button>
                        )}

                      {/* Add more Services */}
                      <button
                        onClick={handleAddMoreService}
                        className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1"
                      >
                        <FaPlus className="text-[#3CA5DC] text-md" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>

        {/* Right Content */}
        <div className="py-6 px-1 flex flex-col w-[60%] self-start justify-center items-center">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg shadow-lg py-6 px-8">
            {/* INVOICE HEADER */}
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col justify-center items-start gap-2 w-1/3">
                <h1 className="text-black text-sm font-regular font-poppins">
                  Invoice Number
                </h1>
                {formData.InvoiceID ? (
                  <div className="flex flex-row justify-center items-center gap-2">
                    <h1 className="text-black text-sm font-semibold font-poppins">
                      # {formData.InvoiceID}
                    </h1>
                  </div>
                ) : (
                  <div className="flex flex-row justify-center items-center gap-2">
                    <h1 className="text-black text-md font-regular font-poppins">
                      #
                    </h1>
                    <div className="flex flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                      <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                        Add here
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center items-start gap-2 w-1/3">
                <h1 className="text-black text-sm font-regular font-poppins">
                  Appointment ID
                </h1>
                {formData.AppointmentID ? (
                  <div className="flex flex-row justify-center items-center gap-2">
                    <h1 className="text-black text-sm font-semibold font-poppins">
                      # {formData.AppointmentID}
                    </h1>
                  </div>
                ) : (
                  <div className="flex flex-row justify-center items-center gap-2">
                    <h1 className="text-black text-md font-regular font-poppins">
                      #
                    </h1>
                    <div className="flex flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                      <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                        Add here
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-center items-center w-1/3">
                <img
                  src={
                    logo
                      ? logo
                      : settings?.OrgImage
                      ? settings?.OrgImage
                      : AHlogo
                  }
                  className="w-full h-auto object-contain"
                  alt="Company Logo"
                />
              </div>
            </div>

            <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

            {/* INVOICE CONTENT */}
            <div className="flex flex-col justify-center items-start w-full">
              {/* Name */}
              <div className="grid grid-cols-2 justify-between items-start gap-2 w-full">
                <div className="flex flex-col justify-center items-start mt-2 gap-2 w-full">
                  <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                    Patient Details
                  </h1>
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    {formData.PatientName} <br />
                    {formData.Address &&
                      formData.Address.split(",").map((line, index) => (
                        <span
                          key={index}
                          className="text-black text-md font-semibold font-poppins"
                        >
                          {line.trim()}
                          {index < formData.Address.split(",").length - 1 && (
                            <br />
                          )}
                        </span>
                      ))}
                  </h1>
                </div>

                <div className="flex flex-col justify-center items-start mt-8 gap-1 w-full">
                  <h1 className="text-black text-sm font-semibold font-poppins w-1/2"></h1>
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    DOB: {moment(formData.DOB).format("DD MMM YYYY")}
                  </h1>
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    AHV Nr.: {formData.AHVNumber}
                  </h1>
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    Patient ID: {formData.AHUID}
                  </h1>
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    Canton: {formData.Canton}
                  </h1>
                </div>
              </div>

              <div className="flex flex-row justify-start items-start gap-1 w-1/2 my-6">
                <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                  Doctor Name:
                </h1>
                <h1 className="text-black text-sm font-regular font-poppins text-left">
                  Dr. {formData?.DrName}
                </h1>
              </div>

              <div className="grid grid-cols-2 justify-between items-start gap-2 w-full">
                <div className="flex flex-row justify-start items-start gap-2 w-full mt-2">
                  <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                    Consultation Date:
                  </h1>
                  <h1 className="text-black text-sm font-regular font-poppins text-left">
                    {moment(formData?.Date).format("DD/MM/YYYY")}
                  </h1>
                </div>

                <div className="flex flex-row justify-start items-start gap-1 w-full mt-2">
                  <h1 className="text-black text-sm font-semibold font-poppins w-1/2">
                    Invoice Date:
                  </h1>
                  <h1 className="text-black text-sm font-regular font-poppins text-left">
                    {moment(new Date()).format("DD/MM/YYYY")}
                  </h1>
                </div>
              </div>

              <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

              {/* Table header */}
              <div className="grid grid-cols-5 gap-2 justify-center items-center w-full mt-1">
                <h1 className="text-black text-xs font-semibold font-poppins">
                  Service Code
                </h1>
                <h1 className="text-black text-xs font-semibold font-poppins">
                  Service Name
                </h1>
                <h1 className="text-black text-xs font-semibold font-poppins">
                  Time / QTY
                </h1>
                <h1 className="text-black text-xs font-semibold font-poppins">
                  Rate
                </h1>
                <h1 className="text-black text-xs font-semibold font-poppins">
                  Cost
                </h1>
              </div>

              {/* Table Content */}
              <div className="flex flex-col justify-center items-center gap-2 w-full mt-10">
                {/* Previous Service Data */}
                {formData?.ServiceData &&
                  formData.ServiceData.length !== 0 &&
                  formData.ServiceData.map((service) => (
                    <div
                      key={service.ID}
                      className="grid grid-cols-5 gap-2 justify-between items-start w-full mb-2 relative"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                          {service.ServiceCode}
                        </h1>
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                          {service.ServiceName}
                        </h1>
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                          {service.Time} Mins
                        </h1>
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                          {service.Rate} CHF
                        </h1>
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                          {service.Cost} CHF
                        </h1>
                      </div>
                    </div>
                  ))}

                {/* Service Data being Added */}
                <div className="grid grid-cols-5 gap-2 justify-between items-center w-full mb-8">
                  <div className="flex flex-row justify-start items-center">
                    {tempServiceData.ServiceCode ? (
                      <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                        {tempServiceData.ServiceCode}
                      </h1>
                    ) : (
                      <div className="flex w-full flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                        <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                          +
                        </h1>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {tempServiceData.ServiceName ? (
                      <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins text-wrap truncate">
                        {tempServiceData.ServiceName}
                      </h1>
                    ) : (
                      <div className="flex w-full flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                        <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                          +
                        </h1>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {tempServiceData.Time ? (
                      <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                        {tempServiceData.Time} Mins
                      </h1>
                    ) : (
                      <div className="flex w-full flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                        <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                          +
                        </h1>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {tempServiceData.Rate ? (
                      <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                        {tempServiceData.Rate} CHF
                      </h1>
                    ) : (
                      <div className="flex w-full flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                        <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                          +
                        </h1>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {tempServiceData.Cost ? (
                      <h1 className="text-[#8A8A8A] text-sm font-regular font-poppins">
                        {tempServiceData.Cost} CHF
                      </h1>
                    ) : (
                      <div className="flex w-full flex-row justify-center items-center bg-[#F3F5F6] border-2 border-dashed border-[#8A8A8A] rounded-lg px-4 py-2">
                        <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                          +
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

              <div className="flex flex-col justify-center items-center w-full mb-[10vh]">
                <div className="flex flex-row self-end justify-center items-center w-1/2">
                  <h1 className="text-black text-xs font-regular font-poppins w-[60%]">
                    Subtotal
                  </h1>
                  <h1 className="text-black text-sm font-regular font-poppins w-[40%] text-right">
                    CHF {formData.Subtotal}
                  </h1>
                </div>
                <div className="flex flex-row self-end justify-center items-center w-1/2 mt-1">
                  <h1 className="text-black text-xs font-regular font-poppins w-[60%]">
                    Tax
                  </h1>
                  <h1 className="text-black text-sm font-regular font-poppins w-[40%] text-right">
                    CHF {formData.Tax}
                  </h1>
                </div>
                <div className="flex flex-row self-end justify-center items-center w-1/2 mt-7">
                  <h1 className="text-black text-sm font-bold font-poppins w-[60%]">
                    Total
                  </h1>
                  <h1 className="text-black text-sm font-bold font-poppins w-[40%] text-right">
                    CHF {formData.Total}
                  </h1>
                </div>
              </div>

              {/* <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

                            <h1 className="text-black text-xs font-regular font-poppins w-[60%]">
                                Tax
                            </h1> */}
            </div>
          </div>

          {/* Btns */}
          <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
            <button
              style={{ backgroundColor: "#3CA5DC" }}
              className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 hover:bg-sky-500 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Send to Patient
            </button>
            <button
              style={{ backgroundColor: "#3CA5DC" }}
              className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 hover:bg-sky-500 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300"
              onClick={(event) => {
                let msg = "Invoice will be sent to Insurance Company";
                submitHandler(event, msg);
              }}
            >
              Send to Insurance Company
            </button>

            {/* Send Invoice Modal */}
            <ModalComponent
              show={showModal}
              handleClose={() => setShowModal(false)}
              outSideModalPress={false}
            >
              <div className="bg-[#FFFFFF] p-4">
                {/* Widget HEADER */}
                <div className="flex flex-row justify-between items-center">
                  {/* TITLE */}
                  <h1 className="text-black text-lg font-bold font-poppins rounded-lg">
                    Send Invoice With
                  </h1>
                </div>

                <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                {/* Send Invoice Content */}
                <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
                  <div
                    onClick={(event) => {
                      let msg = "Invoice will be sent to Patient via Post";
                      submitHandler(event, msg);
                    }}
                    className="py-10 flex flex-col justify-center items-center flex-1 bg-[#E5F2FF] rounded-lg cursor-pointer hover:scale-105 duration-500 relative overflow-hidden"
                  >
                    <div className="flex flex-col justify-center items-center w-[25%] h-auto z-50">
                      <img
                        src={postInvoiceIcon}
                        className="w-full h-full object-contain"
                        alt="Tile Icon"
                      />
                    </div>
                    <h1 className="text-[#3CA5DC] text-lg font-bold font-poppins mt-2">
                      Post
                    </h1>
                  </div>
                  <div
                    onClick={(event) => {
                      let msg = "Invoice will be sent to Patient via Email";
                      submitHandler(event, msg);
                    }}
                    className="py-10 flex flex-col justify-center items-center flex-1 bg-[#E5F2FF] rounded-lg cursor-pointer hover:scale-105 duration-500 relative overflow-hidden"
                  >
                    <div className="flex flex-col justify-center items-center w-[25%] h-auto z-50">
                      <img
                        src={mailInvoiceIcon}
                        className="w-full h-full object-contain"
                        alt="Tile Icon"
                      />
                    </div>
                    <h1 className="text-[#3CA5DC] text-lg font-bold font-poppins mt-2">
                      Email
                    </h1>
                  </div>
                </div>
              </div>
            </ModalComponent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddInvoice;
