import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    MeetingProvider,
    useMeeting,
    useParticipant,
} from "@videosdk.live/react-sdk";
import expandVid from "../../assets/Images/TelehealthDashboard/expandVid.png";
import settingIcon from "../../assets/Images/TelehealthDashboard/settingIcon.png";
import { useNavigate } from "react-router-dom";
import ParticipantView from "./ParticipantView"
import { setShowMeetingView } from "../../slices/dashboardSlice";
import { useDispatch } from "react-redux";

export default function MeetingView(props) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        DiseaseType: "",
        PersonalNotes: "",
        DiagnosisInformation: "",
    });
    const [isAudioDone, setIsAudioDone] = useState(false);

    const navigate = useNavigate();
    const [joined, setJoined] = useState(null);
    const [showSettings, setShowSettings] = useState(false);

    //Creating two react states to store list of available cameras and selected camera
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState("");

    //Creating two react states to store list of available mics and selected microphone
    const [mics, setMics] = useState([]);
    const [selectedMic, setSelectedMic] = useState("");

    //Getting the methods to change and get the microphone
    const { getMics, changeMic } = useMeeting();

    //Getting the methods to change and get the camera
    const { getWebcams, changeWebcam } = useMeeting();

    //Method to get the cameras and load in our state
    const handleGetWebcams = async () => {
        const webcams = await getWebcams();
        setCameras(webcams);
    };

    //Changing the camera with the selected deviceId
    const handleChangeCamera = (event) => {
        changeWebcam(event.target.value);
        setSelectedCamera(event.target.value);
    };

    //Method to get the mics and load in our state
    const handleGetMics = async () => {
        const mics = await getMics();
        setMics(mics);
    };

    const handleFormDataSave = (data) => {
        setFormData(data);
    };

    //Changing the mic with the selected deviceId
    const handleChangeMic = (event) => {
        changeMic(event.target.value);
        setSelectedMic(event.target.value);
    };

    const { join, leave, disableWebcam, muteMic, participants, localParticipant } = useMeeting({
        onMeetingJoined: () => {
            setJoined("JOINED");
            handleGetWebcams();
            handleGetMics();
        },
        //callback for when meeting is left
        onMeetingLeft: () => {
            disableWebcam();
            muteMic();
            leave();
            props.onExpand(false);
            dispatch(setShowMeetingView(false));
            setJoined(null);
        },
    });

    const joinMeeting = () => {
        setJoined("JOINING");
        join();
    };

    useEffect(() => {
        setJoined("JOINING");
        join();

        const handleRouteChange = () => {
            if (document.hidden) {
                disableWebcam();
                muteMic();
                leave();
                dispatch(setShowMeetingView(false));
                props.onExpand(false);
                setJoined(null);
            }
        };

        window.addEventListener('visibilitychange', handleRouteChange);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('visibilitychange', handleRouteChange);
            disableWebcam();
            muteMic();
            leave();
            props.onExpand(false);
            setJoined(null);
        };
    }, []);

    return (
        <div className="container">
            {joined && joined === "JOINED" ? (
                <div className="flex flex-col justify-between w-full h-auto items-center gap-4 rounded-xl bg-[#F3F3F3] relative">
                    {participants.size === 1 ? (
                        <>
                            {[...participants.keys()].map((participantId) => (
                                <div className={`rounded-lg ${participantId === localParticipant.id ? "w-full relative" : "absolute right-3 top-3 w-[30%]"}`}>
                                    <ParticipantView
                                        participantId={participantId}
                                        key={participantId}
                                        isLocal={participantId === localParticipant.id}
                                        className="rounded-lg"
                                        controls={true}
                                        onExpand={props.onExpand}
                                        patient={props.patient}
                                        isExpanded={props.isExpanded}
                                        formData={formData}
                                        isAudioDone={isAudioDone}
                                        style={{ borderRadius: '20%' }}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {[...participants.keys()].map((participantId) => (
                                <div className={`rounded-lg ${participantId === localParticipant.id ? "absolute right-3 top-3 w-[30%] z-50" : "w-full relative z-10"}`}>
                                    <ParticipantView
                                        participantId={participantId}
                                        key={participantId}
                                        isLocal={participantId === localParticipant.id}
                                        className="rounded-lg"
                                        controls={false}
                                        onExpand={props.onExpand}
                                        patient={props.patient}
                                        isExpanded={props.isExpanded}
                                        onSaveFormData={handleFormDataSave}
                                        setIsAudioDone={setIsAudioDone}
                                        style={{ borderRadius: '20%' }}
                                    />
                                </div>
                            ))}
                        </>
                    )}
                    <div className="absolute bottom-2 right-4 z-50">
                        <button onClick={() => { setShowSettings(!showSettings); }} className={`flex justify-center items-center w-[45px] h-[45px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500`}>
                            <img
                                src={settingIcon}
                                alt="Setting icon"
                                className="w-full h-auto rounded-full shadow-xl"
                            />
                        </button>
                    </div>
                    {showSettings && (
                        <div className="absolute bottom-[-3.5vh] w-full flex flex-row justify-center items-center z-50 gap-2">
                            <select value={selectedCamera} onChange={handleChangeCamera} className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md px-4 py-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}>
                                {cameras?.map((camera) => {
                                    return <option value={camera.deviceId}>{camera.label}</option>;
                                })}
                            </select>
                            <select value={selectedMic} onChange={handleChangeMic} className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md px-4 py-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}>
                                {mics?.map((mic) => {
                                    return <option value={mic.deviceId}>{mic.label}</option>;
                                })}
                            </select>
                        </div>
                    )}
                </div>
            ) : joined && joined === "JOINING" ? (
                <div className="flex flex-col justify-center w-full h-auto items-center gap-4 relative rounded-xl bg-[#F3F3F3] py-2">
                    <p className="font-poppins text-sm text-semibold text-[#3CA5DC]">Joining the meeting...</p>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}