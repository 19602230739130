import React, { useState } from "react";
import { IoIosAdd } from "react-icons/io";
import TitleWithAddButton from "./TitleWithAddButton";
import ModalComponent from "./Modal";
import toast from "react-hot-toast";
import moment from "moment";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFamilyMember } from "../services/operations/patientManagementAPI";

function FamilyMember({ familyMembers, patient }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Gender: "Male",
    DateofBirth: "",
    RelationWithUser: "",
  });
  const [errors, setErrors] = useState({
    Name: "",
    Gender: "",
    DateofBirth: "",
    RelationWithUser: "",
  });

  const submitHandler = async (event) => {
    event.preventDefault();

    const mandatoryFields = [
      "Name",
      "Gender",
      "DateofBirth",
      "RelationWithUser",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    let body = {
      AHUID: patient.AHUID,
      FirstName: formData.Name.split(" ")[0],
      LastName: formData.Name.split(" ")[1],
      DOB: moment(formData.DateofBirth).format("YYYY-MM-DD"),
      Gender: formData.Gender,
      RelationWithUser: formData.RelationWithUser,
    };
    dispatch(addFamilyMember(token, body, navigate, patient));
    handleCloseModal();
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    if (name === "DateofBirth" && new Date(value) > new Date()) {
      toast.error("Date of Birth cannot be in the future");
      setFormData((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      Name: "",
      Gender: "Male",
      DateofBirth: "",
      RelationWithUser: "",
    });
    setErrors({
      Name: "",
      Gender: "",
      DateofBirth: "",
      RelationWithUser: "",
    });
  };

  if (familyMembers?.length === 0) {
    return (
      <div>
        <TitleWithAddButton
          title="Family Member"
          showAddButton={true}
          onPress={() => setShowModal(true)}
        />
        <ModalComponent
          show={showModal}
          handleClose={handleCloseModal}
          outSideModalPress={false}
        >
          <div className="w-96 ">
            <div className="text-black text-lg font-bold font-poppins mb-4">
              Create New Family Member
            </div>
            <div className="border border-b-1 border-gray-200 mb-6"></div>
            <div className="px-4 max-h-[calc(100vh-300px)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              {/* Name  */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Name
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="Name"
                    maxLength={20}
                    value={formData.Name}
                    onChange={handleOnChange}
                    placeholder="Input name here"
                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Name ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Date of Birth */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Date of Birth
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="date"
                    name="DateofBirth"
                    value={moment(formData.DateofBirth).format("YYYY-MM-DD")}
                    placeholder="Select Date of Birth"
                    onChange={handleOnChange}
                    max={moment().format("YYYY-MM-DD")}
                    className={`w-full accent-sky-500 text-sky-500 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.DateofBirth ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Gender */}
              <div className="mb-4 mx-20 ml-0">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Gender
                  <sup className="text-red-600">*</sup>
                </p>
                <div className="flex space-x-2">
                  <button
                    className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                      formData.Gender === "Male"
                        ? "border-sky-100 text-white bg-sky-500"
                        : "border-sky-500 text-sky-500  bg-white"
                    }`}
                    onClick={() => setFormData({ ...formData, Gender: "Male" })}
                  >
                    <IoMaleSharp />
                    <div className="text-sm font-medium font-poppins">Male</div>
                  </button>
                  <button
                    className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                      formData.Gender === "Female"
                        ? "border-sky-100 text-white bg-rose-500"
                        : "border-rose-500 text-rose-500  bg-white"
                    }`}
                    onClick={() => {
                      setFormData({ ...formData, Gender: "Female" });
                      console.log({ ...formData, Gender: "Female" });
                    }}
                  >
                    <IoFemaleSharp />
                    <div className="text-sm font-semibold font-poppins">
                      Female
                    </div>
                  </button>
                  <button
                    className={`w-full flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-4 border ${
                      formData.Gender === "Diverse"
                        ? "border-sky-100 text-white bg-lime-500"
                        : "border-lime-500  text-lime-500  bg-white"
                    }`}
                    onClick={() =>
                      setFormData({ ...formData, Gender: "Diverse" })
                    }
                  >
                    <LiaTransgenderSolid />
                    <div className="text-sm font-semibold font-poppins">
                      Diverse
                    </div>
                  </button>
                </div>
              </div>
              {/* RelationWithUser */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                    Relation to patient
                    <sup className="text-red-600">*</sup>
                  </p>
                  <select
                    id="RelationWithUser"
                    name="RelationWithUser"
                    value={formData.RelationWithUser}
                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                      errors.RelationWithUser ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    onChange={handleOnChange}
                  >
                    <option value="">Select Relation</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Mother">Mother</option>
                    <option value="Father">Father</option>
                    <option value="Daughter-In-Law">Daughter-In-Law</option>
                    <option value="Granddaughter">Granddaughter</option>
                    <option value="Grandson">Grandson</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Sister-In-Law">Sister-In-Law</option>
                    <option value="Brother-In-Law">Brother-In-Law</option>
                    <option value="Father-In-Law">Father-In-Law</option>
                    <option value="Mother-In-Law">Mother-In-Law</option>
                    <option value="Son-In-Law">Son-In-Law</option>
                  </select>
                </label>
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex items-center justify-center">
              <button
                onClick={submitHandler}
                className="w-2/3 mx-auto bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80 active:scale-95"
              >
                Finish
              </button>
            </div>
          </div>
        </ModalComponent>
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }
  return (
    <div>
      <TitleWithAddButton
        title="Family Member"
        showAddButton={true}
        onPress={() => setShowModal(true)}
      />
      <ModalComponent
        show={showModal}
        handleClose={handleCloseModal}
        outSideModalPress={false}
      >
        <div className="w-96">
          <div className="text-black text-lg font-bold font-poppins mb-4">
            Create New Family Member
          </div>
          <div className="border border-b-1 border-gray-200 mb-6"></div>
          {/* Name  */}
          <div className="mb-4">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Name
                <sup className="text-red-600">*</sup>
              </p>
              <input
                type="text"
                name="Name"
                maxLength={20}
                value={formData.Name}
                onChange={handleOnChange}
                placeholder="Input name here"
                className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  errors.Name ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Date of Birth */}
          <div className="mb-4">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Date of Birth
                <sup className="text-red-600">*</sup>
              </p>
              <input
                type="date"
                name="DateofBirth"
                value={moment(formData.DateofBirth).format("YYYY-MM-DD")}
                placeholder="Select Date of Birth"
                onChange={handleOnChange}
                // style={{
                //   WebkitAppearance: "none",
                //   MozAppearance: "none",
                //   appearance: "none",
                // }}
                max={moment().format("YYYY-MM-DD")}
                className={`w-full accent-sky-500 text-sky-500 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  errors.DateofBirth ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Gender */}
          <div className="mb-4 mx-20 ml-0">
            <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
              Gender
              <sup className="text-red-600">*</sup>
            </p>
            <div className="flex space-x-2">
              <button
                className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                  formData.Gender === "Male"
                    ? "border-sky-100 text-white bg-sky-500"
                    : "border-sky-500 text-sky-500  bg-white"
                }`}
                onClick={() => setFormData({ ...formData, Gender: "Male" })}
              >
                <IoMaleSharp />
                <div className="text-sm font-medium font-poppins">Male</div>
              </button>
              <button
                className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                  formData.Gender === "Female"
                    ? "border-sky-100 text-white bg-rose-500"
                    : "border-rose-500 text-rose-500  bg-white"
                }`}
                onClick={() => setFormData({ ...formData, Gender: "Female" })}
              >
                <IoFemaleSharp />
                <div className="text-sm font-semibold font-poppins">Female</div>
              </button>
              <button
                className={`w-full flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-4 border ${
                  formData.Gender === "Diverse"
                    ? "border-sky-100 text-white bg-lime-500"
                    : "border-lime-500  text-lime-500  bg-white"
                }`}
                onClick={() => setFormData({ ...formData, Gender: "Diverse" })}
              >
                <LiaTransgenderSolid />
                <div className="text-sm font-semibold font-poppins">
                  Diverse
                </div>
              </button>
            </div>
          </div>
          {/* RelationWithUser */}
          <div className="mb-4">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Relation to user
                <sup className="text-red-600">*</sup>
              </p>
              <select
                id="RelationWithUser"
                name="RelationWithUser"
                value={formData.RelationWithUser}
                className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${
                  errors.RelationWithUser ? "border-red-500" : ""
                } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                onChange={handleOnChange}
              >
                <option value="">Select Relation</option>
                <option value="Spouse">Spouse</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Daughter-In-Law">Daughter-In-Law</option>
                <option value="Granddaughter">Granddaughter</option>
                <option value="Grandson">Grandson</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Sister-In-Law">Sister-In-Law</option>
                <option value="Brother-In-Law">Brother-In-Law</option>
                <option value="Father-In-Law">Father-In-Law</option>
                <option value="Mother-In-Law">Mother-In-Law</option>
                <option value="Son-In-Law">Son-In-Law</option>
              </select>
            </label>
          </div>

          {/* Submit Button */}
          <div className="flex items-center justify-center">
            <button
              onClick={submitHandler}
              className="w-2/3 mx-auto bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80 active:scale-95"
            >
              Finish
            </button>
          </div>
        </div>
      </ModalComponent>
      <div className="flex flex-row items-center flex-wrap gap-4 mt-4">
        {familyMembers.map((item) => (
          <button
            key={item.id}
            className="flex flex-col items-center px-2 py-4 bg-white rounded-xl shadow border border-gray-200 "
            onClick={() => {
              navigate("/patientDashboard/patientProfile", { state: item });
            }}
          >
            <img
              src={item.Image}
              className="w-12 h-12 object-contain rounded-full"
              alt=""
            />
            <div className="text-center text-black text-base font-semibold font-poppins mt-2">
              {item.FirstName + " " + item.LastName}
            </div>
            <div className="flex items-center justify-center space-x-1 mt-1">
              <div className="text-zinc-500 text-base font-normal font-poppins">
                Relation:
              </div>
              <div className="text-black text-base font-semibold font-poppins">
                Daughter
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default FamilyMember;
