import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import PhoneInput from "react-phone-input-2";
import { MultiSelect } from "react-multi-select-component";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import register from "../assets/Images/MasterDataManagement/register.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addNewDoctor,
  updateDoctor,
  uploadOrganizationLogo,
} from "../services/operations/masterDataAPI";
import imageCompression from "browser-image-compression";
import uploadProfile from "../assets/Images/MasterDataManagement/uploadImage.png";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { FaChevronLeft } from "react-icons/fa6";
function AddNewDoctor() {
  const { user } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const doctor = location.state || {};
  const weekdays = [
    // "Monday",
    // "Tuesday",
    // "Wednesday",
    // "Thursday",
    // "Friday",
    // "Saturday",
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
    "SUN",
  ];
  const [timeIntervals, setTimeIntervals] = useState({
    MON: [{ Available_From: "", Available_Until: "" }],
    TUE: [{ Available_From: "", Available_Until: "" }],
    WED: [{ Available_From: "", Available_Until: "" }],
    THU: [{ Available_From: "", Available_Until: "" }],
    FRI: [{ Available_From: "", Available_Until: "" }],
    SAT: [{ Available_From: "", Available_Until: "" }],
    SUN: [{ Available_From: "", Available_Until: "" }],
  });
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [image, setImage] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isViewing, setIsViewing] = useState(false);
  const { token } = useSelector((state) => state.auth);

  console.log("====================================");
  console.log("Doctor", doctor);
  console.log("====================================");

  const [formData, setFormData] = useState({
    ImageLocation: "",
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DateofJoining: "",
    PhoneNumber: "",
    Email: "",
    Speciality: "",
    HoursPerWeek: totalWorkingHours,
    Experience: "",
    // SpokenLanguage: [],
  });
  const [errors, setErrors] = useState({
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DateofJoining: "",
    PhoneNumber: "",
    Email: "",
    Speciality: "",
    HoursPerWeek: "",
    Experience: "",
    // SpokenLanguage: "",
  });
  const options = [
    { label: "General Physician", value: 1 },
    { label: "Pediatrician", value: 2 },
    { label: "Gynaecologist", value: 3 },
    { label: "Psychologist", value: 4 },
    { label: "Dermatologist", value: 5 },
  ];

  const submitHandler = async (event) => {
    // event.preventDefault();
    const mandatoryFields = [
      "ImageLocation",
      "FirstName",
      "LastName",
      "Gender",
      "DateofJoining",
      "PhoneNumber",
      "Email",
      "Experience",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    if (selected.length === 0) {
      toast.error("Please select a speciality");
      return;
    }

    if (totalWorkingHours === 0) {
      toast.error("Please select working hours");
      return;
    }
    if (selectedLanguages.length === 0) {
      toast.error("Please select a language");
      return;
    }

    function convertTo24HourFormat(time) {
      if (!time) return "";
      const match = time.match(/(\d+):(\d+) (\w+)/);
      if (!match) return ""; // Return empty if the time format is incorrect
      let hours = parseInt(match[1], 10);
      const minutes = match[2];
      const meridian = match[3];

      if (meridian.toUpperCase() === "PM" && hours < 12) {
        hours += 12;
      }
      if (meridian.toUpperCase() === "AM" && hours === 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, "0")}:${minutes}:00`;
    }

    const body = {
      Name: formData.FirstName + " " + formData.LastName,
      Gender: formData.Gender,
      Email: formData.Email,
      PhoneNumber: formData.PhoneNumber,
      ORGID: user.ORGID,
      Speciality: selected.map((item) => item.value),
      PreferredLanguage: selectedLanguages,
      JoiningDate: moment(formData.DateofJoining).format("YYYY-MM-DD"),
      ProfileImageUrl: formData.ImageLocation,
      WorkingHours: totalWorkingHours,
      Experience: formData.Experience,
      Consultations: 0,
      Availability: Object.keys(timeIntervals).reduce((acc, day) => {
        acc[day] = timeIntervals[day].map((interval) => {
          const availableFrom = convertTo24HourFormat(interval.Available_From);
          const availableUntil = convertTo24HourFormat(
            interval.Available_Until
          );
          // Determine if the interval is available based on the times
          const isAvailable =
            availableFrom === "" || availableUntil === "" ? 0 : 1;
          return {
            Available_From: availableFrom,
            Available_Until: availableUntil,
            IsAvailable: isAvailable,
          };
        });
        return acc;
      }, {}),
    };

    console.log("====================================");
    console.log("Doctor Body", body);
    console.log("====================================");

    if (doctor.DID) {
      dispatch(updateDoctor(token, doctor.DID, body, navigate));
    } else {
      dispatch(addNewDoctor(token, body, navigate));
    }
  };

  function calculateTotalWorkingHours(timeIntervals) {
    let totalHours = 0;

    Object.values(timeIntervals).forEach((dayIntervals) => {
      dayIntervals.forEach(({ Available_From, Available_Until }) => {
        if (Available_From && Available_Until) {
          const [startHour, startMinute, startMeridiem] = Available_From.match(
            /(\d+):(\d+)\s*(AM|PM)?/
          ).slice(1);
          const [endHour, endMinute, endMeridiem] = Available_Until.match(
            /(\d+):(\d+)\s*(AM|PM)?/
          ).slice(1);

          let startInMinutes =
            (parseInt(startHour) % 12) * 60 + parseInt(startMinute);
          if (startMeridiem === "PM") startInMinutes += 720;

          let endInMinutes =
            (parseInt(endHour) % 12) * 60 + parseInt(endMinute);
          if (endMeridiem === "PM") endInMinutes += 720;

          // Adjust for cases where the end time is "12:00 AM" which is the start of a new day
          if (endMeridiem === "AM" && parseInt(endHour) === 12) {
            endInMinutes = 0;
          }

          totalHours += (endInMinutes - startInMinutes) / 60;
        }
      });
    });

    return totalHours;
  }

  const handleLanguageChange = (event) => {
    const { value, checked } = event.target;
    const languageId = parseInt(value, 10); // Convert value back to number
    if (checked) {
      setSelectedLanguages((prev) => [...prev, languageId]);
    } else {
      setSelectedLanguages((prev) =>
        prev.filter((langId) => langId !== languageId)
      );
    }
  };

  const handleAddTimeInterval = (day) => {
    const updatedIntervals = {
      ...timeIntervals,
      [day]: [
        ...timeIntervals[day],
        { Available_From: "", Available_Until: "" },
      ],
    };
    setTimeIntervals(updatedIntervals);
  };

  const handleRemoveTimeInterval = (day, index) => {
    const updatedDayIntervals = timeIntervals[day].filter(
      (_, i) => i !== index
    );
    setTimeIntervals({ ...timeIntervals, [day]: updatedDayIntervals });
  };

  const handleStartTimeChange = (day, index, value) => {
    const updatedDayIntervals = [...timeIntervals[day]];
    updatedDayIntervals[index].Available_From = value;
    setTimeIntervals({ ...timeIntervals, [day]: updatedDayIntervals });
  };

  const handleEndTimeChange = (day, index, value) => {
    const updatedDayIntervals = [...timeIntervals[day]];
    updatedDayIntervals[index].Available_Until = value;
    setTimeIntervals({ ...timeIntervals, [day]: updatedDayIntervals });
  };

  const timeOptions = [...Array(24 * 2)].map((_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? "00" : "30";
    const time = `${hour % 12 === 0 ? 12 : hour % 12}:${minute} ${
      hour < 12 ? "AM" : "PM"
    }`;
    return (
      <option key={time} value={time}>
        {time}
      </option>
    );
  });

  function handleOnChange(event) {
    const { name, value } = event.target;
    const fieldMaxLengths = {
      Experience: 3,
    };
    const maxLength = fieldMaxLengths[name] || Infinity;

    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        ImageLocation: res?.ProfileImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        ImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const totalHours = calculateTotalWorkingHours(timeIntervals);
    setTotalWorkingHours(totalHours);
  }, [timeIntervals]);

  useEffect(() => {
    if (doctor.DID) {
      setImage(doctor.Image);
      setFormData({
        ImageLocation: doctor.ProfileImageUrl,
        FirstName: doctor.Name.split(" ")[0],
        LastName: doctor.Name.split(" ")[1],
        Gender: doctor.Gender,
        DateofJoining: doctor.JoiningDate,
        PhoneNumber: doctor.Phone,
        Email: doctor.Email,
        Speciality: doctor.RelatedSpecialities[0]?.SID,
        Experience: doctor.Experience,
      });

      setSelected(
        doctor.RelatedSpecialities.map((spec) => ({
          value: spec.SID,
          label: spec.Speciality,
        }))
      );
      setSelectedLanguages(
        doctor.RelatedLanguages.map((lang) => lang.LanguageID)
      );

      const formatTime = (timeString) => {
        if (!timeString || timeString === "") return "";
        const [hours, minutes] = timeString.split(":");
        const hour = parseInt(hours, 10);
        const isPM = hour >= 12;
        const formattedHour = ((hour + 11) % 12) + 1;
        return `${formattedHour}:${minutes} ${isPM ? "PM" : "AM"}`;
      };

      const updatedTimeIntervals = {};
      weekdays.forEach((day) => {
        if (doctor.AvailabilityTimes[day]) {
          updatedTimeIntervals[day] = [
            {
              Available_From: formatTime(
                doctor.AvailabilityTimes[day].Available_From
              ),
              Available_Until: formatTime(
                doctor.AvailabilityTimes[day].Available_Until
              ),
            },
          ];
        } else {
          updatedTimeIntervals[day] = [
            { Available_From: "", Available_Until: "" },
          ];
        }
      });

      setTimeIntervals(updatedTimeIntervals);
    }
  }, [doctor]);

  useEffect(() => {
    if (doctor.isViewing) setIsViewing(true);
  }, [doctor]);

  return (
    <div className="bg-[#F3F3F3] py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="bg-white rounded-lg shadow-lg p-6 relative">
        {/* Header Section */}
        <div className="flex flex-row items-center justify-between">
          {!isViewing ? (
            <>
              <div className="flex flex-row items-center space-x-4">
                <button
                  className="active:scale-90"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaChevronLeft className="h-5" />
                </button>

                <div>
                  <h1 className="text-black text-lg font-bold font-poppins">
                    {doctor.DID ? "Edit Doctor" : " Add New Doctors"}
                  </h1>
                  <p className="text-zinc-500 text-xs font-normal font-poppins">
                    Here is the form to add a new doctor.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-row items-center space-x-4">
              <button
                className="active:scale-90"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaChevronLeft className="h-5" />
              </button>

              <div>
                <h1 className="text-black text-lg font-bold font-poppins">
                  {doctor.Name}
                </h1>
                <p className="text-zinc-500 text-xs font-normal font-poppins">
                  ID : {doctor.DID}
                </p>
              </div>
            </div>
          )}
          {isViewing && (
            <button
              onClick={() => setIsViewing(false)}
              className="bg-sky-500 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins flex flex-row items-center justify-center px-5 py-2"
            >
              <FiEdit className="mr-1 w-4 h-4" />
              Edit
            </button>
          )}
        </div>
        {/* form */}
        <div className="flex flex-row p-6 space-x-10">
          <div className="w-1/3">
            {/* Profile Picture */}
            <div className="">
              <label
                className={` ${
                  errors.ImageLocation
                    ? "border-dashed border-red-500 border-2"
                    : ""
                } rounded-lg p-4  flex flex-col items-center justify-center`}
              >
                {formData?.ImageLocation ? (
                  <>
                    <div className="w-20 h-20 shadow-lg rounded-full shadow-neutral-400">
                      <img
                        src={image}
                        alt="upload icon"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    {!isViewing && (
                      <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex cursor-pointer mt-3">
                        <p className="text-sky-400 text-xs font-semibold font-open">
                          Change Image
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <img
                      src={uploadProfile}
                      alt=""
                      className="w-20 h-20 object-contain cursor-pointer"
                    />
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="ImageLocation"
                  disabled={isViewing}
                  value={undefined}
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>

            {/* First Name  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  First Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="FirstName"
                  maxLength={20}
                  value={formData.FirstName}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input first name here"
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.FirstName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Last Name  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Last Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="LastName"
                  maxLength={20}
                  value={formData.LastName}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input last name here"
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.LastName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Date of Joining */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Date of Joining
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="date"
                  name="DateofJoining"
                  value={moment(formData.DateofJoining).format("YYYY-MM-DD")}
                  placeholder="Select Date of Joining"
                  onChange={handleOnChange}
                  disabled={isViewing}
                  min={moment().format("YYYY-MM-DD")}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } accent-sky-500 text-sky-500 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.DateofJoining ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Gender */}
            <div className="mb-6">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                Gender
                <sup className="text-red-600">*</sup>
              </p>
              <div className="flex space-x-2">
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Male"
                      ? "border-sky-100 text-white bg-sky-500"
                      : "border-sky-500 text-sky-500  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Male" })}
                >
                  <IoMaleSharp />
                  <div className="text-sm font-medium font-poppins">Male</div>
                </button>
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Female"
                      ? "border-sky-100 text-white bg-rose-500"
                      : "border-rose-500 text-rose-500  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Female" })}
                >
                  <IoFemaleSharp />
                  <div className="text-sm font-semibold font-poppins">Female</div>
                </button>
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-4 border ${
                    formData.Gender === "Diverse"
                      ? "border-sky-100 text-white bg-lime-500"
                      : "border-lime-500  text-lime-500  bg-white"
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, Gender: "Diverse" })
                  }
                >
                  <LiaTransgenderSolid />
                  <div className="text-sm font-semibold font-poppins">
                    Diverse
                  </div>
                </button>
              </div>
            </div>

            {/* Phone Number */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  disabled={isViewing}
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins rounded-lg p-4 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>

            {/* Email */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Email"
                  // maxLength={40}
                  value={formData.Email}
                  onChange={handleOnChange}
                  disabled={isViewing || doctor?.DID}
                  placeholder="example@yourmail.com"
                  className={`w-full ${
                    (isViewing || doctor?.DID) && "cursor-not-allowed"
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {!isViewing && (
              <div className="flex flex-row items-center justify-center space-x-8 mt-10">
                <button
                  onClick={submitHandler}
                  className="w-52 h-11 shadow-md shadow-sky-400 bg-sky-400 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins "
                >
                  {doctor.DID ? "Update" : "Finish"}
                </button>
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="w-52 h-11 shadow-md shadow-neutral-400 bg-neutral-400 rounded-lg  active:scale-95 active:shadow-none  text-center text-stone-50 text-sm font-semibold font-poppins "
                >
                  Back
                </button>
              </div>
            )}
          </div>
          <div className="w-2/3 max-w-lg">
            {/* Doctor's Speciality */}
            <div className="mb-6 w-2/3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                  Doctor's Speciality
                  <sup className="text-red-600">*</sup>
                </p>
                {!isViewing && (
                  <MultiSelect
                    disabled={isViewing}
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select Speciality"
                    className="w-full"
                  />
                )}
                {/* <Select
                  isMulti
                  name="colors"
                  options={options}
                  // className="basic-multi-select"
                  // classNamePrefix="select"
                  style={{}}
                /> */}
              </label>

              {selected.length !== 0 && (
                <div className="w-full flex flex-row items-center flex-wrap gap-2 mt-4">
                  {selected.map((item) => (
                    <div
                      key={item.value}
                      className="px-4 py-1 bg-sky-100 rounded-2xl"
                    >
                      <div className="text-sky-500 text-xs font-normal">
                        {item.label}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* Experience  */}
            <div className="mb-6 w-2/3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Doctor's Experience
                  <sup className="text-red-600">*</sup>
                </p>
              </label>
              <div
                className={` ${
                  isViewing && "cursor-not-allowed"
                } flex items-center border rounded-md ${
                  errors.Experience ? "border-red-500" : ""
                } focus:outline-none focus:border-transparent`}
              >
                <input
                  type="number"
                  name="Experience"
                  maxLength={3}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.Experience}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="2.5"
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-l-md p-4  focus:outline-none focus:border-transparent`}
                />
                <div className="bg-sky-100 rounded-r-md p-4 border-none">
                  <div className="w-10 text-sky-400 text-xs font-normal font-poppins">
                    Year(s)
                  </div>
                </div>
              </div>
            </div>
            {/* Working Hours */}
            <div className="mb-6">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Working Hours<sup className="text-red-600">*</sup>
              </p>
              {weekdays.map((day) => (
                <div key={day} className="flex flex-1 items-start mb-4">
                  <div className="w-28 text-zinc-500 text-xs font-semibold font-poppins">
                    {day}
                  </div>
                  <div className="flex flex-col space-y-3.5">
                    {timeIntervals[day].map((interval, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row items-center space-x-6"
                        >
                          <select
                            value={interval.Available_From}
                            disabled={isViewing}
                            onChange={(e) =>
                              handleStartTimeChange(day, index, e.target.value)
                            }
                            className={`w-28 ${
                              isViewing && "cursor-not-allowed"
                            } text-xs text-center font-semibold text-sky-500 bg-sky-100 font-poppins rounded-2xl p-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent truncate`}
                          >
                            <option className="text-center" value="">
                              -
                            </option>
                            {timeOptions}
                          </select>
                          <div className="text-zinc-500 text-xs font-normal font-poppins">
                            To
                          </div>
                          <select
                            value={interval.Available_Until}
                            disabled={isViewing}
                            onChange={(e) =>
                              handleEndTimeChange(day, index, e.target.value)
                            }
                            className={`w-28 ${
                              isViewing && "cursor-not-allowed"
                            } text-xs text-center font-semibold text-sky-500 bg-sky-100 font-poppins rounded-2xl p-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent truncate`}
                          >
                            <option className="text-center" value="">
                              -
                            </option>
                            {timeOptions}
                          </select>
                          {/* {index === timeIntervals[day].length - 1 ? (
                            <>
                              <button
                                onClick={() => handleAddTimeInterval(day)}
                                className="bg-sky-100 active:scale-90 w-6 h-6 rounded-full flex items-center justify-center"
                              >
                                <IoMdAdd className="text-sky-500" />
                              </button>
                              {timeIntervals[day].length > 1 ? (
                                <button
                                  onClick={() =>
                                    handleRemoveTimeInterval(day, index)
                                  }
                                  className="bg-red-100 active:scale-90 w-6 h-6 rounded-full flex items-center justify-center ml-2"
                                >
                                  <IoMdRemove className="text-red-500" />
                                </button>
                              ) : null}
                            </>
                          ) : null}
                          {index < timeIntervals[day].length - 1 ? (
                            <button
                              onClick={() =>
                                handleRemoveTimeInterval(day, index)
                              }
                              className="bg-red-100 active:scale-90 w-6 h-6 rounded-full flex items-center justify-center ml-2"
                            >
                              <IoMdRemove className="text-red-500" />
                            </button>
                          ) : null} */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
            {/* Hours Per Week  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Hours Per Week (Auto Calculated)
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="text"
                  name="HoursPerWeek"
                  maxLength={3}
                  value={totalWorkingHours}
                  onChange={handleOnChange}
                  disabled={true}
                  placeholder="Auto Calculated"
                  className={`w-1/4 cursor-not-allowed text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.HoursPerWeek ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Spoken Languages */}
            <div className="mb-6 w-1/3">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Spoken Languages<sup className="text-red-600">*</sup>
              </p>
              <div className="grid grid-cols-2 gap-3">
                {[
                  {
                    LanguageID: 1,
                    Name: "English",
                  },
                  {
                    LanguageID: 2,
                    Name: "Spanish",
                  },
                  {
                    LanguageID: 3,
                    Name: "French",
                  },
                  {
                    LanguageID: 4,
                    Name: "Hindi",
                  },
                ].map((language) => (
                  <div key={language.LanguageID} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`language-${language.LanguageID}`}
                      name="spokenLanguages"
                      value={language.LanguageID}
                      disabled={isViewing}
                      className="w-4 h-4 text-sky-500 bg-gray-100 rounded border-gray-300"
                      onChange={handleLanguageChange}
                      checked={selectedLanguages.includes(language.LanguageID)}
                    />
                    <label
                      htmlFor={`language-${language.LanguageID}`}
                      className="ml-2 text-black text-xs font-normal font-poppins"
                    >
                      {language.Name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-10 right-8">
          <img src={register} alt="" className="w-72 h-72 object-contain" />
        </div>
      </div>
    </div>
  );
}

export default AddNewDoctor;
