import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import editIcon from "../assets/Images/MasterDataManagement/editIcon.png";
import deleteIcon from "../assets/Images/MasterDataManagement/deleteIcon.png";
import ModalComponent from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteNurse } from "../services/operations/masterDataAPI";

function NurseListView({ nurses }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleDelete = async () => {
    dispatch(deleteNurse(token, selectedDoctor.DID));
    setShowDeleteModal(false);
  };
  return (
    <div className="mt-10">
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        outSideModalPress={false}
      >
        <div className="">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center text-black text-xl font-bold font-poppins">
              Are you Sure?
            </div>
            <div className=" text-center text-sky-400 text-xl font-semibold font-poppins mt-8">
              This will delete Dr. {selectedDoctor?.Name} from <br /> the
              Doctors List from your Clinic.
            </div>
            <div className="flex flex-row items-center justify-center space-x-7 mt-8 w-full">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-white rounded-2xl border border-sky-400 w-1/3 py-2 shadow-2xl active:scale-95"
              >
                <div className="text-center text-sky-400 text-xl font-bold font-poppins">
                  Back
                </div>
              </button>
              <button
                onClick={() => {
                  handleDelete();
                }}
                className="bg-white rounded-2xl border border-rose-500 w-1/3 py-2 shadow-2xl active:scale-95"
              >
                <div className="text-center text-rose-500 text-xl font-bold font-poppins">
                  Delete
                </div>
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
      {nurses.map((n, index) => (
        <>
          <div key={index} className="flex flex-row items-center">
            <div className="flex flex-row items-center mb-6 justify-between flex-1">
              <div
                className="flex flex-row items-center space-x-3 cursor-pointer"
                onClick={() => {
                  navigate("/addNewNurse", {
                    state: { ...n, isViewing: true },
                  });
                }}
              >
                <img
                  src={n.Image}
                  alt="PatientPic"
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex flex-col w-32">
                  <div className="text-black text-sm font-semibold font-poppins">
                    {n.Name}
                  </div>
                  <div className="text-zinc-500 text-xs font-normal font-poppins">
                    ID : {n.DID}
                  </div>
                </div>
                <div className="border border-r-0.5 border-gray-100 h-10"></div>
              </div>
              <div className="flex flex-col w-32">
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Join Date
                </div>
                <div className=" text-black text-xs font-semibold font-poppins">
                  {moment(n.JoiningDate).format("DD MMMM YYYY")}
                </div>
              </div>
              <div className="flex flex-col w-40">
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Speciality
                </div>
                <div className=" text-black text-xs font-semibold font-poppins">
                  {n?.RelatedSpecialities[0]?.Speciality}
                </div>
              </div>
              <div className="border border-r-0.5 border-gray-100 h-10"></div>
              <div className="flex flex-col items-center space-y-2.5">
                <button
                  className="text-sky-400 text-xs font-semibold font-poppins underline w-32"
                  onClick={() => {
                    navigate("/addNewNurse", {
                      state: { ...n, isViewing: true },
                    });
                  }}
                >
                  See Details
                </button>

                <div className="flex flex-row items-center space-x-3">
                  <button
                    onClick={() => {
                      navigate("/addNewNurse", { state: n });
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-5 h-5 object-contain"
                    />
                  </button>
                  <button
                    onClick={() => {
                      setSelectedDoctor(n);
                      setShowDeleteModal(true);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      className="w-5 h-5 object-contain"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {index < nurses.length - 1 && (
            <div className="border border-b-0.5 border-gray-100 mb-5"></div>
          )}
        </>
      ))}
    </div>
  );
}

export default NurseListView;
