import moment from "moment";
import React from "react";
import addAppointment from "../assets/Images/PatientDashboard/addAppointmentIcon.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
function PatientCardView({ patient }) {
  const navigate = useNavigate();
  return (
    <div className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {patient.map((p) => (
        <div
          key={p.AHUID}
          className="flex flex-col p-4 bg-neutral-50 rounded-lg shadow-md"
        >
          <div className="flex items-center justify-between mb-4">
            <div
              className="flex items-center space-x-3 cursor-pointer"
              onClick={() => {
                navigate("patientProfile", { state: p });
              }}
            >
              <img
                src={p.Image}
                alt="PatientPic"
                className="w-10 h-10 rounded-full"
              />
              <div className="flex flex-col">
                <div className="text-black text-sm font-semibold font-poppins">
                  {p.Name}
                </div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  No : {p.AHUID}
                </div>
              </div>
            </div>
            <button
              className="text-sky-400 text-xs font-semibold font-poppins underline"
              onClick={() => {
                navigate("managePatient", { state: p });
              }}
            >
              Manage
            </button>
          </div>
          <div className="border border-b-0.5 border-gray-100 mb-5"></div>
          <div className="flex justify-between text-xs mb-4">
            <div className="text-zinc-500 font-normal font-poppins">
              Date of Birth
            </div>
            <div className="text-black font-semibold font-poppins">
              {moment(p.DOB).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="flex justify-between text-xs mb-4">
            <div className="text-zinc-500 font-normal font-poppins">
              Phone Number
            </div>
            <div className="text-black font-semibold font-poppins">
              {p.Phone.startsWith("+") ? p.Phone : "+" + p.Phone}
            </div>
          </div>
          <div className="flex justify-between text-xs mb-4 space-x-2">
            <div className="text-zinc-500 font-normal font-poppins">Email</div>
            <div className="text-black font-semibold font-poppins overflow-hidden overflow-ellipsis">
              {p.Email}
            </div>
          </div>
          <div className="flex justify-between text-xs mb-5">
            <div className="text-zinc-500 font-normal font-poppins">
              Last Activity
            </div>
            <div className="text-black font-semibold font-poppins">
              {moment(p.LastActivity).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="border border-b-0.5 border-gray-100 mb-5"></div>
          <button
            className="flex flex-row items-center justify-center space-x-2"
            onClick={() => {
              // if (p.TotalSession === 0) {
              //   toast.error("No sessions left for this patient.");
              // } else {
              navigate("bookAppointment", { state: p });
              // }
            }}
          >
            <img
              src={addAppointment}
              alt=""
              className="w-6 h-6 object-contain"
            />
            <div className="text-sky-500 text-xs font-semibold font-poppins underline">
              Add New Appointment
            </div>
          </button>
        </div>
      ))}
    </div>
  );
}

export default PatientCardView;
