import React from "react";
import { BsArrowsAngleExpand } from "react-icons/bs";

function FooterWithExpandButton({ onPress }) {
  return (
    <div className="flex items-center justify-end mt-2">
      <button
        onClick={onPress}
        className="flex items justify-center gap-2 px-4 py-2.5 bg-white rounded-lg shadow border border-neutral-300 group"
      >
        <BsArrowsAngleExpand className="w-6 h-6 text-sky-500 group-active:scale-110" />
      </button>
    </div>
  );
}

export default FooterWithExpandButton;
