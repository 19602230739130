import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractLine } from "react-icons/ri";
import sortIcon from "../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import ModalComponent from "../components/Modal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addServicesCardRate,
  deleteLabPartners,
  deleteServicesCardRate,
  getLabTypes,
  updateServicesCardRate,
} from "../services/operations/masterDataAPI";
import editIcon from "../assets/Images/MasterDataManagement/editIcon.png";
import deleteIcon from "../assets/Images/MasterDataManagement/deleteIcon.png";
import { HiOutlineDotsVertical } from "react-icons/hi";
import noLabPartnerFound from "../assets/Images/LabPartner/noLabPartnerFound.png";

function LabPartners() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allLabPartners, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [labPartners, setLabPartners] = useState(allLabPartners);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLabPartner, setSelectedLabPartner] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredServices = allLabPartners.filter((service) =>
    service.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const increaseItems = () => {
    setItemsToShow(itemsToShow * 2);
  };

  const decreaseItems = () => {
    setItemsToShow(itemsToShow / 2);
  };

  const handleDeleteService = () => {
    dispatch(deleteLabPartners(token, selectedLabPartner.LPID));
    setSelectedLabPartner(null);
    setShowDeleteModal(false);
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (labPartners.length === 0) {
    return (
      <div className="py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
        <div className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center h-full p-20">
          <img
            src={noLabPartnerFound}
            alt=""
            className="w-[533px] h-72 object-contain"
          />
          <div className="text-black text-xl font-bold font-Inter">
            Add Lab Partners to view them on this screen
          </div>
          <button
            className="w-1/2 mx-auto bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80"
            onClick={() => {
              navigate("/addLabPartner");
            }}
          >
            Add Lab Partners
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 scroll-smooth min-h-full">
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        outSideModalPress={false}
      >
        <div className="">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center text-black text-xl font-bold font-poppins">
              Are you Sure?
            </div>
            <div className=" text-center text-sky-400 text-xl font-semibold font-poppins mt-8">
              This will delete {selectedLabPartner?.Name} from <br /> from Lab
              Partners List
            </div>
            <div className="flex flex-row items-center justify-center space-x-7 mt-8 w-full">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-white rounded-2xl border border-sky-400 w-1/3 py-2 shadow-2xl active:scale-95"
              >
                <div className="text-center text-sky-400 text-xl font-bold font-poppins">
                  Back
                </div>
              </button>
              <button
                onClick={() => handleDeleteService()}
                className="bg-white rounded-2xl border border-rose-500 w-1/3 py-2 shadow-2xl active:scale-95"
              >
                <div className="text-center text-rose-500 text-xl font-bold font-poppins">
                  Delete
                </div>
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-4">
          <button
            className="active:scale-90"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaChevronLeft className="h-5" />
          </button>
          <div>
            <h1 className="text-black text-lg font-bold font-poppins">
              Lab Partners
            </h1>
            <p className="text-zinc-500 text-xs font-normal font-poppins">
              Here are list of Lab Partners
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-3">
          <label className=" w-64 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
            <IoIosSearch className="text-neutral-400 w-4 h-4" />
            <input
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearchChange}
              className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
            />
          </label>
          <button
            onClick={() => {
              navigate("/addLabPartner");
            }}
            className="px-3 py-2 bg-sky-500 rounded shadow justify-center items-center gap-2.5 flex cursor-pointer active:scale-95 duration-200"
          >
            <FaPlus className="text-white text-sm" />
            <div className="text-white text-sm font-normal font-poppins">
              Add New Lab Partner
            </div>
          </button>
        </div>
      </div>
      <div className="rounded-2xl shadow-lg p-5 mt-8">
        <div className="grid grid-cols-[1fr,1fr,1fr,2fr,2fr] gap-4">
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Lab Name
            </div>
            <img src={sortIcon} alt="" className="w-6 h-6" />
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Address
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Phone Number
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Type of Lab
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Status
            </div>
            <img src={sortIcon} alt="" className="w-6 h-6" />
          </div>
        </div>
        <div className="border border-b-0.5 border-gray-200 my-6"></div>
        <div className="space-y-9 flex flex-col divide-y-2 divide-gray-100 ">
          {filteredServices.slice(0, itemsToShow).map((item) => (
            <div
              key={item.LPID}
              className="grid grid-cols-[1fr,1fr,1fr,2fr,2fr] gap-4 items-center py-4"
            >
              <div
                className="flex flex-col space-y-1 cursor-pointer"
                onClick={() => {
                  navigate("/addLabPartner", {
                    state: { ...item, isViewing: true },
                  });
                }}
              >
                <div className="text-black text-sm font-semibold font-poppins">
                  {item.Name}
                </div>
                <div className="text-zinc-500 text-xs font-normal font-poppins">
                  Lab ID : {item.LPID}
                </div>
              </div>
              <div className="text-black text-sm font-normal font-poppins">
                {item?.Street +
                  ", " +
                  item?.City +
                  ", " +
                  item?.State +
                  ", " +
                  item?.Pincode +
                  ", " +
                  item?.Country}
              </div>
              <div className="w-28 text-black text-sm font-normal font-poppins">
                {item?.Phone}
              </div>
              <div className="">
                <div className="flex flex-row flex-wrap gap-2">
                  {item?.LabTypes?.map((item) => (
                    <div
                      key={item?.LTID}
                      className="px-2.5 py-1 bg-sky-100 rounded-xl"
                    >
                      <div className="text-sky-400 text-sm font-semibold font-poppins">
                        {item?.Name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className={`w-32 p-2 ${
                    item?.Status === "Active"
                      ? "bg-lime-100 text-lime-500"
                      : item?.Status === "Pending"
                      ? "bg-neutral-300 text-zinc-500"
                      : "bg-red-200 text-rose-500"
                  } rounded-lg`}
                >
                  {/* type: DataTypes.ENUM('Active', 'Inactive', 'Pending', 'Deactive'), */}
                  <div className="text-center  text-xs font-semibold font-poppins">
                    {item?.Status}
                  </div>
                </div>
                {item.Status !== "Pending" ? (
                  <button
                    className="text-sky-400 text-xs font-semibold font-poppins underline w-32"
                    // onClick={() => {
                    //   navigate("/addNewNurse", {
                    //     state: { ...n, isViewing: true },
                    //   });
                    // }}
                  >
                    Resend Invitation
                  </button>
                ) : (
                  <div className="flex flex-col items-center space-y-2.5">
                    <button
                      className="text-sky-400 text-xs font-semibold font-poppins underline w-32"
                      onClick={() => {
                        navigate("/addLabPartner", {
                          state: { ...item, isViewing: true },
                        });
                      }}
                    >
                      See Details
                    </button>

                    <div className="flex flex-row items-center space-x-3">
                      <button
                      onClick={() => {
                        navigate("/addLabPartner", {
                          state: { ...item, isViewing: false },
                        });
                      }}
                      >
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-5 h-5 object-contain"
                        />
                      </button>
                      <button
                        onClick={() => {
                          setSelectedLabPartner(item);
                          setShowDeleteModal(true);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="deleteIcon"
                          className="w-5 h-5 object-contain"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="border border-b-0.5 border-gray-200 my-6"></div>
        <footer className="flex flex-row items-center justify-between">
          <div className="text-zinc-500 text-sm font-normal font-poppins">
            Showing {Math.min(itemsToShow, labPartners.length)} items per page
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                itemsToShow < labPartners.length
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={increaseItems}
              disabled={itemsToShow >= labPartners.length}
            >
              <IoMdAdd />
            </button>
            <div className="text-center text-black text-xs font-normal font-poppins bg-gray-100 rounded w-12 h-10 flex items-center justify-center ">
              1 - {Math.min(itemsToShow, labPartners.length)}
            </div>
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                itemsToShow > 3
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={decreaseItems}
              disabled={itemsToShow <= 3}
            >
              <RiSubtractLine />
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default LabPartners;
