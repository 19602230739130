import React from "react";
import TitleWithAddButton from "./TitleWithAddButton";
import alleryImage from "../assets/Images/PatientProfile/allergyImage.png";

function Allergies({ allergies }) {
  if (allergies?.length === 0 || !allergies) {
    return (
      <div>
        <TitleWithAddButton title="Allergies" showAddButton={true} />
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }
  return (
    <div>
      <TitleWithAddButton title="Allergies" showAddButton={true} />
      <div className="flex flex-row items-center flex-wrap gap-10 mt-4">
        {allergies?.map((item) => (
          <button key={item.APPID} className="flex flex-col items-center">
            <div>
              <img
                src={alleryImage}
                className="w-14 h-14 object-contain rounded-full"
                alt=""
              />
            </div>
            <div className=" text-center text-black text-xs font-normal font-poppins mt-2">
              {item.Name}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Allergies;
