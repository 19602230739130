import React, { useState } from "react";
import RadioIcon from "./RadioIcon";

const NotificationSettings = () => {
  const [appointmentNotification, setAppointmentNotification] =
    useState("immediately");
  const [callbackNotification, setCallbackNotification] =
    useState("immediately");

  return (
    <div className="p-6 rounded-lg shadow-md max-w-3xl mx-auto mt-10">
      {/* <div className="text-slate-800 text-lg font-semibold font-poppins leading-snug tracking-tight">
        Notifications Settings
      </div> */}
      <div className="">
        <h2 className="text-xl font-semibold mb-4">
          Appointment Notifications
        </h2>
        <p className="text-gray-600 mb-4">
          Trigger notification to health managers for booked appointments
        </p>

        <div className="space-y-2 mb-6">
          {[
            "immediately",
            "3 hours after booking",
            "6 hours after booking",
          ].map((option) => (
            <label key={option} className="flex items-center cursor-pointer">
              <input
                type="radio"
                className="hidden"
                checked={appointmentNotification === option}
                onChange={() => setAppointmentNotification(option)}
              />
              <RadioIcon selected={appointmentNotification === option} />
              <span className="text-gray-700">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </span>
            </label>
          ))}
        </div>

        <h2 className="text-xl font-semibold mb-4">
          Callback Request Notifications
        </h2>
        <p className="text-gray-600 mb-4">
          Trigger notification to health managers for callback requests
        </p>

        <div className="space-y-2 mb-6">
          {[
            "immediately",
            "3 hours after booking",
            "6 hours after booking",
          ].map((option) => (
            <label key={option} className="flex items-center cursor-pointer">
              <input
                type="radio"
                className="hidden"
                checked={callbackNotification === option}
                onChange={() => setCallbackNotification(option)}
              />
              <RadioIcon selected={callbackNotification === option} />
              <span className="text-gray-700">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </span>
            </label>
          ))}
        </div>

        {/* <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300">
        Save
      </button> */}
      </div>
    </div>
  );
};

export default NotificationSettings;
