import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { FaList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PatientListView from "../components/PatientListView";
import PatientCardView from "../components/PatientCardView";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";
import { deletePatientAppointment, getPatientProfile, getPatientsUpcomingTelehealthSession } from "../services/operations/patientManagementAPI";
import UserDetails from "../components/UserDetails";
import videoCallIcon from "../assets/Images/BookAppointment/videoCallIcon.png";
import inPersonIcon from "../assets/Images/BookAppointment/inPersonIcon.png";
import SuccessCancel from "../assets/Images/PatientDashboard/SuccessCancel.png";
import ModalComponent from "../components/Modal";
import RadioButton from "../components/RadioButton/RadioButton";
import toast from "react-hot-toast";
import moment from "moment";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";
import Breadcrumb from "../components/Breadcrumb";

function ManagePatient() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let patient;
  if(location.state.patient){
    patient = location?.state?.patient || {};
  } else {
    patient = location.state || {};
  }
  const [upcomingAppointment, setUpcomingAppointment] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [cancellationCharge, setCancellationCharge] = useState("50");
  const [charge, setCharge] = useState("50");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessCancelModal, setShowSuccessCancelModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { doctorDetails } = useSelector((state) => state.profile);
  const {
    loading: patientLoading,
    patientProfile,
  } = useSelector((state) => state.patient);

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const handleConfirm = () => {
    if (selectedAppointment) {
      setShowCancelModal(false);
      deleteAppointment();
    }
    else {
      toast.error("Please Select an Appointment")
    }
  };

  const handleBack = () => {
    setShowCancelModal(false);
  };

  const fetchPatientProfile = () => {
    let body = {
      AHUID: patient?.AHUID,
    };
    dispatch(getPatientProfile(token, body));
  };

  const deleteAppointment = async () => {
    let body = {
      "SessionID": selectedAppointment?.SessionID,
      "AHUID": selectedAppointment?.AHUID,
      "CancellationReason": ""
    };

    try {
      await dispatch(deletePatientAppointment(token, body));
      fetchPatientProfile();
      fetchUpcomingAppointments();
      toast.success("Appointment Deleted")
    } catch (error) {
      console.error("Failed to delete patient appointment", error);
    }
    setSelectedAppointment(null);
    setShowSuccessCancelModal(true);
  }

  const fetchUpcomingAppointments = async () => {
    let id = patient?.AHUID;
    try {
      const res = await dispatch(getPatientsUpcomingTelehealthSession(token, id));
      setUpcomingAppointment(res);
    } catch (error) {
      setUpcomingAppointment([]);
      console.error("Failed to fetch Patients Upcoming Telehealth Sessions", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchPatientProfile(),
        fetchUpcomingAppointments(),
      ]);
    };

    fetchData();
  }, [patient]);

  if (patientLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-[#F3F3F3] h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Header Section */}
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col items-start gap-4">
            <h1 className="text-black text-xl font-semibold font-poppins leading-3 tracking-wide">
              Manage Patient
            </h1>

            <Breadcrumb currentState ={patient}/>
          </div>
        </div>

        <div className="flex flex-row justify-center items-start space-x-3 mt-10">
          <div className="w-1/2 flex flex-col justify-center items-start">
            {/* Patient Card */}
            <div className="bg-white shadow rounded-lg p-8 w-[85%] flex flex-row items-center justify-start space-x-4">
              <img
                src={patient.Image}
                alt="PatientPic"
                className="w-20 h-20 rounded-full"
              />
              <div className="text-center flex-col justify-center items-center ml-4">
                <div className="text-black text-base font-semibold font-poppins text-left">
                  {patient.Name || patient.FirstName + " " + patient.LastName}
                </div>
                <div className="text-[#1c1c1c] text-xs font-medium mt-1 font-poppins text-left">
                  {patient.Gender}
                </div>
                <div className="text-[#1c1c1c] text-xs font-normal mt-1 font-poppins text-left">
                  DOB : {moment(patient.DOB).format("DD.MM.YYYY")}
                </div>
                <div className="text-zinc-500 text-xs font-normal mt-1 font-poppins text-left">
                  No : {patient.AHUID}
                </div>
                {patient.Age !== null && patient.Age !== undefined ? (
                  <>
                    <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                      {patient.Age} Years Old
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                      {new Date().getFullYear() - new Date(patient.DOB).getFullYear()}{" "}
                      Years Old
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* User Detail */}
            <div className={`flex bg-white shadow rounded-lg p-4 mt-6`}>
              <UserDetails patient={patient} patientProfile={patientProfile} />
            </div>
          </div>

          {/* Patient Appointments */}
          <div className="w-1/2 flex flex-col justify-center items-start">
            <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide mb-5">
              Patient's Appointment(s)
            </h1>

            {!upcomingAppointment || upcomingAppointment?.length === 0 && (
              <div className="flex flex-col justify-center items-center w-full">
                <h1 className="text-[#ACACAC] text-sm font-semibold font-poppins mb-5">
                  No Data
                </h1>
              </div>
            )}

            {upcomingAppointment?.map((appointment) => (
              <div
                key={appointment.SessionID}
                className={`flex flex-col justify-center items-center shadow rounded-lg w-full mb-5 px-10 py-3 cursor-pointer ${selectedAppointment?.SessionID === appointment.SessionID
                  ? "border-2 border-[#3CA5DC] bg-[#E5F2FF]"
                  : "bg-[#FCFCFC]"
                  }`}
                onClick={() => handleAppointmentClick(appointment)}
              >
                <div className="grid grid-cols-3 w-full gap-2">
                  <div className="flex flex-col justify-center items-start gap-1">
                    <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                      Scheduled Time
                    </h1>
                    <h1 className="text-black text-base text-sm font-semibold font-poppins">
                      {appointment.Start_time}
                    </h1>
                  </div>

                  <div className="flex flex-col justify-center items-start gap-1">
                    <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                      Scheduled Date
                    </h1>
                    <h1 className="text-black text-base text-sm font-semibold font-poppins">
                      {moment(appointment.Date).format("DD MMM YYYY")}
                    </h1>
                  </div>

                  <div className="flex flex-row justify-center items-start">
                    {appointment.AppointmentType === "TeleHealth" ? (
                      <div className="flex flex-col justify-center items-center w-[35px] h-[35px] rounded-full bg-[#3CA5DC] p-2">
                        <img
                          src={videoCallIcon}
                          className="w-full h-full object-contain text-white"
                          alt="Video Call Icon"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center w-[35px] h-[35px] rounded-full bg-[#F7A025] p-2">
                        <img
                          src={inPersonIcon}
                          className="w-full h-full object-contain text-white"
                          alt="In person Icon"
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-center items-start gap-1 ml-3">
                      <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                        Type Appointment
                      </h1>
                      <h1 className="text-black text-base text-sm font-semibold font-poppins">
                        {appointment.AppointmentType === "TeleHealth" ? "Video Call" : appointment.AppointmentType}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Btns */}
            {selectedAppointment && (
              <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
                <button
                  style={{ backgroundColor: "#3CA5DC" }}
                  className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 py-3 hover:bg-sky-500 transition duration-200 ease-in active:bg-[#3CA5DC] focus:outline-none focus:ring focus:ring-[#3CA5DC]"
                  onClick={() => {
                    if (selectedAppointment)
                      setShowCancelModal(true);
                    else toast.error("Please Select an Appointment")
                  }}
                >
                  Cancel Appointment
                </button>
                <button
                  style={{ backgroundColor: "#E5F2FF" }}
                  className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-[#3CA5DC] text-sm font-semibold font-poppins p-4 py-3 hover:bg-sky-500 transition duration-200 ease-in active:bg-[#3CA5DC] focus:outline-none focus:ring focus:ring-[#3CA5DC]"
                  onClick={() => {
                    if (selectedAppointment)
                      navigate("reschedulePatient", { state: { selectedAppointment: selectedAppointment, patient: patient } });
                    else
                      toast.error("Please Select an Appointment")
                  }}
                >
                  Reschedule Appointment
                </button>

                {/* Cancel Appointment Modal */}
                <ModalComponent
                  show={showCancelModal}
                  handleClose={() => setShowCancelModal(false)}
                  outSideModalPress={false}
                >
                  <div className="bg-[#FFFFFF] p-4">
                    {/* Widget HEADER */}
                    <div className="flex flex-row justify-center items-center">
                      {/* TITLE */}
                      <h1 className="text-black text-lg font-semibold font-poppins rounded-lg">
                        Are you Sure?
                      </h1>
                    </div>

                    <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                    <div className="flex flex-col justify-center items-center w-full">
                      <h1 className="w-3/4 text-black text-md font-semibold font-poppins rounded-lg text-center">
                        {`This will Cancel your Appointment on ${moment(selectedAppointment?.Date).format("DD MMM YYYY")}, ${selectedAppointment?.Start_time} CET`}
                      </h1>

                      <div className="flex flex-col justify-center items-start w-full my-10">
                        <div className="flex flex-row justify-between items-center w-full mb-5">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="cancellationCharge"
                              value="50"
                              checked={cancellationCharge === charge}
                              onChange={() => setCancellationCharge(charge)}
                              className="mr-2"
                            />
                            <span className="font-poppins">Cancellation Charge</span>
                          </label>
                          <span className="text-[#3CA5DC] font-poppins">
                            {charge} CHF
                          </span>
                        </div>
                        <div className="flex flex-row justify-between items-center w-full">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="cancellationCharge"
                              value="0"
                              checked={cancellationCharge === "0"}
                              onChange={() => setCancellationCharge("0")}
                              className="mr-2"
                            />
                            <span className="font-poppins">Cancellation Charge</span>
                          </label>
                          <span className="text-[#3CA5DC] font-poppins">Free: 0 CHF</span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row justify-between items-center w-full gap-2">
                      <button
                        className="bg-[#EC685D] text-white font-normal text-xs font-poppins py-4 px-2 rounded-lg w-1/2"
                        onClick={handleConfirm}
                      >
                        Confirm & Cancel Appointment
                      </button>
                      <button
                        className="bg-[#D9D9D9] text-black font-normal text-xs font-poppins py-4 px-2 rounded-lg w-1/2"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              </div>
            )}
            {/* Successfull Cancellation Modal */}
            <ModalComponent
              show={showSuccessCancelModal}
              handleClose={() => setShowSuccessCancelModal(false)}
              outSideModalPress={false}
            >
              <div className="bg-[#FFFFFF] p-4">
                {/* Widget HEADER */}
                <div className="flex flex-row justify-center items-center">
                  {/* TITLE */}
                  <h1 className="text-black text-lg font-bold font-poppins rounded-lg">
                    Appointment Cancelled Successfully
                  </h1>
                </div>

                <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                <div className="flex flex-col justify-center items-center mb-4 w-96">
                  <img
                    src={SuccessCancel}
                    alt="Successfull Cancel Image"
                    className="w-72 h-60"
                  />
                </div>

                <div className="flex flex-row justify-between items-center w-full gap-2">
                  <button
                    className="bg-[#3CA5DC] text-white font-normal font-poppins text-xs py-4 px-2 rounded-lg w-1/2"
                    onClick={() => {
                      setShowSuccessCancelModal(false);
                    }}
                  >
                    Issue Invoice
                  </button>
                  <button
                    className="bg-[#D9D9D9] text-black font-normal font-poppins text-xs py-4 px-2 rounded-lg w-1/2"
                    onClick={() => {
                      setShowSuccessCancelModal(false);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </ModalComponent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePatient;

const DATA = [
  {
    id: 0,
    time: '09:00',
    date: '18th July',
    type: 'Video Call',
  },
  {
    id: 1,
    time: '09:00',
    date: '18th July',
    type: 'Video Call',
  },
  {
    id: 2,
    time: '09:00',
    date: '18th July',
    type: 'Video Call',
  },
  {
    id: 3,
    time: '09:00',
    date: '18th July',
    type: 'Video Call',
  },
  {
    id: 4,
    time: '09:00',
    date: '18th July',
    type: 'Video Call',
  },
  {
    id: 5,
    time: '09:00',
    date: '18th July',
    type: 'Video Call',
  },
]