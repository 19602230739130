// This will prevent authenticated users from accessing this route
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { USER_TYPE } from "../utils/constants";

function OpenRoute({ children }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  if (token === null) {
    return children;
  } else {
    if (user?.UserType === USER_TYPE.LAB_PARTNER) {
      return <Navigate to="/labPartnerDashboard" />;
    } else {
      return <Navigate to="/clinicianDashboard" />;
    }
  }
}

export default OpenRoute;
