import { createSlice } from "@reduxjs/toolkit";
import { all } from "axios";
import { set } from "date-fns";

const initialState = {
  allPatients: [],
  patientProfile: [],
  mentalHealth: [],
  familyMembers: [],
  consultationHistory: [],
  medicalCondition: [],
  dosageAdherence: [],
  diagnosisType: [],
  allMedicines: [],
  allPreMedicalConditions: [],
  allergies: [],
  allergiesType: [],
  healthProgram: [],
  healthReports: [],
  healthJourney:[],
  loading: false,
};

const patientSlice = createSlice({
  name: "patient",
  initialState: initialState,
  reducers: {
    setAllPatients(state, action) {
      state.allPatients = action.payload;
    },
    setPatientProfile(state, action) {
      state.patientProfile = action.payload;
    },
    setMentalHealthDetails(state, action) {
      state.mentalHealth = action.payload;
    },
    setFamilyMembers(state, action) {
      state.familyMembers = action.payload;
    },
    setMedicalCondition(state, action) {
      state.medicalCondition = action.payload;
    },
    setConsultationHistory(state, action) {
      state.consultationHistory = action.payload;
    },
    setDosageAdherence(state, action) {
      state.dosageAdherence = action.payload;
    },
    setDiagnosisType(state, action) {
      state.diagnosisType = action.payload;
    },
    setAllMedicines(state, action) {
      state.allMedicines = action.payload;
    },
    setAllPreMedicalConditions(state, action) {
      state.allPreMedicalConditions = action.payload;
    },
    setAllergies(state, action) {
      state.allergies = action.payload;
    },
    setAllergiesType(state, action) {
      state.allergiesType = action.payload;
    },
    setHealthProgram(state, action) {
      state.healthProgram = action.payload;
    },
    setHealthReports(state, action) {
      state.healthReports = action.payload;
    },
    setHealthJourney(state, action) {
      state.healthJourney = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  setAllPatients,
  setPatientProfile,
  setMentalHealthDetails,
  setFamilyMembers,
  setMedicalCondition,
  setConsultationHistory,
  setDosageAdherence,
  setDiagnosisType,
  setAllMedicines,
  setAllPreMedicalConditions,
  setAllergies,
  setAllergiesType,
  setHealthProgram,
  setHealthReports,
  setHealthJourney,
  setLoading,
} = patientSlice.actions;

export default patientSlice.reducer;
