import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    MeetingProvider,
    useMeeting,
    useParticipant,
} from "@videosdk.live/react-sdk";
import { IoMicSharp } from "react-icons/io5";
import { BsCameraFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import ModalComponent from '../Modal';
import { toast } from "react-hot-toast";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNotesToPatientProfile1, addPrescription1 } from "../../services/operations/masterDataAPI";
import { PiWaveformBold } from "react-icons/pi";
import { setShowMeetingView } from "../../slices/dashboardSlice";

export default function Controls(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);
    const { doctorDetails } = useSelector((state) => state.profile);
    const canEndCall = props?.canEndCall;
    const sessID = props?.sessID;
    const notesBody = props?.notesBody;
    const prescriptionBody = props?.prescriptionBody;
    const isExpanded = props?.isExpanded;
    const setShowModal = props?.setShowModal;
    const setShowAudioDictation = props?.setShowAudioDictation;
    const showAudioDictation = props?.showAudioDictation;
    const isAudioDone = props?.isAudioDone;

    const { leave, toggleMic, toggleWebcam, localMicOn, localWebcamOn, end } = useMeeting();

    return (
        <div className={`absolute bottom-2 ${showAudioDictation && !isExpanded ? 'bottom-[3.7rem]' : 'bottom-2'} w-full flex flex-row justify-center items-center gap-2 z-99`}>
            <button onClick={() => toggleMic()} className={`flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500 ${localMicOn ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"}`}>
                <IoMicSharp className={`text-lg ${localMicOn ? "text-[#3CA5DC]" : "text-[#ACACAC]"}`} />
            </button>
            <button onClick={() => toggleWebcam()} className={`flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500 ${localWebcamOn ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"}`}>
                <BsCameraFill className={`text-lg ${localWebcamOn ? "text-[#3CA5DC]" : "text-[#ACACAC]"}`} />
            </button>
            {/* {isExpanded && ( */}
            <button disabled={isAudioDone} onClick={() => { if (!isAudioDone) setShowAudioDictation() }} className={`flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer ${!isAudioDone ? "hover:scale-110 duration-500" : ""} ${isAudioDone ? "bg-[#F3F5F6]" : "bg-[#E5F2FF]"}`}>
                <PiWaveformBold className={`text-lg ${isAudioDone ? "text-[#ACACAC]" : "text-[#3CA5DC]"}`} />
            </button>
            {/* )} */}
            <button onClick={() => {
                if (canEndCall) {
                    if (doctorDetails) {
                        let body = {
                            DID: doctorDetails.DID,
                        };
                        dispatch(addPrescription1(token, prescriptionBody));
                        dispatch(addNotesToPatientProfile1(token, sessID, notesBody, body));
                        props.onExpand(false);
                        dispatch(setShowMeetingView(false));
                        end();
                    }
                }
                else {
                    setShowModal(true);
                    toast.error("Please fill all the required fields");
                }
            }
            } className="flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500 bg-[#DF535F]">
                <ImPhone className="text-lg text-white" />
            </button>
        </div>
    );
}